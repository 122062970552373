import { useApi } from '../../api/useApi'
import { ChangeEvent, useEffect, useState } from 'react'
import { parseError } from '../../api'

const Documents = (): JSX.Element => {
  const [file, setFile] = useState<File>()

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0])
    }
  }

  const handleUploadClick = () => {
    if (!file) {
      return
    }

    const formData = new FormData()
    formData.append('file', file, file.name)

    // 👇 Uploading the file using the fetch API to the server
    fetch('/api/file-test', {
      method: 'POST',
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => console.log(data))
      .catch((err) => console.error(err))
  }

  return <></>

  // return (
  //   <div>
  //     <input type="file" onChange={handleFileChange} />
  //
  //     <div>{file && `${file.name} - ${file.type}`}</div>
  //
  //     <button onClick={handleUploadClick}>Upload</button>
  //   </div>
  // );
}
export { Documents }
