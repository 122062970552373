import { companyRoles, TCompany } from '../types/building'

const companyFilter = (
  company: TCompany,
  companyRole: null | keyof typeof companyRoles | (keyof typeof companyRoles)[]
) => {
  if (companyRole && typeof companyRole === 'string') {
    companyRole = [companyRole]
  }
  return companyRole ? companyRole.includes(company.role) : true
}

export const companiesList = (
  companies: TCompany[],
  companyRole: null | keyof typeof companyRoles | (keyof typeof companyRoles)[] = null
) => {
  return companies
    .filter((company) => companyFilter(company, companyRole))
    .map((company) => {
      return {
        label: company.name,
        description: companyRoles[company.role],
        value: company.id,
      }
    })
}

export const companyName = (companies: TCompany[], id: string) => {
  const company = companies.find((c) => c.id === id)
  if (!company) return ''
  return company.name
}
