import { Link, Outlet, useOutletContext } from 'react-router-dom'
import { Box, Paper, Tab, Tabs } from '@mui/material'
import React from 'react'
import { useRouteTab } from '../../../util/useRouteTab'
import { useAuthorization } from '../../../auth'
import { IOutletBuilding } from '../../../types/outlet-context'

export const BuildingData = () => {
  const currentTab = useRouteTab()
  const { building } = useOutletContext<IOutletBuilding>()
  const authManager = useAuthorization()

  const isCompanyRead = authManager.vote('Company', 'read')
  const isUsersManage = authManager.vote('BuildingUser', 'manage')

  if (!building) {
    return <></>
  }

  return (
    <>
      <Box>
        <Paper square elevation={0}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs variant="scrollable" scrollButtons allowScrollButtonsMobile value={currentTab}>
              <Tab label="Карточка объекта" value="card" to="" component={Link} />
              {isCompanyRead && (
                <Tab label="Компании" value="companies" to="companies" component={Link} />
              )}
              <Tab label="Проектная документация" value="pd" to="pd" component={Link} />
              <Tab label="Рабочая документация" value="rd" to="rd" component={Link} />
              {isUsersManage && (
                <Tab label="Пользователи" value="users" to="users" component={Link} />
              )}
            </Tabs>
          </Box>
        </Paper>
        <Outlet context={{ building }} />
      </Box>
    </>
  )
}
