import React from 'react'
import { PageHead1 } from '../../components/PageHead1'
import BuildingsList from '../../components/building/BuildingsList'
import { AddBuildingDialog } from '../../components/building/form/AddBuildingDialog'
import { BaseContainer } from '../../components/common/Container'

function Home(): JSX.Element {
  return (
    <BaseContainer>
      <PageHead1 text={'Объекты'} rightElement={<AddBuildingDialog />} />
      <BuildingsList />
    </BaseContainer>
  )
}

export default Home
