import { TCompanyLegal } from '../../types/building'
import { Link, Typography } from '@mui/material'
import React, { ReactNode } from 'react'
import Box from '@mui/material/Box'

const TextBlock = ({ label, text }: { label?: string; text: string | ReactNode }) => {
  return (
    <Box sx={{ mb: 2 }}>
      {label && (
        <Typography variant={'caption'} color="text.secondary">
          {label}
        </Typography>
      )}
      <Typography variant={'subtitle1'}>{text}</Typography>
    </Box>
  )
}

export const LegalCard = ({ company }: { company: TCompanyLegal }) => {
  return (
    <>
      {company.name !== company.full_name && (
        <TextBlock label={'Полное наименование'} text={company.full_name} />
      )}
      <Box sx={{ mb: 2 }}>
        <Typography variant={'body2'} color="text.secondary">
          {'ИНН: ' + company.inn + ', ОГРН: ' + company.ogrn}
        </Typography>
      </Box>
      <TextBlock label={'Адрес'} text={company.address.string_address} />
      <TextBlock
        label={'Телефон'}
        text={
          <Link href={'tel:' + company.phone} underline="hover">
            {company.phone}
          </Link>
        }
      />
      <TextBlock
        label={'Почта'}
        text={
          <Link href={'mailto:' + company.email} underline="hover">
            {company.email}
          </Link>
        }
      />
      {company.sro?.name && (
        <TextBlock
          label={'СРО'}
          text={`${company.sro.name} (ИНН/ОГРН: ${company.sro.inn}/${company.sro.ogrn})`}
        />
      )}
    </>
  )
}
