import * as z from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { Box, FormHelperText } from '@mui/material'
import FormInput from '../form/FormInput'
import { MuiFileInput } from 'mui-file-input'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import FormInputFile from '../form/FormInputFile'

const schema = {
  file: z.instanceof(File, { message: 'Необходимо выбрать файл' }),
  number: z.string().min(1, 'Необходимо заполнить'),
  description: z.string().min(1, 'Необходимо заполнить'),
}
const registerSchema = z.object(schema)

interface Props {
  values?: any
  onSubmit: (values: z.TypeOf<typeof registerSchema>, methods: any) => void
  id?: string
}

const PrdChangeForm = ({ values, onSubmit, id }: Props) => {
  const methods = useForm<z.TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: values || {},
  })

  const {
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods

  const hs = handleSubmit((values: any) => {
    onSubmit(values, methods)
  })

  return (
    <FormProvider {...methods}>
      <Box id={id} component="form" onSubmit={hs} noValidate autoComplete="off">
        <FormInput name="number" label="Номер" />
        <FormInput name="description" label="Описание" />

        <FormInputFile
          name={'file'}
          label={'Файл PDF'}
          inputProps={{ accept: 'application/pdf' }}
          placeholder={'Выбрать файл (PDF)...'}
        />
      </Box>
    </FormProvider>
  )
}

export default PrdChangeForm
