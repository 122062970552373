import { cadespluginOnload } from './cades'

const parseName = (subject: Record<string, string>) => {
  if (subject.G && subject.SN) {
    return `${subject.SN} ${subject.G} (${subject.CN})`
  }
  if (subject.GIVENNAME && subject.SURNAME) {
    return `${subject.SURNAME} ${subject.GIVENNAME} (${subject.CN})`
  }
  return subject.CN
}

const infoPairs = (pairs: string[]) => {
  return pairs.reduce((acc: any, str: string) => {
    const d = str.split('=')
    return { ...acc, [d[0]]: d[1] }
  }, {})
}

const parseCertData = (cert: any) => {
  const subjectInfo = cert.subjectInfo
  const subjectInfoPairs = subjectInfo.split(', ')
  const issuerInfo = cert.issuerInfo
  const issuerInfoPairs = issuerInfo.split(', ')
  const subject = infoPairs(subjectInfoPairs)
  const issuer = infoPairs(issuerInfoPairs)
  return {
    thumbprint: cert.thumbprint,
    serialNumber: cert.serialNumber,
    subject: subject,
    name: parseName(subject),
    issuerName: issuer.CN,
    issuer: issuer,
    period: {
      from: new Date(cert.validPeriod.from),
      to: new Date(cert.validPeriod.to),
    },
    inn: subject['ИНН'] || subject.INN || '000000000000',
  }
}

export const certList = async (): Promise<any[]> => {
  // const certsApi = await cadespluginOnload()
  return cadespluginOnload().then(certsApi => {
    return certsApi.getCertsList()
  }).then(certsList => {
    return certsList.map((cert: any) => parseCertData(cert))
  })
}
