import { TBuilding, TEmployee } from '../../../../types/building'
import { RightPanel, SkidPagination, useSkidPagination } from '../../../common'
import {
  Box,
  Button,
  CardHeader,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import * as React from 'react'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGetEmployeesQuery } from '../../../../store/employees'
import { formatDate } from '../../../../util/date'
import { toast } from 'react-toastify'
import { useGetDocumentsQuery, useRemoveDocumentMutation } from '../../../../store/document'
import { documentType } from '../../../../types/document'
import { useAuthorization } from '../../../../auth'
import { useGetUserQuery } from '../../../../store/user'
import { Part7View } from './Part7View'
import { Part7AddDialog } from './Part7AddDialog'
import { StatusChip } from '../../../common/StatusChip'
import { toastError } from '../../../../util/toastError'
import { nextNumber } from '../../../../util/maxNumber'

const signerName = (employees: TEmployee[], employee_id: string): string => {
  return employees.find((em) => em.id === employee_id)?.profile.name.full_name || ''
}

export const Part7List = ({ building }: { building: TBuilding }) => {
  const { journalId } = useParams()
  const { pages, page, offset, limit, onPageChange, setTotal } = useSkidPagination(20)

  const { data, isLoading, isSuccess, refetch } = useGetDocumentsQuery({
    buildingId: building.id,
    params: { type: documentType.GWJ7Item, parent_id: journalId },
  })
  const { data: defectsData, refetch: refetchDefects } = useGetDocumentsQuery({
    buildingId: building.id,
    params: { type: documentType.GWJ7Defect, parent_id: journalId },
  })

  const sorted = useMemo(() => {
    if (!data || data.items.length < 1) return []
    return Array.from(data.items).sort(
      (a, b) => parseInt(a.number || '0') - parseInt(b.number || '0')
    )
  }, [data])

  const refetchAll = () => {
    refetch()
    refetchDefects()
  }

  const { data: employees } = useGetEmployeesQuery(building.id)

  const [removeItem] = useRemoveDocumentMutation()

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [currentId, setCurrentId] = useState<string | undefined>()

  const currentItem = useMemo(() => {
    return data && data.items.find((item) => item.id === currentId)
  }, [data, currentId])

  const currentDefect = useMemo(() => {
    return (
      defectsData &&
      currentItem &&
      currentItem?.over?.defect &&
      defectsData.items.find((d) => d.id === currentItem?.over?.defect[0].over_id)
    )
  }, [defectsData, currentId])

  const authManager = useAuthorization()
  const { data: user } = useGetUserQuery()
  const isCreate = useMemo(() => authManager.vote('Document.GWJ7Item', 'create'), [])
  const isManage = useMemo(() => {
    return (
      (currentItem &&
        user &&
        authManager.vote('Document.GWJ7Item', 'manage', currentItem.owner_id === user.id) &&
        currentItem.status === 'draft') ||
      false
    )
  }, [currentItem, user])

  if (isSuccess) {
    setTotal(data.metadata.total)
  }

  if (isLoading || data === undefined || defectsData === undefined) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    )
  }

  const viewItem = (id: string) => {
    setCurrentId(id)
    setDrawerOpen(true)
  }

  const handleRemove = (id: string | undefined, e: React.MouseEvent) => {
    e.stopPropagation()
    if (id) {
      removeItem({
        buildingId: building.id,
        body: {
          document_id: id,
          type: documentType.GWJ7Item,
        },
      })
        .unwrap()
        .then((res) => {
          refetch()
          setDrawerOpen(false)
          toast.success('Запись удалена')
        })
        .catch(toastError)
    }
  }

  return (
    <>
      <Paper>
        <TableContainer>
          <CardHeader
            title={'Сведения о государственном строительном надзоре'}
            action={
              isCreate && (
                <Part7AddDialog buildingId={building.id} nextNumber={nextNumber(sorted) + ''} />
              )
            }
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>№</TableCell>
                <TableCell>Дата</TableCell>
                <TableCell>Данные о результатах КНМ</TableCell>
                <TableCell>Ответственный</TableCell>
                <TableCell>Статус</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sorted.slice(offset, offset + limit).map((item) => {
                const defect = defectsData.items.find(
                  (d) => item.over.defect && item.over.defect[0].over_id === d.id
                )
                return (
                  <TableRow
                    key={item.id}
                    hover
                    onClick={() => viewItem(item.id)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell>{item.number}</TableCell>
                    <TableCell>{formatDate(item.date)}</TableCell>
                    <TableCell>{defect && defect.data.controlEventResultsInfo}</TableCell>
                    <TableCell>
                      {employees &&
                        item.employees.signer &&
                        signerName(employees, item.employees.signer[0].employee_id)}
                    </TableCell>
                    <TableCell onClick={() => viewItem(item.id)}>
                      <StatusChip status={item.status} />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
          {data.items && data.items.length > 0 && (
            <SkidPagination page={page} pages={pages} onChange={onPageChange} />
          )}
        </TableContainer>
      </Paper>
      <RightPanel
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
        actions={
          <>
            {isManage && (
              <Button
                variant={'outlined'}
                color={'error'}
                onClick={(e) => handleRemove(currentItem?.id, e)}
              >
                Удалить
              </Button>
            )}
          </>
        }
      >
        {currentItem && currentDefect && (
          <Part7View
            building={building}
            document={currentItem}
            defect={currentDefect}
            onChange={refetchAll}
            onReady={refetchAll}
            onSign={refetchAll}
          />
        )}
      </RightPanel>
    </>
  )
}
