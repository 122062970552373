export const additionUnits = (units: string[]): string[] => {
  return [
    ...new Set([
      ...units,
      'шт',
      'м',
      'м.кв',
      'м.куб',
      'пог.м',
      'т',
      'кг',
      'л',
      'уп',
      'бух',
      'лист',
    ]),
  ]
}
