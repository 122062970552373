import { TBuilding } from '../../../types/building'
import { RightPanel, SkidPagination, useSkidPagination } from '../../common'
import { useGetDocumentsQuery, useRemoveDocumentMutation } from '../../../store/document'
import { documentType } from '../../../types/document'
import { useMemo, useState } from 'react'
import { nextNumber } from '../../../util/maxNumber'
import { useAuthorization } from '../../../auth'
import { useGetUserQuery } from '../../../store/user'
import {
  Box, Button,
  CardHeader,
  LinearProgress,
  Paper,
  Table, TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, Typography
} from '@mui/material'
import * as React from 'react'
import { toast } from 'react-toastify'
import { toastError } from '../../../util/toastError'
import { formatDate } from '../../../util/date'
import { StatusChip } from '../../common/StatusChip'
import { ActOSRDialog } from './ActOSRDialog'
import { TableHoverRow } from '../../common/TableHoverRow'
import { ActOSRView } from './ActOSRView'

export const ActsOSRList = ({ building }: { building: TBuilding }) => {

  const { pages, page, offset, limit, onPageChange, setTotal } = useSkidPagination(20)

  const { data, isLoading, isSuccess, refetch } = useGetDocumentsQuery({
    buildingId: building.id,
    params: { type: documentType.ActOSR },
  })

  const filteredItems = useMemo(() => {
    return data ? data.items.slice(offset, offset + limit) : []
  }, [data, offset, limit])


  const [removeItem] = useRemoveDocumentMutation()

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [currentId, setCurrentId] = useState<string | undefined>()

  const currentItem = useMemo(() => {
    return data && data.items.find((item) => item.id === currentId)
  }, [data, currentId])

  const values = {
    sequence_number: data ? nextNumber(data.items, (doc) => doc.data.sequence_number || '0') : {},
  }

  const authManager = useAuthorization()
  const { data: user } = useGetUserQuery()
  const isCreate = useMemo(() => authManager.vote('Document.ActOSR', 'create'), [])
  const isManage = useMemo(() => {
    return (
      (currentItem &&
        user &&
        authManager.vote('Document.ActOSR', 'manage', currentItem.owner_id === user.id) &&
        currentItem.status === 'draft') ||
      false
    )
  }, [currentItem, user])

  if (isSuccess) {
    setTotal(data.metadata.total)
  }

  if (isLoading || data === undefined) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    )
  }

  const viewItem = (id: string) => {
    setCurrentId(id)
    setDrawerOpen(true)
  }

  const handleRemove = (id: string | undefined, e: React.MouseEvent) => {
    e.stopPropagation()
    if (id) {
      removeItem({
        buildingId: building.id,
        body: {
          document_id: id,
          type: documentType.GWJ2Item,
        },
      })
        .unwrap()
        .then((res) => {
          refetch()
          setDrawerOpen(false)
          toast.success('Запись удалена')
        })
        .catch(toastError)
    }
  }

  return (
    <>
      <Paper>
        <TableContainer>
          <CardHeader
            title={'Акты ОСР'}
            action={isCreate && <ActOSRDialog buildingId={building.id} isCreate={true} />}
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Акт</TableCell>
                <TableCell>Статус документа</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredItems.map((item) => {
                return (
                  <TableHoverRow key={item.id}>
                    <TableCell onClick={() => viewItem(item.id)}>
                      <Box>
                        <Typography variant={'body1'}>{item.data.name} {item.number}</Typography>
                      </Box>
                      <Box>
                        <Typography variant={'caption'} color={'grey'}>
                          {formatDate(item.date)}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell onClick={() => viewItem(item.id)}>
                      <StatusChip status={item.status} />
                    </TableCell>
                  </TableHoverRow>
                )
              })}
            </TableBody>
          </Table>
          {data.items && data.items.length > 0 && (
            <SkidPagination page={page} pages={pages} onChange={onPageChange} />
          )}
        </TableContainer>
      </Paper>
      <RightPanel
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
        actions={
          <>
            {isManage && (
              <Button
                variant={'outlined'}
                color={'error'}
                onClick={(e) => handleRemove(currentItem?.id, e)}
              >
                Удалить
              </Button>
            )}
          </>
        }
      >
        {currentItem && (
          <ActOSRView
            document={currentItem}
            isManage={isManage}
            onSign={refetch}
            onReady={refetch}
            onChange={refetch}
          />
        )}
      </RightPanel>
    </>
  )
}
