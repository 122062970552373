import { TBuilding } from '../../../../types/building'
import { Stack } from '@mui/material'
import * as React from 'react'
import { CommonInfo } from './CommonInfo'
import { Representatives } from './Representatives'
import { Sar } from './Sar'
import { BuildingDocuments } from '../../../building/BuildingDocuments'
import { useAuthorization } from '../../../../auth'

export const TitleListComponent = ({ building }: { building: TBuilding }) => {

  const authManager = useAuthorization()
  const isBuildingManage = authManager.vote('Building', 'manage')

  return (
    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="stretch">
      <Stack direction={'column'} spacing={2}>
        <CommonInfo building={building} />
        <BuildingDocuments building={building} type={'permission'} isManage={isBuildingManage} />
        <BuildingDocuments building={building} type={'pd_examination'} isManage={isBuildingManage} />
        <Sar building={building} />
      </Stack>
      <Representatives building={building} />
    </Stack>
  )
}
