import { LightButton } from '../../common/LightButton'
import { Add } from '@mui/icons-material'
import React, { useEffect, useId, useState } from 'react'
import { FormDialog } from '../../common/FormDialog'
import { boolean, object, string, TypeOf } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { Box } from '@mui/material'
import { toastError } from '../../../util/toastError'
import { useAddPolicyMutation } from '../../../store/auth'
import { toast } from 'react-toastify'
import { FormInputSwitch, FormSelect } from '../../form'
import { actionsLabels, policyObjectsData } from '../../../types/auth'


const schema = object({
  object: string().min(1),
  action: string().min(1),
  access: boolean(),
})

const objectItems = policyObjectsData.map(i => ({label: i.label, value: i.object}))

type Props = {
  buId: string
  buildingId: string
}
export const AddPolicyDialog = ({buId, buildingId}: Props) => {

  const [open, setOpen] = useState(false)
  const [actions, setActions] = useState<any[]>([])
  const id = useId()
  const [isLoading, setIsLoading] = useState(false)
  const [addPolicy] = useAddPolicyMutation()

  const methods = useForm<TypeOf<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {access: true}
  })

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    reset,
  } = methods


  useEffect(() => {
    const {unsubscribe} = watch((values, { name, type }) => {
      if (name === 'object') {
        const obj = values[name]
        const objData = policyObjectsData.find(od => od.object === obj)

        if (objData) {
          setActions(objData.actions.map(a => ({label: actionsLabels[a], value: a})))
        }

      }
    })
    return () => unsubscribe()
  }, [watch])


  const onSubmitHandler = (values: any) => {
    setIsLoading(true)
    addPolicy({
      buildingId: buildingId,
      body: {
        role: buId,
        ...values
      }
    })
      .unwrap()
      .then((res) => {
        toast.success('Правило добавлено')
        setOpen(false)
      })
      .catch(toastError)
      .finally(() => {
        setIsLoading(false)
      })
  }

  return(
    <>
      <LightButton startIcon={<Add />} onClick={() => setOpen(true)}>Добавить</LightButton>
      <FormDialog
        formId={id}
        open={open}
        onClose={() => setOpen(false)}
        title={'Добавить правило'}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <FormProvider {...methods}>
          <Box
            component="form"
            id={id}
            onSubmit={handleSubmit(onSubmitHandler)}
            noValidate
            autoComplete="off"
          >
            <FormSelect name={'object'} label={''} items={objectItems} />
            <FormSelect name={'action'} label={'Действие'} items={actions} />
            <FormInputSwitch name={'access'} label={'Разрешить'} />
          </Box>
        </FormProvider>
      </FormDialog>
    </>
  )
}
