import { useParams } from 'react-router-dom'
import { Button } from '@mui/material'
import { Add } from '@mui/icons-material'
import { FormDialog } from '../../common/FormDialog'
import * as React from 'react'
import { useState } from 'react'
import { AddRepresentativeForm } from './AddRepresentativeForm'
import { TBuilding } from '../../../types/building'
import { parseError } from '../../../api'
import { toast } from 'react-toastify'
import { useGetCompaniesQuery } from '../../../store/companies'
import { employeesList } from '../../../util/employees'
import { useGetEmployeesQuery } from '../../../store/employees'
import { LightButton } from '../../common/LightButton'
import { useAddDocumentMutation, useGetDocumentsQuery } from '../../../store/document'
import { documentType } from '../../../types/document'
import { toastError } from '../../../util/toastError'

interface IProps {
  building: TBuilding
}

export const AddRepresentativeDialog = ({ building }: IProps) => {
  const id = 'add-title-employee-dialog'
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { journalId } = useParams()
  const [addRepresentative] = useAddDocumentMutation()

  const { data: employees } = useGetEmployeesQuery(building.id)
  const { data: companies } = useGetCompaniesQuery(building.id)
  const { data: representatives } = useGetDocumentsQuery({
    buildingId: building.id,
    params: { type: documentType.IMCRepresentative, parent_id: journalId },
  })

  const employeeItems =
    companies && employees && representatives
      ? employeesList(
          companies,
          employees,
          ['building_contractor', 'contractor'],
          ['auth_representative', 'auth_itp'],
          representatives.items.map((rep) => rep.employees.employee[0].employee_id)
        )
      : []

  const handleSubmit = (values: any, methods: any) => {
    setIsLoading(true)
    addRepresentative({
      buildingId: building.id,
      body: {
        parent_id: journalId,
        type: documentType.IMCRepresentative,
        employees: [{ id: values.employee_id, type: 'employee' }],
      },
    })
      .unwrap()
      .then((res) => {
        methods.reset()
        setIsLoading(false)
        setOpen(false)
      })
      .catch(toastError)
      .finally(() => setIsLoading(false))
  }

  return (
    <>
      <LightButton startIcon={<Add />} onClick={() => setOpen(true)}>
        Добавить представителя
      </LightButton>
      <FormDialog
        formId={id}
        open={open}
        onClose={() => setOpen(false)}
        title={'Добавить представителя'}
        button={employeeItems.length > 0 ? 'Отправить' : false}
        isLoading={isLoading}
      >
        <AddRepresentativeForm
          id={id}
          onSubmit={handleSubmit}
          building={building}
          itemsList={employeeItems}
        />
      </FormDialog>
    </>
  )
}
