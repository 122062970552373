import { ChangeEvent, useState } from 'react'

const useChecked = (options: string[]) => {
  const [checked, setChecked] = useState<string[]>([])
  const [isCheckedAll, setIsCheckedAll] = useState(false)
  const [isIndeterminate, setIsIndeterminate] = useState(false)

  const onCheck = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (value === 'all') {
      setChecked(checked.length === options.length ? [] : [...options])
      setIsCheckedAll(checked.length !== options.length)
      setIsIndeterminate(false)
    } else {
      const list = [...checked]
      const index = list.indexOf(value)
      index === -1 ? list.push(value) : list.splice(index, 1)
      setChecked(list)
      setIsCheckedAll(list.length === options.length)
      setIsIndeterminate(list.length !== options.length && list.length > 0)
    }
  }

  return { onCheck, checked, isCheckedAll, isIndeterminate }
}
export { useChecked }
