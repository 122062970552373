import { object, string, TypeOf } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { useMemo } from 'react'
import { Box } from '@mui/material'
import FormSelect from '../../form/FormSelect'
import { useGetExternalDocumentsQuery } from '../../../store/externalDocuments'
import { formatDate } from '../../../util/date'
import { IDocument } from '../../../types/document'
import { FormInputExtDocument } from '../../form'

const schema = {
  external_document_id: string().min(1, 'Необходимо заполнить'),
}
const registerSchema = object(schema)

interface Props {
  values?: any
  onSubmit: (values: TypeOf<typeof registerSchema>, methods: any) => void
  type: 'dpk'
  item: IDocument
  id?: string
}

const ItemExtDocForm = ({ values, type, item, onSubmit, id }: Props) => {
  const { data: documents } = useGetExternalDocumentsQuery({ buildingId: item.building_id })

  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: values || {},
  })

  const { handleSubmit } = methods

  const documentItems = useMemo(() => {
    if (documents === undefined) return []
    return documents.items
      .filter((doc) => doc.type === type)
      .map((doc) => {
        return {
          label: doc.name + (doc.number && ` № ${doc.number}`) + ' от ' + formatDate(doc.date),
          value: doc.id,
        }
      })
  }, [documents])

  const submitHandler = (values: any) => {
    onSubmit(values, methods)
  }

  return (
    <FormProvider {...methods}>
      <Box
        id={id}
        component="form"
        onSubmit={handleSubmit(submitHandler)}
        noValidate
        autoComplete="off"
      >
        <FormInputExtDocument name="external_document_id" label="Документ" docType={'dpk'} />
      </Box>
    </FormProvider>
  )
}

export default ItemExtDocForm
