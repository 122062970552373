import { array, number, object, preprocess, string, TypeOf } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { useEffect, useMemo, useState } from 'react'
import { Box } from '@mui/material'
import { TBuilding } from '../../types/building'
import FormSelect from '../form/FormSelect'
import { TPrdType } from '../../types/prd'
import { useGetDocumentsQuery, useGetGroupsQuery } from '../../store/prd'
import { prdDocumentLists, prdDocumentsList, prdGroupsList } from '../../util/prd'

const schema = {
  group_id: string(),
  document_id: string().min(1, 'Необходимо заполнить'),
  lists: preprocess((v: any) => v.map((v: string) => parseInt(v)), array(number())),
}
const registerSchema = object(schema)

interface Props {
  buildingId: string
  type: TPrdType
  values?: any
  onSubmit: (values: TypeOf<typeof registerSchema>, methods: any) => void
  id?: string
}

const PrdDocumentSelectForm = ({ buildingId, type, values, onSubmit, id }: Props) => {
  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: values
      ? { ...values, lists: values.lists ? values.lists.map((l: any) => l + '') : [] }
      : { lists: [] },
  })

  const [groupId, setGroupId] = useState<string | undefined>(
    values && values.group_id ? values.group_id : undefined
  )
  const [documentId, setDocumentId] = useState<string | undefined>(
    values && values.document_id ? values.document_id : undefined
  )
  const { data: groups } = useGetGroupsQuery(buildingId)
  const { data: docs } = useGetDocumentsQuery(buildingId)

  const groupsItems = useMemo(() => {
    return groups ? prdGroupsList(type, groups) : []
  }, [groups, type])

  const documentsItems = useMemo(() => {
    return docs && groupId ? prdDocumentsList(groupId, docs) : []
  }, [docs, groupId])

  const listsItems = useMemo(() => {
    if (docs && documentId) {
      const doc = docs.find((d) => d.id === documentId)
      if (doc) {
        return prdDocumentLists(doc.lists_count)
      }
      return []
    }
    return []
  }, [docs, documentId])

  const {
    handleSubmit,
    formState: { isSubmitSuccessful },
    watch,
    setValue,
  } = methods

  useEffect(() => {
    const subscription = watch((values, { name, type }) => {
      if (name === 'group_id') {
        setGroupId(values[name])
        setDocumentId(undefined)
        setValue('document_id', '')
        setValue('lists', [])
      } else if (name === 'document_id') {
        setDocumentId(values[name])
        if (values[name] !== documentId) {
          setValue('lists', [])
        }
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, documentId])

  const hs = handleSubmit((values: any) => {
    onSubmit(values, methods)
  })

  const onFormSubmit = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    return hs(e)
  }

  return (
    <FormProvider {...methods}>
      <Box id={id} component="form" onSubmit={onFormSubmit} noValidate autoComplete="off">
        <FormSelect name="group_id" label={'Раздел'} items={groupsItems} />
        <FormSelect name="document_id" label={'Документ'} items={documentsItems} />
        <FormSelect name="lists" label={'Листы'} items={listsItems} multiple />
      </Box>
    </FormProvider>
  )
}

export default PrdDocumentSelectForm
