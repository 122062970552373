import React, { useEffect, useState } from 'react'
import { Link as RouterLink, Navigate, useLocation } from 'react-router-dom'
import System from '../../../layout/system'
import api, { parseError } from '../../../api'
import AlertError from '../../../components/alert/AlertError'
import Avatar from '@mui/material/Avatar'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import { Link, Grid } from '@mui/material'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

function Confirm() {
  const [success, setSuccess] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const query = useQuery()
  const token = query.get('token')

  useEffect(() => {
    if (token && !success && error === null) {
      api
        .post('/v1/auth/join/confirm', { token })
        .then(() => setSuccess(true))
        .catch(async (error) => setError(await parseError(error)))
    }
  }, [success, error, token])

  if (success) {
    return <Navigate to="/join/success" replace />
  }

  if (!token) {
    return <Navigate to="/" replace />
  }

  return (
    <System>
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5" sx={{ mb: 1 }}>
        Регистрация
      </Typography>

      <AlertError message={error} />

      <Typography component="div" variant="body1" sx={{ mb: 1 }}>
        <Link to="/" variant="body2" component={RouterLink}>
          На главную
        </Link>
      </Typography>
    </System>
  )
}

export default Confirm
