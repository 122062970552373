import { TCompanyFilter, TCompanyRole, TCompanyType } from '../../types/building'
import { FormSelect } from './index'
import React, { useState } from 'react'
import { useAttachCompanyIEMutation, useAttachCompanyLegalMutation, useGetCompaniesQuery } from '../../store/companies'
import { companiesList } from '../../util/companies'
import { IconButton, InputAdornment } from '@mui/material'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { CompanyDialogForm } from '../building/form/CompanyDialogForm'
import { toast } from 'react-toastify'
import { toastError } from '../../util/toastError'



type Props = {
  buildingId: string
  roles?: TCompanyRole[]
  name: string,
  label: string
  hint?: string
  onAdd: (id: string) => void
  filter?: TCompanyFilter
}
export const FormSelectCompany = ({buildingId, roles, name, label, hint, onAdd, filter}: Props) => {

  const [open, setOpen] = useState(false)
  const { data: companies } = useGetCompaniesQuery(buildingId)
  const [attachLegal] = useAttachCompanyLegalMutation()
  const [attachIe] = useAttachCompanyIEMutation()

  const items = companies ? companiesList(companies, roles) : []

  const handleAddSubmit = (values: any, type: TCompanyType) => {
    const attach = type === 'legal' ? attachLegal : attachIe
    attach({ buildingId: buildingId, body: values })
      .unwrap()
      .then((res) => {
        toast.success('Компания добавлена')
        setOpen(false)
        onAdd && onAdd(res.id)
      })
      .catch(toastError)
  }

  return (
    <>
      <FormSelect
        name={name}
        label={label}
        hint={hint}
        items={items}
        endAdornment={(
          <IconButton onClick={() => setOpen(true)} title="Добавить новую компанию" sx={{mr: 1}} color={'success'}>
            <AddCircleOutlineOutlinedIcon />
          </IconButton>
        )}
      />
      <CompanyDialogForm
        type={'add'}
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={handleAddSubmit}
        buildingId={buildingId}
        filter={filter}
      />
    </>
  )
}
