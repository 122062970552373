import { useEffect, useState } from 'react'
import { bool } from 'prop-types'

type FilterType = 'string' | 'range' | 'select' | 'list' | 'date' | 'dateRange' | 'bool'

type TFilterState = {
  count: number
  found: number
}
type TValueNumberRange = {begin: number, end: number}
type TValueDateRange = {begin: string, end: string}
type TValue = string | string[] | number | number[] | TValueNumberRange | TValueDateRange | boolean
type FilterHandler = (item: any, value: TValue | any) => boolean


export interface TFilterSetting {
  name: string,
  label: string,
  filter: FilterType,
  data?: any[],
  prepare?: (value: any) => any
  handler?: FilterHandler
}

export interface UseFilterResult {
  setItems: (items: any[]) => void
  setValues: (values: any) => void
  removeValue: (name: string) => void,
  settings: TFilterSetting[],
  items: any[],
  filtered: any[],
  defaultValues: Record<string, any>,
  values: Record<string, TValue>,
  state: TFilterState
  clear: () => void
  watchClear: (callback: () => void) => {unsubscribe: () => void}
  watchRemove: (callback: (name: string) => void) => {unsubscribe: () => void}
}


type FilterFunc = (item: any, name: string, value: any, prepare?: (val: any) => any) => boolean


const getProperty = (obj: any, name: string) => name.split('.').reduce((o,i) => o !== undefined && o[i], obj)

const filters: Record<FilterType, FilterFunc> = {

  string: (item: any, name: string, value: string, prepare = v => v) =>
    value.trim() !== '' ? new RegExp(value.trim(), 'i').test(prepare(getProperty(item, name))) : true,

  range: (item, name, value: TValueNumberRange, prepare = v => v) => {
    const begin = Number(value.begin)
    const end  = Number(value.end)

    return (begin ? prepare(getProperty(item, name)) >= begin : true) &&
           (end ? prepare(getProperty(item, name)) <= end : true)
  },

  select: (item, name, value, prepare = v => v) => {

    if (value.trim() === '') return true

    const itemValue = getProperty(item, name)

    if (Array.isArray(itemValue)) {
      return itemValue.find(iv => value === prepare(iv)) || false
    }
    return prepare(itemValue) === value
  },

  list: (item, name, value: string[], prepare = v => v) => {

    if (!Array.isArray(value) || value.length === 0) return true

    const itemValue = getProperty(item, name)

    if (Array.isArray(itemValue)) {
      return itemValue.filter(iv => value.includes(prepare(iv))).length > 0
    }
    return value.includes(prepare(itemValue))
  },

  date: (item, name, value: string, prepare = v => v) =>
    value.trim() !== '' ? prepare(getProperty(item, name)).substring(0, 10) === value.substring(0, 10) : true,

  dateRange: (item, name, value: TValueDateRange, prepare = v => v) =>
    (value.begin ? new Date(prepare(getProperty(item, name))).getTime() >= new Date(value.begin).getTime() : true) &&
    (value.end ? new Date(prepare(getProperty(item, name))).getTime() <= new Date(value.end.substring(0, 10) + ' 23:59:59').getTime() : true),

  bool: (item, name, value: boolean, prepare = v => v) => (!!prepare(getProperty(item, name)))

}


const isNotEmpty = (filter: TFilterSetting, value: any) => {
  switch (filter.filter){
    case 'list':
      return Array.isArray(value) && value.length > 0
    case 'date':
    case 'dateRange':
      return value.begin.trim() !== '' || value.end.trim() !== ''
    case 'bool':
      return value
    default :
      return value.trim() !== ''
  }
}


const actualValues = (values: any, filterSettings: TFilterSetting[]) => {

  let data = {}

  for (const s of filterSettings) {
    const value = values[s.name] || getProperty(values, s.name)
    if (value !== undefined && isNotEmpty(s, value)) {
      data = {...data, [s.name]: value}
    }
  }

  return data
}

export const useFilter = (filterSettings: TFilterSetting[], defaultValues: Record<string, any> = {}): UseFilterResult => {

  const [items, setItems] = useState<any[]>([])
  const [values, setValues] = useState<Record<string, TValue>>(actualValues(defaultValues, filterSettings))
  const [filtered, setFiltered] = useState<any[]>([])
  const [state, setState] = useState<TFilterState>({count: 0, found: 0})


  let subsRemove: ((name: string) => void)[] = []

  const watchRemove = (callback: (name: string) => void) => {
    subsRemove.push(callback)
    return {
      unsubscribe: () => {
        subsRemove = subsRemove.filter(fn => fn !== callback)
      }
    }
  }

  const removeValue = (name: string) => {

    const {[name]: removed, ...newValues} = values
    const actualVals = actualValues(newValues, filterSettings)
    setValues(actualVals)
    setState(state => ({...state, count: Object.keys(actualVals).length}))
    subsRemove.forEach(f => f(name))
  }

  const setFilteredValues = (vals: any) => {
    const actualVals = actualValues(vals, filterSettings)
    setValues(actualVals)
    setState(state => ({...state, count: Object.keys(actualVals).length}))
  }


  let subsClear: (() => void)[] = []
  const clear = () => {
    setValues({})
    subsClear.forEach(f => f())
  }

  const watchClear = (callback: () => void) => {
    subsClear.push(callback)
    return {
      unsubscribe: () => {
        subsClear = subsClear.filter(fn => fn !== callback)
      }
    }
  }


  useEffect(() => {

    const f = items.filter(item => {
      for (const name in values) {
        const filterSetting = filterSettings.find(fs => fs.name === name)
        if ( filterSetting ) {
          if ( filterSetting.handler ) {
            if (!filterSetting.handler(item, values[name])) {
              return false
            }
          } else if( !filters[filterSetting.filter](item, name, values[name], filterSetting.prepare) ) {
            return false
          }
        }
      }
      return true
    })

    setFiltered(f)
    setState(state => ({...state, count: Object.keys(values).length, found: f.length}))

  }, [items, values])


  return {
    setItems,
    setValues: setFilteredValues,
    removeValue,
    settings: filterSettings,
    items,
    filtered,
    defaultValues,
    values,
    state,
    clear,
    watchClear,
    watchRemove
  }
}
