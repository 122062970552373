import { useOutletContext } from 'react-router-dom'
import React from 'react'
import { Employees } from '../../../components/company/Employees'
import { CompanyCard } from '../../../components/company/CompanyCard'
import { BaseContainer } from '../../../components/common/Container'
import { IOutletCompany } from '../../../types/outlet-context'
import { Box, Stack } from '@mui/material'
import { CompanyWorks } from '../../../components/company/CompanyWorks'
import { M4dList } from '../../../components/company/M4dList'
import { ceRoles, TCompany, TCompanyRole } from '../../../types/building'
import { CompanyPrd } from '../../../components/company/CompanyPrd'

const isWorks = (company: TCompany): boolean => {
  const roles: TCompanyRole[] = ['building_contractor', 'contractor']
  return roles.includes(company.role)
}
const isPrd = (company: TCompany): boolean => {
  const roles: TCompanyRole[] = ['designer']
  return roles.includes(company.role)
}
const isM4d = (company: TCompany): boolean => {
  const roles: TCompanyRole[] = [
    'developer',
    'operating_person',
    'regional_operator',
    'developer_control',
    'customer',
    'customer_control',
    'building_contractor',
    'contractor',
    'designer',
  ]
  return roles.includes(company.role)
}

export const CompanyMain = () => {
  const { building, company } = useOutletContext<IOutletCompany>()

  return (
    <BaseContainer>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
        <Box>
          <CompanyCard building={building} company={company} />
          {isM4d(company) && (
            <Box sx={{ mt: 2 }}>
              <M4dList company={company} />
            </Box>
          )}
        </Box>
        <Box>
          {ceRoles[company.role].length > 0 && <Employees building={building} company={company} />}
          {isWorks(company) && (
            <Box sx={{ mt: 2 }}>
              <CompanyWorks company={company} building={building} />
            </Box>
          )}
          {isPrd(company) && (
            <Box sx={{ mt: 2 }}>
              <CompanyPrd company={company} building={building} />
            </Box>
          )}
        </Box>
      </Stack>
    </BaseContainer>
  )
}
