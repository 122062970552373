import { FormDialog } from '../common/FormDialog'
import * as React from 'react'
import { useState } from 'react'
import { useAddDocumentMutation } from '../../store/prd'
import { TBuilding } from '../../types/building'
import { toastError } from '../../util/toastError'
import PrdDocumentForm from './PrdDocumentForm'
import { toast } from 'react-toastify'

type Props = {
  building: TBuilding
  onSave?: (id: string) => void
  onClose: () => void
  groupId?: string
  open: boolean
}

const PrdDocumentAddDialog = ({ onSave, building, groupId, open, onClose }: Props) => {
  const id = 'add-prd-document-dialog'
  const [isLoading, setIsLoading] = useState(false)

  const [addDocument] = useAddDocumentMutation()

  const handleSubmit = (values: any, methods: any) => {
    setIsLoading(true)
    const body = {
      ...values,
      group_id: groupId,
    }
    addDocument({ buildingId: building.id, body })
      .unwrap()
      .then((res) => {
        methods.reset()
        setIsLoading(false)
        onSave && onSave(res.id)
        toast.success('документ добавлен')
      })
      .catch(async (e) => {
        await toastError(e)
        setIsLoading(false)
      })
  }

  return (
    <>
      <FormDialog
        formId={id}
        open={open}
        onClose={onClose}
        title={'Добавить документ'}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <PrdDocumentForm id={id} building={building} onSubmit={handleSubmit} />
      </FormDialog>
    </>
  )
}

export default PrdDocumentAddDialog
