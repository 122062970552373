import { useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { Add } from '@mui/icons-material'
import { FormDialog } from '../../../common/FormDialog'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import { useAddDocumentMutation, useEditDocumentMutation } from '../../../../store/document'
import { documentType, IDocument } from '../../../../types/document'
import { Part6ItemForm } from './Part6ItemForm'
import { LightButton } from '../../../common/LightButton'
import { toastError } from '../../../../util/toastError'
import { AddButton } from '../../../common/AddButton'

type Props = {
  buildingId: string
  document?: IDocument
}

const Part6Dialog = ({ buildingId, document }: Props) => {
  const id = 'add-item-6-dialog'
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { journalId } = useParams()

  const [addDocument] = useAddDocumentMutation()
  const [editDocument] = useEditDocumentMutation()

  const values = useMemo(() => {
    if (!document) return undefined
    return document.data
  }, [document])

  const handleSubmit = (values: any, methods: any) => {
    if (!document) {
      add(values)
    } else {
      edit(values, document.id)
    }
    methods.reset()
  }

  const add = (values: any) => {
    const body = {
      type: documentType.GWJ6Item,
      parent_id: journalId,
      data: values,
    } as any

    setIsLoading(true)

    addDocument({ buildingId: buildingId, body })
      .unwrap()
      .then(() => {
        setOpen(false)
        toast.success('Документ добавлен')
      })
      .catch(toastError)
      .finally(() => {
        setIsLoading(false)
      })
  }

  const edit = (values: any, documentId: string) => {
    const body = {
      type: documentType.GWJ6Item,
      document_id: documentId,
      data: values,
    } as any

    setIsLoading(true)

    editDocument({ buildingId: buildingId, body })
      .unwrap()
      .then(() => {
        setOpen(false)
      })
      .catch(toastError)
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <>
      {document &&
        <AddButton onClick={() => setOpen(true)}>
          {'Добавить запись'}
        </AddButton>
      }
      {!document &&
        <LightButton onClick={() => setOpen(true)}>
          {'Редактировать'}
        </LightButton>
      }
      <FormDialog
        formId={id}
        open={open}
        onClose={() => setOpen(false)}
        title={document ? 'Редактировать' : 'Добавить запись'}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <Part6ItemForm id={id} onSubmit={handleSubmit} values={values} />
      </FormDialog>
    </>
  )
}

export { Part6Dialog }
