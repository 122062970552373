import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
} from '@mui/material'
import * as React from 'react'
import Box from '@mui/material/Box'
import { TExternalDocument } from '../../types/external-document'
import ExtDocActions from './ExtDocActions'
import { DocumentView } from './index'

type Props = {
  open: boolean
  document: TExternalDocument
  onClose: () => void
  onChange: () => void
}

const ExtDocViewDialog = ({ open, document, onClose, onChange }: Props) => {
  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth={'lg'} fullWidth scroll={'body'}>
        <DialogContent>
          <DocumentView document={document} />
        </DialogContent>
        <DialogActions>
          <Stack spacing={2} direction="row" sx={{ alignItems: 'center' }}>
            <ExtDocActions document={document} onSign={onChange} onRemove={onChange} onSave={onChange} />
            <Box sx={{ flexGrow: 1, justifyContent: 'flex-end', display: 'flex' }}>
              <Button variant={'outlined'} onClick={onClose}>
                Закрыть
              </Button>
            </Box>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default ExtDocViewDialog
