import { TWork } from '../../types/works'
import {
  Box,
  Card,
  CardContent,
  Divider,
  LinearProgress,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import * as React from 'react'
import { MaterialAmounts } from './MaterialAmounts'
import { WorkPrd } from './WorkPrd'

export const WorkView = ({ work, isManage }: { work: TWork; isManage: boolean }) => {
  return (
    <>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <Box>
          <Card sx={{ mb: 2 }}>
            <CardContent>
              <Typography variant={'h6'}>{work.name}</Typography>
              <Typography variant={'subtitle1'} color="text.secondary">
                {work.description}
              </Typography>
            </CardContent>
          </Card>

          <Card sx={{ mb: 2 }}>
            <CardContent>
              <Typography variant={'body1'} sx={{ fontWeight: 'medium' }}>
                {'Выполнение:'}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                  <LinearProgress variant="determinate" value={work.done} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                  <Typography variant="body2" color="text.secondary">
                    {`${work.done}%`}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>

          <MaterialAmounts work={work} isManage={isManage} />
        </Box>
        <Box>
          <WorkPrd work={work} isManage={isManage} type={'rd'} />
          <WorkPrd work={work} isManage={isManage} type={'pd'} />
        </Box>
      </Stack>
    </>
  )
}
