import { FormHelperText, FormControl, InputProps, OutlinedInput, InputLabel } from '@mui/material'
import { FC, ReactNode } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

type IFormInputProps = {
  name: string
  label: string
  hint?: string
  startAdornment?: ReactNode
  endAdornment?: ReactNode
  rows?: number
} & InputProps

const FormTextarea: FC<IFormInputProps> = ({
  name,
  label,
  hint,
  rows = 2,
  startAdornment,
  endAdornment,
  ...otherProps
}) => {
  const {
    control,
    formState: { errors },
    getFieldState,
  } = useFormContext()

  const error = getFieldState(name).error
  const errorMessage: string = (error ? error.message : '') as string

  const hintEl = hint ? <FormHelperText>{hint}</FormHelperText> : null

  return (
    <Controller
      control={control}
      defaultValue=""
      name={name}
      render={({ field, fieldState }) => (
        <FormControl fullWidth margin={'dense'}>
          <InputLabel>{label}</InputLabel>
          <OutlinedInput
            {...field}
            error={!!error}
            label={label}
            startAdornment={startAdornment}
            endAdornment={endAdornment}
            {...otherProps}
            multiline
            rows={rows}
          />
          {hintEl}
          <FormHelperText error={!!error}>{errorMessage}</FormHelperText>
        </FormControl>
      )}
    />
  )
}

export default FormTextarea
