import { Representatives } from './Representatives'
import { TBuilding } from '../../../types/building'
import React from 'react'

const TitleList = ({ building }: { building: TBuilding }) => {
  return (
    <>
      <Representatives building={building} />
    </>
  )
}

export { TitleList }
