import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { addAuthorization } from '../../util/http/addAuthorization'
import { IDocument } from '../../types/document'

type TRequest = {
  buildingId: string
  params: {
    type: string
    parent_id?: string
    limit?: number
    offset?: number
    total?: number
    filters?: any
  }
}

type TListResponse = {
  metadata: {
    limit: number
    offset: number
    total: number
  }
  items: IDocument[]
}

export const documentApi = createApi({
  reducerPath: 'document',
  tagTypes: ['Documents', 'Type'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1/',
    prepareHeaders: addAuthorization,
    validateStatus: (response) => response.ok,
  }),
  endpoints: (builder) => ({
    getDocuments: builder.query<TListResponse, TRequest>({
      query: ({ buildingId, params }) => ({
        url: `/buildings/${buildingId}/document/list`,
        method: 'GET',
        params,
      }),
      providesTags: (result, error, arg) => [{ type: 'Documents' as const, id: arg.params.type }],
    }),
    getXml: builder.query<string, any>({
      query: ({ buildingId, params }) => ({
        url: `/buildings/${buildingId}/document/xml`,
        method: 'GET',
        params,
        responseHandler: 'text',
      }),
    }),
    ready: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/document/ready`,
        method: 'POST',
        body,
      }),
    }),
    reset: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/document/reset`,
        method: 'POST',
        body,
      }),
    }),
    sign: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/document/sign`,
        method: 'POST',
        body,
      }),
    }),
    addDocument: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/document/add`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Documents' as const, id: arg.body.type }],
    }),
    removeDocument: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/document/remove`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Documents' as const, id: arg.body.type }],
    }),
    editDocument: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/document/edit`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Documents' as const, id: arg.body.type }],
    }),
    addCompany: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/document/add-company`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Documents'],
    }),
    removeCompany: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/document/remove-company`,
        method: 'POST',
        body,
      }),
    }),
    addEmployee: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/document/add-employee`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Documents'],
    }),
    removeEmployee: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/document/remove-employee`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Documents'],
    }),
    addWork: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/document/add-work`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Documents'],
    }),
    removeWork: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/document/remove-work`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Documents'],
    }),
    addExtDoc: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/document/add-ext-doc`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Documents'],
    }),
    removeExtDoc: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/document/remove-ext-doc`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Documents'],
    }),
    addPrd: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/document/add-prd`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Documents'],
    }),
    removePrd: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/document/remove-prd`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Documents'],
    }),
    addOver: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/document/add-over`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Documents'],
    }),
    removeOver: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/document/remove-over`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Documents'],
    }),
    addLocation: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/document/add-location`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Documents'],
    }),
    removeLocation: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/document/remove-location`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Documents'],
    }),
  }),
})

export const {
  useGetDocumentsQuery,
  useGetXmlQuery,
  useReadyMutation,
  useResetMutation,
  useSignMutation,
  useAddDocumentMutation,
  useEditDocumentMutation,
  useRemoveDocumentMutation,
  useAddCompanyMutation,
  useRemoveCompanyMutation,
  useAddEmployeeMutation,
  useRemoveEmployeeMutation,
  useAddWorkMutation,
  useRemoveWorkMutation,
  useAddExtDocMutation,
  useRemoveExtDocMutation,
  useAddOverMutation,
  useRemoveOverMutation,
  useAddPrdMutation,
  useRemovePrdMutation,
  useAddLocationMutation,
  useRemoveLocationMutation,
} = documentApi
