import { Card, CardContent, CardHeader, Divider, MenuItem, Stack } from '@mui/material'
import { IDocument } from '../../../../types/document'
import { DataRow } from '../../../common/DataRow'
import { formatDate } from '../../../../util/date'
import { useGetEmployeesQuery } from '../../../../store/employees'
import React, { useMemo } from 'react'
import { StatusChip } from '../../../common/StatusChip'
import TitleChangeItemDialog from './TitleChangeItemDialog'
import { SignDocument } from '../../../document/SignDocument'
import { useAuthorization } from '../../../../auth'
import { useGetUserQuery } from '../../../../store/user'

type Props = {
  document: IDocument
  onSign: () => void
}
const TitleChangeView = ({ document, onSign }: Props) => {
  const { data: employees } = useGetEmployeesQuery(document.building_id)

  const authManager = useAuthorization()
  const { data: user } = useGetUserQuery()
  const isManage = useMemo(() => {
    return (
      (user &&
        authManager.vote(
          'Document.TitleChangesListItem',
          'manage',
          document.owner_id === user.id
        ) &&
        document.status === 'draft') ||
      false
    )
  }, [user])

  const employeeName = employees
    ? employees.find((em) => em.id === document.employees.signer[0].employee_id)?.profile.name
        .full_name || ''
    : ''

  return (
    <Stack direction={'column'} spacing={2}>
      <Card>
        <CardHeader avatar={<StatusChip status={document.status} />} />
        <Divider />
        <CardContent>
          <DataRow label={'Внесённые изменения'} text={document.data.description} />
          <DataRow label={'Основания для внесения данного изменения'} text={document.data.basis} />
          <DataRow label={'Дата внесения изменения'} text={formatDate(document.date)} />
          <DataRow label={'Представитель ответственны за внесение изменений'} text={employeeName} />
        </CardContent>
        {isManage && (
          <CardContent>
            <TitleChangeItemDialog
              buildingId={document.building_id}
              values={{
                date: document.date,
                number: document.number,
                data: document.data,
                signer_id: document.employees.signer[0].employee_id || '',
              }}
              documentId={document.id}
              onSave={console.log}
            />
          </CardContent>
        )}
      </Card>
      <SignDocument document={document} onSign={onSign} onReady={onSign} />
    </Stack>
  )
}

export { TitleChangeView }
