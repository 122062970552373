import { object, string, TypeOf } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import FormSelect from '../../../form/FormSelect'
import React from 'react'
import { Box } from '@mui/material'
import { useGetCompaniesQuery } from '../../../../store/companies'
import { TBuilding } from '../../../../types/building'
import { employeesList } from '../../../../util/employees'
import { useParams } from 'react-router-dom'
import FormInput from '../../../form/FormInput'
import FormInputDate from '../../../form/FormInputDate'
import { useGetEmployeesQuery } from '../../../../store/employees'

const schema = {
  signer_id: string().min(1, 'Необходимо заполнить'),
  number: string().min(1, 'Необходимо заполнить'),
  date: string().min(1, 'Необходимо заполнить дату'),
}
const registerSchema = object(schema)

interface Props {
  building: TBuilding
  values?: any
  onSubmit: (values: TypeOf<typeof registerSchema>, methods: any) => void
  id?: string
}

export const AddSarForm = ({ building, values, onSubmit, id }: Props) => {
  const { journalId } = useParams()
  const { data: employees } = useGetEmployeesQuery(building.id)
  const { data: companies } = useGetCompaniesQuery(building.id)

  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: values || {},
  })

  const { handleSubmit } = methods

  const submitHandler = (values: any) => {
    onSubmit(values, methods)
  }

  const employeeItems =
    companies && employees
      ? employeesList(companies, employees, ['gsn'], ['auth_representative', 'auth_supervisory'])
      : []

  return (
    <FormProvider {...methods}>
      <Box
        id={id}
        component="form"
        onSubmit={handleSubmit(submitHandler)}
        noValidate
        autoComplete="off"
      >
        <FormInput
          name="number"
          label="Идентификационный номер журнала в системе Государственного строительного надзора (Номер дела/регистрационный номер)"
        />
        <FormInputDate name="date" label="Дата регистрации" />
        <FormSelect
          name="signer_id"
          label="Представитель Госстройнадзора, внёсший регистрационную запись"
          items={employeeItems}
        />
      </Box>
    </FormProvider>
  )
}
