import { Link, Outlet, useOutletContext, useMatches } from 'react-router-dom'
import React from 'react'
import { Box, Paper, Tab, Tabs } from '@mui/material'
import { BaseContainer } from '../../../../components/common/Container'
import { IOutletBuilding } from '../../../../types/outlet-context'

export const Gwj = () => {
  const { building } = useOutletContext<IOutletBuilding>()

  const routeMatch = useMatches()
  const currentTab = (routeMatch[routeMatch.length - 1].handle as any).key

  return (
    <>
      <Box>
        <Paper square elevation={0}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs variant="scrollable" scrollButtons allowScrollButtonsMobile value={currentTab}>
              <Tab label="Титульный лист" value="title" to="title" component={Link} />
              <Tab label="Лист изменений" value="change-list" to="change-list" component={Link} />
              <Tab label="Раздел 1" value="part1" to="part1" component={Link} />
              <Tab label="Раздел 2" value="part2" to="part2" component={Link} />
              <Tab label="Раздел 3" value="part3" to="" component={Link} />
              <Tab label="Раздел 4" value="part4" to="part4" component={Link} />
              <Tab label="Раздел 5" value="part5" to="part5" component={Link} />
              <Tab label="Раздел 6" value="part6" to="part6" component={Link} />
              <Tab label="Раздел 7" value="part7" to="part7" component={Link} />
              <Tab label="XML" value="xml" to="xml" component={Link} />
            </Tabs>
          </Box>
        </Paper>
        <BaseContainer>
          <Outlet context={{ building }} />
        </BaseContainer>
      </Box>
    </>
  )
}
