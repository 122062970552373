import { Button } from '@mui/material'
import { Add } from '@mui/icons-material'
import { FormDialog } from '../common/FormDialog'
import { DocumentForm } from './index'
import * as React from 'react'
import { useState } from 'react'
import { TBuilding } from '../../types/building'
import { useAddExternalDocumentMutation } from '../../store/externalDocuments'
import { toast } from 'react-toastify'
import { AddButton } from '../common/AddButton'

const AddDialog = ({ building, type, onAdd }: { building: TBuilding, type?: string, onAdd: (res: any) => void }) => {
  const id = 'add-external-document-dialog'
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [addDocument] = useAddExternalDocumentMutation()

  const handleSubmit = (values: any, methods: any) => {
    setIsLoading(true)
    addDocument({ buildingId: building.id, body: values })
      .unwrap()
      .then((res) => {
        methods.reset()
        setOpen(false)
        setIsLoading(false)
        toast.success('Документ добавлен')
        onAdd(res)
      })
      .catch(() => {
        toast.error('Произошла ошибка')
        setIsLoading(false)
      })
  }

  return (
    <>
      <AddButton onClick={() => setOpen(true)} variant={'contained'}>
        Добавить документ
      </AddButton>
      <FormDialog
        formId={id}
        open={open}
        onClose={() => setOpen(false)}
        title={'Добавить документ'}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <DocumentForm id={id} onSubmit={handleSubmit} values={type && {type}} />
      </FormDialog>
    </>
  )
}

export default AddDialog
