import { TBuilding, TEmployee } from '../../../../types/building'
import { useParams } from 'react-router-dom'
import { RightPanel, useSkidPagination } from '../../../common'
import { useGetEmployeesQuery } from '../../../../store/employees'
import { useMemo, useState } from 'react'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { parseError } from '../../../../api'
import { toast } from 'react-toastify'
import { formatDate, formatPeriodDig } from '../../../../util/date'
import { StatusChip } from '../../../common/StatusChip'
import * as React from 'react'
import { useGetDocumentsQuery, useRemoveDocumentMutation } from '../../../../store/document'
import { documentType, IDocument } from '../../../../types/document'
import { Record4ViewDefect } from './Record4ViewDefect'
import { Record4View } from './Record4View'
import { useAuthorization } from '../../../../auth'
import { useGetUserQuery } from '../../../../store/user'
import { Events } from './Events'
import { AddRecord4Dialog } from './AddRecord4Dialog'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import { toastError } from '../../../../util/toastError'

const signerName = (employees: TEmployee[], item: IDocument): string => {
  if (item.employees === undefined) return ''
  return (
    employees.find((em) => em.id === item.employees?.signer[0].employee_id)?.profile.name
      .full_name || ''
  )
}

export const Part4EventsList = ({ building }: { building: TBuilding }) => {
  const { journalId } = useParams()
  const { pages, page, offset, limit, onPageChange, setTotal, setLimit } = useSkidPagination(20)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [currentId, setCurrentId] = useState<string | undefined>()
  const [defectView, setDefectView] = useState(false)
  const [currentEvent, setCurrentEvent] = useState<IDocument>()

  const {
    data,
    isLoading,
    isSuccess,
    refetch: refetchData,
  } = useGetDocumentsQuery({
    buildingId: building.id,
    params: {
      type: documentType.GWJ4Item,
      parent_id: journalId,
    },
  })

  const {
    data: events,
    isLoading: isEventsLoading,
    isSuccess: isEventsSuccess,
  } = useGetDocumentsQuery({
    buildingId: building.id,
    params: {
      type: documentType.GWJ4ControlEvent,
      parent_id: journalId,
    },
  })

  const {
    data: defectsRes,
    isLoading: isDefectsLoading,
    isSuccess: isDefectsSuccess,
    refetch: refetchDefects,
  } = useGetDocumentsQuery({
    buildingId: building.id,
    params: {
      type: documentType.GWJ4DefectInfo,
      parent_id: journalId,
    },
  })

  const items = useMemo(() => {
    if (!data || !currentEvent) return []
    return data.items.filter(
      (item) => item.over.event && item.over.event[0].over_id === currentEvent.id
    )
  }, [data, currentEvent])

  const defects = useMemo(
    () => defectsRes?.items?.reduce((acc: any, row: IDocument) => ({ ...acc, [row.id]: row }), {}),
    [defectsRes]
  )

  const { data: employees } = useGetEmployeesQuery(building.id)
  const [removeDocument] = useRemoveDocumentMutation()

  const currentItem = useMemo(() => {
    return data && data.items.find((item) => item.id === currentId)
  }, [data, currentId])

  const currentDefect = useMemo(() => {
    return (
      defects &&
      currentItem !== undefined &&
      currentItem?.over?.defectInfo &&
      defects[currentItem?.over?.defectInfo[0].over_id]
    )
  }, [defects, currentId])

  const authManager = useAuthorization()
  const { data: user } = useGetUserQuery()
  const isCreate = useMemo(() => authManager.vote('Document.GWJ4Item', 'create'), [])
  const isManage = useMemo(() => {
    return (
      (currentItem &&
        user &&
        authManager.vote('Document.GWJ4Item', 'manage', currentItem.owner_id === user.id)) ||
      false
    )
  }, [currentItem, user])

  const refetchAll = () => {
    return Promise.all([refetchData, refetchDefects])
  }

  if (isSuccess) {
    setTotal(data.metadata.total)
  }

  if (isLoading || isEventsLoading || isDefectsLoading) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    )
  }

  if (!isSuccess || !isDefectsSuccess || !isEventsSuccess) {
    return <></>
  }

  const viewItem = (id: string, isDefectView: boolean) => {
    setDefectView(isDefectView)
    setCurrentId(id)
    setDrawerOpen(true)
  }

  const removeDoc = (id: string, type: string) => {
    return removeDocument({
      buildingId: building.id,
      body: {
        journal_id: journalId,
        document_id: id,
        type,
      },
    }).unwrap()
  }

  const handleRemove = (id: string | undefined) => {
    if (id) {
      removeDoc(id, documentType.GWJ4Item)
        .then((res) => {
          toast.success('Запись удалена')
        })
        .catch(toastError)
      setDrawerOpen(false)
    }
  }

  const handleEventSelect = (id: string) => {
    if (events) {
      setCurrentEvent(events.items.find((e) => e.id === id))
    }
  }

  return (
    <>
      <Grid container spacing={2} alignItems={'stretch'}>
        <Grid item xs={12} md={3}>
          {journalId && (
            <Events building={building} journalId={journalId} onSelect={handleEventSelect} />
          )}
        </Grid>
        <Grid item xs={12} md={9}>
          {currentEvent && (
            <Paper>
              <TableContainer>
                <CardHeader
                  title={'Сведения о строительном контроле застройщика или технического заказчика'}
                  subheader={
                    'инспекция: ' + formatPeriodDig(currentEvent.data.from, currentEvent.data.to)
                  }
                  action={
                    isCreate && (
                      <AddRecord4Dialog
                        journalId={journalId}
                        building={building}
                        onAdd={console.log}
                        eventId={currentEvent.id}
                      />
                    )
                  }
                />
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell width={'50px'}></TableCell>
                      <TableCell>Описание</TableCell>
                      <TableCell>Дата внесения записи</TableCell>
                      <TableCell>Ответственный</TableCell>
                      <TableCell>Статус</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {items.map((item: IDocument) => {
                      const defect =
                        item.over.defectInfo && defects[item.over.defectInfo[0].over_id]
                      return (
                        <TableRow
                          key={item.id}
                          hover
                          onClick={() => viewItem(item.id, item.data.is_defect)}
                          sx={{ cursor: 'pointer' }}
                        >
                          {item.data.is_defect ? (
                            <>
                              <TableCell>
                                <ErrorOutlineOutlinedIcon color={'error'} />
                              </TableCell>
                              <TableCell>{defect.data?.description}</TableCell>
                              <TableCell>{formatDate(item.date)}</TableCell>
                              <TableCell>{employees && signerName(employees, item)}</TableCell>
                              <TableCell>
                                <StatusChip status={item.status} />
                              </TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell>
                                <CheckCircleOutlineOutlinedIcon color={'success'} />
                              </TableCell>
                              <TableCell>
                                <Box>Нарушения не выявлены</Box>
                                <Typography variant={'caption'}>{item.data.description}</Typography>
                              </TableCell>
                              <TableCell>{formatDate(item.date)}</TableCell>
                              <TableCell>{employees && signerName(employees, item)}</TableCell>
                              <TableCell>
                                <StatusChip status={item.status} />
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}
        </Grid>
      </Grid>

      <RightPanel
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
        actions={
          <>
            {isManage && currentItem && currentItem.status === 'draft' && (
              <Button
                variant={'outlined'}
                color={'error'}
                onClick={() => handleRemove(currentItem.id)}
              >
                Удалить
              </Button>
            )}
          </>
        }
      >
        {currentItem && defectView && (
          <Record4ViewDefect
            building={building}
            item={currentItem}
            defect={currentDefect}
            onChange={refetchAll}
            onReady={refetchData}
            onSign={refetchData}
            onDefectChange={refetchDefects}
          />
        )}
        {currentItem && !defectView && (
          <Record4View
            building={building}
            item={currentItem}
            onChange={refetchAll}
            onReady={refetchData}
            onSign={refetchData}
            onDefectReady={refetchDefects}
            onDefectSign={refetchDefects}
          />
        )}
      </RightPanel>
    </>
  )
}
