import { auth } from '../oauth/Auth'

const getFile = async (id: string, buildingId: string) => {
  const token = await auth.getToken()
  const url = `/api/v1/buildings/${buildingId}/files/${id}`
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: token,
    },
  }).then((response) => {
    if (!response.ok) {
      throw new Error(`Failed to fetch file: ${response.statusText}`)
    }
    return response.blob()
  })
}

const getFile64 = async (id: string, buildingId: string) => {
  const token = await auth.getToken()
  const url = `/api/v1/buildings/${buildingId}/files64/${id}`
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: token,
    },
  }).then((response) => {
    if (!response.ok) {
      throw new Error(`Failed to fetch file: ${response.statusText}`)
    }
    return response.text()
  })
}

const getFileHash = async (id: string, buildingId: string) => {
  const token = await auth.getToken()
  const url = `/api/v1/buildings/${buildingId}/files/hash/${id}`
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: token,
    },
  }).then((response) => {
    if (!response.ok) {
      throw new Error(`Failed to fetch file: ${response.statusText}`)
    }
    return response.text()
  })
}

export { getFile, getFile64, getFileHash }
