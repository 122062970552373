import { Avatar } from '@mui/material'
import React, { FC, PropsWithChildren } from 'react'

interface Props {
  text: string
}

function hashCode(str: string) {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  return hash
}

function pickColor(str: string) {
  return `hsl(${hashCode(str) % 360}, 80%, 40%)`
}

function stringToColor(string: string) {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

function stringAvatar(text: string) {
  return {
    sx: {
      bgcolor: pickColor(text),
      fontWeight: 400,
      fontSize: '1rem',
    },
  }
}

export const ColoredAvatar = (props: PropsWithChildren<Props>) => {
  const children = props.children || `${props.text.split(' ')[0][0]}${props.text.split(' ')[1][0]}`
  return <Avatar {...stringAvatar(props.text)}>{children}</Avatar>
}
