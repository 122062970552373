import { object, string, TypeOf, z } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { Box } from '@mui/material'
import { useGetCompaniesQuery } from '../../../../store/companies'
import { employeesList } from '../../../../util/employees'
import FormSelect from '../../../form/FormSelect'
import FormInputDate from '../../../form/FormInputDate'
import { IDocument } from '../../../../types/document'
import { useGetEmployeesQuery } from '../../../../store/employees'
import FormTextarea from '../../../form/FormTextarea'

const schemaDefect = {
  data: object({
    defect_fixing_fact_date: string().min(1, 'Необходимо заполнить'),
  }),
  signer_id: string().min(1, 'Необходимо заполнить'),
}

const schemaAbsence = {
  data: object({
    description: string().min(1, 'Необходимо заполнить'),
  }),
  signer_id: string().min(1, 'Необходимо заполнить'),
}

interface Props {
  item: IDocument
  values?: any
  onSubmit: (values: any, methods: any) => void
  id?: string
}

export const Record4EditForm = ({ item, onSubmit, id }: Props) => {
  const buildingId = item.building_id
  const isDefect = item.data.is_defect

  const registerSchema = object(isDefect ? schemaDefect : schemaAbsence)

  const { data: employees } = useGetEmployeesQuery(buildingId)
  const { data: companies } = useGetCompaniesQuery(buildingId)

  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: {
      signer_id: item.employees.signer[0].employee_id || undefined,
      data: item.data,
    },
  })

  const {
    handleSubmit,
    formState: { errors },
  } = methods

  const submitHandler = (values: any) => {
    onSubmit(values, methods)
  }

  const signerItems =
    companies && employees
      ? employeesList(
          companies,
          employees,
          ['developer', 'operating_person', 'regional_operator', 'customer'],
          ['auth_representative_control']
        )
      : []

  return (
    <FormProvider {...methods}>
      <Box
        id={id}
        component="form"
        onSubmit={handleSubmit(submitHandler)}
        noValidate
        autoComplete="off"
      >
        <FormSelect name="signer_id" label="Сотрудник, проводивший контроль" items={signerItems} />
        {isDefect ? (
          <FormInputDate
            name="data.defect_fixing_fact_date"
            label="Фактическая дата устранения недостатка"
          />
        ) : (
          <FormTextarea name="data.description" label="Сведения об отсутствии недостатков" />
        )}
      </Box>
    </FormProvider>
  )
}
