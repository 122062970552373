import { Link } from 'react-router-dom'
import { useGetBuildingQuery } from '../../store/buildings'
import * as React from 'react'
import Typography from '@mui/material/Typography'

export const BuildingName = ({ buildingId }: { buildingId: string }) => {
  const {
    data: building,
    isSuccess,
    isUninitialized,
    isFetching,
  } = useGetBuildingQuery(buildingId as string, { skip: !buildingId })

  if (!isSuccess || building === undefined) {
    return <></>
  }

  return (
    <Typography
      color={'white'}
      component={Link}
      to={`/building/${building.id}/data`}
      sx={{ textDecoration: 'none' }}
    >
      {building.name.substring(0, 200)}
    </Typography>
  )
}
