import { useGetProfileQuery } from '../../store/profile'
import ProfileDialogForm from './ProfileDialogForm'

const AlertVerified = (): JSX.Element => {
  // const {data} = useGetUserQuery()
  const { data: profile, isSuccess } = useGetProfileQuery()
  const isProfileEmpty = isSuccess && profile === null
  const isProfileNoActive = isSuccess && profile && profile.active === false

  if (isProfileEmpty) {
    return <ProfileDialogForm values={{}} />
  }
  if (isProfileNoActive) {
    return <ProfileDialogForm values={profile} />
  }

  return <></>
}

export { AlertVerified }
