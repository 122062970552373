import { array, object, string, TypeOf } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { Box } from '@mui/material'
import { FormDialog } from '../common/FormDialog'
import React, { useEffect, useMemo, useState } from 'react'
import { parseError } from '../../api'
import { toast } from 'react-toastify'
import { useEditEmployeeMutation } from '../../store/employees'
import {
  ceRoles,
  employeeRoles,
  TBuilding,
  TCompany,
  TEmployee,
  TEmployeeRole,
} from '../../types/building'
import FormInput from '../form/FormInput'
import FormSelect from '../form/FormSelect'
import { useGetGroupsQuery, useGetWorksQuery } from '../../store/works'
import { worksList } from '../../util/works'

const schema = {
  role: string().min(1, 'Необходимо заполнить'),
  position: string().min(1, 'Необходимо заполнить'),
  works: array(string()),
}

const registerSchema = object(schema)

type Props = {
  building: TBuilding
  company: TCompany
  employee: TEmployee
  open: boolean
  values: TypeOf<typeof registerSchema>
  onClose: () => void
}

const EditEmployeeDialog = ({ building, company, employee, open, values, onClose }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const { data: works } = useGetWorksQuery(building.id)
  const { data: groups } = useGetGroupsQuery(building.id)
  const [role, setRole] = useState<string | undefined>(values.role || undefined)

  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: values,
  })

  const { handleSubmit, reset, watch } = methods

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'role') {
        setRole(value[name])
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])

  const [edit] = useEditEmployeeMutation()

  const roles = useMemo(() => {
    const rolesForCompany = ceRoles[company.role]
    return rolesForCompany.map((role) => {
      return {
        label: employeeRoles[role as TEmployeeRole],
        value: role,
      }
    })
  }, [])

  const worksItems = useMemo(
    () => worksList(groups, works, company.works || []) || [],
    [groups, works, company]
  )

  const onSubmitHandler = (values: any) => {
    setIsLoading(true)
    edit({
      buildingId: building.id,
      body: {
        employee_id: employee.id,
        ...values,
      },
    })
      .unwrap()
      .then((res) => {
        setIsLoading(false)
        reset()
        onClose()
      })
      .catch(async (e) => {
        const error = await parseError(e)
        toast.error(error)
        setIsLoading(false)
      })
  }

  return (
    <>
      <FormDialog
        formId={'edit-employee-form'}
        open={open}
        onClose={onClose}
        title={'Редактировать сотрудника'}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <FormProvider {...methods}>
          <Box
            component="form"
            id={'edit-employee-form'}
            onSubmit={handleSubmit(onSubmitHandler)}
            noValidate
            autoComplete="off"
          >
            <Box sx={{ pt: 1 }}>
              <FormSelect name="role" label="Назначение" items={roles} />
              <FormInput name="position" label="Должность" />
              {['building_contractor', 'contractor'].includes(company.role) &&
                role === 'auth_itp' && (
                  <FormSelect
                    multiple
                    name="works"
                    label="Список выполняемых работ"
                    items={worksItems}
                    hint="Заполняется в случае необходимости ограничения выполняемых работ для сотрудника"
                  />
                )}
            </Box>
          </Box>
        </FormProvider>
      </FormDialog>
    </>
  )
}

export { EditEmployeeDialog }
