import { Box, Stack, Table, TableBody, TableCell, TableRow } from '@mui/material'
import Typography from '@mui/material/Typography'
import { formatDate } from '../../util/date'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import * as React from 'react'
import { TExternalDocument } from '../../types/external-document'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import SignatureIcon from '../common/SignatureIcon'

type Props = {
  items: TExternalDocument[]
  onRowClick?: (documentId: string) => void
  variant: 'full' | 'select'
}

const ExtDocsList = ({ items, onRowClick, variant }: Props) => {
  return (
    <>
      <Table size="small">
        <TableBody>
          {items.map((document) => {
            return (
              <TableRow
                key={document.id}
                hover
                onClick={() => onRowClick && onRowClick(document.id)}
                sx={{ cursor: 'pointer' }}
              >
                <TableCell>
                  <Stack direction="row">
                    <Box sx={{ pr: 1 }}>
                      <ArticleOutlinedIcon fontSize="large" color={'disabled'} />
                    </Box>
                    <Box sx={{ flexGrow: 1 }}>
                      <div>
                        <Typography variant={'subtitle2'}>{document.name}</Typography>
                      </div>
                      <div>
                        <Typography variant={'caption'}>{document.number}</Typography>{' '}
                        <Typography variant={'caption'} color={'grey'}>
                          {formatDate(document.date)}
                        </Typography>
                      </div>
                    </Box>
                  </Stack>
                </TableCell>
                <TableCell sx={{ width: '50px' }}>
                  {document.file && <AttachFileIcon color="primary" />}
                </TableCell>
                <TableCell sx={{ width: '50px' }}>
                  {document.file?.signatures && document.file.signatures.length > 0 && <SignatureIcon color="success" />}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </>
  )
}

export default ExtDocsList
