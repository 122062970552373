import { auth } from '../../oauth/Auth'

export const addAuthorization = async (headers: Headers) => {
  const token = await auth.getToken()
  if (token) {
    headers.set('authorization', token)
    headers.set('Accept', 'application/json')
  }
  return headers
}
