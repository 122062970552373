import z, { string } from 'zod'

export const location = () =>
  z.record(z.string(), z.string().nullish()).refine((val) => Object.keys(val).length > 0, {
    message: 'Необходимо заполнить',
  })

export const positiveIntegerRequire = () =>
  string()
    .min(1, 'Необходимо заполнить')
    .refine((val) => /^[1-9]+$/.test(val), {
      message: 'Должно быть целым положительным числом',
    })
