import { Button } from '@mui/material'
import { Add } from '@mui/icons-material'
import { FormDialog } from '../common/FormDialog'
import * as React from 'react'
import { useState } from 'react'
import { TBuilding } from '../../types/building'
import { toast } from 'react-toastify'
import { useAddWorkMutation, useGetGroupsQuery } from '../../store/works'
import WorkForm from './WorkForm'

const AddWorkDialog = ({ building }: { building: TBuilding }) => {
  const id = 'add-work-dialog'
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const {
    data: groups,
    isSuccess: isGroupsSuccess,
    isLoading: isGroupsLoading,
    isError,
  } = useGetGroupsQuery(building.id)

  const [addWork] = useAddWorkMutation()

  if (!isGroupsSuccess) {
    return <></>
  }

  const selectGroupItems = Object.values(groups).map((group) => ({
    value: group.id,
    label: group.name,
  }))

  const handleSubmit = (values: any, methods: any) => {
    setIsLoading(true)
    addWork({ buildingId: building.id, body: values })
      .unwrap()
      .then(() => {
        methods.reset()
        setOpen(false)
        setIsLoading(false)
        toast.success('Работа добавлена')
      })
      .catch(() => {
        toast.error('Произошла ошибка')
        setIsLoading(false)
      })
  }

  return (
    <>
      <Button
        startIcon={<Add />}
        onClick={() => setOpen(true)}
        variant={'contained'}
        disabled={selectGroupItems.length === 0}
      >
        Добавить работу
      </Button>
      <FormDialog
        formId={id}
        open={open}
        onClose={() => setOpen(false)}
        title={'Добавить работу'}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <WorkForm id={id} onSubmit={handleSubmit} groups={selectGroupItems} />
      </FormDialog>
    </>
  )
}

export default AddWorkDialog
