import React from 'react'
import { ListItemIcon, MenuItem, MenuItemProps } from '@mui/material'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'

const MenuItemDelete = ({ children, ...overProps }: MenuItemProps) => {
  return (
    <MenuItem {...overProps}>
      <ListItemIcon>
        <DeleteOutlinedIcon />
      </ListItemIcon>
      {children}
    </MenuItem>
  )
}

export { MenuItemDelete }
