import * as React from 'react'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import {
  Badge,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
} from '@mui/material'
import ApartmentIcon from '@mui/icons-material/Apartment'
import { useAuth } from '../../oauth'
import { DocumentScanner, Logout, Mail, ManageAccounts } from '@mui/icons-material'
import { Link as RouterLink, useParams } from 'react-router-dom'
import { BuildingSiteBar } from '../../components/building/BuildingSiteBar'
import { useAuthorization } from '../../auth'

type Props = {
  toggleDrawer(open: boolean): React.KeyboardEventHandler | React.MouseEventHandler
  open: boolean
}

const LeftSidebar = (props: Props) => {
  const { isAuthenticated, login, logout } = useAuth()
  const authManager = useAuthorization()
  const { buildingId } = useParams<string>()
  const list = () => (
    <Box
      sx={{ width: 320 }}
      role="presentation"
      onClick={props.toggleDrawer(false) as React.MouseEventHandler<HTMLDivElement>}
      onKeyDown={props.toggleDrawer(false) as React.KeyboardEventHandler<HTMLDivElement>}
    >
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >
        <IconButton
          onClick={props.toggleDrawer(false) as React.MouseEventHandler<HTMLButtonElement>}
        >
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>

      <Divider />

      <List>
        <ListItemButton to="/" component={RouterLink}>
          <ListItemIcon>
            <ApartmentIcon />
          </ListItemIcon>
          <ListItemText primary={'Объекты'} />
        </ListItemButton>
        <ListItemButton to="/documents" component={RouterLink}>
          <ListItemIcon>
            <Badge badgeContent={4} color="error">
              <DocumentScanner />
            </Badge>
          </ListItemIcon>
          <ListItemText primary={'Документы на подпись'} />
        </ListItemButton>
      </List>
      {buildingId && (
        <>
          <Divider />
          <BuildingSiteBar buildingId={buildingId} />
        </>
      )}
      <Divider />
      <List>
        <ListItemButton>
          <ListItemIcon>
            <ManageAccounts />
          </ListItemIcon>
          <ListItemText primary={'Профиль'} />
        </ListItemButton>
        <ListItemButton onClick={logout}>
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          <ListItemText primary={'Выйти'} />
        </ListItemButton>
      </List>
    </Box>
  )

  return (
    <SwipeableDrawer
      open={props.open}
      onClose={props.toggleDrawer(false)}
      onOpen={props.toggleDrawer(true)}
    >
      {list()}
    </SwipeableDrawer>
  )
}

export default LeftSidebar
