import { TExternalDocument } from '../../types/external-document'
import {
  Card,
} from '@mui/material'
import { useGetProfilesQuery } from '../../store/buildings'
import { FileSignatures } from '../file/FileSignatures'

type Props = {
  document: TExternalDocument
}


const ExtDocSignatures = ({ document }: Props) => {

  if (!document.file || !document.file.signatures || document.file.signatures.length === 0) {
    return <></>
  }

  return (
    <Card variant={'outlined'}>
      <FileSignatures file={document.file} />
    </Card>
  )
}
export default ExtDocSignatures
