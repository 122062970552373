import { FormDialog } from '../common/FormDialog'
import * as React from 'react'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { useEditAmountMutation } from '../../store/works'
import { TMaterialAmount, TWork } from '../../types/works'
import VolumeForm from './VolumeForm'
import { TUnit } from '../../types/lists'

type Props = {
  work: TWork
  ma: TMaterialAmount
  min: number
  unit: TUnit
  onEdit: () => void
  onClose: () => void
  open: boolean
}

const EditVolumeDialog = ({ work, ma, min, unit, open, onEdit, onClose }: Props) => {
  const id = 'edit-volume-dialog'
  const [isLoading, setIsLoading] = useState(false)

  const [editAmount] = useEditAmountMutation()

  const handleSubmit = (values: any, methods: any) => {
    setIsLoading(true)

    editAmount({
      buildingId: work.building_id,
      body: { ...values, work_id: work.id, wma_id: ma.id },
    })
      .unwrap()
      .then(() => {
        methods.reset()
        setIsLoading(false)
        onEdit()
      })
      .catch((e) => {
        toast.error(e)
        setIsLoading(false)
      })
  }

  return (
    <>
      <FormDialog
        formId={id}
        open={open}
        onClose={onClose}
        title={'Изменить объем'}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <VolumeForm id={id} onSubmit={handleSubmit} value={ma.value} min={min} unit={unit} />
      </FormDialog>
    </>
  )
}

export default EditVolumeDialog
