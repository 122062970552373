import { IDocument } from '../../types/document'
import { useGetXmlQuery, useResetMutation, useSignMutation } from '../../store/document'
import { CardContent, List, ListItem, ListItemAvatar, ListItemText, Skeleton } from '@mui/material'
import { SignDialog } from './SignDialog'
import Avatar from '@mui/material/Avatar'
import { green, grey } from '@mui/material/colors'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { formatDateTime } from '../../util/date'
import { useGetUserQuery } from '../../store/user'
import { useGetEmployeesQuery } from '../../store/employees'
import { useGetProfileQuery } from '../../store/profile'
import { TEmployee } from '../../types/building'
import React, { useState } from 'react'
import { LightButton, LightLoadingButton } from '../common/LightButton'
import { toastError } from '../../util/toastError'
import { useApi } from '../../api/useApi'

const signerName = (employees: TEmployee[], employee_id: string): string => {
  return employees.find((em) => em.id === employee_id)?.profile.name.full_name || ''
}

type Props = {
  document: IDocument
  onSign?: () => void
  isOwner: boolean
}
const ReadySign = ({ document, onSign, isOwner }: Props) => {
  const { data: user, isSuccess: isUserSuccess, isLoading: isUserLoading } = useGetUserQuery()
  const [reset] = useResetMutation()
  const [sign] = useSignMutation()
  const { data: employees } = useGetEmployeesQuery(document.building_id)
  const { data: profile } = useGetProfileQuery()
  const [isLoading, setIsLoading] = useState(false)

  const isSignProcess = ['ready', 'sign_start'].includes(document.status)


  const handleSign = (signature: any) => {

    sign({
      buildingId: document.building_id,
      body: {
        document_id: document.id,
        signature,
      },
    })
      .unwrap()
      .then((res) => {
        onSign && onSign()
      })
  }

  const handleReset = () => {
    setIsLoading(true)
    reset({
      buildingId: document.building_id,
      body: {
        document_id: document.id,
      },
    })
      .unwrap()
      .then((res) => {
        onSign && onSign()
      })
      .catch(toastError)
      .finally(() => setIsLoading(false))
  }

  return (
    <>
      <CardContent>
        <List>
          <ListItem disableGutters>
            <b>Подписанты</b>
          </ListItem>
          {document.signatures &&
            document.signatures.map((signature) => (
              <ListItem
                disableGutters
                key={signature.id}
                secondaryAction={
                  user && signature.user_id === user.id && profile && !signature.is_signed ? (
                    <SignDialog
                      document={document}
                      profile={profile}
                      onSign={handleSign}
                    />
                  ) : null
                }
              >
                <ListItemAvatar>
                  <Avatar
                    sx={signature.is_signed ? { bgcolor: green[500] } : { bgcolor: grey[500] }}
                  >
                    {signature.is_signed ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={employees && signerName(employees, signature.employee_id)}
                  secondary={
                    signature.is_signed
                      ? 'подписан: ' + formatDateTime(signature.signed_time)
                      : 'не подписан'
                  }
                />
              </ListItem>
            ))}
        </List>
      </CardContent>
      {isOwner && isSignProcess && (
        <CardContent>
          <LightLoadingButton onClick={handleReset} loading={isLoading}>
            Сбросить
          </LightLoadingButton>
        </CardContent>
      )}
    </>
  )
}

export { ReadySign }
