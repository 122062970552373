import { employeeRoles, TBuilding, TCompany, TEmployee } from '../../types/building'
import {
  Box,
  Card,
  CardHeader,
  Chip,
  LinearProgress,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material'
import React, { useMemo } from 'react'
import { useGetEmployeesQuery } from '../../store/employees'
import { AddEmployeeDialog } from './AddEmployeeDialog'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'
import { ColoredAvatar } from '../common/ColoredAvatar'
import { useAuthorization } from '../../auth'
import GradingIcon from '@mui/icons-material/Grading'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

const isM4d = (m4d: string[], employee: TEmployee) => {
  if (!employee.profile || !employee.profile.inn) return false
  return m4d.includes(employee.profile.inn)
}

const isActual = (dateBegin: string, dateEnd: string) => {
  const now = Date.now()
  return new Date(dateBegin).getTime() <= now && now <= new Date(dateEnd).getTime()
}

export const Employees = ({ building, company }: { building: TBuilding; company: TCompany }) => {
  const authManager = useAuthorization()

  const { data: employeesAll, isLoading } = useGetEmployeesQuery(building.id)

  const isAdmin: boolean = authManager.vote('Employee', 'manage')

  const navigate = useNavigate()

  const m4dInn = useMemo(() => {
    let inn: string[] = []
    company.m4d.forEach((m4d) => {
      if (isActual(m4d.date_begin, m4d.date_end)) {
        inn = [...inn, ...m4d.inn]
      }
    })
    return inn.filter((value, index, array) => array.indexOf(value) === index)
  }, [company])

  const handleClick = (id: string) => {
    if (isAdmin) {
      navigate(`employee/${id}`)
    }
  }

  if (isLoading || employeesAll === undefined) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    )
  }

  const employees = employeesAll.filter((employee) => employee.company_id === company.id)

  return (
    <>
      <TableContainer component={Card}>
        <CardHeader
          action={isAdmin && <AddEmployeeDialog building={building} company={company} />}
          title={'Сотрудники'}
          subheader={'участники'}
        />
        <Table>
          <TableBody>
            {employees.map((employee) => {
              return (
                <TableRow
                  key={employee.id}
                  sx={{ cursor: 'pointer' }}
                  hover
                  onClick={() => handleClick(employee.id)}
                >
                  <TableCell>
                    <Stack direction="row">
                      <Box sx={{ pr: 1 }}>
                        <ColoredAvatar text={employee.profile.name.full_name} />
                      </Box>
                      <Box sx={{ flexGrow: 1 }}>
                        <div>
                          <Typography variant={'subtitle2'}>
                            {employee.profile.name.full_name || employee.id}
                          </Typography>
                        </div>
                        <div>
                          <Typography variant={'caption'}>{employee.position}</Typography>
                        </div>
                        <div>
                          <Typography variant={'caption'} display={'block'} color={'grey'}>
                            {employeeRoles[employee.role]}
                          </Typography>
                        </div>
                        <div>
                          {isM4d(m4dInn, employee) ? (
                            <Typography
                              variant={'caption'}
                              color={'green'}
                              alignItems={'center'}
                              display={'flex'}
                            >
                              <CheckCircleOutlineIcon color={'success'} /> <span>Есть в МЧД</span>
                            </Typography>
                          ) : (
                            <Typography
                              variant={'caption'}
                              color={'error'}
                              alignItems={'center'}
                              display={'flex'}
                            >
                              <WarningAmberIcon color={'error'} />{' '}
                              <span>Отсутствует в действующей МЧД</span>
                            </Typography>
                          )}
                        </div>
                      </Box>
                    </Stack>
                  </TableCell>
                  <TableCell>
                    {employee.administrative_document && (
                      <span
                        title={`${employee.administrative_document.document.name} ${employee.administrative_document.document.number}`}
                      >
                        <GradingIcon color={'success'} />
                      </span>
                    )}
                  </TableCell>
                  <TableCell align={'right'}>
                    {employee.status === 'active' ? (
                      <Chip color="success" label="Активен" size="small" />
                    ) : (
                      <Chip color="warning" label="Отключен" size="small" />
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
