import { TWork } from '../../types/works'
import { useGetStoreQuery } from '../../store/store'
import { useMemo } from 'react'
import {
  Box,
  Card,
  CardContent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import AddMaterialAmountDialog from './AddMaterialAmountDialog'
import { MaterialAmountRow } from './MaterialAmountRow'
import * as React from 'react'
import { useGetMaterialsQuery, useGetUnitsQuery } from '../../store/lists'

type Props = {
  work: TWork
  isManage: boolean
}
const MaterialAmounts = ({ work, isManage }: Props) => {
  const { data: store } = useGetStoreQuery(work.building_id)
  const { data: units } = useGetUnitsQuery(work.building_id)
  const { data: materials } = useGetMaterialsQuery(work.building_id)

  const storeValues = useMemo(() => {
    const res: Record<string, number> = {}
    if (store) {
      store.forEach((s) => {
        s.debits.forEach((d) => {
          if (!res[d.wma_id]) {
            res[d.wma_id] = 0
          }
          res[d.wma_id] += d.value
        })
      })
    }
    return res
  }, [store])

  const sortedList = useMemo(() => {
    if (materials && work.materials_amounts && work.materials_amounts?.length > 0) {
      return Array.from(work.materials_amounts).sort((a, b) =>
        materials[a.material_id] && materials[b.material_id]
          ? materials[a.material_id].name.localeCompare(materials[b.material_id].name)
          : 1
      )
    }
    return []
  }, [materials, work.materials_amounts])

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant={'body1'} sx={{ fontWeight: 'medium' }}>
            {'Объёмы материалов:'}
          </Typography>
          {isManage && <AddMaterialAmountDialog work={work} />}
        </Stack>
      </CardContent>
      <CardContent sx={{ p: 0 }}>
        <Table size={'small'}>
          <TableHead>
            <TableRow>
              <TableCell>Наименование</TableCell>
              <TableCell>Объем</TableCell>
              <TableCell>Выполнено</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          {units && (
            <TableBody>
              {sortedList.map((ma) => (
                <MaterialAmountRow
                  key={ma.id}
                  work={work}
                  ma={ma}
                  unit={units[ma.unit_id]}
                  done={storeValues[ma.id] || 0}
                />
              ))}
            </TableBody>
          )}
        </Table>
      </CardContent>
    </Card>
  )
}

export { MaterialAmounts }
