import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { addAuthorization } from '../../util/http/addAuthorization'
import { IProfile } from './types'

export const profileApi = createApi({
  reducerPath: 'profile',
  tagTypes: ['Profile'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1/',
    prepareHeaders: addAuthorization,
    validateStatus: (response) => response.ok,
  }),
  endpoints: (builder) => ({
    getProfile: builder.query<IProfile | null, void>({
      query: () => ({
        url: '/profile',
        method: 'GET',
      }),
      providesTags: ['Profile'],
    }),
    addProfile: builder.mutation({
      query: (body) => ({
        url: '/profile',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Profile'],
    }),
    addCertificate: builder.mutation({
      query: (body) => ({
        url: '/profile/add-certificate',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Profile'],
    }),
    removeCertificate: builder.mutation({
      query: (body) => ({
        url: '/profile/remove-certificate',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Profile'],
    }),
    addTheme: builder.mutation({
      query: (body) => ({
        url: '/profile/add-theme',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Profile'],
    }),
  }),
})

export const {
  useGetProfileQuery,
  useAddProfileMutation,
  useAddCertificateMutation,
  useRemoveCertificateMutation,
  useAddThemeMutation,
} = profileApi
