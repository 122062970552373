import { TWork, IWorkPrd } from '../../types/works'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import * as React from 'react'
import { Add, Delete } from '@mui/icons-material'
import PrdDocumentSelectDialog from '../prd/PrdDocumentSelectDialog'
import { useMemo, useState } from 'react'
import PrdDocLine from '../prd/PrdDocLine'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { AccordionHeader } from '../journals/gwj/part3/AccordionHeader'
import { CountChip } from '../journals/gwj/part3/CountChip'
import { TPrdDocument, TPrdType } from '../../types/prd'
import { useGetDocumentsQuery } from '../../store/prd'
import { useAddWorkPrdMutation, useRemoveWorkPrdMutation } from '../../store/works'
import { toastError } from '../../util/toastError'
import { LightButton } from '../common/LightButton'

type Props = {
  work: TWork
  isManage: boolean
  type: TPrdType
}
const WorkPrd = ({ work, isManage, type }: Props) => {
  const [openAdd, setOpenAdd] = useState(false)
  const { data: docs } = useGetDocumentsQuery(work.building_id)

  const [addPrd] = useAddWorkPrdMutation()
  const [deletePrd] = useRemoveWorkPrdMutation()

  const handleSelect = (values: any) => {
    console.log(values)
    addPrd({
      buildingId: work.building_id,
      body: {
        ...values,
        work_id: work.id,
      },
    })
      .unwrap()
      .then(() => {
        setOpenAdd(false)
      })
      .catch(toastError)
  }

  const removeDocHandler = (id: string) => {
    deletePrd({
      buildingId: work.building_id,
      body: {
        work_id: work.id,
        document_id: id,
      },
    })
      .unwrap()
      .catch(toastError)
  }

  interface twp extends IWorkPrd {
    doc: TPrdDocument | undefined
  }

  const workPrd: twp[] = useMemo(() => {
    if (!docs || !work.prd) return []

    return work.prd
      .map((wp) => ({ ...wp, doc: docs.find((d) => wp.document_id === d.id) }))
      .filter((wp) => wp.doc && wp.doc.type === type)
  }, [docs, work, type])

  return (
    <Card sx={{ mb: 2 }}>
      <CardHeader
        titleTypographyProps={{ variant: 'body1', sx: { fontWeight: 'medium' } }}
        title={'Разделы ' + (type === 'pd' ? 'ПД' : 'РД')}
      />

      <CardContent sx={{ p: 0 }}>
        <Table size={'small'}>
          <TableHead>
            <TableRow>
              <TableCell>Раздел/шифр</TableCell>
              <TableCell width={'50%'}>Листы</TableCell>
              {isManage && (
                <TableCell align="right">
                  <LightButton startIcon={<Add />} onClick={() => setOpenAdd(true)}>
                    Добавить
                  </LightButton>
                  <PrdDocumentSelectDialog
                    buildingId={work.building_id}
                    type={type}
                    open={openAdd}
                    onClose={() => setOpenAdd(false)}
                    onSelect={handleSelect}
                  />
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          {workPrd.map((wp) => (
            <TableRow key={wp.document_id}>
              <TableCell>
                {wp.doc && <PrdDocLine document={wp.doc} />}
                <Typography variant="subtitle2" color="text.disabled">
                  {wp.doc && wp.doc.code}
                </Typography>
              </TableCell>
              <TableCell>{wp.lists?.length && wp.lists.join(', ')}</TableCell>
              {isManage && (
                <TableCell align="right">
                  <IconButton
                    color="error"
                    title="Удалить"
                    onClick={() => removeDocHandler(wp.document_id)}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}
        </Table>
      </CardContent>
    </Card>
  )
}

export { WorkPrd }
