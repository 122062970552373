import { Outlet, useOutletContext, useParams } from 'react-router-dom'
import { TCompany } from '../../../types/building'
import React, { useMemo } from 'react'
import AlertError from '../../../components/alert/AlertError'
import { useGetCompaniesQuery } from '../../../store/companies'
import { Box, LinearProgress } from '@mui/material'
import { IOutletBuilding } from '../../../types/outlet-context'

export const Company = () => {
  const { building } = useOutletContext<IOutletBuilding>()
  const { companyId } = useParams()
  const { data: companies, isLoading } = useGetCompaniesQuery(building.id)

  const company = useMemo<TCompany | undefined>((): TCompany | undefined => {
    return companies && companies.find((c) => c.id === companyId)
  }, [companyId, companies])

  if (isLoading || companies === undefined) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    )
  }

  if (!company) {
    return <AlertError>Компания не найдена</AlertError>
  }

  return <Outlet context={{ building, company }} />
}
