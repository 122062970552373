import { TExternalDocument } from '../../types/external-document'
import { IProfile } from '../../store/profile/types'
import { FileSignButton } from '../file/FileSignButton'


type Props = {
  profile: IProfile
  document: TExternalDocument
  onSign?: () => void
}

const ExtDocSignButton = ({ document, onSign }: Props) => {

  if (!document.file) {
    return <></>
  }
  return (
    <>
      <FileSignButton file={document.file} onSign={onSign} />
    </>
  )
}
export default ExtDocSignButton
