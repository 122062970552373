import { TBaseDocumentType, TDocumentType } from './document'

export type TPolicyObject =
  | 'Building'
  | 'Company'
  | 'Employee'
  | 'BuildingUser'
  | 'ExternalDocument'
  | 'Work'
  | 'Prd'
  | 'Gwj'
  | 'Imc'
  | 'Policy'
  | 'Document'
  | `Document.${TDocumentType}`
  | 'XMLDocument'
  | `XMLDocument.${TBaseDocumentType}`


type TAction = 'create' | 'manage' | 'delete' | 'update' | 'view'

export interface TPolicyItem {
  role: string
  object: string
  action: TAction
  access: boolean
}

export interface TPolicyItemWithId extends TPolicyItem{
  id: string
}

export type TAuth = {
  policy: TPolicyItem[]
  roles: string[]
}


export const actionsLabels: Record<TAction, string> = {
  create: 'создание',
  manage: 'управление',
  delete: 'удаление',
  update: 'редактирование',
  view: 'просмотр'
}

export interface TPolicyObjectDataItem {
  object: TPolicyObject,
  label: string,
  actions: TAction[]
}

export const policyObjectsData: TPolicyObjectDataItem[] = [
  {object: 'Building', label: 'Управление объектом', actions: ['manage']},
  {object: 'Company', label: 'Управление компаниями', actions: ['manage']},
  {object: 'Employee', label: 'Управление сотрудниками', actions: ['manage']},
  {object: 'BuildingUser', label: 'Управление пользователями объекта', actions: ['manage']},
  {object: 'Work', label: 'Управление работами', actions: ['manage']},
  {object: 'Prd', label: 'ПД/РД', actions: ['manage']},
  // {object: 'ExternalDocument', label: 'Внешние документы', actions: ['create']},
  // {object: 'Document', label: 'Все документы', actions: ['manage']},
  {object: 'Gwj', label: 'ОЖР управление', actions: ['manage']},
  {object: 'Document.TitleChangesListItem', label: 'ОЖР лист изменений', actions: ['create', 'manage']},
  {object: 'Document.SupervisoryAuthorityRegistry', label: 'ОЖР регистрационная надпись', actions: ['manage']},
  {object: 'Document.GWJ1Item', label: 'ОЖР раздел 1', actions: ['create', 'manage']},
  {object: 'Document.GWJ2Item', label: 'ОЖР раздел 2', actions: ['create', 'manage']},
  {object: 'Document.GWJ3Item', label: 'ОЖР раздел 3', actions: ['create', 'manage']},
  {object: 'Document.GWJ4Item', label: 'ОЖР раздел 4', actions: ['create', 'manage']},
  {object: 'Document.GWJ6Item', label: 'ОЖР раздел 6', actions: ['create', 'manage']},
  {object: 'Document.GWJ7Item', label: 'ОЖР раздел 7', actions: ['create', 'manage']},
  {object: 'Imc', label: 'ЖВК управление', actions: ['manage']},
  {object: 'Document.IMCItem', label: 'ЖВК записи', actions: ['create', 'manage']},
]
