import React from 'react'
import { ListItemIcon, MenuItem, MenuItemProps } from '@mui/material'
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined'

const MenuItemEdit = ({ children, ...overProps }: MenuItemProps) => {
  return (
    <MenuItem {...overProps}>
      <ListItemIcon>
        <EditNoteOutlinedIcon />
      </ListItemIcon>
      {children}
    </MenuItem>
  )
}

export { MenuItemEdit }
