import { Avatar, Link, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, ListSubheader } from '@mui/material'
import SignatureIcon from '../common/SignatureIcon'
import { formatDateTime } from '../../util/date'
import { TFile, TFileSignature } from '../../types/file'
import { useGetProfilesQuery } from '../../store/buildings'
import { TProfile } from '../../types/Profile'
import { download } from '../../util/download'

const downloadLink = (
  profiles: Record<string, TProfile>,
  signature: TFileSignature,
  file?: TFile
) => {
  if (!file) return null
  const name = profiles ? profiles[signature.user_id]?.name.full_name : ''
  const handleClick = async () => {
    await download(
      `/api/v1/buildings/${file.building_id}/files/${file.id}/signature/${signature.id}`,
      `${name}.sig`,
      'application/x-pkcs7-signature',
      'GET'
    )
  }
  return (
    <Link href={'#'} onClick={handleClick}>
      {name}
    </Link>
  )
}

type  Props = {
  file: TFile
}
export const FileSignatures = ({file}: Props) => {

  const { data: profiles } = useGetProfilesQuery(file.building_id)

  if (!file.signatures) return <></>

  return(
    <List
      sx={{ width: '100%'}}
      subheader={<ListSubheader component="div">Подписи</ListSubheader>}
    >
      {file.signatures.map((signature) => (
        <ListItem key={signature.id}>
          <ListItemIcon>
            <SignatureIcon color={"success"} />
          </ListItemIcon>
          <ListItemText
            primary={profiles && downloadLink(profiles, signature, file)}
            secondary={formatDateTime(signature.date)}
          />
        </ListItem>
      ))}
    </List>
  )
}
