import * as React from 'react'
import { useGetExternalDocumentsQuery } from '../../store/externalDocuments'
import { TBuilding } from '../../types/building'
import { Box, Card, CardContent, CardHeader, LinearProgress, Stack, TableContainer } from '@mui/material'
import { RightPanel } from '../common'
import { AddDialog, DocumentView } from './index'
import { useEffect, useMemo, useState } from 'react'
import { useGetUserQuery } from '../../store/user'
import ExtDocActions from './ExtDocActions'
import ExtDocsList from './ExtDocsList'
import { TFilterSetting, useFilter } from '../filter/useFilter'
import { extDocTypesList, TExternalDocumentType } from '../../types/external-document'
import { FilterButton } from '../filter/FilterButton'
import { FilterPanel } from '../filter/FilterPanel'
import { FilterChips } from '../filter/FilterChips'


const typeItems = Object.keys(extDocTypesList).map((key) => ({
    label: extDocTypesList[key as TExternalDocumentType],
    value: key,
  }))


const filterSettings: TFilterSetting[] = [
  {name: 'type', label: 'Тип', filter: 'list', data: typeItems},
  {name: 'name', label: 'Наименование', filter: 'string'},
  {name: 'number', label: 'Номер', filter: 'string'},
  {name: 'date', label: 'Дата', filter: 'dateRange'}
]

const ExternalDocuments = ({ building }: { building: TBuilding }) => {
  const { data, isLoading, refetch } = useGetExternalDocumentsQuery({ buildingId: building.id })
  const { data: user } = useGetUserQuery()

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [currentId, setCurrentId] = useState<string | undefined>()

  const currentDocument = useMemo(() => {
    return data && data.items.find((document) => document.id === currentId)
  }, [data, currentId])

  const userData = useMemo(() => data?.items && user ? data.items.filter(i => i.owner_id === user.id) : [], [data])

  const [filterOpen, setFilterOpen] = useState(false)
  const filter = useFilter(filterSettings)
  useEffect(() => {
    if (userData) {
      filter.setItems(userData)
    }
  }, [userData, filter])

  if (isLoading || data === undefined || user === undefined) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    )
  }

  const viewDocument = (id: string) => {
    setCurrentId(id)
    setDrawerOpen(true)
  }

  const onRemove = () => {
    setCurrentId(undefined)
    setDrawerOpen(false)
  }

  const onSaveDocument = (id: string) => {
    setCurrentId(id)
  }

  const handleAdd = (res: any) => {
    viewDocument(res.id)
  }

  return (
    <>
      <TableContainer component={Card}>
        <CardHeader
          title={'Внешние документы'}
          subheader={''}
          action={<Stack direction={'row'} spacing={2}>
            <FilterButton count={filter.state.count} onClick={() => setFilterOpen(true)} />
            <AddDialog building={building} onAdd={handleAdd} />
          </Stack>}
        />
        <CardContent>
          <FilterChips filter={filter} />
        </CardContent>
        <ExtDocsList
          items={filter.filtered}
          variant={'full'}
          onRowClick={viewDocument}
        />
      </TableContainer>
      <FilterPanel
        open={filterOpen}
        onOpen={() => setFilterOpen(true)}
        onClose={() => setFilterOpen(false)}
        filter={filter}
      />
      <RightPanel
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
        actions={
          currentDocument && (
            <ExtDocActions document={currentDocument} onSave={onSaveDocument} onRemove={onRemove} onSign={refetch} />
          )
        }
      >
        <DocumentView document={currentDocument} />
      </RightPanel>
    </>
  )
}

export default ExternalDocuments
