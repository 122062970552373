import { TBuilding } from '../../types/building'
import { TPrdType } from '../../types/prd'
import { Card, CardContent, CardHeader, Grid, IconButton } from '@mui/material'
import { GroupsMenu } from './GroupsMenu'
import ControlPointIcon from '@mui/icons-material/ControlPoint'
import React, { useMemo, useState } from 'react'
import PrdGroupAddDialog from './PrdGroupAddDialog'
import { useGetGroupsQuery } from '../../store/prd'
import { PrdGroup } from './PrdGroup'
import { useAuthorization } from '../../auth'
import { LightButton } from '../common/LightButton'
import { Add } from '@mui/icons-material'

type Props = {
  building: TBuilding
  type: TPrdType
}
const Prd = ({ building, type }: Props) => {
  const [openAddGroup, setOpenAddGroup] = useState(false)
  const [groupId, setGroupId] = useState<string>('')
  const { data: groups, isLoading } = useGetGroupsQuery(building.id)

  const authManager = useAuthorization()

  const isManage = authManager.vote('Prd', 'manage')

  const group = useMemo(() => {
    if (!groups || !groupId) return null
    return groups.find((g) => g.id === groupId)
  }, [groupId, groups])

  if (isLoading) {
    return <></>
  }

  return (
    <>
      <Grid container spacing={2} alignItems={'stretch'}>
        <Grid item xs={12} md={3}>
          <Card>
            <CardHeader
              subheader={'Разделы'}
              action={
                isManage && (
                  <LightButton
                    startIcon={<Add />}
                    onClick={() => setOpenAddGroup(!openAddGroup)}
                    title={'Добавить раздел'}
                    color={'primary'}
                  >
                    Добавить раздел
                  </LightButton>
                )
              }
            />
            <CardContent>
              {groups && (
                <GroupsMenu
                  building={building}
                  groups={groups}
                  type={type}
                  onSelect={(id) => setGroupId(id)}
                  isManage={isManage}
                />
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={9}>
          {group && <PrdGroup building={building} group={group} isManage={isManage} />}
        </Grid>
      </Grid>
      {isManage && (
        <PrdGroupAddDialog
          building={building}
          type={type}
          open={openAddGroup}
          onClose={() => setOpenAddGroup(false)}
          onSave={(id) => {
            setOpenAddGroup(false)
          }}
        />
      )}
    </>
  )
}
export { Prd }
