import { TBuilding } from '../../types/building'
import { useGetGroupsQuery, useGetWorksQuery, useSortGroupsMutation } from '../../store/works'
import { Box, Button, Card, CardHeader, LinearProgress, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { GroupItem } from './GroupItem'
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensors,
  useSensor,
  closestCenter,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import AddGroupDialog from './AddGroupDialog'
import AddWorkDialog from './AddWorkDialog'
import { RightPanel } from '../common'
import { TWork, TWorkGroup } from '../../types/works'
import { WorkView } from './WorkView'
import EditGroupDialog from './EditGroupDialog'
import EditWorkDialog from './EditWorkDialog'
import { BaseContainer } from '../common/Container'
import { useAuthorization } from '../../auth'

const actions = (building: TBuilding, isGroups: boolean) => {
  return (
    <Stack direction="row" spacing={1}>
      <AddGroupDialog building={building} />
      <AddWorkDialog building={building} />
    </Stack>
  )
}

export const WorksList = ({ building }: { building: TBuilding }) => {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [currentWorkId, setCurrentWorkId] = useState<string | undefined>()
  const [items, setItems] = useState<string[]>([])
  const [editGroup, setEditGroup] = useState<TWorkGroup | null>()
  const [editWork, setEditWork] = useState<TWork | null>()

  const authManager = useAuthorization()

  const isManage = authManager.vote('Work', 'manage')

  const {
    data: works,
    isSuccess: isWorksSuccess,
    isLoading: isWorksLoading,
  } = useGetWorksQuery(building.id)

  const {
    data: groups,
    isSuccess: isGroupsSuccess,
    isLoading: isGroupsLoading,
    isError,
  } = useGetGroupsQuery(building.id)

  const [sortGroups] = useSortGroupsMutation()

  const isSuccess = isGroupsSuccess && isWorksSuccess
  const isLoading = isGroupsLoading || isWorksLoading

  useEffect(() => {
    if (isSuccess && groups) {
      const items = Object.keys(groups)
      items.sort((a, b) => groups[a].pos - groups[b].pos)
      setItems(items)
    }
  }, [isSuccess, groups])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  if (isLoading) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    )
  }

  const newItems = (items: string[], active: string, over: string) => {
    const oldIndex = items.indexOf(active)
    const newIndex = items.indexOf(over)
    return arrayMove(items, oldIndex, newIndex)
  }

  function handleDragEnd(event: any) {
    const { active, over } = event
    if (active.id !== over.id) {
      const sortedItems = newItems(items, active.id, over.id)
      setItems(sortedItems)
      sortGroups({ buildingId: building.id, body: { groups: sortedItems } })
    }
  }

  const handleWorkClick = (work: TWork) => {
    setDrawerOpen(true)
    setCurrentWorkId(work.id)
  }

  return (
    <BaseContainer>
      {groups && works && (
        <Card>
          <CardHeader
            title={'Работы'}
            action={isManage && groups && actions(building, Object.keys(groups).length > 0)}
          />
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext items={items} strategy={verticalListSortingStrategy}>
              {items.map(
                (item, index) =>
                  groups[item] && (
                    <GroupItem
                      isManage={isManage}
                      group={groups[item]}
                      groupIndex={index}
                      building={building}
                      works={works}
                      key={groups[item].id}
                      sortKey={item}
                      onWorkClick={handleWorkClick}
                      onEditGroup={(group) => setEditGroup(group)}
                      onEditWork={(work) => setEditWork(work)}
                    />
                  )
              )}
            </SortableContext>
          </DndContext>
        </Card>
      )}
      <RightPanel
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
      >
        {works && currentWorkId && <WorkView work={works[currentWorkId]} isManage={isManage} />}
      </RightPanel>
      {editGroup && isManage && (
        <EditGroupDialog
          group={editGroup}
          open={Boolean(editGroup)}
          onClose={() => setEditGroup(null)}
          onEdit={() => setEditGroup(null)}
        />
      )}

      {editWork && isManage && (
        <EditWorkDialog
          work={editWork}
          open={Boolean(editWork)}
          onClose={() => setEditWork(null)}
          onEdit={() => setEditWork(null)}
        />
      )}
    </BaseContainer>
  )
}
