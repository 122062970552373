import { TWork } from '../../types/works'
import { TBuilding } from '../../types/building'
import {
  Box,
  Divider,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import { DragHandle } from '@mui/icons-material'
import React, { useState } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { MenuItemDelete, MenuItemEdit, MoreVertMenu } from '../menu'
import { useDeleteWorkMutation } from '../../store/works'
import { toast } from 'react-toastify'
import { toastError } from '../../util/toastError'

type TProps = {
  work: TWork
  groupIndex: number
  workIndex: number
  building: TBuilding
  sortKey: any
  onClick: (work: TWork) => void
  onEditWork: (work: TWork) => void
  isManage: boolean
}

export const WorkItem = ({
  work,
  groupIndex,
  workIndex,
  sortKey,
  onClick,
  onEditWork,
  isManage,
}: TProps) => {
  const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef } =
    useSortable({ id: sortKey })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  const [menuOpen, setMenuOpen] = useState(false)
  const handleEdit = () => {
    setMenuOpen(false)
    onEditWork(work)
  }

  const [deleteWork] = useDeleteWorkMutation()
  const handleDelete = () => {
    setMenuOpen(false)
    deleteWork({ buildingId: work.building_id, body: { work_id: work.id } })
      .unwrap()
      .then((res) => {
        toast.success('Работа удалена')
      })
      .catch(toastError)
  }

  return (
    <div style={style} {...attributes} ref={setNodeRef}>
      <List component="div" disablePadding>
        <ListItem
          secondaryAction={
            isManage && (
              <MoreVertMenu
                open={menuOpen}
                onOpen={() => setMenuOpen(true)}
                onClose={() => setMenuOpen(false)}
              >
                <MenuItemEdit onClick={handleEdit}>Редактировать</MenuItemEdit>
                <MenuItemDelete onClick={handleDelete}>Удалить</MenuItemDelete>
              </MoreVertMenu>
            )
          }
          sx={{ ':hover': { backgroundColor: 'action.hover' } }}
        >
          <ListItemIcon
            ref={setActivatorNodeRef}
            {...listeners}
            sx={{ cursor: 'move', display: isManage ? 'inline-flex' : 'none' }}
          >
            <DragHandle />
          </ListItemIcon>
          <ListItemIcon></ListItemIcon>
          <ListItemText sx={{ pl: 1 }}>
            <Stack direction={'row'}>
              <Typography variant={'body2'} sx={{ pr: 1 }}>
                {groupIndex + 1}.{workIndex + 1}.
              </Typography>
              <Box>
                <Link href={'#' + work.id} onClick={() => onClick(work)} underline={'hover'}>
                  <Typography variant="body2" component="div">
                    {work.name}
                  </Typography>
                </Link>
                <Typography variant="body2" color="text.disabled">
                  {work.description}
                </Typography>
              </Box>
            </Stack>
          </ListItemText>
        </ListItem>
      </List>
      <Divider />
    </div>
  )
}
