import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { Link } from 'react-router-dom'
import * as React from 'react'
import { useGetBuildingQuery } from '../../store/buildings'
import ImportContactsIcon from '@mui/icons-material/ImportContacts'
import EngineeringIcon from '@mui/icons-material/Engineering'
import FileOpenIcon from '@mui/icons-material/FileOpen'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import { FileCheck } from '../icons'

type Props = {
  buildingId: string
}

export const BuildingSiteBar = ({ buildingId }: Props) => {
  const {
    data: building,
    isSuccess,
    isUninitialized,
    isFetching,
  } = useGetBuildingQuery(buildingId as string, { skip: !buildingId })

  if (buildingId === undefined) {
    return <></>
  }

  return (
    <>
      {isSuccess && (
        <>
          <List>
            <ListItemButton component={Link} to={`/building/${building.id}/data`}>
              <ListItemIcon>
                <CorporateFareIcon />
              </ListItemIcon>
              <ListItemText primary={'Объект'} />
            </ListItemButton>
            <ListItemButton component={Link} to={`/building/${building.id}/works`}>
              <ListItemIcon>
                <EngineeringIcon />
              </ListItemIcon>
              <ListItemText primary={'Работы'} />
            </ListItemButton>
            <ListItemButton component={Link} to={`/building/${building.id}/journals`}>
              <ListItemIcon>
                <ImportContactsIcon />
              </ListItemIcon>
              <ListItemText primary={'Журналы'} />
            </ListItemButton>
            <ListItemButton component={Link} to={`/building/${building.id}/acts`}>
              <ListItemIcon>
                <FileCheck />
              </ListItemIcon>
              <ListItemText primary={'Акты'} />
            </ListItemButton>
            <ListItemButton component={Link} to={`/building/${building.id}/ext-docs`}>
              <ListItemIcon>
                <FileOpenIcon />
              </ListItemIcon>
              <ListItemText primary={'Внешние документы'} />
            </ListItemButton>
          </List>
        </>
      )}
    </>
  )
}
