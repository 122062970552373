import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material'
import * as React from 'react'
import Box from '@mui/material/Box'
import { TPrdDocument } from '../../types/prd'
import { PrdDocumentView } from './PrdDocumentView'

type Props = {
  open: boolean
  document: TPrdDocument
  onClose: () => void
}

const PrdDocViewDialog = ({ open, document, onClose }: Props) => {
  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth={'lg'} fullWidth scroll={'body'}>
        <DialogContent>
          <PrdDocumentView document={document} />
        </DialogContent>
        <DialogActions>
          <Stack spacing={2} direction="row" sx={{ alignItems: 'center' }}>
            <Box sx={{ flexGrow: 1, justifyContent: 'flex-end', display: 'flex' }}>
              <Button variant={'outlined'} onClick={onClose}>
                Закрыть
              </Button>
            </Box>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default PrdDocViewDialog
