import { TBuilding } from '../../types/building'
import { baseDocumentType, TBaseDocumentType } from '../../types/document'
import { download } from '../../util/download'
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import React from 'react'

type Props = {
  building: TBuilding
  documentId: string,
  types: TBaseDocumentType[]
}
const XmlDownloadList = ({building, documentId, types}: Props) => {

  type ItemType = {
    type: TBaseDocumentType
    label: string
    description?: string
  }

  const list: ItemType[] =
    types.map((type: TBaseDocumentType) => ({
      type: type,
      label: baseDocumentType[type].name,
      description: baseDocumentType[type].description}))


  const handleClick = (type: TBaseDocumentType) => {
    download(`/api/v1/buildings/${building.id}/xml/get-file?document_id=${documentId}&type=${type}`, `${type}.xml`, 'text/xml')
  }

  return(
    <>
      <List>
        {
          list.map(t => (
            <ListItem key={t.type} component="div" disablePadding>
              <ListItemButton onClick={() => handleClick(t.type)}>
                <ListItemIcon>
                  <FileDownloadIcon />
                </ListItemIcon>
                <ListItemText primary={t.label} secondary={t.description} />
              </ListItemButton>
            </ListItem>
          ))
        }
      </List>
    </>
  )

}

export {XmlDownloadList}
