import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { Filter } from './Filter'
import { RightPanel } from '../common'
import * as React from 'react'
import { UseFilterResult } from './useFilter'

type Props = {
  open: boolean
  onOpen: () => void
  onClose: () => void
  filter: UseFilterResult
  variant?: 'panel' | 'dialog'
}

export const FilterPanel = ({open, onOpen, onClose, filter, variant = 'panel'}: Props) => {
  return(
    <>
      {variant === 'panel' &&
        <RightPanel
          open={open}
          onOpen={onOpen}
          onClose={onClose}
          actions={
            <Stack direction={'row'} spacing={2}>
              <Button variant={'contained'} color={'primary'} onClick={onClose}>Показать</Button>
              {filter.state.count > 0 && <Button variant={'outlined'} color={'primary'} onClick={() => filter.clear()}>Сбросить</Button>}
            </Stack>
          }
        >
          <Typography variant={'h5'} sx={{fontWeight: 'bold', mb: 1}}>Фильтры</Typography>
          <Filter filter={filter} />
        </RightPanel>
      }
      {variant === 'dialog' &&
        <Dialog open={open} onClose={onClose} maxWidth={'lg'} fullWidth scroll={'paper'}>
          <DialogTitle>Фильтры</DialogTitle>
          <DialogContent>
            <Filter filter={filter} />
          </DialogContent>
          <DialogActions>
            <Button variant={'contained'} color={'primary'} onClick={onClose}>Показать</Button>
            {filter.state.count > 0 && <Button variant={'outlined'} color={'primary'} onClick={() => filter.clear()}>Сбросить</Button>}
          </DialogActions>
        </Dialog>
      }
    </>
  )
}
