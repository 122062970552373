import React, { PropsWithChildren } from 'react'
import Box from '@mui/material/Box'
import { SxProps, Typography } from '@mui/material'

type Props = {
  label: string
  text?: string | null | undefined
  description?: string
  sx?: SxProps
}

const DataRow = ({ label, text, description, children, sx = {} }: PropsWithChildren<Props>) => {
  return (
    <Box sx={{ mb: 2, ...sx }}>
      {label && (
        <Typography
          variant={'caption'}
          color="text.secondary"
          component={'div'}
          sx={{ lineHeight: 1.2 }}
        >
          {label}
        </Typography>
      )}
      {text && <Typography variant={'body2'}>{text}</Typography>}
      {description && (
        <Typography variant={'body2'} color="text.secondary">
          {description}
        </Typography>
      )}
      {children && <Box>{children}</Box>}
    </Box>
  )
}

export { DataRow }
