import React from 'react'
import { useOutletContext } from 'react-router-dom'
import { Gwj3ItemsList } from '../../../../components/journals/gwj/part3'
import { IOutletBuilding } from '../../../../types/outlet-context'

export const Part3 = () => {
  const { building } = useOutletContext<IOutletBuilding>()
  return (
    <>
      <Gwj3ItemsList building={building} />
    </>
  )
}
