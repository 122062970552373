import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from '@mui/material'
import { green, red } from '@mui/material/colors'
import { Domain, DomainDisabled, MoreVert } from '@mui/icons-material'
import React, { useState } from 'react'
import { formatDate } from '../../util/date'
import { IBuildingCard } from '../../store/buildings/types'
import { Link, useNavigate } from 'react-router-dom'
import { MoreVertMenu } from '../menu'
import { BuildingSiteBar } from './BuildingSiteBar'

export function BuildingCard({ building }: { building: IBuildingCard }): JSX.Element {
  const isActive: boolean = building.status === 'active'
  const navigate = useNavigate()
  const [menuOpen, setMenuOpen] = useState(false)
  const handleCardClick = () => {
    navigate(`/building/${building.id}/data`)
  }

  return (
    <Card
      sx={{
        height: '100%',
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
      }}
      onClick={handleCardClick}
    >
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: isActive ? green[500] : red[500] }} aria-label="recipe">
            {isActive ? <Domain /> : <DomainDisabled />}
          </Avatar>
        }
        action={
          <MoreVertMenu
            open={menuOpen}
            onOpen={() => setMenuOpen(true)}
            onClose={() => setMenuOpen(false)}
          >
            <BuildingSiteBar buildingId={building.id} />
          </MoreVertMenu>
        }
        title={isActive ? 'Активен' : 'Не активен'}
        subheader={formatDate(building.created_at)}
      />
      <CardContent>
        <Typography variant="subtitle2" component="div">
          {building.name}
        </Typography>
      </CardContent>
    </Card>
  )
}
