import {
  FormHelperText,
  FormControl,
  InputProps,
  OutlinedInput,
  InputLabel,
  SxProps,
  Theme,
} from '@mui/material'
import React, { FC, ReactNode } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { MuiFileInput } from 'mui-file-input'

type IFormInputProps = {
  name: string
  label: string
  hint?: ReactNode
  startAdornment?: ReactNode
  endAdornment?: ReactNode
  size?: 'small' | 'medium'
  sx?: SxProps<Theme>
  inputProps?: any
  placeholder?: string
} & InputProps

const FormInputFile: FC<IFormInputProps> = ({
  name,
  label,
  hint,
  startAdornment,
  endAdornment,
  size,
  inputProps,
  placeholder,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const errorMessage: string = (errors[name] ? errors[name]?.message : '') as string

  const hintEl = hint ? <FormHelperText>{hint}</FormHelperText> : null

  return (
    <Controller
      control={control}
      defaultValue=""
      name={name}
      render={({ field }) => (
        <FormControl fullWidth margin={'dense'} size={size}>
          <MuiFileInput
            {...field}
            error={!!errors[name]}
            label={label}
            InputProps={{
              inputProps: inputProps,
              startAdornment: <AttachFileIcon />,
            }}
            placeholder={placeholder}
          />
          {hintEl}
          <FormHelperText error={!!errors[name]}>{errorMessage}</FormHelperText>
        </FormControl>
      )}
    />
  )
}

export default FormInputFile
