import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { addAuthorization } from '../../util/http/addAuthorization'
import { TBuildingDocument } from '../../types/building'

export const buildingDocumentsApi = createApi({
  reducerPath: 'buildingDocuments',
  tagTypes: ['BuildingDocuments'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1/',
    prepareHeaders: addAuthorization,
    validateStatus: (response) => response.ok,
  }),
  endpoints: (builder) => ({
    getBuildingDocuments: builder.query<TBuildingDocument[], any>({
      query: (buildingId) => ({
        url: `/buildings/${buildingId}/documents`,
        method: 'GET',
      }),
      providesTags: ['BuildingDocuments'],
    }),
    addBuildingDocument: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/documents/add`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['BuildingDocuments'],
    }),
    removeBuildingDocument: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/documents/remove`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['BuildingDocuments'],
    }),
  }),
})

export const {
  useGetBuildingDocumentsQuery,
  useAddBuildingDocumentMutation,
  useRemoveBuildingDocumentMutation,
} = buildingDocumentsApi
