import { TBuilding, TCompany, TEmployee } from '../../../../types/building'
import { useParams } from 'react-router-dom'
import { RightPanel, SkidPagination, useSkidPagination } from '../../../common'
import { useGetCompaniesQuery } from '../../../../store/companies'
import {
  Box,
  Button,
  CardHeader,
  IconButton,
  LinearProgress,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { formatDate } from '../../../../util/date'
import { StatusChip } from '../../../common/StatusChip'
import * as React from 'react'
import { documentType, IDocEmployee } from '../../../../types/document'
import { Delete } from '@mui/icons-material'
import AddItem1Dialog from './AddItem1Dialog'
import { useGetDocumentsQuery, useRemoveDocumentMutation } from '../../../../store/document'
import { useAuthorization } from '../../../../auth'
import { useGetUserQuery } from '../../../../store/user'
import { useMemo, useState } from 'react'
import { useGetEmployeesQuery } from '../../../../store/employees'
import { Part1View } from './Part1View'
import { toastError } from '../../../../util/toastError'
import { SkeletonRows } from '../../../common/SkeletonRows'
import { toast } from 'react-toastify'
import { nextNumber } from '../../../../util/maxNumber'

const employeeName = (docEmployee: IDocEmployee, employees: TEmployee[]): string => {
  return employees.find((em) => em.id === docEmployee.employee_id)?.profile.name.full_name || ''
}

const companyName = (
  docEmployee: IDocEmployee,
  employees: TEmployee[],
  companies: TCompany[]
): string => {
  const employee = employees.find((em) => em.id === docEmployee.employee_id)
  if (!employee || companies === undefined) return ''

  return companies.find((c) => c.id === employee.company_id)?.name || ''
}

export const Part1List = ({ building }: { building: TBuilding }) => {
  const { journalId } = useParams()
  const { pages, page, offset, limit, onPageChange, setTotal } = useSkidPagination(20)

  const { data, isLoading, isSuccess, refetch } = useGetDocumentsQuery({
    buildingId: building.id,
    params: { type: documentType.GWJ1Item, parent_id: journalId },
  })

  const { data: employees } = useGetEmployeesQuery(building.id)
  const { data: companies } = useGetCompaniesQuery(building.id)

  const [removeItem] = useRemoveDocumentMutation()

  const authManager = useAuthorization()

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [currentId, setCurrentId] = useState<string | undefined>()

  const currentItem = useMemo(() => {
    return data && data.items.find((item) => item.id === currentId)
  }, [data, currentId])

  const sorted = useMemo(() => {
    if (!data) return []
    return Array.from(data.items).sort(
      (a, b) => parseInt(a.number || '0') - parseInt(b.number || '0')
    )
  }, [data])

  const { data: user } = useGetUserQuery()
  const isCreate = useMemo(() => authManager.vote('Document.GWJ1Item', 'create'), [])
  const isManage = useMemo(() => {
    return (
      (currentItem &&
        user &&
        authManager.vote('Document.GWJ1Item', 'manage', currentItem.owner_id === user.id)) ||
      false
    )
  }, [currentItem, user])

  if (isSuccess) {
    setTotal(data.metadata.total)
  }

  if (isLoading || data === undefined) {
    return <SkeletonRows />
  }

  const viewItem = (id: string) => {
    setCurrentId(id)
    setDrawerOpen(true)
  }

  const removeHandler = (id: string | undefined, e: React.MouseEvent) => {
    e.stopPropagation()
    if (id) {
      removeItem({
        buildingId: building.id,
        body: {
          type: documentType.GWJ1Item,
          document_id: id,
        },
      })
        .unwrap()
        .then((res) => {
          refetch()
          toast.success('Запись удалена')
        })
        .catch(toastError)
    }
  }

  return (
    <>
      <Paper>
        <TableContainer>
          <CardHeader
            title={'Список инженерно-технического персонала'}
            action={
              isCreate && (
                <AddItem1Dialog
                  building={building}
                  onAdd={refetch}
                  nextNumber={nextNumber(data.items)}
                />
              )
            }
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>№</TableCell>
                <TableCell>Сотрудник</TableCell>
                <TableCell>Вид работ</TableCell>
                <TableCell>Дата начала/окончания работ</TableCell>
                <TableCell>Ответственный</TableCell>
                <TableCell>Статус документа</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sorted.slice(offset, offset + limit).map((item) => {
                return (
                  <TableRow
                    key={item.id}
                    hover
                    onClick={() => viewItem(item.id)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell>{item.number}</TableCell>
                    <TableCell>
                      <Box>
                        <Typography variant={'body1'}>
                          {employees && employeeName(item.employees?.itp[0], employees)}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant={'caption'} color={'grey'}>
                          {companies &&
                            employees &&
                            companyName(item.employees?.itp[0], employees, companies)}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{item.data.work_type}</TableCell>
                    <TableCell>
                      {formatDate(item.data.date_begin)}-{formatDate(item.data.date_end)}
                    </TableCell>
                    <TableCell>
                      {employees && employeeName(item.employees?.signer[0], employees)}
                    </TableCell>
                    <TableCell>
                      <StatusChip status={item.status} />
                    </TableCell>
                    <TableCell align="right">
                      {isManage && item.status === 'draft' && (
                        <IconButton
                          color="error"
                          title="Удалить"
                          onClick={(e) => removeHandler(item.id, e)}
                        >
                          <Delete />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
          {data.items && data.items.length > 0 && (
            <SkidPagination page={page} pages={pages} onChange={onPageChange} />
          )}
        </TableContainer>
      </Paper>
      <RightPanel
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
        actions={
          <>
            {isManage && currentItem && currentItem.status === 'draft' && (
              <Button
                variant={'outlined'}
                color={'error'}
                onClick={(e) => removeHandler(currentItem.id, e)}
              >
                Удалить
              </Button>
            )}
          </>
        }
      >
        {currentItem && <Part1View document={currentItem} onSign={refetch} />}
      </RightPanel>
    </>
  )
}
