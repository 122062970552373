import { object, string, TypeOf } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { Box } from '@mui/material'
import FormInputExtDocument from '../../form/FormInputExtDocument'

const schema = {
  external_document_id: string().min(1, 'Необходимо заполнить'),
}
const registerSchema = object(schema)

interface Props {
  values?: any
  onSubmit: (values: TypeOf<typeof registerSchema>, methods: any) => void
  type: 'isp' | 'result'
  id?: string
}

const ActOSRExtDocForm = ({ values, type, onSubmit, id }: Props) => {

  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: values || {},
  })

  const { handleSubmit } = methods

  const submitHandler = (values: any) => {
    onSubmit(values, methods)
  }

  return (
    <FormProvider {...methods}>
      <Box
        id={id}
        component="form"
        onSubmit={handleSubmit(submitHandler)}
        noValidate
        autoComplete="off"
      >
        <FormInputExtDocument name="external_document_id" label="Документ" docType={type} />
      </Box>
    </FormProvider>
  )
}

export default ActOSRExtDocForm
