import { TBuilding, TCompany, TEmployee } from '../../../types/building'
import { RightPanel, SkidPagination, useSkidPagination } from '../../common'
import {
  Badge,
  Box,
  Button, CardContent,
  CardHeader,
  LinearProgress,
  Paper, Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import * as React from 'react'
import Checkbox from '@mui/material/Checkbox'
import { useParams } from 'react-router-dom'
import { useGetEmployeesQuery } from '../../../store/employees'
import { formatDate } from '../../../util/date'
import { StatusChip } from '../../common/StatusChip'
import { useEffect, useMemo, useState } from 'react'
import { ItemView } from './ItemView'
import AddItem3Dialog from './AddItemDialog'
import { useGetMaterialsQuery, useGetUnitsQuery } from '../../../store/lists'
import { toast } from 'react-toastify'
import { useGetStoreQuery } from '../../../store/store'
import { IStore } from '../../../types/store'
import { useGetDocumentsQuery, useRemoveDocumentMutation } from '../../../store/document'
import { documentType, IDocument, statusLabel, TDocumentStatus } from '../../../types/document'
import { useAuthorization } from '../../../auth'
import { useGetUserQuery } from '../../../store/user'
import { useGetCompaniesQuery } from '../../../store/companies'
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined'
import { toastError } from '../../../util/toastError'
import { useChecked } from '../../../util/useChecked'
import { TFilterSetting, useFilter } from '../../filter/useFilter'
import { FilterButton } from '../../filter/FilterButton'
import { FilterPanel } from '../../filter/FilterPanel'
import { FilterChips } from '../../filter/FilterChips'

const employeeName = (employees: TEmployee[], item: IDocument) => {
  return employees.find(e => e.id === item.employees.signer[0].employee_id)?.profile.name.full_name || ''
}

const signerName = (employees: TEmployee[], companies: TCompany[], item: IDocument) => {
  const employee = employees.find((em) => em.id === item.employees.signer[0].employee_id)
  const company = employee && companies.find(c => c.id === employee?.company_id)
  return employee && company && (
    <>
      <Typography variant={'body1'}>{employee?.profile.name.full_name}</Typography>
      <Typography variant={'caption'} color={'text.disabled'}>{company.name}</Typography>
    </>
  )
}
const supplierName = (companies: TCompany[], item: IDocument): string => {
  return companies.find((em) => em.id === item.companies.supplier[0].company_id)?.name || ''
}

const UsedProgress = (value: number, balance: number) => {
  const progress = (balance / value) * 100
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="caption" color="text.secondary">{`${balance}`}</Typography>
      </Box>
    </Box>
  )
}

export const ImcItemsList = ({ building }: { building: TBuilding }) => {
  const { journalId } = useParams()
  const { pages, page, offset, limit, onPageChange, setTotal } = useSkidPagination(20)

  const { data, isLoading, isSuccess, refetch } = useGetDocumentsQuery({
    buildingId: building.id,
    params: { type: documentType.IMCItem, parent_id: journalId },
  })

  const { data: storesRes, refetch: refetchStore } = useGetStoreQuery(building.id)

  const {
    data: materials,
    isLoading: isMaterialsLoading,
    isSuccess: isWorksSuccess,
  } = useGetMaterialsQuery(building.id)

  const { data: employees } = useGetEmployeesQuery(building.id)
  const { data: companies } = useGetCompaniesQuery(building.id)

  const { data: units, refetch: refetchUnits } = useGetUnitsQuery(building.id)

  const [removeItem] = useRemoveDocumentMutation()


  const statusItems = Object.keys(statusLabel)
    .map(k => ({label: statusLabel[k as TDocumentStatus], value: k}))

  const supplierItems = useMemo(
    () => {
      if (!data?.items || !companies) return []
      const list: Record<string, string> = data.items.reduce((acc, i) => ({
        ...acc,
        [i.companies.supplier[0].company_id]: supplierName(companies, i)
      }), {}) || {}

      return Object.keys(list).map(id => ({value: id, label: list[id]}))

    }
    , [data, companies])

  const employeesItems = useMemo(
    () => {
      if (!data?.items || !employees) return []
      const list: Record<string, string> = data.items.reduce((acc, i) => ({
        ...acc,
        [i.employees.signer[0].employee_id]: employeeName(employees, i)
      }), {}) || {}

      return Object.keys(list).map(id => ({value: id, label: list[id]}))

    }
    , [data, employees])


  const filterSettings: TFilterSetting[] = [
    {name: 'companies.supplier', label: 'Поставщик', filter: 'list', data: supplierItems, prepare: (i => i.company_id)},
    {name: 'employees.signer', label: 'Ответственный', filter: 'list', data: employeesItems, prepare: (i => i.employee_id)},
    {name: 'date', label: 'Дата', filter: 'dateRange'},
    {name: 'status', label: 'Статус', filter: 'list', data: statusItems},
  ]

  const [filterOpen, setFilterOpen] = useState(false)
  const filter = useFilter(filterSettings)
  useEffect(() => {
    if (data?.items) {
      filter.setItems(data.items)
    }
  }, [data, filter])

  const filteredItems = useMemo(() => {
    return filter?.filtered ? filter.filtered.slice(offset, offset + limit) : []
  }, [filter, offset, limit])

  const { onCheck, checked, isCheckedAll, isIndeterminate } = useChecked(
    filteredItems.map((i) => i.id)
  )


  const stores = useMemo((): Record<string, IStore> => {
    if (storesRes === undefined) return {}
    return storesRes.reduce((acc, store) => ({ ...acc, [store.imc_id]: store }), {})
  }, [storesRes])

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [currentId, setCurrentId] = useState<string | undefined>()

  const currentItem = useMemo(() => {
    return data && data.items.find((item) => item.id === currentId)
  }, [data, currentId])

  const currentStore = useMemo(() => {
    return stores && currentId && stores[currentId]
  }, [stores, currentId])

  const authManager = useAuthorization()
  const { data: user } = useGetUserQuery()
  const isCreate = useMemo(() => authManager.vote('Document.IMCItem', 'create'), [])
  const isManage = useMemo(() => {
    return (
      (currentItem &&
        user &&
        authManager.vote('Document.IMCItem', 'manage', currentItem.owner_id === user.id)) ||
      false
    )
  }, [currentItem, user])

  if (isSuccess) {
    setTotal(data.metadata.total)
  }

  if (isLoading || isMaterialsLoading || data === undefined) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    )
  }

  const viewItem = (id: string) => {
    setCurrentId(id)
    setDrawerOpen(true)
  }

  const refetchAll = () => {
    refetch()
    refetchStore()
    refetchUnits()
  }

  const removeItemHandler = (id: string | undefined) => {
    if (id === undefined) return
    removeItem({
      buildingId: building.id,
      body: {
        document_id: id,
        type: documentType.IMCItem,
      },
    })
      .unwrap()
      .then(() => {
        toast.success('Запись удалена')
        setCurrentId(undefined)
        setDrawerOpen(false)
        refetchAll()
      })
      .catch(toastError)
  }

  return (
    <>
      <Paper>
        <TableContainer>
          <CardHeader
            action={
              <Stack direction={'row'} spacing={2}>
                <FilterButton count={filter.state.count} onClick={() => setFilterOpen(true)} />
                {isCreate && <AddItem3Dialog building={building} onAdd={refetchAll} />}
              </Stack>
            }
          />
          <CardContent>
            <FilterChips filter={filter} />
          </CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '50px' }}>
                  <Checkbox
                    checked={isCheckedAll}
                    value={'all'}
                    onChange={onCheck}
                    indeterminate={isIndeterminate}
                  />
                </TableCell>
                <TableCell>
                  <div>Материал</div>
                  <Typography variant={'caption'}>Поставщик</Typography>
                </TableCell>
                <TableCell>
                  <div>Объем/кол-во</div>
                  <Typography variant={'caption'}>Остаток</Typography>
                </TableCell>
                <TableCell>Дата поступления</TableCell>
                <TableCell>ДПК</TableCell>
                <TableCell>Ответственный</TableCell>
                <TableCell>Статус документа</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredItems.map((item) => {
                const store = stores[item.id]
                return (
                  <TableRow key={item.id} hover sx={{ cursor: 'pointer' }}>
                    <TableCell>
                      <Checkbox
                        checked={checked.includes(item.id)}
                        value={item.id}
                        onChange={onCheck}
                      />
                    </TableCell>
                    <TableCell onClick={() => viewItem(item.id)}>
                      <Box>
                        <Typography variant={'body1'}>
                          {materials && store && materials[store.material_id]?.name}
                        </Typography>
                      </Box>
                      <Typography variant={'caption'} color={'text.disabled'}>
                        {companies && supplierName(companies, item)}
                      </Typography>
                    </TableCell>
                    <TableCell onClick={() => viewItem(item.id)}>
                      {store &&
                        <>
                          <Box>
                            <Typography variant={'body1'} component={'span'}>
                              {store.value}
                            </Typography>
                            <Typography variant={'caption'} color={'text.disabled'}>
                              {'\u00A0'}
                              {units && units[store.unit_id]?.name}
                            </Typography>
                          </Box>
                          {UsedProgress(store.value, store.balance)}
                        </>
                      }
                    </TableCell>
                    <TableCell onClick={() => viewItem(item.id)}>
                      {formatDate(item.date)}
                    </TableCell>
                    <TableCell onClick={() => viewItem(item.id)}>
                      {item.external_documents.dpk && (
                        <Badge badgeContent={item.external_documents.dpk.length} color={'error'}>
                          <TaskOutlinedIcon />
                        </Badge>
                      )}
                    </TableCell>
                    <TableCell onClick={() => viewItem(item.id)}>
                      {employees && companies && signerName(employees, companies, item)}
                    </TableCell>
                    <TableCell onClick={() => viewItem(item.id)}>
                      <StatusChip status={item.status} />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
          {data.items && data.items.length > 0 && (
            <SkidPagination page={page} pages={pages} onChange={onPageChange} />
          )}
        </TableContainer>
      </Paper>
      <RightPanel
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
        actions={
          <>
            {isManage && (
              <Button
                variant={'outlined'}
                color={'error'}
                onClick={() => removeItemHandler(currentId)}
              >
                Удалить
              </Button>
            )}
          </>
        }
      >
        {currentItem && currentStore && (
          <ItemView
            building={building}
            item={currentItem}
            store={currentStore}
            onChange={refetchAll}
            isManage={isManage}
          />
        )}
      </RightPanel>
      <FilterPanel
        open={filterOpen}
        onOpen={() => setFilterOpen(true)}
        onClose={() => setFilterOpen(false)}
        filter={filter}
      />
    </>
  )
}
