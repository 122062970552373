import { Link, useOutletContext } from 'react-router-dom'
import { IOutletBuilding } from '../../../types/outlet-context'
import {
  CardHeader,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper
} from '@mui/material'
import { BaseContainer } from '../../../components/common/Container'
import Avatar from '@mui/material/Avatar'
import AutoStoriesIcon from '@mui/icons-material/AutoStories'
import React from 'react'

export const ActsMain = () => {
  const { building } = useOutletContext<IOutletBuilding>()

  return (
    <BaseContainer>
      <Paper>
        <CardHeader
          title={"Акты"}
        />
        <List component="nav" sx={{ width: '100%', bgcolor: 'background.paper' }}>
          <ListItemButton component={Link} to={`osr`}>
            <ListItemAvatar>
              <Avatar>
                <AutoStoriesIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Акты ОСР"/>
          </ListItemButton>
          <ListItemButton component={Link} to={`ook`}>
            <ListItemAvatar>
              <Avatar>
                <AutoStoriesIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Акты ООК"
            />
          </ListItemButton>
        </List>
      </Paper>
    </BaseContainer>
  )
}
