import { coerce, object, string, TypeOf } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { Box, FormHelperText, InputAdornment, Stack } from '@mui/material'
import FormInput from '../form/FormInput'
import { TUnit } from '../../types/lists'

const schema = {
  amount: coerce.number({
    invalid_type_error: 'Значение должно быть числом',
    required_error: 'Значение не должно быть пустым',
  }),
}
const registerSchema = object(schema)

interface Props {
  value: number
  min: number
  unit: TUnit
  onSubmit: (values: TypeOf<typeof registerSchema>, methods: any) => void
  id?: string
}

const VolumeForm = ({ value, min, unit, onSubmit, id }: Props) => {
  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: value ? { amount: value } : {},
  })

  const { handleSubmit, setError } = methods

  const submitHandler = (values: any) => {
    if (values.amount < min) {
      setError('amount', {
        type: 'minLength',
        message: `Нельзя установить объем меньше уже использованного: ${min} ${unit.name}`,
      })
    } else {
      onSubmit(values, methods)
    }
  }

  return (
    <FormProvider {...methods}>
      <Box
        id={id}
        component="form"
        onSubmit={handleSubmit(submitHandler)}
        noValidate
        autoComplete="off"
      >
        <FormInput
          name="amount"
          label="Объем"
          endAdornment={<InputAdornment position="start">{unit.name}</InputAdornment>}
          hint={min > 0 && `Минимум ${min}`}
        />
      </Box>
    </FormProvider>
  )
}

export default VolumeForm
