import * as React from 'react'
import { RouterProvider } from 'react-router-dom'
import { useAuth } from '../../oauth'
import { privateRouter, publicRouter } from '../../router'

function AppRoutes() {
  const { isAuthenticated } = useAuth()
  const router = isAuthenticated ? privateRouter : publicRouter

  return <RouterProvider router={router} />
}

export default AppRoutes
