import { useState } from 'react'
import { FormDialog } from '../../../common/FormDialog'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import { useEditDocumentMutation } from '../../../../store/document'
import { documentType, IDocument } from '../../../../types/document'
import { LightButton } from '../../../common/LightButton'
import { toastError } from '../../../../util/toastError'
import { Part7ItemForm } from './Part7ItemForm'

type Props = {
  item: IDocument
}

const Part7ItemDialog = ({ item }: Props) => {
  const id = 'edit-defect-7-dialog'
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { journalId } = useParams()

  const [editDocument] = useEditDocumentMutation()

  const handleSubmit = (values: any, methods: any) => {
    setIsLoading(true)

    editDocument({
      buildingId: item.building_id,
      body: {
        type: documentType.GWJ7Item,
        document_id: item.id,
        employees: [{ id: values.signerId, type: 'signer' }],
        data: values.data,
      },
    })
      .unwrap()
      .then((res) => {
        setOpen(false)
        methods.reset()
      })
      .catch(toastError)
      .finally(() => setIsLoading(false))
  }

  return (
    <>
      <LightButton onClick={() => setOpen(true)}>Редактировать</LightButton>
      <FormDialog
        formId={id}
        open={open}
        onClose={() => setOpen(false)}
        title={'Редактировать запись'}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <Part7ItemForm id={id} item={item} onSubmit={handleSubmit} />
      </FormDialog>
    </>
  )
}

export { Part7ItemDialog }
