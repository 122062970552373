import { TBuilding } from '../../../../types/building'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { FormDialog } from '../../../common/FormDialog'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import Item1Form from './Item1Form'
import { useAddEmployeeMutation, useEditDocumentMutation } from '../../../../store/document'
import { documentType, IDocument } from '../../../../types/document'
import { LightButton } from '../../../common/LightButton'
import { toastError } from '../../../../util/toastError'

const EditItem1Dialog = ({ document, onSave }: { document: IDocument; onSave?: () => void }) => {
  const id = 'edit-item-1-dialog'
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { journalId } = useParams()

  const [editDocument] = useEditDocumentMutation()
  const [addEmployee] = useAddEmployeeMutation()

  const handleSubmit = (values: any, methods: any) => {
    const body = {
      type: documentType.GWJ1Item,
      document_id: document.id,
      number: values.number,
      data: values.data,
      employees: [
        { id: values.employee_id, type: 'itp' },
        { id: values.signer_id, type: 'signer' },
      ],
    }

    setIsLoading(true)
    editDocument({ buildingId: document.building_id, body })
      .unwrap()
      .then(() => {
        methods.reset()
        setOpen(false)
        toast.success('Документ изменен')
        onSave && onSave()
      })
      .catch(toastError)
      .finally(() => setIsLoading(false))
  }

  return (
    <>
      <LightButton onClick={() => setOpen(true)}>Изменить</LightButton>
      <FormDialog
        formId={id}
        open={open}
        onClose={() => setOpen(false)}
        title={'Изменить запись'}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <Item1Form
          values={{
            number: document.number,
            data: document.data,
            employee_id: document.employees.itp[0].employee_id,
            signer_id: document.employees.signer[0].employee_id,
          }}
          buildingId={document.building_id}
          id={id}
          onSubmit={handleSubmit}
        />
      </FormDialog>
    </>
  )
}

export default EditItem1Dialog
