import { FormDialog } from '../common/FormDialog'
import * as React from 'react'
import { useState } from 'react'
import { useEditDocumentMutation } from '../../store/prd'
import { TBuilding } from '../../types/building'
import { toastError } from '../../util/toastError'
import PrdDocumentForm from './PrdDocumentForm'
import { toast } from 'react-toastify'
import { TPrdDocument } from '../../types/prd'

type Props = {
  building: TBuilding
  document: TPrdDocument
  onSave?: () => void
  onClose: () => void
  open: boolean
}

const PrdDocumentEditDialog = ({ onSave, document, building, open, onClose }: Props) => {
  const id = 'edit-prd-document-dialog'
  const [isLoading, setIsLoading] = useState(false)

  const [editDocument] = useEditDocumentMutation()

  const handleSubmit = (values: any, methods: any) => {
    setIsLoading(true)
    const body = {
      ...values,
      group_id: document.group_id,
      document_id: document.id,
    }
    editDocument({ buildingId: building.id, body })
      .unwrap()
      .then((res) => {
        methods.reset()
        setIsLoading(false)
        onSave && onSave()
        toast.success('Документ изменен')
      })
      .catch(async (e) => {
        await toastError(e)
        setIsLoading(false)
      })
  }

  return (
    <>
      <FormDialog
        formId={id}
        open={open}
        onClose={onClose}
        title={'Редактировать документ'}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <PrdDocumentForm id={id} building={building} onSubmit={handleSubmit} values={document} />
      </FormDialog>
    </>
  )
}

export default PrdDocumentEditDialog
