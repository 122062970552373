import { TEmployee } from '../../types/building'
import { IDocument } from '../../types/document'
import { useGetUserQuery } from '../../store/user'
import { Chip, Skeleton, Stack } from '@mui/material'
import { useReadyMutation, useResetMutation } from '../../store/document'
import React, { useMemo, useState } from 'react'
import { useAuthorization } from '../../auth'
import { SignButton } from './SignButton'
import { toastError } from '../../util/toastError'
import { LightButton, LightLoadingButton } from '../common/LightButton'
import { LightChip } from '../common/LightChip'

type Props = {
  document: IDocument
  onReady?: () => void
  onSign?: () => void
  description?: string
  size?: 'small' | 'medium'
}

const isReadySign = (status: string): boolean => {
  return ['ready', 'sign_start', 'success'].includes(status)
}

const signerName = (employees: TEmployee[], employee_id: string): string => {
  return employees.find((em) => em.id === employee_id)?.profile.name.full_name || ''
}

export const SignDocumentButton = ({
  document,
  description,
  onReady,
  onSign,
  size = 'medium',
}: Props) => {
  const { data: user, isSuccess: isUserSuccess, isLoading: isUserLoading } = useGetUserQuery()
  const [ready] = useReadyMutation()
  const [reset] = useResetMutation()

  const [isLoading, setIsLoading] = useState(false)
  const [isResetLoading, setResetIsLoading] = useState(false)

  const authManager = useAuthorization()

  const isOwner = useMemo(() => {
    return user ? authManager.vote('Document', 'manage', user.id === document.owner_id) : false
  }, [document, user, authManager])

  if (isUserLoading || !isUserSuccess || user === undefined) {
    return <Skeleton height={100} />
  }

  const handleReady = () => {
    setIsLoading(true)
    ready({ buildingId: document.building_id, body: { document_id: document.id } })
      .unwrap()
      .then(onReady)
      .catch(toastError)
      .finally(() => setIsLoading(false))
  }

  const handleReset = () => {
    setResetIsLoading(true)
    reset({
      buildingId: document.building_id,
      body: {
        document_id: document.id,
      },
    })
      .unwrap()
      .then((res) => {
        onSign && onSign()
      })
      .catch(toastError)
      .finally(() => setResetIsLoading(false))
  }

  switch (document.status) {
    case 'draft':
      return isOwner ? (
        <LightLoadingButton color={'primary'} onClick={handleReady} size={size} loading={isLoading}>
          <span>Сформировать</span>
        </LightLoadingButton>
      ) : null
    case 'ready':
    case 'sign_start':
      return (
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          <LightChip label={'сформирован'} color={'info'} size={'small'} />
          <SignButton document={document} onSign={onSign} />
          {isOwner && (
            <LightLoadingButton onClick={handleReset} size={size} loading={isResetLoading}>
              <span>Сбросить</span>
            </LightLoadingButton>
          )}
        </Stack>
      )
    case 'success':
      return (
        <>
          <LightChip label={'подписан'} color={'success'} size={'small'} />
        </>
      )
    default:
      return null
  }
}
