import { Add } from '@mui/icons-material'
import { FormDialog } from '../../../common/FormDialog'
import * as React from 'react'
import { useState } from 'react'
import { useEditDocumentMutation } from '../../../../store/document'
import { documentType, IDocument } from '../../../../types/document'
import { LightButton } from '../../../common/LightButton'
import { DefectForm } from './DefectForm'
import { toastError } from '../../../../util/toastError'
import { Record4EditForm } from './Record4EditForm'

interface IProps {
  item: IDocument
}

export const EditRecord4Dialog = ({ item }: IProps) => {
  const id = 'edit-control-defect-form'
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [edit] = useEditDocumentMutation()

  const handleSubmit = async (values: any, methods: any) => {
    setIsLoading(true)

    edit({
      buildingId: item.building_id,
      body: {
        document_id: item.id,
        type: documentType.GWJ4Item,
        data: { ...values.data, is_defect: item.data.is_defect },
        employees: [{ id: values.signer_id, type: 'signer' }],
      },
    })
      .unwrap()
      .then((res) => {
        setOpen(false)
      })
      .catch(toastError)
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <>
      <LightButton startIcon={<Add />} onClick={() => setOpen(true)}>
        Редактировать
      </LightButton>
      <FormDialog
        formId={id}
        open={open}
        onClose={() => setOpen(false)}
        title={'Редактировать запись'}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <Record4EditForm id={id} onSubmit={handleSubmit} item={item} />
      </FormDialog>
    </>
  )
}
