import { isRouteErrorResponse, useRouteError } from 'react-router-dom'
import { Error } from '../pages/error/Error'

export const ErrorBoundary = () => {
  const error = useRouteError()

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <Error message={'Страница не найдена'} status={error.status} />
    }

    if (error.status === 401) {
      return <Error message={'Вы не авторизованы'} status={error.status} />
    }

    if (error.status === 403) {
      return <Error message={'Доступ запрещен'} status={error.status} />
    }

    if (error.status === 503) {
      return <Error message={'Looks like our API is down'} status={error.status} />
    }
  }

  return <Error message={'Произошла ошибка. Поробуйте ещё раз позже.'} />
}
