import { any, array, boolean, object, record, string, TypeOf, z } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { useMemo, useState } from 'react'
import { Box, FormControlLabel, Switch } from '@mui/material'
import { useGetGroupsQuery, useGetWorksQuery } from '../../../../store/works'
import { TBuilding } from '../../../../types/building'
import { useGetCompaniesQuery } from '../../../../store/companies'
import { employeesList } from '../../../../util/employees'
import FormInput from '../../../form/FormInput'
import FormSelect from '../../../form/FormSelect'
import { worksList } from '../../../../util/works'
import FormInputDate from '../../../form/FormInputDate'
import FormTextarea from '../../../form/FormTextarea'
import dayjs from 'dayjs'
import { useGetDocumentsQuery } from '../../../../store/document'
import { useParams } from 'react-router-dom'
import { documentType } from '../../../../types/document'
import { formatDate, formatPeriodDig } from '../../../../util/date'
import { useGetEmployeesQuery } from '../../../../store/employees'
import FormInputLocation from '../../../form/FormInputLocation'

const schema = {
  event_id: string().min(1, 'Необходимо выбрать'),
  date: string().min(1, 'Необходимо указать дату'),
  signer_id: string().min(1, 'Необходимо заполнить'),
  description: string().min(1, 'Необходимо заполнить'),
}

const schemaOn = {
  ...schema,
  number: string()
    .min(1, 'Необходимо заполнить')
    .refine((val) => /^[1-9]+$/.test(val), {
      message: 'Должно быть целым положительным числом',
    }),
  structural_element: string().min(1, 'Необходимо заполнить'),
  works: array(string()),
  location: record(string(), string()).refine((val) => Object.keys(val).length > 0, {
    message: 'Необходимо заполнить',
  }),
  defect_fixing_plan_date: string().min(1, 'Необходимо указать дату'),
}

const registerSchema = object(schema)
const registerSchemaOn = object(schemaOn)

interface Props {
  building: TBuilding
  values?: any
  onSubmit: (
    values: TypeOf<typeof registerSchema> | TypeOf<typeof registerSchemaOn>,
    methods: any
  ) => void
  id?: string
}

export const Record4AddForm = ({ building, values, onSubmit, id }: Props) => {
  const [isDefect, setIsDefect] = useState<boolean>(true)

  const { data: works } = useGetWorksQuery(building.id)
  const { data: groups } = useGetGroupsQuery(building.id)
  const { data: employees } = useGetEmployeesQuery(building.id)
  const { data: companies } = useGetCompaniesQuery(building.id)
  const { journalId } = useParams()

  const { data: controlEvents } = useGetDocumentsQuery({
    buildingId: building.id,
    params: {
      parent_id: journalId,
      type: documentType.GWJ4ControlEvent,
    },
  })

  const methods = useForm<TypeOf<typeof registerSchema> | TypeOf<typeof registerSchemaOn>>({
    resolver: zodResolver(isDefect ? registerSchemaOn : registerSchema),
    defaultValues: values || {
      is_defect: true,
      works: [],
      date: dayjs(new Date()).format('YYYY-MM-DD'),
      location: {},
    },
  })

  const {
    handleSubmit,
    formState: { errors },
  } = methods

  const submitHandler = (values: any) => {
    onSubmit({ ...values, is_defect: isDefect }, methods)
  }

  const worksListItems = useMemo(() => worksList(groups, works), [groups, works])

  const signerItems =
    companies && employees
      ? employeesList(
          companies,
          employees,
          ['developer', 'operating_person', 'regional_operator', 'customer'],
          ['auth_representative_control']
        )
      : []

  const eventsList =
    controlEvents !== undefined
      ? controlEvents.items.map((e) => ({
          value: e.id,
          label: formatPeriodDig(e.data.from, e.data.to),
        }))
      : []

  return (
    <FormProvider {...methods}>
      <Box
        id={id}
        component="form"
        onSubmit={handleSubmit(submitHandler)}
        noValidate
        autoComplete="off"
      >
        <FormSelect name="event_id" label="Контрольное мероприятие" items={eventsList} />
        <FormSelect name="signer_id" label="Сотрудник, проводивший контроль" items={signerItems} />
        <FormInputDate name="date" label="Дата внесения записи о недостатке" />
        <FormControlLabel
          control={<Switch checked={isDefect} onChange={(e) => setIsDefect(e.target.checked)} />}
          label="Дефекты (недостатки) имеются"
        />
        {isDefect && (
          <>
            <FormInput name="number" label="Номер недостатка" />
            <FormInputLocation name="location" label="Местоположение выявленного недостатка" />
            <FormInput
              name="structural_element"
              label="Наименование и обозначение структурного элемента"
            />
            <FormSelect
              name="works"
              label="Список работ в отношении структурного элемента и результата работ в указанном месторасположении"
              items={worksListItems}
              multiple
            />
            <FormTextarea name="description" label="Описание выявленного недостатка" />
            <FormInputDate
              name="defect_fixing_plan_date"
              label="Установленная (запланированная) дата устранения выявленного недостатка"
            />
          </>
        )}
        {!isDefect && (
          <>
            <FormTextarea name="description" label="Сведения об отсутствии недостатков" />
          </>
        )}
      </Box>
    </FormProvider>
  )
}
