import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { addAuthorization } from '../../util/http/addAuthorization'

type TRequest = {
  buildingId: string
  id: string
}

type TSignRequest = {
  buildingId: string
  body: {
    file_id: string
    signature: string
    employee_id?: string
    single?: boolean
  }
}

export const filesApi = createApi({
  reducerPath: 'files',
  tagTypes: ['Files'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1/',
    prepareHeaders: addAuthorization,
    validateStatus: (response) => response.ok,
  }),
  endpoints: (builder) => ({
    getFile: builder.query<string, TRequest>({
      query: ({ buildingId, id }) => ({
        url: `/buildings/${buildingId}/files/${id}`,
        method: 'GET',
        // params: params
      }),
    }),
    getFile64: builder.query<string, TRequest>({
      query: ({ buildingId, id }) => ({
        url: `/buildings/${buildingId}/files64/${id}`,
        method: 'GET',
        // params: params
      }),
    }),
    getFileHash: builder.query<string, TRequest>({
      query: ({ buildingId, id }) => ({
        url: `/buildings/${buildingId}/files/hash/${id}`,
        method: 'GET',
        // params: params
      }),
    }),
    signFile: builder.mutation<void, TSignRequest>({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/files/sign`,
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  useGetFileQuery,
  useGetFile64Query,
  useGetFileHashQuery,
  useSignFileMutation,
} = filesApi
