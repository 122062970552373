import { FormDialog } from '../common/FormDialog'
import * as React from 'react'
import { useState } from 'react'
import PrdDocumentSelectForm from './PrdDocumentSelectForm'
import { TPrdDocument, TPrdType } from '../../types/prd'
import PrdListsForm from './PrdListsForm'

type Props = {
  document: TPrdDocument
  onSelect: (values: any) => void
  onClose: () => void
  open: boolean
  values?: number[]
}

const PrdListsDialog = ({ onSelect, document, open, onClose, values }: Props) => {
  const id = 'prd-lists-dialog'
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = (values: any, methods: any) => {
    onSelect(values)
  }

  return (
    <>
      <FormDialog
        formId={id}
        open={open}
        onClose={onClose}
        title={'Выбрать листы'}
        button={'Выбрать'}
        isLoading={isLoading}
      >
        <PrdListsForm id={id} document={document} values={values} onSubmit={handleSubmit} />
      </FormDialog>
    </>
  )
}

export default PrdListsDialog
