import { Box, Card, CardContent, CardHeader, Table } from '@mui/material'
import { companyRoles, TBuilding, TCompany, TEmployee } from '../../../../types/building'

import { AddRepresentativeDialog } from './AddRepresentativeDialog'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import { useGetCompaniesQuery } from '../../../../store/companies'
import { useGetEmployeesQuery } from '../../../../store/employees'
import { useAuthorization } from '../../../../auth'
import { useGetDocumentsQuery } from '../../../../store/document'
import { documentType, IDocument } from '../../../../types/document'
import { RepresentativesCompany } from './RepresentativesCompany'

const representativesByRole = (
  representatives: IDocument[],
  companies: TCompany[],
  role: string
): Record<string, IDocument[]> => {
  return (
    representatives
      .filter((representative) => representative.data.company_role === role)
      .reduce((acc: any, current) => {
        if (!acc[current.data.company_id]) {
          acc[current.data.company_id] = []
        }
        acc[current.data.company_id].push(current)
        return acc
      }, {}) || {}
  )
}

const representativeEmployee = (
  representative: IDocument,
  employees: TEmployee[]
): TEmployee | undefined =>
  employees.find((e) => e.id === representative.employees.employee[0].employee_id)

const companyRepresentatives = (
  company: TCompany,
  employees: TEmployee[],
  representatives: IDocument[]
) => representatives.filter((r) => representativeEmployee(r, employees)?.company_id === company.id)

type Props = {
  building: TBuilding
}

const Representatives = ({ building }: Props) => {
  const authManager = useAuthorization()

  const { journalId } = useParams()
  const {
    data: representatives,
    isLoading,
    refetch,
  } = useGetDocumentsQuery({
    buildingId: building.id,
    params: {
      parent_id: journalId,
      type: documentType.Representative,
    },
  })

  const { data: companies } = useGetCompaniesQuery(building.id)
  const { data: employees } = useGetEmployeesQuery(building.id)

  const isManage = authManager.vote('Gwj', 'manage')

  if (
    isLoading ||
    representatives === undefined ||
    companies === undefined ||
    employees === undefined
  ) {
    return <>Loading...</>
  }

  return (
    <Box flexGrow={1}>
      <Card>
        <CardHeader title="Представители" subheader={'Компании участники и их представители'} />
        <Table>
          {Object.keys(companyRoles).map((role: string) =>
            companies
              .filter((c) => c.role === role)
              .map((company) => (
                <RepresentativesCompany
                  key={company.id}
                  building={building}
                  company={company}
                  employees={employees}
                  representatives={representatives.items}
                  onSign={refetch}
                  onReady={refetch}
                />
              ))
          )}
        </Table>
        <CardContent>
          {isManage && <AddRepresentativeDialog building={building} onAddAll={refetch} />}
        </CardContent>
      </Card>
    </Box>
  )
}

export { Representatives }
