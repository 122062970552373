import { preprocess, number, object, string, TypeOf } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Stack, Typography } from '@mui/material'
import FormSelect from '../../../form/FormSelect'
import React, { useMemo } from 'react'
import { FormInput, FormInputDate } from '../../../form'
import { useGetEmployeesQuery } from '../../../../store/employees'
import { useGetCompaniesQuery } from '../../../../store/companies'
import { employeesList } from '../../../../util/employees'

const schema = {
  number: string().min(1, 'Необходимо заполнить'),
  developerRepresentative: string().min(1, 'Необходимо заполнить'),
  dateBegin: string().min(1, 'Необходимо заполнить дату'),
  dateEnd: string().min(1, 'Необходимо заполнить дату'),
  volume: preprocess((x: any) => Number(x), number().int()),
}
const registerSchema = object(schema)

interface Props {
  buildingId: string
  values?: any
  onSubmit: (values: TypeOf<typeof registerSchema>, methods: any) => void
  id?: string
}

const CommonInfoForm = ({ buildingId, values, onSubmit, id }: Props) => {
  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: values || {},
  })
  const { data: employees } = useGetEmployeesQuery(buildingId)
  const { data: companies } = useGetCompaniesQuery(buildingId)

  const signerItems = useMemo(
    () =>
      companies && employees
        ? employeesList(companies, employees, [
            'customer',
            'developer',
            'regional_operator',
            'operating_person',
          ])
        : [],
    [companies, employees]
  )

  const {
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = methods

  const submitHandler = (values: any) => {
    onSubmit(values, methods)
  }

  return (
    <FormProvider {...methods}>
      <Box
        id={id}
        component="form"
        onSubmit={handleSubmit(submitHandler)}
        noValidate
        autoComplete="off"
      >
        <FormInput name={'number'} label={'Номер журнала'} />
        <FormInput name={'volume'} label={'Объем журнала'} />
        <Typography variant={'body1'} sx={{ fontWeight: 'medium' }}>
          Сведения о временном периоде строительства, содержащемся в журнале
        </Typography>
        <Stack direction={'row'} spacing={1}>
          <FormInputDate name={'dateBegin'} label={'с'} />
          <FormInputDate name={'dateEnd'} label={'по'} />
        </Stack>
        <FormSelect
          name="developerRepresentative"
          label="Представитель застройщика, внёсший общие сведения"
          items={signerItems}
        />
      </Box>
    </FormProvider>
  )
}

export { CommonInfoForm }
