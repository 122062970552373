import { useParams } from 'react-router-dom'
import { Button } from '@mui/material'
import { Add } from '@mui/icons-material'
import { FormDialog } from '../../../common/FormDialog'
import * as React from 'react'
import { useState } from 'react'
import { AddRepresentativeForm } from './AddRepresentativeForm'
import { useAddAllRepresentativesMutation } from '../../../../store/gwj'
import { TBuilding } from '../../../../types/building'
import { useGetCompaniesQuery } from '../../../../store/companies'
import { employeesList } from '../../../../util/employees'
import { useGetEmployeesQuery } from '../../../../store/employees'
import { LightButton } from '../../../common/LightButton'
import { useAddDocumentMutation, useGetDocumentsQuery } from '../../../../store/document'
import { documentType } from '../../../../types/document'
import { toastError } from '../../../../util/toastError'
import { toast } from 'react-toastify'

interface IProps {
  building: TBuilding
  onAddAll: () => void
}

export const AddRepresentativeDialog = ({ building, onAddAll }: IProps) => {
  const id = 'add-title-employee-dialog'
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { journalId } = useParams()
  const [addRepresentative] = useAddDocumentMutation()
  const [addAllRepresentatives] = useAddAllRepresentativesMutation()

  const { data: employees } = useGetEmployeesQuery(building.id)
  const { data: companies } = useGetCompaniesQuery(building.id)
  const { data: representatives } = useGetDocumentsQuery({
    buildingId: building.id,
    params: { parent_id: journalId, type: documentType.Representative },
  })

  const employeeItems =
    companies && employees && representatives
      ? employeesList(
          companies,
          employees,
          [
            'developer',
            'developer_control',
            'operating_person',
            'regional_operator',
            'customer',
            'customer_control',
            'designer',
            'building_contractor',
            'contractor',
            'gsn',
          ],
          [
            'auth_representative',
            'auth_representative_control',
            'auth_representative_doc_control',
            'auth_supervisory_control',
          ],
          representatives.items.map((rep) => rep.employees.employee[0].employee_id)
        )
      : []

  const handleSubmit = (values: any, methods: any) => {
    setIsLoading(true)
    addRepresentative({
      buildingId: building.id,
      body: {
        parent_id: journalId,
        type: documentType.Representative,
        employees: [{ id: values.employee_id, type: 'employee' }],
        ...values,
      },
    })
      .unwrap()
      .then((res) => {
        methods.reset()
        setOpen(false)
        toast.success('Представитель добавлен')
      })
      .catch(toastError)
      .finally(() => setIsLoading(false))
  }

  const handleAddAll = () => {
    setIsLoading(true)
    addAllRepresentatives({
      buildingId: building.id,
      body: {
        journal_id: journalId,
      },
    })
      .unwrap()
      .then((res) => {
        setOpen(false)
        toast.success('Представители добавлены')
        onAddAll && onAddAll()
      })
      .catch(toastError)
      .finally(() => setIsLoading(false))
  }

  return (
    <>
      <LightButton startIcon={<Add />} onClick={() => setOpen(true)}>
        Добавить представителя
      </LightButton>
      <FormDialog
        formId={id}
        open={open}
        onClose={() => setOpen(false)}
        title={'Добавить представителя'}
        button={employeeItems.length > 0 ? 'Отправить' : false}
        isLoading={isLoading}
        buttons={employeeItems.length > 0 && <Button onClick={handleAddAll}>Добавить всех</Button>}
      >
        <AddRepresentativeForm
          id={id}
          onSubmit={handleSubmit}
          building={building}
          itemsList={employeeItems}
        />
      </FormDialog>
    </>
  )
}
