import { generateCodeChallenge, generateCodeVerifier, generateState } from './crypt'

interface TokenResponse {
  token_type: string
  access_token: string
  expires_in: number
  refresh_token: string
}

type Tokens = {
  accessToken: string
  expires: number
  refreshToken: string
}

interface ApiData {
  authorizeUrl: string
  tokenUrl: string
  clientId: string
  scope: string
  redirectPath: string
}

interface Auth {
  data: ApiData
  refreshPromises: Record<string, Promise<string>>
  buildTokens: (data: TokenResponse) => Tokens
  code: () => Promise<any>
  login: () => void
  logout: () => void
  getToken: () => Promise<any>
}

const auth: Auth = {
  data: { authorizeUrl: '', tokenUrl: '', clientId: '', scope: '', redirectPath: '' },
  refreshPromises: {},
  buildTokens: (data: TokenResponse): Tokens => ({
    accessToken: data.token_type + ' ' + data.access_token,
    expires: new Date().getTime() + (data.expires_in - 5) * 1000,
    refreshToken: data.refresh_token,
  }),
  code() {
    const { authorizeUrl, tokenUrl, clientId, scope, redirectPath } = this.data

    const isAuthRedirect = window.location.pathname === redirectPath

    if (!isAuthRedirect) {
      return Promise.resolve()
    }
    const query = new URLSearchParams(window.location.search)

    const getStateError = (): string | null => {
      if (!query.get('state')) {
        return 'Empty state.'
      }
      if (query.get('state') !== window.localStorage.getItem('auth.state')) {
        return 'Invalid state.'
      }
      return null
    }

    const getAuthRedirectError = (): string | null => {
      return query.get('hint') || query.get('error_description') || query.get('error')
    }

    const error = getStateError() || getAuthRedirectError() || null

    if (error) {
      return Promise.reject(new Error(error))
    }

    const codeVerifier = window.localStorage.getItem('auth.code_verifier')

    if (!codeVerifier) {
      return Promise.reject(new Error('Empty verifier'))
    }

    const authCode = query.get('code')

    if (!authCode) {
      return Promise.reject(new Error('Empty code'))
    }

    return fetch(tokenUrl, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        client_id: clientId,
        code_verifier: codeVerifier,
        grant_type: 'authorization_code',
        redirect_uri: window.location.origin + redirectPath,
        access_type: 'offline',
        code: authCode,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response
        }
        throw response
      })
      .then(async (response) => {
        const data = (await response.json()) as TokenResponse

        const tokens = this.buildTokens(data)

        window.localStorage.setItem('auth.tokens', JSON.stringify(tokens))
        window.localStorage.removeItem('auth.state')
        window.localStorage.removeItem('auth.code_verifier')

        let location = window.localStorage.getItem('auth.location') ?? '/'
        window.localStorage.removeItem('auth.location')

        if (
          [
            redirectPath,
            '/join',
            '/join/confirm',
            '/join/success',
            '/reset/request',
            '/reset/reset',
          ].includes(location)
        ) {
          location = '/'
        }
        window.location.replace(location)
      })
      .catch(async (error) => {
        if (!(error instanceof Response)) {
          throw error
        } else {
          const headers = error.headers.get('content-type')
          if (headers && headers.includes('application/json')) {
            const data = await error.json()
            throw new Error(data.hint || data.error_description || data.error || data.message)
          } else {
            throw new Error(error.statusText)
          }
        }
      })
  },
  async login() {
    const { authorizeUrl, tokenUrl, clientId, scope, redirectPath } = this.data

    const currentLocation = window.location.pathname
    const codeVerifier = generateCodeVerifier()
    const codeChallenge = await generateCodeChallenge(codeVerifier)
    const state = generateState()

    window.localStorage.setItem('auth.location', currentLocation)
    window.localStorage.setItem('auth.code_verifier', codeVerifier)
    window.localStorage.setItem('auth.state', state)

    const args = new URLSearchParams({
      response_type: 'code',
      client_id: clientId,
      code_challenge_method: 'S256',
      code_challenge: codeChallenge,
      redirect_uri: window.location.origin + redirectPath,
      scope,
      state,
    })

    window.location.assign(authorizeUrl + '?' + args)
  },
  logout() {
    window.localStorage.removeItem('auth.tokens')
  },
  getToken() {
    const { authorizeUrl, tokenUrl, clientId, scope, redirectPath } = this.data

    const storageTokens = window.localStorage.getItem('auth.tokens')

    if (storageTokens === null) {
      return Promise.reject(new Error('Empty storage tokens'))
    }

    const tokens = JSON.parse(storageTokens) as Tokens

    if (tokens === null) {
      return Promise.reject(new Error())
    }

    if (tokens.expires > new Date().getTime()) {
      return Promise.resolve(tokens.accessToken)
    }

    if (Object.prototype.hasOwnProperty.call(this.refreshPromises, tokens.refreshToken)) {
      return this.refreshPromises[tokens.refreshToken]
    }

    this.refreshPromises[tokens.refreshToken] = fetch(tokenUrl, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        client_id: clientId,
        grant_type: 'refresh_token',
        redirect_uri: window.location.origin + redirectPath,
        access_type: 'offline',
        refresh_token: tokens.refreshToken,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw response
        }
        return response
      })
      .then(async (response) => {
        const data = await response.json()
        const tokens = this.buildTokens(data)
        window.localStorage.setItem('auth.tokens', JSON.stringify(tokens))
        return tokens.accessToken
      })
      .catch((error) => {
        window.localStorage.removeItem('auth.tokens')
        throw error
      })

    return this.refreshPromises[tokens.refreshToken]
  },
}

export { auth }
export type { Auth }
