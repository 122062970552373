import React from 'react'
import { ListItemIcon, MenuItem, MenuItemProps } from '@mui/material'
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined'

const MenuItemEditSingle = ({ children, ...overProps }: MenuItemProps) => {
  return (
    <MenuItem {...overProps}>
      <ListItemIcon>
        <DriveFileRenameOutlineOutlinedIcon />
      </ListItemIcon>
      {children}
    </MenuItem>
  )
}

export { MenuItemEditSingle }
