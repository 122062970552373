import {
  FormHelperText,
  FormControl,
  InputProps,
  Autocomplete,
  debounce,
  Grid,
} from '@mui/material'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import { match, parse } from '../../util/highlight'

type IFormInputProps = {
  name: string
  label: string
  hint?: string
} & InputProps

interface ICity {
  value: string
  unrestricted_value: string
}

const FormInputAddress: FC<IFormInputProps> = ({ name, label, hint, ...otherProps }) => {
  const {
    control,
    formState: { errors, defaultValues },
    getValues,
    setValue,
    getFieldState,
  } = useFormContext()

  const error = getFieldState(name).error
  const errorMessage: string = (error ? error.message : '') as string

  const [optionValue, setOptionValue] = useState<ICity | null>(null)
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState<readonly ICity[]>([])

  const value = getValues(name)

  useEffect(() => {
    if (value) {
      const val = {
        value: value,
        unrestricted_value: value,
      }
      setOptionValue(val)
      setOptions([val])
      setInputValue(value)
    }
  }, [value])

  const fetchCity = useMemo(
    () =>
      debounce(
        async (request: { input: string }, callback: (results?: readonly ICity[]) => void) => {
          const result = await fetch('/api/address.php?term=' + request.input).then((response) => {
            return response.json()
          })
          callback(result)
        },
        400
      ),
    []
  )

  useEffect(() => {
    let active = true

    if (inputValue === '') {
      setOptions(optionValue ? [optionValue] : [])
      return undefined
    }

    fetchCity({ input: inputValue }, (results?: readonly ICity[]) => {
      if (active) {
        let newOptions: readonly ICity[] = []

        if (optionValue) {
          newOptions = [optionValue]
        }

        if (results) {
          newOptions = [...newOptions, ...results]
        }

        setOptions(newOptions)
      }
    })
    return () => {
      active = false
    }
  }, [optionValue, inputValue, value, fetchCity])

  return (
    <Controller
      control={control}
      defaultValue=""
      name={name}
      render={({ field }) => (
        <FormControl fullWidth margin={'dense'}>
          <Autocomplete
            getOptionLabel={(option) =>
              typeof option === 'string' ? option : option.unrestricted_value
            }
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={optionValue}
            noOptionsText="Поиск..."
            filterOptions={(x) => x}
            freeSolo
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue)
              setValue(name, newInputValue)
            }}
            inputValue={inputValue}
            renderInput={(params) => (
              <TextField {...params} {...field} error={!!error} label={label} />
            )}
            renderOption={(props, option) => {
              try {
                const matches = match(option.unrestricted_value, inputValue)
                const parts = parse(option.unrestricted_value, matches)
                return (
                  <li {...props}>
                    <Grid container alignItems="center">
                      <Grid item sx={{ display: 'flex', width: 44 }}>
                        <LocationOnIcon sx={{ color: 'text.secondary' }} />
                      </Grid>
                      <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                        {parts.map((part: any, index: number) =>
                          part.highlight ? (
                            <Box
                              key={index}
                              component="span"
                              sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                            >
                              {part.text}
                            </Box>
                          ) : (
                            part.text
                          )
                        )}
                      </Grid>
                    </Grid>
                  </li>
                )
              } catch (error) {
                return <li>{option.unrestricted_value}</li>
              }
            }}
          />
          {hint && <FormHelperText>{hint}</FormHelperText>}
          <FormHelperText error={!!error}>{errorMessage}</FormHelperText>
        </FormControl>
      )}
    />
  )
}

export default FormInputAddress
