import { FormDialog } from '../../../common/FormDialog'
import * as React from 'react'
import { useState } from 'react'
import { AddControlEventForm } from './AddControlEventForm'
import { useEditDocumentMutation } from '../../../../store/document'
import { documentType, IDocument } from '../../../../types/document'
import { toastError } from '../../../../util/toastError'

interface IProps {
  event: IDocument
  onSave: () => void
  open: boolean
  onClose: () => void
}

export const EditControlEventDialog = ({ event, onSave, onClose, open }: IProps) => {
  const id = 'edit-control-event-form'
  const [isLoading, setIsLoading] = useState(false)
  const [editEvent] = useEditDocumentMutation()

  const handleSubmit = (values: any, methods: any) => {
    setIsLoading(true)
    editEvent({
      buildingId: event.building_id,
      body: {
        document_id: event.id,
        type: documentType.GWJ4ControlEvent,
        data: values,
      },
    })
      .unwrap()
      .then((res) => {
        methods.reset()
        onClose()
        onSave()
      })
      .catch(toastError)
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <>
      <FormDialog
        formId={id}
        open={open}
        onClose={onClose}
        title={'Редактировать контрольное мероприятие'}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <AddControlEventForm id={id} onSubmit={handleSubmit} values={event.data} />
      </FormDialog>
    </>
  )
}
