import { useState } from 'react'
import { Link } from '@mui/material'
import { TPrdDocument } from '../../types/prd'
import PrdDocViewDialog from './PrdDocViewDialog'

type Props = {
  document: TPrdDocument
  showCode?: boolean
}

const PrdDocLine = ({ document, showCode = false }: Props) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Link underline={'hover'} sx={{ cursor: 'pointer' }} onClick={() => setOpen(true)}>
        {`${document.name}`}
        {showCode && ` ${document.code}`}
      </Link>
      <PrdDocViewDialog document={document} open={open} onClose={() => setOpen(false)} />
    </>
  )
}

export default PrdDocLine
