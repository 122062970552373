import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  SwipeableDrawer,
  useMediaQuery,
} from '@mui/material'
import * as React from 'react'
import CloseIcon from '@mui/icons-material/Close'

type Props = {
  open: boolean
  onClose: () => void
  onOpen: () => void
  children: React.ReactNode
  actions?: React.ReactNode
  bgDefault?: boolean
}

const RightPanel = ({ open, onClose, onOpen, actions, bgDefault = true, children }: Props) => {
  const matches = useMediaQuery('(min-width:600px)')
  const minWidth = matches ? '30%' : 'calc(100% - 15px)'
  const maxWidth = matches ? '70%' : 'calc(100% - 15px)'

  return (
    <SwipeableDrawer
      anchor={'right'}
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      PaperProps={{
        sx: {
          minWidth: minWidth,
          maxWidth: maxWidth,
          justifyContent: 'space-between',
          overflowY: 'hidden',
        },
      }}
    >
      <Box sx={{ overflowY: 'auto', flexGrow: 1, backgroundColor: bgDefault ? 'background.default' : 'background.paper', p: 2 }}>
        {children}
      </Box>
      <Box sx={{ p: 2, backgroundColor: 'background.paper' }}>
        <Stack spacing={2} direction="row" sx={{ alignItems: 'center' }}>
          {actions}
          <Box sx={{ flexGrow: 1, justifyContent: 'flex-end', display: 'flex' }}>
            <IconButton onClick={onClose} aria-label="close" size="large">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Box>
        </Stack>
      </Box>
    </SwipeableDrawer>
  )
}

export default RightPanel
