import { object, string, TypeOf } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { useState } from 'react'
import { Box } from '@mui/material'
import FormInput from '../../../form/FormInput'
import { FormInputDate, FormInputExtDocument, FormTextarea } from '../../../form'

const schema = {
  name: string().min(1, 'Необходимо заполнить'),
  number: string().min(1, 'Необходимо заполнить'),
  date: string().min(1, 'Необходимо заполнить дату'),
  // is_external: boolean(),
  // document_id: string().min(1, 'Необходимо заполнить'),
  docSubject: string().min(1, 'Необходимо заполнить'),
}
const registerSchema = object(schema)

interface Props {
  values?: any
  onSubmit: (values: TypeOf<typeof registerSchema>, methods: any) => void
  id: string
}

export const Part6ItemForm = ({ values, onSubmit, id }: Props) => {
  const [isExternal, setIsExternal] = useState(true)

  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: values || { is_external: true },
  })

  const { handleSubmit, setValue } = methods

  const handleTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsExternal(e.target.checked)
    // setValue('is_external', e.target.checked)
  }

  const submitHandler = (values: any) => {
    onSubmit(values, methods)
  }

  return (
    <FormProvider {...methods}>
      <Box
        id={id}
        component="form"
        onSubmit={handleSubmit(submitHandler)}
        noValidate
        autoComplete="off"
      >
        {/* <FormControlLabel */}
        {/*  control={<Switch checked={isExternal} onChange={handleTypeChange} />} */}
        {/*  label="Внешний документ" /> */}
        {/* {isExternal && */}
        {/*  <FormInputExtDocument name='document_id' label='Документ' docType={'act'} /> */}
        {/* } */}
        {/* {!isExternal && <FormInput name='document_id' label='Документ' />} */}

        <FormInput name="name" label="Наименование документа" />
        <FormInput name="number" label="Номер документа" />
        <FormInputDate name="date" label="Дата документа" />

        <FormTextarea
          name="docSubject"
          label="Предмет документирования (с указанием вида работ, места расположения конструкций, участков инженерно-технических сетей и т.д.)"
        />
      </Box>
    </FormProvider>
  )
}
