import { useMatches } from 'react-router-dom'

const useRouteTab = () => {
  const routeMatch = useMatches()
  for (let i = routeMatch.length - 1; i > 0; i--) {
    if (routeMatch[i].handle && (routeMatch[i].handle as any).key) {
      return (routeMatch[i].handle as any)?.key
    }
  }
  return null
}

export { useRouteTab }
