import {
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import React, { ReactNode } from 'react'
import Box from '@mui/material/Box'

interface Props {
  formId: string
  open: boolean
  title?: string
  button?: string | boolean
  onClose: () => void
  isLoading?: boolean
  buttons?: ReactNode
  maxWidth?: false | Breakpoint
  fullWidth?: boolean
  children: any
}

const FormDialog = ({
  formId,
  open,
  onClose,
  title,
  button = 'Сохранить',
  isLoading = false,
  buttons,
  children,
  maxWidth = 'lg',
  fullWidth = true,
}: Props) => {

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      scroll={fullScreen ? 'paper' : 'body'}
      fullScreen={fullScreen}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <Box sx={{ mt: 1 }}>{children}</Box>
      </DialogContent>
      <DialogActions>
        {buttons}
        {button && (
          <LoadingButton
            variant="contained"
            type="submit"
            form={formId}
            loading={isLoading}
            color={'success'}
          >
            {button}
          </LoadingButton>
        )}
        <Button onClick={onClose}>Закрыть</Button>
      </DialogActions>
    </Dialog>
  )
}

export { FormDialog }
