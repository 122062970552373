import { object, string, TypeOf } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { Box } from '@mui/material'
import FormInput from '../form/FormInput'

const schema = {
  name: string().min(1, 'Необходимо заполнить'),
}
const registerSchema = object(schema)

interface Props {
  values?: any
  onSubmit: (values: TypeOf<typeof registerSchema>, methods: any) => void
  id?: string
}

const PrdGroupForm = ({ values, onSubmit, id = 'prd-group-form' }: Props) => {
  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: values || {
      type: 'default',
    },
  })

  const {
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods

  const hs = handleSubmit((values: any) => {
    onSubmit(values, methods)
  })

  const onFormSubmit = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    return hs(e)
  }

  return (
    <FormProvider {...methods}>
      <Box id={id} component="form" onSubmit={onFormSubmit} noValidate autoComplete="off">
        <FormInput name="name" label="Наименование" />
      </Box>
    </FormProvider>
  )
}

export default PrdGroupForm
