import { FormHelperText, FormControl, InputProps } from '@mui/material'
import React, { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'

type IFormInputProps = {
  name: string
  label: string
  size?: 'small' | 'medium'
} & InputProps

const FormInput: FC<IFormInputProps> = ({ name, label, size = 'medium', ...otherProps }) => {
  const {
    control,
    formState: { errors },
    getFieldState,
  } = useFormContext()

  const error = getFieldState(name).error
  const errorMessage: string = (error ? error.message : '') as string

  return (
    <Controller
      control={control}
      defaultValue=""
      name={name}
      render={({ field: { onChange, value } }) => (
        <FormControl fullWidth margin={'dense'}>
          <DatePicker
            label={label}
            value={typeof value === 'string' && value ? dayjs(value) : null}
            onChange={(tDate) => {
              onChange(tDate?.isValid() ? tDate.format('YYYY-MM-DD HH:mm:ss') : '')
            }}
            closeOnSelect
            slotProps={{
              textField: { size: size },
              field: { clearable: true },
              openPickerButton: {
                color: 'primary',
              },
              inputAdornment: {
                position: 'end',
              },
            }}
          />
          <FormHelperText error={!!error}>{errorMessage}</FormHelperText>
        </FormControl>
      )}
    />
  )
}

export default FormInput
