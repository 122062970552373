import { useId, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { FormDialog } from '../../../common/FormDialog'
import * as React from 'react'
import Item3Form from './Item3Form'
import { useParams } from 'react-router-dom'
import { useAddDocumentMutation, useEditDocumentMutation } from '../../../../store/document'
import { documentType, IDocument } from '../../../../types/document'
import dayjs from 'dayjs'
import { toastError } from '../../../../util/toastError'
import { AddButton } from '../../../common/AddButton'
import { LightButton } from '../../../common/LightButton'

const AddItem3Dialog = ({ buildingId, document, isEdit = false }: { buildingId: string, isEdit?: boolean, document?: IDocument }) => {
  const id = useId()
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { journalId } = useParams()

  const [addDocument] = useAddDocumentMutation()
  const [editDocument] = useEditDocumentMutation()

  const values = useMemo(() => {
    if (!document) return undefined
    return {
      signer_id: document.employees.signer[0].employee_id,
      work_id: document.works[0],
      date: document.date
    }
  }, [document])

  const handleSubmit = (values: any, methods: any) => {
    const { date, signer_id, work_id } = values


    const body: any = {
      type: documentType.GWJ3Item,
      date,
      parent_id: journalId,
      data: {
        location: {
          place: values.place,
        },
      },
      works: [work_id],
      employees: [{ id: signer_id, type: 'signer' }],
    }

    if (isEdit && document) {
      body.document_id = document.id
    } else {
      body.parent_id = journalId
    }

    const mutation = (isEdit && document) ? editDocument : addDocument

    setIsLoading(true)
    mutation({ buildingId: buildingId, body })
      .unwrap()
      .then(() => {
        methods.reset()
        setOpen(false)
        toast.success(isEdit ? 'Документ добавлен' : 'Документ обновлен')
      })
      .catch(toastError)
      .finally(() => setIsLoading(false))
  }

  return (
    <>
      {!isEdit &&  <AddButton onClick={() => setOpen(true)}>Добавить запись</AddButton>}
      {isEdit &&  <LightButton onClick={() => setOpen(true)}>Редактировать</LightButton>}
      <FormDialog
        formId={id}
        open={open}
        onClose={() => setOpen(false)}
        title={isEdit ? 'Добавить запись' : 'Редектировать запись'}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <Item3Form
          buildingId={buildingId}
          id={id}
          onSubmit={handleSubmit}
          values={values || { date: dayjs().format('YYYY-MM-DD HH:mm:ss') }}
        />
      </FormDialog>
    </>
  )
}

export default AddItem3Dialog
