import { TBuilding } from '../../../../types/building'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { Add } from '@mui/icons-material'
import { FormDialog } from '../../../common/FormDialog'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import { Item2Form } from './Item2Form'
import { useAddDocumentMutation } from '../../../../store/document'
import { documentType } from '../../../../types/document'
import { LightButton } from '../../../common/LightButton'
import { AddButton } from '../../../common/AddButton'

const AddItem2Dialog = ({ building, values }: { building: TBuilding; values: any }) => {
  const id = 'add-item-2-dialog'
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { journalId } = useParams()

  const [addDocument] = useAddDocumentMutation()

  const handleSubmit = (values: any, methods: any) => {
    const { date, date_pass, name, sequence_number, signer_id } = values

    const body = {
      type: documentType.GWJ2Item,
      parent_id: journalId,
      date: date,
      data: { date_pass, name, sequence_number },
      employees: [{ id: signer_id, type: 'signer' }],
    }

    setIsLoading(true)

    addDocument({ buildingId: building.id, body })
      .unwrap()
      .then(() => {
        methods.reset()
        setOpen(false)
        setIsLoading(false)
        toast.success('Документ добавлен')
      })
      .catch(() => {
        toast.error('Произошла ошибка')
        setIsLoading(false)
      })
  }

  return (
    <>
      <AddButton onClick={() => setOpen(true)}>
        Добавить запись
      </AddButton>
      <FormDialog
        formId={id}
        open={open}
        onClose={() => setOpen(false)}
        title={'Добавить запись'}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <Item2Form buildingId={building.id} id={id} onSubmit={handleSubmit} values={values} />
      </FormDialog>
    </>
  )
}

export default AddItem2Dialog
