import { Skeleton, Stack } from '@mui/material'
import React from 'react'

type Props = {
  rows?: number
  rowHeight?: number
  variant?: 'rounded' | 'text' | 'rectangular' | 'circular' | undefined
  spacing?: number
}
const SkeletonRows = ({ rows = 3, rowHeight = 50, variant = 'rounded', spacing = 1 }: Props) => {
  return (
    <Stack direction={'column'} spacing={spacing}>
      {Array.from(Array(rows).keys()).map((v, i) => (
        <Skeleton height={rowHeight} key={i} variant={variant} />
      ))}
    </Stack>
  )
}

export { SkeletonRows }
