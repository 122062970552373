import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import React, { useState } from 'react'
import { CompanyLegalForm } from './CompanyLegalForm'
import { TBuilding, TCompanyFilter, TCompanyType } from '../../../types/building'
import { CompanyIEForm } from './CompanyIEForm'



type Props = {
  type: 'add' | 'edit'
  open: boolean
  onClose: () => void
  onSubmit: (values: any, type: TCompanyType) => void
  values?: Record<string, string>
  buildingId: string,
  filter?: TCompanyFilter
}

export const CompanyDialogForm = ({
  type,
  open,
  onClose,
  onSubmit,
  values,
  buildingId,
  filter
}: Props) => {

  const [companyType, setCompanyType] = useState<TCompanyType>(
    (values?.type || 'legal') as TCompanyType
  )

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setCompanyType(value as TCompanyType)
  }

  const handleSubmit = (values: any) => {
    onSubmit(values, companyType)
  }

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth={'lg'} fullWidth>
        <DialogTitle>{type === 'add' ? 'Добавить компанию' : 'Редактировать компанию'}</DialogTitle>
        <DialogContent>
          {type === 'add' && (
            <>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="company-type"
                  onChange={handleTypeChange}
                  value={companyType}
                >
                  <FormControlLabel value="legal" control={<Radio />} label="Юридическое лицо" />
                  <FormControlLabel value="individual_entrepreneur" control={<Radio />} label="ИП" />

                </RadioGroup>
              </FormControl>

            </>
          )}
          {companyType === 'legal' && (
            <CompanyLegalForm
              id={type + '-legal-company'}
              onSubmit={handleSubmit}
              values={values}
              buildingId={buildingId}
              isAdd={type === 'add'}
              filter={filter}
            />
          )}
          {companyType === 'individual_entrepreneur' && (
            <CompanyIEForm
              id={type + '-individual_entrepreneur-company'}
              onSubmit={handleSubmit}
              values={values}
              buildingId={buildingId}
              isAdd={type === 'add'}
              filter={filter}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            type={'submit'}
            form={type + '-' + companyType + '-company'}
            variant={'contained'}
          >
            Отправить
          </Button>
          <Button onClick={onClose}>Закрыть</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
