import { useOutletContext } from 'react-router-dom'
import React from 'react'
import { WorksList } from '../../../components/works/WorksList'
import { IOutletBuilding } from '../../../types/outlet-context'

export const WorksMain = () => {
  const { building } = useOutletContext<IOutletBuilding>()

  return (
    <>
      <WorksList building={building} />
    </>
  )
}
