import { coerce, object, string, TypeOf } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { Box, Stack } from '@mui/material'
import FormInput from '../form/FormInput'
import FormSelect, { SelectItem } from '../form/FormSelect'

const schema = {
  name: string().min(1, 'Необходимо заполнить'),
  description: string().min(0),
  group_id: string().min(1, 'Необходимо выбрать группу'),
}
const registerSchema = object(schema)

interface Props {
  values?: any
  onSubmit: (values: TypeOf<typeof registerSchema>, methods: any) => void
  groups: SelectItem[] | undefined
  id?: string
}

const WorkForm = ({ values, onSubmit, groups, id }: Props) => {
  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: values || {
      type: 'default',
    },
  })

  const { handleSubmit } = methods

  const submitHandler = (values: any) => {
    onSubmit(values, methods)
  }

  return (
    <FormProvider {...methods}>
      <Box
        id={id}
        component="form"
        onSubmit={handleSubmit(submitHandler)}
        noValidate
        autoComplete="off"
      >
        <FormInput name="name" label="Наименование" />
        <FormInput name="description" label="Описание" />
        <FormSelect name="group_id" label="Входит в группу" items={groups} />
      </Box>
    </FormProvider>
  )
}

export default WorkForm
