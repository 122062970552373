import { TBuilding, TEmployee } from '../../../../types/building'
import { RightPanel, SkidPagination, useSkidPagination } from '../../../common'
import {
  Box,
  Button,
  CardHeader,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import * as React from 'react'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGetEmployeesQuery } from '../../../../store/employees'
import { formatDate } from '../../../../util/date'
import { StatusChip } from '../../../common/StatusChip'
import { Item2View } from './Item2View'
import AddItem2Dialog from './AddItem2Dialog'
import { parseError } from '../../../../api'
import { toast } from 'react-toastify'
import { useGetDocumentsQuery, useRemoveDocumentMutation } from '../../../../store/document'
import { documentType, IDocument } from '../../../../types/document'
import { useAuthorization } from '../../../../auth'
import { useGetUserQuery } from '../../../../store/user'
import { Delete } from '@mui/icons-material'
import { toastError } from '../../../../util/toastError'
import { nextNumber } from '../../../../util/maxNumber'

const signerName = (employees: TEmployee[], item: IDocument): string => {
  return (
    employees.find((em) => em.id === item.employees.signer[0].employee_id)?.profile.name
      .full_name || ''
  )
}

export const Part2List = ({ building }: { building: TBuilding }) => {
  const { journalId } = useParams()
  const { pages, page, offset, limit, onPageChange, setTotal } = useSkidPagination(20)

  const { data, isLoading, isSuccess, refetch } = useGetDocumentsQuery({
    buildingId: building.id,
    params: { type: documentType.GWJ2Item, parent_id: journalId },
  })

  const sorted = useMemo(() => {
    if (!data || data.items.length < 1) return []
    return Array.from(data.items).sort((a, b) => a.data.sequence_number - b.data.sequence_number)
  }, [data])

  const { data: employees } = useGetEmployeesQuery(building.id)

  const [removeItem] = useRemoveDocumentMutation()

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [currentId, setCurrentId] = useState<string | undefined>()

  const currentItem = useMemo(() => {
    return data && data.items.find((item) => item.id === currentId)
  }, [data, currentId])

  const values = {
    sequence_number: data ? nextNumber(data.items, (doc) => doc.data.sequence_number || '0') : {},
  }

  const authManager = useAuthorization()
  const { data: user } = useGetUserQuery()
  const isCreate = useMemo(() => authManager.vote('Document.GWJ2Item', 'create'), [])
  const isManage = useMemo(() => {
    return (
      (currentItem &&
        user &&
        authManager.vote('Document.GWJ2Item', 'manage', currentItem.owner_id === user.id) &&
        currentItem.status === 'draft') ||
      false
    )
  }, [currentItem, user])

  if (isSuccess) {
    setTotal(data.metadata.total)
  }

  if (isLoading || data === undefined) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    )
  }

  const viewItem = (id: string) => {
    setCurrentId(id)
    setDrawerOpen(true)
  }

  const handleRemove = (id: string | undefined, e: React.MouseEvent) => {
    e.stopPropagation()
    if (id) {
      removeItem({
        buildingId: building.id,
        body: {
          document_id: id,
          type: documentType.GWJ2Item,
        },
      })
        .unwrap()
        .then((res) => {
          refetch()
          setDrawerOpen(false)
          toast.success('Запись удалена')
        })
        .catch(toastError)
    }
  }

  return (
    <>
      <Paper>
        <TableContainer>
          <CardHeader
            title={'Перечень специальных журналов'}
            action={isCreate && <AddItem2Dialog building={building} values={values} />}
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Журнал/дата</TableCell>
                <TableCell>Ответственный</TableCell>
                <TableCell>Статус документа</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sorted.slice(offset, offset + limit).map((item) => {
                return (
                  <TableRow key={item.id} hover>
                    <TableCell>{item.data.sequence_number}</TableCell>
                    <TableCell onClick={() => viewItem(item.id)}>
                      <Box>
                        <Typography variant={'body1'}>{item.data.name}</Typography>
                      </Box>
                      <Box>
                        <Typography variant={'caption'} color={'grey'}>
                          {formatDate(item.date)}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell onClick={() => viewItem(item.id)}>
                      {employees && signerName(employees, item)}
                    </TableCell>
                    <TableCell onClick={() => viewItem(item.id)}>
                      <StatusChip status={item.status} />
                    </TableCell>
                    <TableCell align="right">
                      {isManage && (
                        <IconButton
                          color="error"
                          title="Удалить"
                          onClick={(e) => handleRemove(item.id, e)}
                        >
                          <Delete />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
          {data.items && data.items.length > 0 && (
            <SkidPagination page={page} pages={pages} onChange={onPageChange} />
          )}
        </TableContainer>
      </Paper>
      <RightPanel
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
        actions={
          <>
            {isManage && (
              <Button
                variant={'outlined'}
                color={'error'}
                onClick={(e) => handleRemove(currentItem?.id, e)}
              >
                Удалить
              </Button>
            )}
          </>
        }
      >
        {currentItem && (
          <Item2View
            building={building}
            item={currentItem}
            onChange={refetch}
            isManage={isManage}
          />
        )}
      </RightPanel>
    </>
  )
}
