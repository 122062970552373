import { TBuilding } from '../../types/building'
import { TPrdGroup, TPrdType } from '../../types/prd'
import React, { SyntheticEvent, useMemo, useState } from 'react'
import { Box, MenuItem, Typography } from '@mui/material'
import { MoreVertMenu } from '../menu'
import PrdGroupAddDialog from './PrdGroupAddDialog'
import { useDeleteGroupMutation } from '../../store/prd'
import { toastError } from '../../util/toastError'
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView'
import { TreeItem } from '@mui/x-tree-view/TreeItem'

const TreeLabel = ({
  group,
  onPlus,
  onDelete,
  isManage,
}: {
  group: TPrdGroup
  onPlus: () => void
  onDelete: () => void
  isManage: boolean
}) => {
  const [open, setOpen] = useState(false)

  const handlePlus = (e: SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()
    onPlus()
    setOpen(false)
  }
  const handleDelete = (e: SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()
    onDelete()
    setOpen(false)
  }

  const onOpen = (e: SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setOpen(true)
  }
  const onClose = (e: SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setOpen(false)
  }

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      sx={{ mt: 0.5, mb: 0.5 }}
    >
      <Typography variant={'body2'}>{group.name}</Typography>
      {isManage && (
        <MoreVertMenu onClose={onClose} open={open} onOpen={onOpen}>
          {!group.parent_id && <MenuItem onClick={handlePlus}>Добавить подраздел</MenuItem>}
          <MenuItem onClick={handleDelete}>Удалить</MenuItem>
        </MoreVertMenu>
      )}
    </Box>
  )
}

type Props = {
  building: TBuilding
  type: TPrdType
  groups: TPrdGroup[]
  onSelect: (id: string) => void
  isManage: boolean
}
const GroupsMenu = ({ building, groups, type, onSelect, isManage }: Props) => {
  const [openAddGroup, setOpenAddGroup] = useState(false)
  const [groupId, setGroupId] = useState('')

  const typeGroups = useMemo(() => groups.filter((group) => group.type === type), [type, groups])

  const [deleteGroup] = useDeleteGroupMutation()

  const handleSelect = (event: SyntheticEvent, nodeId: string, isSelected: boolean) => {
    if (isSelected) {
      onSelect(nodeId)
    }
  }

  const handlePlus = (id: string) => {
    setGroupId(id)
    setOpenAddGroup(true)
  }

  const handleDelete = (id: string) => {
    setGroupId(id)
    deleteGroup({ buildingId: building.id, body: { group_id: id } })
      .unwrap()
      .catch(toastError)
  }

  return (
    <>
      <SimpleTreeView
        onItemSelectionToggle={handleSelect}
        // onNodeSelect={handleSelect}
      >
        {typeGroups
          .filter((g) => g.parent_id === null)
          .map((group) => (
            <TreeItem
              key={group.id}
              itemId={group.id}
              label={
                <TreeLabel
                  group={group}
                  onPlus={() => handlePlus(group.id)}
                  onDelete={() => handleDelete(group.id)}
                  isManage={isManage}
                />
              }
            >
              {typeGroups
                .filter((g) => g.parent_id === group.id)
                .map((subGroup) => (
                  <TreeItem
                    key={subGroup.id}
                    itemId={subGroup.id}
                    label={
                      <TreeLabel
                        group={subGroup}
                        onPlus={() => handlePlus(subGroup.id)}
                        onDelete={() => handleDelete(subGroup.id)}
                        isManage={isManage}
                      />
                    }
                  />
                ))}
            </TreeItem>
          ))}
      </SimpleTreeView>
      {groupId && isManage && (
        <PrdGroupAddDialog
          building={building}
          type={type}
          open={openAddGroup}
          parentId={groupId}
          onClose={() => setOpenAddGroup(false)}
          onSave={() => setOpenAddGroup(false)}
        />
      )}
    </>
  )
}

export { GroupsMenu }
