import { object, string, TypeOf } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import FormSelect from '../../form/FormSelect'
import React from 'react'
import { Box } from '@mui/material'
import { TBuilding } from '../../../types/building'
import { Alert } from '@mui/lab'

const schema = {
  employee_id: string().min(1, 'Необходимо заполнить'),
}
const registerSchema = object(schema)

interface Props {
  building: TBuilding
  values?: any
  onSubmit: (values: TypeOf<typeof registerSchema>, methods: any) => void
  id?: string
  itemsList: any[]
}

export const AddRepresentativeForm = ({ building, values, onSubmit, id, itemsList }: Props) => {
  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: values || {},
  })

  const {
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = methods

  const submitHandler = (values: any) => {
    onSubmit(values, methods)
  }

  return (
    <FormProvider {...methods}>
      <Box
        id={id}
        component="form"
        onSubmit={handleSubmit(submitHandler)}
        noValidate
        autoComplete="off"
      >
        {itemsList.length > 0 && (
          <FormSelect name="employee_id" label="Сотрудник" items={itemsList} />
        )}
        {itemsList.length === 0 && (
          <Alert severity="warning">
            Все имеющиеся сотрудники уже добавлены в журнал или не заведены в объекте
          </Alert>
        )}
      </Box>
    </FormProvider>
  )
}
