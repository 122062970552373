import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { addAuthorization } from '../../util/http/addAuthorization'
import { IUser } from './types'

export const userApi = createApi({
  reducerPath: 'user',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1/',
    prepareHeaders: addAuthorization,
  }),
  endpoints: (builder) => ({
    getUser: builder.query<IUser, void>({
      query: () => ({
        url: '/auth/user',
        method: 'GET',
      }),
    }),
    invite: builder.mutation({
      query: (arg) => ({
        url: '/auth/invite',
        method: 'POST',
        body: {
          email: arg,
        },
      }),
    }),
  }),
})

export const { useGetUserQuery, useInviteMutation } = userApi
