import { Menu, MenuItem } from '@mui/material'
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state"
import React from 'react'
import { useConfirm } from '../../../../util/useConfirm'
import { ActOSRDialog } from '../../../acts/aosr/ActOSRDialog'
import { LightButton } from '../../../common/LightButton'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


type Props = {
  buildingId: string,
  items: string[]
}
export const CreateActMenu = ({buildingId, items}: Props) => {

  const confirm = useConfirm()
  const handleNoActs = () => {
    confirm({
      title: 'Выберите записи',
      description: 'Для создания акта необходимо выбрать записи',
      hideCancelButton: true,
      confirmationText: 'Закрыть'
    })
  }

  if (items.length === 0){
    return <div onClick={handleNoActs}><LightButton disabled endIcon={<ArrowDropDownIcon />}>Создать акт</LightButton></div>
  }

  return(
    <>
      <PopupState variant="popover" popupId="create-act-menu">
        {(popupState) => (
          <>
            <LightButton {...bindTrigger(popupState)} endIcon={<ArrowDropDownIcon />}>
              Создать акт
            </LightButton>
            <Menu {...bindMenu(popupState)}>
              <ActOSRDialog buildingId={buildingId} isCreate={true} renderButton={(onClick) => (
                <MenuItem onClick={onClick}>Акт ОСР</MenuItem>
              )} prevValues={{items: items}} onClose={popupState.close}/>

              <MenuItem onClick={popupState.close}>Акт ООК</MenuItem>
            </Menu>
          </>
        )}
      </PopupState>
    </>
  )
}
