import { TConstructionSiteAddress, TPostalAddress } from '../../types/address'

const locList: Record<string, string> = {
  город: 'г',
  'поселок городского типа': 'пгт',
  'рабочий поселок': 'рп',
  поселок: 'п',
  село: 'с',
  деревня: 'д',
  слобода: 'сл',
  станица: 'ст',
  хутор: 'х',
  улус: 'у',
  аул: 'а',
}

export function postalToString(address: TPostalAddress): string {
  if (address.string_address) return address.string_address

  const locType = address.name_of_locality_type
  const parts = []

  const localityName = address.locality_name
    ? (locType ? locList[locType] + ' ' : '') + address.locality_name
    : ''

  if (address.postal_code) parts.push(address.postal_code)
  if (address.district) parts.push(address.district)
  if (address.entity_of_federation) parts.push(address.entity_of_federation)
  if (address.region_code) parts.push(address.region_code)
  if (localityName) parts.push(localityName)
  if (address.street) parts.push(address.street)
  if (address.house) parts.push(address.house)
  if (address.building) parts.push(address.building)
  if (address.block) parts.push(address.block)
  if (address.floor) parts.push(address.floor)
  if (address.premises) parts.push(address.premises)
  if (address.room) parts.push(address.room)

  return parts.join(', ')
}

export function constructionSiteToString(address: TConstructionSiteAddress): string {
  if (address.string_address) return address.string_address

  const locType = address.name_of_locality_type
  const parts = []

  const localityName = address.locality_name
    ? (locType ? locList[locType] + ' ' : '') + address.locality_name
    : ''

  if (address.federation_subject) parts.push(address.federation_subject)
  if (localityName) parts.push(localityName)
  if (address.county) parts.push(address.county)
  if (address.region) parts.push(address.region)
  if (address.street) parts.push(address.street)
  if (address.building) parts.push(address.building)
  if (address.string_address) parts.push(address.string_address)

  return parts.join(', ')
}
