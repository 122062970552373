import KeyIcon from '@mui/icons-material/Key'
import KeyOffIcon from '@mui/icons-material/KeyOff';
import { SvgIconOwnProps } from '@mui/material'
import React from 'react'

interface Props {
  off?: boolean
}

const SignatureIcon = ({off = false, ...props}: Props & SvgIconOwnProps) => {

  return off ? <KeyOffIcon {...props} /> : <KeyIcon {...props} />
}

export default SignatureIcon
