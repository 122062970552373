import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { addAuthorization } from '../../util/http/addAuthorization'
import { TMaterial, TUnit } from '../../types/lists'

export const listsApi = createApi({
  reducerPath: 'lists',
  tagTypes: ['Materials', 'Units'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1/',
    prepareHeaders: addAuthorization,
    validateStatus: (response) => response.ok,
  }),
  endpoints: (builder) => ({
    getMaterials: builder.query<Record<string, TMaterial>, string>({
      query: (buildingId) => ({
        url: `/buildings/${buildingId}/materials`,
        method: 'GET',
      }),
      transformResponse(response: TMaterial[]) {
        return response.reduce((acc, curr) => {
          acc[curr.id] = curr
          return acc
        }, {} as Record<string, TMaterial>)
      },
      providesTags: ['Materials'],
    }),
    addMaterial: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/materials`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Materials'],
    }),
    getUnits: builder.query<Record<string, TUnit>, string>({
      query: (buildingId) => ({
        url: `/buildings/${buildingId}/units`,
        method: 'GET',
      }),
      transformResponse(response: TUnit[]) {
        return response.reduce((acc, curr) => {
          acc[curr.id] = curr
          return acc
        }, {} as Record<string, TUnit>)
      },
      providesTags: ['Units'],
    }),
    addUnit: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/units`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Units'],
    }),
  }),
})

export const {
  useGetMaterialsQuery,
  useAddMaterialMutation,
  useGetUnitsQuery,
  useAddUnitMutation,
} = listsApi
