import { LightButton } from '../common/LightButton'
import TuneIcon from '@mui/icons-material/Tune'
import { Badge, Box, useMediaQuery, useTheme } from '@mui/material'
import * as React from 'react'

type Props = {
  count: number,
  onClick: () => void
  text?: string
}
export const FilterButton = ({count, onClick, text = 'Фильтр'}: Props) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))
  return(
    <Badge badgeContent={count} color="error">
      <LightButton onClick={onClick} startIcon={<TuneIcon />}>
        <Box component={'span'} sx={{display: matches ? 'inline' : 'none'}}>{text}</Box>
      </LightButton>
    </Badge>
  )
}
