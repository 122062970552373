import React from 'react'
import System from '../../../layout/system'
import { useAuth } from '../../../oauth'
import Avatar from '@mui/material/Avatar'
import LoginIcon from '@mui/icons-material/Login'
import Typography from '@mui/material/Typography'
import { Button } from '@mui/material'

function Success() {
  const { isAuthenticated, login, logout } = useAuth()
  return (
    <System>
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LoginIcon />
      </Avatar>
      <Typography component="h1" variant="h3" sx={{ mb: 1 }}>
        Отлично!
      </Typography>
      <Typography component="div" variant="h5" sx={{ mb: 1 }}>
        Вы успешно зарегистрированы!
      </Typography>
      <p data-testid="join-success">
        <Button variant="contained" onClick={login}>
          Войти
        </Button>
      </p>
    </System>
  )
}

export default Success
