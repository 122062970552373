import { coerce, number, object, string, TypeOf } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { Box } from '@mui/material'
import { useGetCompaniesQuery } from '../../../../store/companies'
import { employeesList } from '../../../../util/employees'
import FormInput from '../../../form/FormInput'
import FormSelect from '../../../form/FormSelect'
import FormInputDate from '../../../form/FormInputDate'
import { useGetEmployeesQuery } from '../../../../store/employees'

const schema = {
  signer_id: string().min(1, 'Необходимо заполнить'),
  date: string().min(1, 'Необходимо заполнить дату'),
  sequence_number: coerce
    .number({ invalid_type_error: 'Должно быть целым положительным числом' })
    .int()
    .min(1, 'Должно быть целым положительным числом'),
  date_pass: string(),
  name: string().min(1, 'Необходимо заполнить'),
}
const registerSchema = object(schema)

interface Props {
  buildingId: string
  values?: any
  onSubmit: (values: TypeOf<typeof registerSchema>, methods: any) => void
  id: string
}

export const Item2Form = ({ buildingId, values, onSubmit, id }: Props) => {
  const { data: employees } = useGetEmployeesQuery(buildingId)
  const { data: companies } = useGetCompaniesQuery(buildingId)

  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: values || {},
  })

  const { handleSubmit } = methods

  const submitHandler = (values: any) => {
    onSubmit(values, methods)
  }

  const signerItems =
    companies && employees
      ? employeesList(
          companies,
          employees,
          ['building_contractor', 'contractor'],
          'auth_representative'
        )
      : []

  return (
    <FormProvider {...methods}>
      <Box
        id={id}
        component="form"
        onSubmit={handleSubmit(submitHandler)}
        noValidate
        autoComplete="off"
      >
        <FormInput name="sequence_number" label="Порядковый номер записи в перечне" />
        <FormInputDate name="date" label="Дата журнала" />
        <FormInput name="name" label="Наименование журнала" />
        <FormInputDate
          name="date_pass"
          label="Дата передачи застройщику или техническому заказчику законченного журнала"
        />
        <FormSelect
          name="signer_id"
          label="Уполномоченный представитель застройщика или технического заказчика, подтверждающий получение законченного журнала"
          items={signerItems}
        />
      </Box>
    </FormProvider>
  )
}
