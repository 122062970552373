import { Button, ButtonProps } from '@mui/material'
import { Add } from '@mui/icons-material'
import * as React from 'react'


export const AddButton = ({children, variant, color, startIcon, ...props}: ButtonProps) => {

  return(
    <Button
      variant={variant || 'contained'}
      color={color || 'info'}
      startIcon={startIcon || <Add />}
      {...props}
    >{children || 'Добавить'}</Button>
  )
}
