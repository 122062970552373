import { array, object, string, TypeOf } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { Box } from '@mui/material'
import { useGetCompaniesQuery } from '../../../../store/companies'
import { employeesList } from '../../../../util/employees'
import FormSelect from '../../../form/FormSelect'
import FormInputDate from '../../../form/FormInputDate'
import { useGetEmployeesQuery } from '../../../../store/employees'
import { AutocompleteInput } from '../../../form'
import FormInput from '../../../form/FormInput'

const schema = {
  number: string()
    .min(1, 'Необходимо заполнить')
    .refine((val) => /^[1-9]+$/.test(val), {
      message: 'Должно быть целым положительным числом',
    }),
  signer_id: string().min(1, 'Необходимо заполнить'),
  employee_id: string().min(1, 'Необходимо заполнить'),
  data: object({
    date_begin: string().min(1, 'Необходимо заполнить дату'),
    date_end: string().min(1, 'Необходимо заполнить дату'),
    work_type: array(string().min(1)).min(1, 'Необходимо заполнить'),
  }),
}
const registerSchema = object(schema)

interface Props {
  buildingId: string
  values?: any
  onSubmit: (values: TypeOf<typeof registerSchema>, methods: any) => void
  id?: string
  nextNumber?: number
}

const Item1Form = ({ buildingId, values, onSubmit, nextNumber, id = 'item-3-form' }: Props) => {
  const { data: employees } = useGetEmployeesQuery(buildingId)
  const { data: companies } = useGetCompaniesQuery(buildingId)

  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: values || { number: nextNumber + '' },
  })

  const { handleSubmit } = methods

  const submitHandler = (values: any) => {
    onSubmit(values, methods)
  }

  const signerItems =
    companies && employees
      ? employeesList(
          companies,
          employees,
          ['building_contractor', 'contractor'],
          'auth_representative'
        )
      : []

  const employeeItems =
    companies && employees
      ? employeesList(companies, employees, ['building_contractor', 'contractor'], 'auth_itp')
      : []

  return (
    <FormProvider {...methods}>
      <Box
        id={id}
        component="form"
        onSubmit={handleSubmit(submitHandler)}
        noValidate
        autoComplete="off"
      >
        <FormInput name="number" label="Порядковый номер записи" />
        <FormSelect
          name="employee_id"
          label="Сотрудник из списка инженерно-технического персонала"
          items={employeeItems}
        />
        <FormInputDate name="data.date_begin" label="Дата начала работ" />
        <FormInputDate name="data.date_end" label="Дата окончания работ" />
        <AutocompleteInput
          name="data.work_type"
          label="Вид работ"
          multiple
          variants={[
            'Общестроительные работы',
            'Инженерные сети',
            'Электроснабжение',
            'Наружное электроосвещение',
            'Внутреннее электроосвещение',
            'Наружное силовое электрооборудование',
            'Внутреннее силовое электрооборудование',
            'Слаботочные системы',
            'Наружные сети водоснабжения',
            'Наружные сети канализации',
            'Наружные сети водоснабжения и канализации',
            'Внутренние системы водоснабжения и канализации',
            'Отопление вентиляция и кондиционирование',
            'Воздухоснабжение',
            'Холодоснабжение ',
            'Радиосвязь, радиовещание и телевидение',
            'Системы пожаротушения',
            'Пожарная сигнализация ',
            'Охранная и охранно-пожарная сигнализация',
            'Газоснабжение (внутренние устройства)',
            'Наружные газопроводы',
            'Благоустройство',
          ]}
        />
        <FormSelect
          name="signer_id"
          label="Представитель лица, осуществляющего строительство, уполномоченный на внесение сведений в список"
          items={signerItems}
        />
      </Box>
    </FormProvider>
  )
}

export default Item1Form
