import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { object, string, TypeOf } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { Box, Grid } from '@mui/material'
import FormInput from '../../form/FormInput'
import FormInputDate from '../../form/FormInputDate'
import FormSelect from '../../form/FormSelect'
import FormTextarea from '../../form/FormTextarea'
import FormInputAddress from '../../form/FormInputAddress'

const typeItems = [
  { label: 'строительство', value: 'building' },
  { label: 'реконструкция', value: 'reconstruction' },
  { label: 'капитальный ремонт', value: 'repair' },
]

const typesForDate = {
  building: ' строительства',
  reconstruction: ' реконструкции',
  repair: ' капитального ремонта',
}

type FormProps = {
  onSubmit(values: any, methods: any): void
  id: string
  values?: any
}

const BuildingForm = ({ onSubmit, values, id }: FormProps) => {
  const schema = {
    name: string().min(1, 'Необходимо заполнить'),
    type: string().min(1, 'Необходимо заполнить'),
    specifications: string().min(1),
    address: string().min(1),
    date_begin: string().min(1, 'Необходимо заполнить дату'),
    date_end: string()
      .nullish()
      .refine((val) => val !== '')
      .catch(null),
  }

  const registerSchema = object(schema)

  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: values || {
      type: 'building',
    },
  })

  const {
    handleSubmit,
    formState: { isSubmitSuccessful },
    setValue,
    watch,
  } = methods

  const typeForDate = watch('type') as keyof typeof typesForDate

  const onSubmitHandler: SubmitHandler<TypeOf<typeof registerSchema>> = (values) => {
    onSubmit(values, methods)
  }

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        id={id}
        onSubmit={handleSubmit(onSubmitHandler)}
        noValidate
        autoComplete="off"
      >
        <Box sx={{ pt: 1 }}>
          <FormInput name="name" label="Наименование" hint="Официальное наименование" />
          <FormSelect name="type" label="Тип" items={typeItems} />
          <FormInputAddress name="address" label="Адрес объекта" />
          <FormTextarea
            rows={3}
            name="specifications"
            label="Краткие проектные характеристики объекта"
          />
          <Grid container sx={{ flexGrow: 1 }} spacing={2}>
            <Grid item xs={12} md={6}>
              <FormInputDate name="date_begin" label={'Дата начала' + typesForDate[typeForDate]} />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInputDate name="date_end" label={'Дата окончания' + typesForDate[typeForDate]} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </FormProvider>
  )
}

export { BuildingForm }
