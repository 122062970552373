import { TBuilding } from '../../../../types/building'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { Button } from '@mui/material'
import { Add } from '@mui/icons-material'
import { FormDialog } from '../../../common/FormDialog'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import Item1Form from './Item1Form'
import { useAddDocumentMutation } from '../../../../store/document'
import { documentType } from '../../../../types/document'
import { FormFullscreenDialog } from '../../../common/FormFullscreenDialog'
import { AddButton } from '../../../common/AddButton'

const AddItem1Dialog = ({
  building,
  onAdd,
  nextNumber,
}: {
  building: TBuilding
  onAdd: () => void
  nextNumber: number
}) => {
  const id = 'add-item-1-dialog'
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { journalId } = useParams()

  const [addDocument] = useAddDocumentMutation()

  const handleSubmit = (values: any, methods: any) => {
    const body = {
      type: documentType.GWJ1Item,
      parent_id: journalId,
      number: values.number,
      employees: [
        { id: values.employee_id, type: 'itp' },
        { id: values.signer_id, type: 'signer' },
      ],
      data: values.data,
    }

    setIsLoading(true)
    addDocument({ buildingId: building.id, body })
      .unwrap()
      .then(() => {
        methods.reset()
        setOpen(false)
        setIsLoading(false)
        toast.success('Документ добавлен')
        onAdd()
      })
      .catch(() => {
        toast.error('Произошла ошибка')
        setIsLoading(false)
      })
  }

  return (
    <>
      <AddButton onClick={() => setOpen(true)}>
        Добавить запись
      </AddButton>
      <FormDialog
        formId={id}
        open={open}
        onClose={() => setOpen(false)}
        title={'Добавить запись'}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <Item1Form
          buildingId={building.id}
          id={id}
          onSubmit={handleSubmit}
          nextNumber={nextNumber}
        />
      </FormDialog>
    </>
  )
}

export default AddItem1Dialog
