import { BuildingCompanies } from '../../../components/building/BuildingCompanies'
import React from 'react'
import { useOutletContext } from 'react-router-dom'
import { IOutletBuilding } from '../../../types/outlet-context'

const Companies = () => {
  const { building } = useOutletContext<IOutletBuilding>()

  return <BuildingCompanies building={building} />
}
export { Companies }
