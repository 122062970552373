import { Add } from '@mui/icons-material'
import { FormDialog } from '../../../common/FormDialog'
import * as React from 'react'
import { useState } from 'react'
import { TBuilding } from '../../../../types/building'
import { parseError } from '../../../../api'
import { toast } from 'react-toastify'
import { AddEmployeeForm } from './AddEmployeeForm'
import { IDocument } from '../../../../types/document'
import { useAddEmployeeMutation } from '../../../../store/document'
import { LightButton } from '../../../common/LightButton'

interface IProps {
  building: TBuilding
  item: IDocument
  onAdd: () => void
}

export const AddEmployeeDialog = ({ building, item, onAdd }: IProps) => {
  const id = 'add-part2-employee-dialog'
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [addEmployee] = useAddEmployeeMutation()

  const handleSubmit = (values: any, methods: any) => {
    setIsLoading(true)
    addEmployee({
      buildingId: building.id,
      body: {
        document_id: item.id,
        employee_id: values.employee_id,
        type: 'employee',
      },
    })
      .unwrap()
      .then((res) => {
        methods.reset()
        setIsLoading(false)
        setOpen(false)
        onAdd()
      })
      .catch(async (e) => {
        const error = await parseError(e)
        toast.error(error)
        setIsLoading(false)
      })
  }

  return (
    <>
      <LightButton startIcon={<Add />} onClick={() => setOpen(true)}>
        Добавить
      </LightButton>
      <FormDialog
        formId={id}
        open={open}
        onClose={() => setOpen(false)}
        title={'Добавить представителя'}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <AddEmployeeForm id={id} onSubmit={handleSubmit} building={building} />
      </FormDialog>
    </>
  )
}
