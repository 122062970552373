import {
  Avatar, Backdrop,
  Box,
  Button,
  Card,
  CardHeader, CircularProgress,
  FormHelperText,
  IconButton,
  Stack,
  Typography
} from '@mui/material'
import { green } from '@mui/material/colors'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { formatBytes } from '../../util/formatBytes'
import * as React from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { TExternalDocument } from '../../types/external-document'
import { useGetUserQuery } from '../../store/user'
import { custom, object, TypeOf } from 'zod'
import { ChangeEvent, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { useAttachFileMutation, useDetachFileMutation } from '../../store/externalDocuments'
import { toast } from 'react-toastify'
import { download } from '../../util/download'
import CloseIcon from '@mui/icons-material/Close'
import { MuiFileInput } from 'mui-file-input'
import { toastError } from '../../util/toastError'
import { LoadingButton } from '@mui/lab'
import { LightLoadingButton } from '../common/LightButton'
import { useConfirm } from '../../util/useConfirm'

const fileUploadSchema = object({
  file: custom<File>(),
})

type IUploadFile = TypeOf<typeof fileUploadSchema>

type Props = {
  document: TExternalDocument
}

const ExtDocFile = ({ document }: Props) => {
  const { data: user } = useGetUserQuery()

  const isOwner = user && user.id === document.owner_id
  const [openBackdrop, setOpenBackdrop] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState<File | null>(null)
  const confirm = useConfirm()

  const methods = useForm<IUploadFile>({
    resolver: zodResolver(fileUploadSchema),
  })

  const [attachFile] = useAttachFileMutation()
  const [detachFile] = useDetachFileMutation()

  const onSubmitHandler: SubmitHandler<IUploadFile> = (values) => {
    if (!file) return
    const formData = new FormData()
    formData.append('file', file)
    formData.append('document_id', document.id)

    setIsLoading(true)

    attachFile({ buildingId: document.building_id, body: formData })
      .unwrap()
      .finally(() => {
        setIsLoading(false)
        setFile(null)
        methods.reset()
      })
  }

  const handleChange = (newFile: File | null) => {
    setFile(newFile)
  }

  const handleDetachFile = (e: any) => {
    e.stopPropagation()

    confirm({
      content: 'Открепить и удалить файл?'
    }).then(() => {
      detachFile({
        buildingId: document.building_id,
        body: { document_id: document.id },
      })
        .unwrap()
        .then(() => {
          toast.success('Файл откреплен')
        })
        .catch(toastError)
    })
  }

  const handleDownload = (file: any) => {
    const url = `/api/v1/buildings/${document.building_id}/files/${file.id}`
    setOpenBackdrop(true)
    download(url, file.name, file.media_type)
      .finally(() => setOpenBackdrop(false))
  }

  return (
    <>
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {document.file && (
        <>
          <Typography variant={'caption'}>Прикрепленный файл</Typography>
          <Card variant={'outlined'}>
            <CardHeader
              onClick={() => handleDownload(document.file)}
              sx={{ cursor: 'pointer' }}
              avatar={
                <Avatar sx={{ bgcolor: green[500] }}>
                  <AttachFileIcon />
                </Avatar>
              }
              action={
                isOwner && (
                  <IconButton onClick={handleDetachFile} color={'error'} title={'Открепить'}>
                    <CloseIcon />
                  </IconButton>
                )
              }
              title={document.file.name}
              subheader={formatBytes(document.file.size)}
            />
          </Card>
        </>
      )}
      {isOwner && (
        <FormProvider {...methods}>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={methods.handleSubmit(onSubmitHandler)}
          >
            <Stack direction={'row'} alignItems={'center'} sx={{ mt: 2 }} spacing={0.5}>
              <MuiFileInput
                value={file}
                onChange={handleChange}
                disabled={isLoading}
                size={'small'}
                InputProps={{
                  startAdornment: <AttachFileIcon />,
                }}
                label={document.file ? 'Заменить файл' : 'Прикрепить файл'}
                placeholder={'Выбрать файл...'}
                clearIconButtonProps={{
                  title: 'Remove',
                  children: <CloseIcon fontSize="small" />,
                }}
              />
              {file && <LightLoadingButton type={'submit'} loading={isLoading}>Отправить</LightLoadingButton>}
            </Stack>
            <FormHelperText error={!!methods.formState.errors.file}>
              {methods.formState.errors.file?.message}
            </FormHelperText>
          </Box>
        </FormProvider>
      )}
    </>
  )
}

export default ExtDocFile
