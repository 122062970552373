import { TBuilding } from '../../types/building'
import {
  Alert,
  Box, Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import React, { lazy, useEffect, useState } from 'react'
import { LightIconButton, LightLoadingButton } from '../common/LightButton'
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt'
import { useAddBuildingLocationMutation, useGetOwmQuery } from '../../store/buildings'
import { toastError } from '../../util/toastError'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { LoadingButton } from '@mui/lab'

const MapView = lazy(() => import('../../components/map/MapView'))


type Props = {
  building: TBuilding
  isManage: boolean
}
export const BuildingLocation = ({building, isManage}: Props) => {

  const [editMode, setEditMode] = useState(false)
  const [location, setLocation] = useState<[number, number]>([0, 0])
  const [editLocation, setEditLocation] = useState<[number, number]>([0, 0])
  const [save] = useAddBuildingLocationMutation()
  const {data: owm, refetch: refetchOwm} = useGetOwmQuery({
    longitude: location[0] || 0,
    latitude: location[1] || 0,
  })
  const [isLoading, setIsLoading] = useState(false)

  const isCoordsInit = location[0] > 0 && location[1] > 0

  useEffect(() => {
    if (building.location && building.location.latitude && building.location.longitude) {
      setLocation([building.location.longitude, building.location.latitude])
    } else {
      navigator.geolocation.getCurrentPosition((position) => {
        setLocation([position.coords.longitude, position.coords.latitude])
      })
    }
  }, [])

  const movePointHandler = (coords: any) => {
    setEditLocation(coords)
  }

  const handleSave = () => {
    setIsLoading(true)
    save({
      buildingId: building.id,
      body: {
        longitude: editLocation[0],
        latitude: editLocation[1]
      }
    }).unwrap()
      .then(() => {
        setLocation(editLocation)
      })
      .catch(toastError)
      .finally(() => {
        setIsLoading(false)
        setEditMode(false)
      })
  }

  return (
    <Card>
      <CardHeader
        title={'Местоположение'}
        titleTypographyProps={{variant: 'body1', sx: {fontWeight: 'bold'}}}
        subheader={isCoordsInit && 'погодные условия на объекте'}
        action={
          isManage && !editMode &&
          <LightIconButton onClick={() => setEditMode(true)} title={'Изменить'} color={'info'}>
            <EditLocationAltIcon />
          </LightIconButton>
        }
      />
      <CardContent sx={{pt: 0, pb: 0}}>
        <Box sx={{borderRadius: '6px', overflow: 'hidden'}}>
          {!isCoordsInit && !editMode &&
            <Box sx={{
              width: "100%",
              height: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "background.default",
              color: "action.disabled"
            }}>
              <LocationOnIcon sx={{transform: "scale(7)", opacity: 0.4}} />
            </Box>
          }
          {(isCoordsInit || editMode) && <MapView center={location} editable={editMode} zoom={isCoordsInit ? 12 : 1} onPointMove={movePointHandler} />}
        </Box>
      </CardContent>
      {!editMode && <CardContent>
        {isCoordsInit && <Typography variant={'caption'}>{location[0]}, {location[1]}</Typography>}
      </CardContent>}
      {editMode && <CardContent>
        <Alert severity={'info'}>Переместите местоположение в нужное место</Alert>
      </CardContent>}
      {!editMode && owm && owm.weather &&
        <CardContent sx={{pt:0, pb:0}}>
          <Stack direction={'row'} alignItems={'center'}>
            <Box sx={{fontSize: "36px"}}>{owm.main.temp > 0 && '+'}{Math.round(owm.main.temp)}°</Box>
            {owm.weather[0].icon && <Box><img src={`/wi/${owm.weather[0].icon}.svg`} width={100} /></Box>}
            <Box>
              <Typography variant={'body1'}>{owm.weather[0].description}</Typography>
              <Typography variant={'body2'} color={'text.disabled'}>ветер: {owm.wind.speed} м/с {owm.wind.description}</Typography>
            </Box>
          </Stack>
          <Stack spacing={3} direction={'row'}>
            <Typography variant={'caption'}>влажность: {owm.main.humidity}%</Typography>
            <Typography variant={'caption'}>а/д: {Math.round(owm.main.pressure * 0.75)} мм/р.с.</Typography>
          </Stack>

        </CardContent>
      }
      {isManage && editMode &&
        <CardContent>
          <Stack direction={'row'} spacing={1}>
            <LoadingButton onClick={handleSave} loading={isLoading} variant={'contained'} color={'info'}>Сохранить</LoadingButton>
            <Button variant={'outlined'} onClick={() => setEditMode(false)}>Отменить</Button>
          </Stack>
        </CardContent>
      }
    </Card>
  )
}
