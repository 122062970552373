/* eslint-disable import/first */
require('./lib/cadesplugin_api')
import { CertificatesApi } from './certificatesApi'
window.cadesplugin.catch(e => console.log(e))

const cadespluginOnload = () =>
  (async function cadespluginOnload() {
    try {
      await window.cadesplugin.catch(e => console.log(e))

      const {
        getCertsList,
        getCert,
        currentCadesCert,
        signBase64,
        signHash,
        signXml,
        about,
        xmlSignatureAlgorithm,
        b64Cert,
      } = CertificatesApi

      return {
        getCertsList,
        getCert,
        currentCadesCert,
        signBase64,
        signHash,
        signXml,
        about,
        xmlSignatureAlgorithm,
        b64Cert,
      }
    } catch (error) {
      throw new Error(error)
    }
  })()

export { cadespluginOnload }
