import React from 'react'
import { useOutletContext } from 'react-router-dom'
import { ImcItemsList } from '../../../../components/journals/imc/ImcItemsList'
import { IOutletBuilding } from '../../../../types/outlet-context'

export const ImcList = () => {
  const { building } = useOutletContext<IOutletBuilding>()
  return (
    <>
      <ImcItemsList building={building} />
    </>
  )
}
