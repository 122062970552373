import { TExternalDocument } from '../../types/external-document'
import { Box, Typography } from '@mui/material'
import { formatDate } from '../../util/date'
import * as React from 'react'
import { DataRow } from '../common/DataRow'

const ExtDocView = ({ document }: { document: TExternalDocument }) => {
  return (
    <>
      <DataRow label={'Наименование:'} text={document.name} />
      {document.number && (
        <DataRow label={'Номер документа:'} text={document.number} />
      )}
      {document.date && (
        <DataRow label={'Дата документа:'} text={formatDate(document.date)} />
      )}
      {document.date_expire && (
        <DataRow label={'Срок действия:'} text={formatDate(document.date_expire)} />
      )}
    </>
  )
}
export default ExtDocView
