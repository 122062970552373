import { useOutletContext } from 'react-router-dom'
import { IOutletBuilding } from '../../../../types/outlet-context'
import React from 'react'
import { XmlList } from '../../../../components/journals/imc/XmlList'

export const ImcXml = () => {
  const { building } = useOutletContext<IOutletBuilding>()
  return (
    <>
      <XmlList building={building} />
    </>
  )
}
