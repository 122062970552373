import { object, string, TypeOf } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { Box, Divider } from '@mui/material'
import FormInput from '../../../form/FormInput'
import FormInputDate from '../../../form/FormInputDate'
import { FormInputExtDocument, FormTextarea } from '../../../form'
import { useGetEmployeesQuery } from '../../../../store/employees'
import { useGetCompaniesQuery } from '../../../../store/companies'
import { employeesList } from '../../../../util/employees'
import FormSelect from '../../../form/FormSelect'
import { IDocument } from '../../../../types/document'
import { positiveIntegerRequire } from '../../../../util/zodTypes'

const schema = {
  number: positiveIntegerRequire(),
  data: object({
    controlEventRegistryNumber: string().min(1, 'Необходимо заполнить'),
    defectsFixingPlanDateInfo: string(),
    controlEventResultsInfo: string().min(1, 'Необходимо заполнить'),
  }),
  actKnm: string().min(1, 'Необходимо заполнить'),
  signerId: string().min(1, 'Необходимо заполнить'),
}
const registerSchema = object(schema)

interface Props {
  buildingId: string
  defect?: IDocument
  onSubmit: (values: TypeOf<typeof registerSchema>, methods: any) => void
  id: string
  nextNumber?: string
}

export const Part7AddForm = ({ buildingId, defect, onSubmit, id, nextNumber }: Props) => {
  const { data: employees } = useGetEmployeesQuery(buildingId)
  const { data: companies } = useGetCompaniesQuery(buildingId)

  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: defect
      ? {
          number: defect.number || nextNumber || undefined,
          signerId: defect.employees.signer[0].employee_id || undefined,
          actKnm: defect.external_documents.actKnm[0].external_document_id,
          data: defect.data,
        }
      : { number: nextNumber },
  })

  const { handleSubmit } = methods

  const submitHandler = (values: any) => {
    onSubmit(values, methods)
  }

  const signerItems =
    companies && employees
      ? employeesList(companies, employees, ['gsn'], ['auth_supervisory_control'])
      : []

  return (
    <FormProvider {...methods}>
      <Box
        id={id}
        component="form"
        onSubmit={handleSubmit(submitHandler)}
        noValidate
        autoComplete="off"
      >
        <FormInput name="number" label="Порядковый номер записи" />
        <FormInput name="data.controlEventRegistryNumber" label="Учетный номер проверки" />
        <FormInputDate
          name="data.defectsFixingPlanDateInfo"
          label="Дата, к наступлению которой выявленные нарушения должны быть устранены"
        />
        <FormTextarea name="data.controlEventResultsInfo" label="Данные о результатах КНМ" />
        <FormInputExtDocument name={'actKnm'} label={'Акт КНМ'} docType={'act'} />
        <FormSelect
          name="signerId"
          label="Должностное лицо государственного строительного надзора"
          items={signerItems}
        />
      </Box>
    </FormProvider>
  )
}
