import { useCallback, useState } from 'react'

const useSkidPagination = (defaultLimit = 20) => {
  const [page, setPage] = useState(1)
  const [offset, setOffset] = useState(0)
  const [pages, setPages] = useState(10)
  const [currentTotal, setCurrentTotal] = useState(0)
  const [currentLimit, setCurrentLimit] = useState(defaultLimit)

  const setTotal = useCallback(
    (total: number) => {
      if (currentTotal !== total) {
        setCurrentTotal(total)
        setPages(Math.ceil(total / currentLimit))
      }
    },
    [currentTotal, currentLimit]
  )

  const setLimit = useCallback(
    (limit: number) => {
      if (currentLimit !== limit) {
        setCurrentLimit(limit)
        setPages(Math.ceil(currentTotal / limit))
      }
    },
    [currentTotal, currentLimit]
  )

  const onPageChange = (e: any, p: number) => {
    setPage(p)
    setOffset((p - 1) * currentLimit)
  }

  const reset = () => {
    setPage(1)
    setOffset(0)
  }

  return { pages, page, offset, limit: currentLimit, onPageChange, setTotal, setLimit, reset }
}

export default useSkidPagination
