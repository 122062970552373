import { Alert, AlertTitle, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import { IProfile } from '../../store/profile/types'
import { useState } from 'react'

export const CertList = ({
  profile,
  onChange,
}: {
  profile: IProfile
  onChange: (value: string) => void
}) => {
  const [value, setValue] = useState('')

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value as string)
    onChange(event.target.value)
  }

  return (
    <Select onChange={handleChange} label={'Сертификат'} value={value}>
      {profile.certificates.length > 0 &&
        profile.certificates.map((cert) => (
          <MenuItem key={cert.id} value={cert.thumbprint}>
            <Typography sx={{ color: 'text.primary' }}>{cert.name}</Typography>
            <Typography sx={{ color: 'text.disabled' }}>{cert.issuer_name}</Typography>
          </MenuItem>
        ))}
    </Select>
  )
}
