import { IDocument } from '../../../types/document'
import { useGetExternalDocumentsQuery } from '../../../store/externalDocuments'
import { useRemoveExtDocMutation } from '../../../store/document'
import ParseError from '../../../api/parseError'
import { toast } from 'react-toastify'
import { useMemo } from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material'
import AddItem3ExtDocDialog from '../../journals/gwj/part3/AddItem3ExtDocDialog'
import { StyledTableRow } from '../../journals/gwj/part3/StyledTableRow'
import ExtDocLine from '../../ExternalDocuments/ExtDocLine'
import { formatDate } from '../../../util/date'
import { Delete } from '@mui/icons-material'
import * as React from 'react'
import AddActOSRExtDocDialog from './AddActOSRExtDocDialog'

type Props = {
  document: IDocument
  onChange: () => void
  type: 'isp' | 'result'
  isManage: boolean
}
const ActOSRExtDocs = ({ document, onChange, type, isManage }: Props) => {
  const { data: extDocs } = useGetExternalDocumentsQuery({ buildingId: document.building_id })

  const [removeExtDoc] = useRemoveExtDocMutation()

  const removeExtDocHandler = (docId: string) => {
    if (window.confirm('Удалить документ из записи?')) {
      removeExtDoc({
        buildingId: document.building_id,
        body: {
          document_id: document.id,
          external_document_id: docId,
        },
      })
        .unwrap()
        .then((res) => {
          onChange()
        })
        .catch(async (e) => {
          const error = await ParseError(e)
          toast.error(error)
        })
    }
  }

  const dDocs = useMemo(() => document.external_documents[type] || [], [type, document])

  return (
    <Card variant={'elevation'} elevation={0} sx={{ mb: 2 }}>
      <CardHeader
        titleTypographyProps={{ variant: 'body1', sx: { fontWeight: 'medium' } }}
        title={
          type === 'isp'
            ? 'Список исполнительных схем и чертежей'
            : 'Результаты экспертиз, обследований, лабораторных и иных испытаний'
        }
      />
      <CardContent sx={{ p: 0 }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell></TableCell>
              {isManage && (
                <TableCell align="right">
                  <AddActOSRExtDocDialog
                    type={type}
                    document={document}
                    onAdd={onChange}
                  />
                </TableCell>
              )}
            </TableRow>
          </TableBody>
          <TableBody>
            {dDocs.map((dDoc) => {
              if (!extDocs) return ''
              const doc = extDocs.items.find((doc) => doc.id === dDoc.external_document_id)
              if (!doc) return ''
              return (
                <StyledTableRow key={dDoc.id}>
                  <TableCell sx={{ whiteSpace: 'pre-wrap', width: '100%' }}>
                    <ExtDocLine document={doc} showDate={false} />
                    <Typography variant="subtitle2" color="text.disabled">
                      {formatDate(doc.date)}
                    </Typography>
                  </TableCell>
                  {isManage && (
                    <TableCell align="right">
                      <IconButton
                        color="error"
                        title="Удалить"
                        onClick={() => removeExtDocHandler(dDoc.id)}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  )}
                </StyledTableRow>
              )
            })}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}
export { ActOSRExtDocs }
