import * as React from 'react'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import { Alert, FormControl, FormHelperText } from '@mui/material'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import { useState } from 'react'
import api, { parseError, parseErrors } from '../../api'
import { Link as RouterLink } from 'react-router-dom'
import System from '../../layout/system'

export function Request() {
  const [formData, setFormData] = useState({
    email: '',
  })

  const [buttonActive, setButtonActive] = useState(true)
  const [errors, setErrors] = useState<Record<string, string>>({})
  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState<string | null>(null)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target
    setFormData({
      ...formData,
      [input.name]: input.type === 'checkbox' ? input.checked : input.value,
    })
  }

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault()

    setButtonActive(false)
    setErrors({})
    setError(null)
    setSuccess(null)

    api
      .post('/v1/auth/reset-password/request', {
        email: formData.email,
      })
      .then(() => {
        setSuccess('Проверьте почту. Для дальнейших действий следуйте инструкциям в письме.')
        setButtonActive(true)
      })
      .catch(async (error) => {
        setErrors(await parseErrors(error))
        setError(await parseError(error))
        setButtonActive(true)
      })
  }

  return (
    <System>
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5" sx={{ mb: 1 }}>
        Восстановление пароля
      </Typography>
      <>
        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}
        {!success && (
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3, width: '100%', maxWidth: '350px' }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Адрес электронной почты"
                  name="email"
                  type="email"
                  autoComplete="email"
                  onChange={handleChange}
                  value={formData.email}
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={!buttonActive}
              data-testid="join-button"
            >
              Подтвердить
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to="/" component={RouterLink} variant="body2">
                  На главную
                </Link>
              </Grid>
            </Grid>
          </Box>
        )}
      </>
    </System>
  )
}
