import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { addAuthorization } from '../../util/http/addAuthorization'
import { TEmployee } from '../../types/building'

export const employeesApi = createApi({
  reducerPath: 'employees',
  tagTypes: ['Employees'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1/',
    prepareHeaders: addAuthorization,
  }),
  endpoints: (builder) => ({
    getEmployees: builder.query<TEmployee[], string>({
      query: (id) => ({
        url: `/buildings/${id}/employees`,
        method: 'GET',
      }),
      keepUnusedDataFor: 10,
      providesTags: (result, error, arg) => [{ type: 'Employees', id: arg }],
    }),

    attachEmployee: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/attach-employee`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Employees', id: arg.buildingId }],
    }),
    deactivateEmployee: builder.mutation({
      query: ({ buildingId, employeeId }) => ({
        url: `/buildings/${buildingId}/deactivate-employee`,
        method: 'POST',
        body: { employee_id: employeeId },
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Employees', id: arg.buildingId }],
    }),
    activateEmployee: builder.mutation({
      query: ({ buildingId, employeeId }) => ({
        url: `/buildings/${buildingId}/activate-employee`,
        method: 'POST',
        body: { employee_id: employeeId },
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Employees', id: arg.buildingId }],
    }),
    removeEmployee: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/remove-employee`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Employees', id: arg.buildingId }],
    }),
    editEmployee: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/edit-employee`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Employees', id: arg.buildingId }],
    }),
    addAdministrativeDocument: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/employees/add-administrative-document`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Employees', id: arg.buildingId }],
    }),
    removeAdministrativeDocument: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/employees/remove-administrative-document`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Employees', id: arg.buildingId }],
    }),
  }),
})

export const {
  useGetEmployeesQuery,
  useAttachEmployeeMutation,
  useDeactivateEmployeeMutation,
  useActivateEmployeeMutation,
  useAddAdministrativeDocumentMutation,
  useRemoveAdministrativeDocumentMutation,
  useRemoveEmployeeMutation,
  useEditEmployeeMutation,
} = employeesApi
