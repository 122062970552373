import { useMemo } from 'react'
import { companyRoles, TCompanyFilter, TCompanyRole } from '../types/building'
import { useGetCompaniesQuery } from '../store/companies'

export const useCompaniesRolesList = (buildingId: string, filter?: TCompanyFilter) => {
  const { data: companies, isLoading } = useGetCompaniesQuery(buildingId)

  return useMemo(() => {
    if (isLoading || companies === undefined) return []
    return (Object.keys(companyRoles) as TCompanyRole[])
      .filter(r => filter ? filter.role.includes(r) : true).reduce((u: any, role) => {
      if (
        [
          'developer',
          'customer',
          'building_contractor',
          'project_documentation_contractor',
          'operating_person',
          'regional_operator',
        ].includes(role) &&
        companies.find((company) => company.role === role)
      ) {
        return u
      }
      if (
        ['developer', 'operating_person', 'regional_operator'].includes(role) &&
        companies.find((c) =>
          ['developer', 'operating_person', 'regional_operator'].includes(c.role)
        )
      ) {
        return u
      }
      return [
        ...u,
        {
          label: companyRoles[role as TCompanyRole],
          value: role,
        },
      ]
    }, [])
  }, [companies, isLoading, buildingId])
}
