import { Link, Outlet, useOutletContext, useMatches } from 'react-router-dom'
import React from 'react'
import { Box, Paper, Tab, Tabs } from '@mui/material'
import { IOutletBuilding } from '../../../../types/outlet-context'
import { BaseContainer } from '../../../../components/common/Container'

export const Imc = () => {
  const { building } = useOutletContext<IOutletBuilding>()

  const routeMatch = useMatches()
  const currentTab = (routeMatch[routeMatch.length - 1].handle as any).key

  return (
    <>
      <Paper>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs variant="scrollable" scrollButtons allowScrollButtonsMobile value={currentTab}>
            <Tab label="Титульный лист" value="title" to="" component={Link} />
            <Tab label="Записи" value="list" to="list" component={Link} />
            <Tab label="XML" value="xml" to="xml" component={Link} />
          </Tabs>
        </Box>
      </Paper>
      <BaseContainer>
        <Outlet context={{ building }} />
      </BaseContainer>
    </>
  )
}
