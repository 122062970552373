import { auth } from '../oauth/Auth'
import api from './api'

const privateApi = {
  get: (url: string, headers = {}) => {
    return auth.getToken().then((token) => {
      return api.get(url, { Authorization: token, ...headers })
    })
  },
  post: (url: string, data: object | null = null, headers = {}) => {
    return auth.getToken().then((token) => {
      return api.post(url, data, { Authorization: token, ...headers })
    })
  },
}

export { privateApi }
