import { companyRoles, employeeRoles, TCompany, TEmployee } from '../types/building'
import { TProfile } from '../types/Profile'

const employeeFilter = (
  employee: TEmployee,
  company: TCompany,
  employeeRole: null | keyof typeof employeeRoles | (keyof typeof employeeRoles)[],
  exclude: string[] | null = null
) => {
  if (exclude && exclude.includes(employee.id)) return false
  if (employeeRole && typeof employeeRole === 'string') {
    employeeRole = [employeeRole]
  }
  return (
    employee.company_id === company.id &&
    (employeeRole ? employeeRole.includes(employee.role) : true)
  )
}

const companyFilter = (
  company: TCompany,
  companyRole: null | keyof typeof companyRoles | (keyof typeof companyRoles)[]
) => {
  if (companyRole && typeof companyRole === 'string') {
    companyRole = [companyRole]
  }
  return companyRole ? companyRole.includes(company.role) : true
}

const companySort = (companies: TCompany[]) => {
  const data = []

  for (const role in companyRoles) {
    for (const company of companies) {
      if (company.role === role) {
        data.push(company)
      }
    }
  }
  return data
}

export const employeesList = (
  companies: TCompany[],
  employees: TEmployee[],
  companyRole: null | keyof typeof companyRoles | (keyof typeof companyRoles)[] = null,
  employeeRole: null | keyof typeof employeeRoles | (keyof typeof employeeRoles)[] = null,
  exclude: string[] | null = null
) => {
  if (!companies || !employees) return []

  let data: any[] = []
  const companiesList = companySort(
    companies.filter((company) => companyFilter(company, companyRole))
  )
  companiesList.forEach((company) => {
    const items = employees
      .filter((employee) => employeeFilter(employee, company, employeeRole, exclude))
      .map((employee) => {
        return {
          label: employee.profile.name.full_name,
          value: employee.id,
          description: employeeRoles[employee.role],
        }
      })
    if (items.length > 0) {
      data.push({
        label: company.name,
        isGroup: true,
        description: companyRoles[company.role],
      })
      data = [...data, ...items]
    }
  })

  return data
}
