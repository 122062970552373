import { Outlet, useOutletContext, useParams } from 'react-router-dom'
import { TEmployee } from '../../../../types/building'
import { useGetCompaniesQuery } from '../../../../store/companies'
import React, { useMemo } from 'react'
import { Box, LinearProgress } from '@mui/material'
import AlertError from '../../../../components/alert/AlertError'
import { useGetEmployeesQuery } from '../../../../store/employees'
import { IOutletCompany } from '../../../../types/outlet-context'

export const Employee = () => {
  const { building, company } = useOutletContext<IOutletCompany>()

  const { employeeId } = useParams()
  const { data: companies, isLoading } = useGetCompaniesQuery(building.id)
  const { data: employees, isLoading: isEmployeesLoading } = useGetEmployeesQuery(building.id)

  const employee = useMemo<TEmployee | undefined>((): TEmployee | undefined => {
    return employees && employees.find((e) => e.id === employeeId)
  }, [employeeId, employees])

  if (isLoading || isEmployeesLoading || companies === undefined || employees === undefined) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    )
  }

  if (!company) {
    return <AlertError>Компания не найдена</AlertError>
  }

  return <Outlet context={{ building, company, employee }} />
}
