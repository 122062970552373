import React from 'react'
import { FeaturesProvider } from '../featureToggle'
import { AuthProvider } from '../../oauth'
import AppRoutes from './AppRoutes'
import { ThemeProvider } from '@mui/material'
import { auth } from '../../oauth/Auth'
import 'dayjs/locale/ru'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import '@fancyapps/ui/dist/fancybox/fancybox.css'
import { darkTheme, lightTheme } from '../../theme/theme'
import { useGetProfileQuery } from '../../store/profile'

function App({ features }: { features: string[] }) {
  auth.data = {
    authorizeUrl: process.env.REACT_APP_AUTH_URL + '/authorize',
    tokenUrl: process.env.REACT_APP_AUTH_URL + '/token',
    clientId: 'frontend',
    scope: 'common',
    redirectPath: '/oauth',
  }

  const { data: profile } = useGetProfileQuery()

  const themeLs = (profile && profile.theme) || 'light'
  const theme = themeLs === 'dark' ? darkTheme : lightTheme

  return (
    <FeaturesProvider features={features}>
      <AuthProvider auth={auth}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'ru'}>
            <AppRoutes />
          </LocalizationProvider>
        </ThemeProvider>
      </AuthProvider>
    </FeaturesProvider>
  )
}

export default App
