import { EditDialog } from './index'
import { Button } from '@mui/material'
import * as React from 'react'
import { TExternalDocument } from '../../types/external-document'
import { toast } from 'react-toastify'
import { useRemoveExternalDocumentMutation } from '../../store/externalDocuments'
import ExtDocSignButton from './ExtDocSignButton'
import { useGetProfileQuery } from '../../store/profile'
import { useAuthorization } from '../../auth'
import { useGetUserQuery } from '../../store/user'
import { useMemo } from 'react'

type Props = {
  document: TExternalDocument
  onSave?: (id: string) => void
  onRemove?: () => void
  onSign?: () => void
}

const ExtDocActions = ({ document, onSave, onRemove, onSign }: Props) => {
  const [removeDocument] = useRemoveExternalDocumentMutation()
  const { data: profile } = useGetProfileQuery()
  const authManager = useAuthorization()
  const { data: user } = useGetUserQuery()

  const isManage = useMemo(() => {
    return authManager.vote('ExternalDocument', 'manage', user && document.owner_id === user.id)
  }, [document, user])

  const handleRemove = (document?: TExternalDocument) => {
    if (document && onRemove) {
      removeDocument({
        buildingId: document.building_id,
        body: { document_id: document.id },
      })
        .unwrap()
        .then(() => {
          toast.success('Документ удален')
          onRemove()
        })
        .catch((e) => {
          toast.error('Ошибка')
        })
    }
  }

  return (
    <>
      {profile && <ExtDocSignButton profile={profile} document={document} onSign={onSign} />}
      {onSave && isManage && <EditDialog document={document} onSave={onSave} />}
      {onRemove && isManage && (
        <Button variant={'outlined'} color={'error'} onClick={() => handleRemove(document)}>
          Удалить
        </Button>
      )}
    </>
  )
}

export default ExtDocActions
