import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { addAuthorization } from '../../util/http/addAuthorization'
import { TPrdDocument, TPrdGroup } from '../../types/prd'

export const prdApi = createApi({
  reducerPath: 'prd',
  tagTypes: ['Groups', 'Documents'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1/',
    prepareHeaders: addAuthorization,
    validateStatus: (response) => response.ok,
  }),
  endpoints: (builder) => ({
    getGroups: builder.query<TPrdGroup[], string>({
      query: (buildingId) => ({
        url: `/buildings/${buildingId}/prd`,
        method: 'GET',
      }),
      providesTags: ['Groups'],
    }),
    addGroup: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/prd/add-group`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Groups'],
    }),
    deleteGroup: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/prd/delete-group`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Groups'],
    }),
    getDocuments: builder.query<TPrdDocument[], string>({
      query: (buildingId) => ({
        url: `/buildings/${buildingId}/prd/documents`,
        method: 'GET',
      }),
      providesTags: ['Documents'],
    }),
    addDocument: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/prd/add-document`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Documents'],
    }),
    editDocument: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/prd/edit-document`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Documents'],
    }),
    deleteDocument: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/prd/delete-document`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Documents'],
    }),
    attachFile: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/prd/attach-file`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Documents'],
    }),
    detachFile: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/prd/detach-file`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Documents'],
    }),
    moveDocuments: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/prd/move`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Documents'],
    }),
    addChange: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/prd/add-change`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Documents'],
    }),
    deleteChange: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/prd/delete-change`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Documents'],
    }),
  }),
})

export const {
  useGetGroupsQuery,
  useAddGroupMutation,
  useDeleteGroupMutation,
  useGetDocumentsQuery,
  useAddDocumentMutation,
  useEditDocumentMutation,
  useDeleteDocumentMutation,
  useAttachFileMutation,
  useDetachFileMutation,
  useMoveDocumentsMutation,
  useAddChangeMutation,
  useDeleteChangeMutation,
} = prdApi
