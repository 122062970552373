import {
  companyRoles,
  employeeRoles,
  TBuilding,
  TCompany,
  TEmployee,
} from '../../../../types/building'
import { documentType, IDocument } from '../../../../types/document'
import {
  Box,
  IconButton,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { ColoredAvatar } from '../../../common/ColoredAvatar'
import { SignDocumentButton } from '../../../document/SignDocumentButton'
import { Delete } from '@mui/icons-material'
import * as React from 'react'
import { useAuthorization } from '../../../../auth'
import ExtDocLine from '../../../ExternalDocuments/ExtDocLine'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { useRemoveDocumentMutation } from '../../../../store/document'

const representativeEmployee = (
  representative: IDocument,
  employees: TEmployee[]
): TEmployee | undefined =>
  employees.find((e) => e.id === representative.employees.employee[0].employee_id)

const companyRepresentatives = (
  company: TCompany,
  employees: TEmployee[],
  representatives: IDocument[]
) => representatives.filter((r) => representativeEmployee(r, employees)?.company_id === company.id)

type Props = {
  building: TBuilding
  company: TCompany
  employees: TEmployee[]
  representatives: IDocument[]
  onSign: () => void
  onReady: () => void
}
export const RepresentativesCompany = ({
  building,
  company,
  employees,
  representatives,
  onReady,
  onSign,
}: Props) => {
  const [removeRepresentative] = useRemoveDocumentMutation()

  const authManager = useAuthorization()
  const isManage = authManager.vote('Gwj', 'manage')

  const companyReps = companyRepresentatives(company, employees, representatives)

  if (companyReps.length === 0) return null

  const removeRepresentativeHandler = (id: string) => {
    removeRepresentative({
      buildingId: building.id,
      body: {
        document_id: id,
        type: documentType.Representative,
      },
    })
      .unwrap()
      .catch((e) => {
        console.log(e)
      })
  }

  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell sx={{ backgroundColor: 'action.hover' }} colSpan={2}>
            <Typography sx={{ fontWeight: 'bold' }}>{company && company.name}</Typography>
            <Typography variant="subtitle2" color="text.disabled">
              {companyRoles[company.role]}
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {companyReps.map((representative) => {
          const employee = representativeEmployee(representative, employees)
          return (
            employee && (
              <TableRow key={representative.id}>
                <TableCell>
                  <Stack direction="row">
                    <Box sx={{ pr: 1 }}>
                      <ColoredAvatar text={employee.profile.name.full_name} />
                    </Box>
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography sx={{ fontWeight: 500 }}>
                        {employee.profile.name.full_name}
                      </Typography>
                      <Typography variant="caption" sx={{ lineHeight: 1.5 }}>
                        {employee.position}
                      </Typography>
                      <Typography variant="subtitle2" color="text.disabled">
                        {employeeRoles[employee.role]}
                      </Typography>
                      <Typography variant="caption" sx={{ lineHeight: 1.5 }} component={'div'}>
                        {employee?.administrative_document?.document && (
                          <ExtDocLine document={employee.administrative_document.document} />
                        )}
                        {!employee?.administrative_document?.document && (
                          <Box display={'flex'} alignItems={'center'}>
                            <WarningAmberIcon color={'error'} /> отсутствует распорядительный
                            документ подтверждающий полномочия
                          </Box>
                        )}
                      </Typography>
                      <Box sx={{ mt: 1 }}>
                        <SignDocumentButton
                          document={representative}
                          onReady={onReady}
                          onSign={onSign}
                          size={'small'}
                        />
                      </Box>
                    </Box>
                  </Stack>
                </TableCell>
                {isManage && (
                  <TableCell align="right">
                    <IconButton
                      color="error"
                      title="Удалить"
                      onClick={() => removeRepresentativeHandler(representative.id)}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            )
          )
        })}
      </TableBody>
    </>
  )
}
