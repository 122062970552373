import { useState } from 'react'
import AlertError from '../../components/alert/AlertError'
import AlertSuccess from '../../components/alert/AlertSuccess'
import Avatar from '@mui/material/Avatar'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { Box, ThemeProvider } from '@mui/material'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import api, { parseError, parseErrors } from '../../api'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import System from '../../layout/system'

export function Reset(): JSX.Element {
  const [buttonActive, setButtonActive] = useState(true)
  const [errors, setErrors] = useState<Record<string, string>>({})
  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState<string | null>(null)

  const [formData, setFormData] = useState({
    password: '',
  })

  const query = new URLSearchParams(window.location.search)
  const token = query.get('token')
  if (!token) {
    setError('Empty token.')
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.currentTarget
    setFormData({
      ...formData,
      [input.name]: input.type === 'checkbox' ? input.checked : input.value,
    })
  }

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault()

    setButtonActive(false)
    setErrors({})
    setError(null)
    setSuccess(null)

    api
      .post('/v1/auth/reset-password/reset', {
        password: formData.password,
        token,
      })
      .then(() => {
        setSuccess('Пароль успешно восстановлен.')
        setButtonActive(true)
      })
      .catch(async (error) => {
        setErrors(await parseErrors(error))
        setError(await parseError(error))
        setButtonActive(true)
      })
  }

  return (
    <System>
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5" sx={{ mb: 1 }}>
        Восстановление пароля
      </Typography>
      <AlertError>{error}</AlertError>
      <AlertSuccess>{success}</AlertSuccess>
      {!success ? (
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{ mt: 3, width: '100%', maxWidth: '350px' }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Придумайте пароль"
                type="password"
                id="password"
                autoComplete="new-password"
                onChange={handleChange}
                value={formData.password}
                error={!!errors.password}
                helperText={errors.password}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={!buttonActive}
            data-testid="join-button"
          >
            Отправить
          </Button>
        </Box>
      ) : null}
      <Box
        sx={{
          marginTop: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Link component={RouterLink} to="/" variant="body2">
              Войти
            </Link>
          </Grid>
        </Grid>
      </Box>
    </System>
  )
}
