import { useOutletContext } from 'react-router-dom'
import React from 'react'
import { Employee } from '../../../../components/company/Employee'
import { IOutletEmployee } from '../../../../types/outlet-context'

export const EmployeeMain = () => {
  const { building, company, employee } = useOutletContext<IOutletEmployee>()

  return <Employee building={building} company={company} employee={employee} />
}
