import { TPrdDocument, TPrdGroup, TPrdType } from '../types/prd'

export const prdGroupsList = (type: TPrdType, groups: TPrdGroup[]) => {
  const data: any = []
  groups
    .filter((g) => g.type === type && g.parent_id === null)
    .forEach((g) => {
      data.push({
        label: g.name,
        value: g.id,
      })
      groups
        .filter((sg) => sg.parent_id === g.id)
        .forEach((sg) => {
          data.push({
            label: `- ${sg.name}`,
            value: sg.id,
          })
        })
    })
  return data
}

export const prdDocumentsList = (groupId: string, documents: TPrdDocument[]) => {
  return documents
    .filter((d) => d.group_id === groupId)
    .map((d) => ({
      label: d.name,
      description: d.code,
      value: d.id,
    }))
}

export const prdDocumentLists = (count: number) => {
  return new Array(count).fill(0).map((v, i) => ({
    label: i + 1 + '',
    value: i + 1 + '',
  }))
}


export const prdGroupsDocumentsList = (
  groups: TPrdGroup[],
  docs: TPrdDocument[],
  type: TPrdType,
  include: string[] = [],
  exclude: string[] = []
) => {

  docs = docs.filter(d => d.type === type)
  docs = include.length > 0 ? docs.filter((d) => include.includes(d.id)) : docs
  docs = exclude.length > 0 ? docs.filter((d) => !include.includes(d.id)) : docs


  let data: any[] = []

  groups.forEach((group) => {
    const items = docs
      .filter((d) => d.group_id === group.id)
      .map((d) => {
        return { label: `${d.name} ${d.code}`, value: d.id }
      })
    if (items.length > 0) {
      data.push({ label: group.name, isGroup: true })
      data = [...data, ...items]
    }
  })
  return data
}
