import { Outlet, useParams } from 'react-router-dom'
import { useGetBuildingQuery } from '../../../store/buildings'
import AlertError from '../../../components/alert/AlertError'
import { Box, LinearProgress } from '@mui/material'
import React, { useMemo } from 'react'
import { useGetBuildingAuthQuery } from '../../../store/auth'
import { AuthManagerContext } from '../../../auth'
import { AuthManager } from '../../../auth/authManager'

export const Building = () => {
  const { buildingId } = useParams()

  const {
    data: building,
    isSuccess,
    isUninitialized,
    isFetching,
  } = useGetBuildingQuery(buildingId as string, {
    skip: !buildingId,
    refetchOnMountOrArgChange: true,
  })

  const { data: authContext } = useGetBuildingAuthQuery(buildingId as string, {
    skip: !buildingId,
    refetchOnMountOrArgChange: true,
  })

  const auth = useMemo(() => {
    return new AuthManager(authContext)
  }, [authContext])

  if (buildingId === undefined) {
    return <AlertError>Неизвестный ID</AlertError>
  }

  if (authContext === undefined) {
    return <AlertError>Error: auth</AlertError>
  }

  if (isFetching || isUninitialized) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    )
  }

  return (
    <>
      {isSuccess && (
        <AuthManagerContext.Provider value={auth}>
          <Outlet context={{ building }} />
        </AuthManagerContext.Provider>
      )}
    </>
  )
}
