import { object, string, TypeOf } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import FormSelect from '../../../form/FormSelect'
import React from 'react'
import { Box } from '@mui/material'
import { useGetCompaniesQuery } from '../../../../store/companies'
import { TBuilding } from '../../../../types/building'
import { employeesList } from '../../../../util/employees'
import { useGetEmployeesQuery } from '../../../../store/employees'

const schema = {
  employee_id: string().min(1, 'Необходимо заполнить'),
}
const registerSchema = object(schema)

interface Props {
  building: TBuilding
  values?: any
  onSubmit: (values: TypeOf<typeof registerSchema>, methods: any) => void
  id?: string
}

export const AddEmployeeForm = ({ building, values, onSubmit, id }: Props) => {
  const { data: employees } = useGetEmployeesQuery(building.id)
  const { data: companies } = useGetCompaniesQuery(building.id)

  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: values || {},
  })

  const {
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = methods

  const submitHandler = (values: any) => {
    onSubmit(values, methods)
  }

  const employeeItems =
    companies && employees
      ? employeesList(
          companies,
          employees,
          [
            'developer',
            'operating_person',
            'regional_operator',
            'customer',
            'building_contractor',
            'contractor',
          ],
          ['auth_representative', 'auth_representative_control']
        )
      : []

  return (
    <FormProvider {...methods}>
      <Box
        id={id}
        component="form"
        onSubmit={handleSubmit(submitHandler)}
        noValidate
        autoComplete="off"
      >
        {employeeItems.length > 0 && (
          <FormSelect name="employee_id" label="Сотрудник" items={employeeItems} />
        )}
      </Box>
    </FormProvider>
  )
}
