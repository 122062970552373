import { array, number, object, preprocess, string, TypeOf } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { Box } from '@mui/material'
import FormSelect from '../form/FormSelect'
import { TPrdDocument } from '../../types/prd'
import { prdDocumentLists } from '../../util/prd'

const schema = {
  lists: preprocess((v: any) => v.map((v: string) => parseInt(v)), array(number())),
}
const registerSchema = object(schema)

interface Props {
  document: TPrdDocument
  values?: any
  onSubmit: (values: TypeOf<typeof registerSchema>, methods: any) => void
  id?: string
}

const PrdDocumentSelectForm = ({ document, values, onSubmit, id }: Props) => {
  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: { lists: values ? values.map((l: any) => l + '') : [] },
  })

  const listsItems = prdDocumentLists(document.lists_count)

  const {
    handleSubmit,
    formState: { isSubmitSuccessful },
    watch,
    setValue,
  } = methods

  const hs = handleSubmit((values: any) => {
    onSubmit(values, methods)
  })

  const onFormSubmit = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    return hs(e)
  }

  return (
    <FormProvider {...methods}>
      <Box id={id} component="form" onSubmit={onFormSubmit} noValidate autoComplete="off">
        <FormSelect name="lists" label={'Листы'} items={listsItems} multiple />
      </Box>
    </FormProvider>
  )
}

export default PrdDocumentSelectForm
