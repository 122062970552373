import { TBuilding } from '../../types/building'
import { baseDocumentType, TBaseDocumentType } from '../../types/document'
import { useState } from 'react'
import { LightButton } from './LightButton'
import { FormDialog } from './FormDialog'
import * as React from 'react'
import { object, string, TypeOf } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box } from '@mui/material'
import FormSelect from '../form/FormSelect'
import { useCreateXmlDocumentMutation } from '../../store/xml'
import { toastError } from '../../util/toastError'
import { toast } from 'react-toastify'


const schema = {
  type: string().min(1, 'Необходимо заполнить'),
}
const registerSchema = object(schema)

interface IProps {
  building: TBuilding
  types: TBaseDocumentType[]
  documentId: string
  onCreate?: () => void
}

export const XmlDocumentDialog = ({ building, types, documentId, onCreate }: IProps) => {
  const id = 'xml-document-dialog'
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [createDocument] = useCreateXmlDocumentMutation()

  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema)
  })

  const {
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = methods

  const submitHandler = (values: TypeOf<typeof registerSchema>) => {
    setIsLoading(true)

    createDocument({
      buildingId: building.id,
      body: {
        type: values.type,
        document_id: documentId
      }
    }).unwrap()
      .then(() => {
        toast.success("Документ сформирован")
        setOpen(false)
        onCreate && onCreate()
      })
      .catch(toastError)
      .finally(() => setIsLoading(false))
  }

  const typesItems = types.map(t =>
    ({
      label: baseDocumentType[t].name,
      value: t,
      description: baseDocumentType[t].description
    }))

  return (
    <>
      <LightButton onClick={() => setOpen(true)}>
        Сформировать документ
      </LightButton>
      <FormDialog
        formId={id}
        open={open}
        onClose={() => setOpen(false)}
        title={'Сформировать документ'}
        button={'Сформировать'}
        isLoading={isLoading}
      >
        <FormProvider {...methods}>
          <Box
            id={id}
            component="form"
            onSubmit={handleSubmit(submitHandler)}
            noValidate
            autoComplete="off"
          >
              <FormSelect name="type" label="Тип документа" items={typesItems} />
          </Box>
        </FormProvider>
      </FormDialog>
    </>
  )
}
