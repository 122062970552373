import { useState } from 'react'
import { toast } from 'react-toastify'
import { Button } from '@mui/material'
import { Add } from '@mui/icons-material'
import { FormDialog } from '../../common/FormDialog'
import * as React from 'react'
import { parseError } from '../../../api'
import ItemExtDocForm from './ItemExtDocForm'
import { IDocument } from '../../../types/document'
import { useAddExtDocMutation } from '../../../store/document'

const AddItemExtDocDialog = ({
  item,
  type,
  onAdd,
}: {
  item: IDocument
  type: 'dpk'
  onAdd: () => void
}) => {
  const id = 'add-item-ext-doc-dialog'
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [addExtDoc] = useAddExtDocMutation()

  const handleSubmit = (values: any, methods: any) => {
    const body = {
      document_id: item.id,
      type,
      ...values,
    }

    setIsLoading(true)
    addExtDoc({ buildingId: item.building_id, body })
      .unwrap()
      .then(() => {
        methods.reset()
        setOpen(false)
        setIsLoading(false)
        onAdd()
      })
      .catch(async (e) => {
        const error = await parseError(e)
        toast.error('Ошибка: ' + error)
        setIsLoading(false)
      })
  }

  return (
    <>
      <Button startIcon={<Add />} onClick={() => setOpen(true)}>
        Добавить
      </Button>
      <FormDialog
        formId={id}
        open={open}
        onClose={() => setOpen(false)}
        title={'Добавить документ'}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <ItemExtDocForm type={type} item={item} id={id} onSubmit={handleSubmit} />
      </FormDialog>
    </>
  )
}

export default AddItemExtDocDialog
