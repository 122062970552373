import {
  FormHelperText,
  FormControl,
  InputProps,
  OutlinedInput,
  InputLabel,
  SxProps,
  Theme,
} from '@mui/material'
import { FC, ReactNode, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import LocationAddDialog from '../location/LocationAddDialog'
import * as React from 'react'
import { locationString } from '../../util/location'
import { TLocation } from '../../types/location'

type IFormInputProps = {
  name: string
  label: string
  hint?: ReactNode
  startAdornment?: ReactNode
  endAdornment?: ReactNode
  size?: 'small' | 'medium'
  sx?: SxProps<Theme>
} & InputProps

const FormInputLocation: FC<IFormInputProps> = ({
  name,
  label,
  hint,
  startAdornment,
  endAdornment,
  size,
  sx,
  ...otherProps
}) => {
  const [open, setOpen] = useState(false)
  const [formValue, setFormValue] = useState<TLocation>({} as TLocation)

  const {
    control,
    formState: { errors, defaultValues },
    setValue,
    getValues,
  } = useFormContext()

  useEffect(() => {
    setFormValue(getValues(name) || ({} as TLocation))
  }, [])

  const errorMessage: string = (errors[name] ? errors[name]?.message : '') as string

  const hintEl = hint ? <FormHelperText>{hint}</FormHelperText> : null

  const submitHandler = (values: any) => {
    setValue(name, values)
    setFormValue(values)
    setOpen(false)
  }

  return (
    <>
      <Controller
        control={control}
        defaultValue=""
        name={name}
        render={({ field }) => (
          <FormControl fullWidth margin={'dense'} size={size}>
            <InputLabel>{label}</InputLabel>
            <OutlinedInput
              error={!!errors[name]}
              label={label}
              readOnly
              value={locationString(formValue)}
              onClick={() => setOpen(true)}
            />
            {hintEl}
            <FormHelperText error={!!errors[name]}>{errorMessage}</FormHelperText>
          </FormControl>
        )}
      />
      <LocationAddDialog
        open={open}
        isLoading={false}
        onClose={() => setOpen(false)}
        onSubmit={submitHandler}
        values={formValue}
      />
    </>
  )
}

export default FormInputLocation
