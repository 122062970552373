import { FormDialog } from '../common/FormDialog'
import * as React from 'react'
import LocationForm from './LocationForm'
import { useId } from 'react'

type Props = {
  onSubmit: (values: any, methods: any) => void
  isLoading: boolean
  open: boolean
  onClose: () => void
  values?: any
}

const LocationAddDialog = ({ onSubmit, open, isLoading, onClose, values }: Props) => {
  const id = useId()

  return (
    <>
      <FormDialog
        formId={id}
        open={open}
        onClose={onClose}
        title={'Информация о местоположении'}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <LocationForm id={id} onSubmit={onSubmit} values={values} />
      </FormDialog>
    </>
  )
}

export default LocationAddDialog
