import { TEmployee } from '../../types/building'
import { IDocument } from '../../types/document'
import { useGetUserQuery } from '../../store/user'
import { Card, CardActions, CardContent, CardHeader, Skeleton, Typography } from '@mui/material'
import { useReadyMutation } from '../../store/document'
import { useGetEmployeesQuery } from '../../store/employees'
import Avatar from '@mui/material/Avatar'
import { useGetProfileQuery } from '../../store/profile'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import { green, grey } from '@mui/material/colors'
import { useMemo, useState } from 'react'
import { useAuthorization } from '../../auth'
import { ReadySign } from './ReadySign'
import { LightLoadingButton } from '../common/LightButton'
import { toastError } from '../../util/toastError'

type Props = {
  document: IDocument
  onReady?: () => void
  onSign?: () => void
  description?: string
}

const isReadySign = (status: string): boolean => {
  return ['ready', 'sign_start', 'success'].includes(status)
}

const signerName = (employees: TEmployee[], employee_id: string): string => {
  return employees.find((em) => em.id === employee_id)?.profile.name.full_name || ''
}

export const SignDocument = ({ document, description, onReady, onSign }: Props) => {
  const { data: user, isSuccess: isUserSuccess, isLoading: isUserLoading } = useGetUserQuery()
  const [ready] = useReadyMutation()
  const { data: employees } = useGetEmployeesQuery(document.building_id)
  const { data: profile } = useGetProfileQuery()

  const [isLoading, setIsLoading] = useState(false)

  const authManager = useAuthorization()

  const isOwner = useMemo(() => {
    return user ? authManager.vote('Document', 'manage', user.id === document.owner_id) : false
  }, [document, user, authManager])

  if (isUserLoading || !isUserSuccess || user === undefined) {
    return <Skeleton height={100} />
  }

  const handleReady = () => {
    setIsLoading(true)
    ready({ buildingId: document.building_id, body: { document_id: document.id } })
      .unwrap()
      .then(onReady)
      .catch(toastError)
      .finally(() => setIsLoading(false))
  }

  return (
    <Card variant={'elevation'} elevation={0}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: green[500] }}>
            <VpnKeyIcon />
          </Avatar>
        }
        title="Подписание"
        subheader={description}
      />
      {document.status === 'draft' && isOwner && (
        <>
          <CardContent>
            <Typography variant={'body1'}>Документ ещё не сформирован для подписания</Typography>
          </CardContent>
          <CardActions>
            <LightLoadingButton onClick={handleReady} loading={isLoading}>
              Сформировать
            </LightLoadingButton>
          </CardActions>
        </>
      )}
      {isReadySign(document.status) && (
        <ReadySign document={document} onSign={onSign} isOwner={isOwner} />
      )}
    </Card>
  )
}
