import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
} from '@mui/material'
import { CertList } from './CertList'
import { useMemo, useState } from 'react'
import { IProfile } from '../../store/profile/types'
import { Link } from 'react-router-dom'

type Props = {
  profile: IProfile
  open: boolean
  onClose: () => void
  onSign: (cert: string) => void
}

const CertListDialog = ({ profile, open, onClose, onSign }: Props) => {
  const [cert, setCert] = useState<string>('')

  const handleSign = () => {
    if (!cert) {
      return
    }
    onSign(cert)
  }

  const certs = useMemo(
    () => profile.certificates.filter((cert) => new Date() < new Date(cert.period_to)),
    [profile]
  )

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{'Подписание'}</DialogTitle>
      {certs.length > 0 && (
        <DialogContent>
          <DialogContentText>Выберите сертификат для подписания</DialogContentText>
          <FormControl sx={{ mt: 2, width: '100%' }}>
            <InputLabel>Сертификат</InputLabel>
            <CertList profile={profile} onChange={(v) => setCert(v)} />
          </FormControl>
        </DialogContent>
      )}
      {certs.length === 0 && (
        <DialogContent>
          <Alert severity={'warning'}>
            <AlertTitle>Отсутствуют сертификаты</AlertTitle>
            Для подписания добавьте сертификат в <Link to={'/profile'}>профиле</Link>.
            <br />
            Если вы уже добавляли сертификат, возможно истек срок действия.
          </Alert>
        </DialogContent>
      )}
      <DialogActions>
        {cert && (
          <Button onClick={handleSign} variant={'outlined'} color={'success'}>
            Подписать
          </Button>
        )}
        <Button onClick={onClose} autoFocus>
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CertListDialog
