import {
  FormHelperText,
  FormControl,
  InputProps,
  OutlinedInput,
  InputLabel,
  SxProps,
  Theme,
} from '@mui/material'
import { FC, ReactNode } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

type IFormInputProps = {
  name: string
  label: string
  hint?: ReactNode
  startAdornment?: ReactNode
  endAdornment?: ReactNode
  size?: 'small' | 'medium'
  sx?: SxProps<Theme>
} & InputProps

const FormInput: FC<IFormInputProps> = ({
  name,
  label,
  hint,
  startAdornment,
  endAdornment,
  size,
  sx,
  ...otherProps
}) => {
  const {
    control,
    formState: { errors },
    getFieldState,
  } = useFormContext()

  const error = getFieldState(name).error
  const errorMessage: string = (error ? error.message : '') as string

  const hintEl = hint ? <FormHelperText>{hint}</FormHelperText> : null

  return (
    <Controller
      control={control}
      defaultValue=""
      name={name}
      render={({ field }) => (
        <FormControl fullWidth margin={'dense'} size={size}>
          <InputLabel>{label}</InputLabel>
          <OutlinedInput
            {...field}
            error={!!error}
            label={label}
            startAdornment={startAdornment}
            endAdornment={endAdornment}
            {...otherProps}
          />
          {hintEl}
          <FormHelperText error={!!error}>{errorMessage}</FormHelperText>
        </FormControl>
      )}
    />
  )
}

export default FormInput
