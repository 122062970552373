import { TLocation } from '../types/location'

export const locationString = (location: TLocation) => {
  const data = []
  if (location.axes) {
    data.push(`оси: ${location.axes}`)
  }
  if (location.marks) {
    data.push(`отметки: ${location.marks}`)
  }
  if (location.ranks) {
    data.push(`ряды: ${location.ranks}`)
  }
  if (location.floors) {
    data.push(`этажи: ${location.floors}`)
  }
  if (location.tiers) {
    data.push(`ярусы: ${location.tiers}`)
  }
  if (location.sections) {
    data.push(`секции: ${location.sections}`)
  }
  if (location.premises) {
    data.push(`помещения: ${location.premises}`)
  }
  if (location.place) {
    data.push(location.place)
  }
  return data.join('; ')
}


export const locationIsEqual = (location1: TLocation, location2: TLocation): boolean => {
  return  location1.axes === location2.axes &&
          location1.marks === location2.marks &&
          location1.ranks === location2.ranks &&
          location1.floors === location2.floors &&
          location1.tiers === location2.tiers &&
          location1.sections === location2.sections &&
          location1.premises === location2.premises &&
          location1.place === location2.place
}
