import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { object, string, TypeOf } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import FormInput from '../form/FormInput'
import { Box } from '@mui/material'

const registerSchema = object({
  last_name: string().min(1, 'Необходимо заполнить').max(100),
  first_name: string().min(1, 'Необходимо заполнить').max(100),
  middle_name: string().min(1, 'Необходимо заполнить').max(100),
  inn: string()
    .min(1, 'Необходимо заполнить')
    .regex(/^\d{12}$/, 'Неверный формат ИНН'),
  specialist_id_number: string().min(0),
})

export type RegisterInput = TypeOf<typeof registerSchema>

export const ProfileFrom = ({
  values,
  id,
  onSubmit,
}: {
  values?: any
  id: string
  onSubmit: (values: any, methods: any) => void
}) => {
  const methods = useForm<RegisterInput>({
    resolver: zodResolver(registerSchema),
    defaultValues: values
      ? { ...values, specialist_id_number: values.specialist_id_number || '' }
      : {},
  })

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods

  const onSubmitHandler: SubmitHandler<RegisterInput> = (values) => {
    onSubmit(values, methods)
  }

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmitHandler)}
        noValidate
        autoComplete="off"
        id={id}
      >
        <FormInput name="last_name" label="Фамилия" />
        <FormInput name="first_name" label="Имя" />
        <FormInput name="middle_name" label="Отчество" />
        <FormInput name="inn" label="ИНН" />
        <FormInput name="specialist_id_number" label="Идентификационный номер специалиста" />
      </Box>
    </FormProvider>
  )
}
