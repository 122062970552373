import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import { Add, Delete } from '@mui/icons-material'
import * as React from 'react'
import { IDocPrdDocument, IDocument } from '../../../../types/document'
import { TPrdDocument, TPrdType } from '../../../../types/prd'
import { StyledTableRow } from './StyledTableRow'
import { useAddPrdMutation, useRemovePrdMutation } from '../../../../store/document'
import ParseError from '../../../../api/parseError'
import { toast } from 'react-toastify'
import { useGetDocumentsQuery } from '../../../../store/prd'
import PrdDocLine from '../../../prd/PrdDocLine'
import PrdDocumentSelectDialog from '../../../prd/PrdDocumentSelectDialog'
import { useState } from 'react'
import { toastError } from '../../../../util/toastError'
import { LightButton } from '../../../common/LightButton'
import PrdListsDialog from '../../../prd/PrdListsDialog'

type Props = {
  item: IDocument
  type: TPrdType
  onChange: () => void
  isManage: boolean
}

const Item3ViewPrd = ({ item, type, onChange, isManage }: Props) => {
  const { data: prdDocs } = useGetDocumentsQuery(item.building_id)

  const [listsOpen, setListsOpen] = useState(false)
  const [currentPrd, setCurrentPrd] = useState<TPrdDocument | undefined>()
  const [currentLists, setCurrentLists] = useState<number[] | undefined>()

  const [removeDoc] = useRemovePrdMutation()
  const [addDoc] = useAddPrdMutation()

  const [openAdd, setOpenAdd] = useState(false)

  const prd = item.prd_documents[type] ? (item.prd_documents[type] as IDocPrdDocument[]) : []

  const removeDocHandler = (docId: string) => {
    if (window.confirm('Удалить документ из записи?')) {
      removeDoc({
        buildingId: item.building_id,
        body: {
          document_id: item.id,
          prd_id: docId,
        },
      })
        .unwrap()
        .then((res) => {
          onChange()
        })
        .catch(async (e) => {
          const error = await ParseError(e)
          toast.error(error)
        })
    }
  }

  const handleAdd = (values: any) => {
    addDoc({
      buildingId: item.building_id,
      body: {
        document_id: item.id,
        prd_id: values.document_id,
        lists: values.lists,
      },
    })
      .unwrap()
      .then((res) => {
        onChange()
        setOpenAdd(false)
      })
      .catch(toastError)
  }

  const handleLists = (doc: TPrdDocument, lists: number[]) => {
    setCurrentPrd(doc)
    setCurrentLists(lists)
    setListsOpen(true)
  }

  const handleListsUpdate = (values: any) => {
    if (!currentPrd) return
    addDoc({
      buildingId: item.building_id,
      body: {
        document_id: item.id,
        prd_id: currentPrd.id,
        lists: values.lists,
      },
    })
      .unwrap()
      .then((res) => {
        onChange()
        setListsOpen(false)
      })
      .catch(toastError)
  }

  return (
    <>
      <Card variant={'elevation'} elevation={0} sx={{ mb: 2 }}>
        <CardHeader
          titleTypographyProps={{ variant: 'body1', sx: { fontWeight: 'medium' } }}
          title={'Разделы ' + (type === 'pd' ? 'проектной документации' : 'рабочей документации')}
        />
        <CardContent sx={{ p: 0 }}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Раздел/шифр</TableCell>
                <TableCell width={'50%'}>Листы</TableCell>
                {isManage && (
                  <TableCell align="right">
                    <LightButton startIcon={<Add />} onClick={() => setOpenAdd(true)}>
                      Добавить
                    </LightButton>
                    <PrdDocumentSelectDialog
                      buildingId={item.building_id}
                      type={type}
                      open={openAdd}
                      onClose={() => setOpenAdd(false)}
                      onSelect={handleAdd}
                    />
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
            <TableBody>
              {prd &&
                prd.map((prdDoc) => {
                  if (!prdDocs) return ''
                  const doc = prdDocs.find((doc) => doc.id === prdDoc.prd_id)
                  if (!doc) return ''
                  return (
                    <StyledTableRow key={prdDoc.id}>
                      <TableCell>
                        <PrdDocLine document={doc} />
                        <Typography variant="subtitle2" color="text.disabled">
                          {doc.code}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {isManage && (
                          <Link
                            sx={{ cursor: 'pointer' }}
                            onClick={() => handleLists(doc, prdDoc.lists || [])}
                          >
                            {prdDoc.lists?.length && prdDoc.lists.join(', ')}
                          </Link>
                        )}
                        {!isManage && <Box>{prdDoc.lists?.length && prdDoc.lists.join(', ')}</Box>}
                      </TableCell>
                      {isManage && (
                        <TableCell align="right">
                          <IconButton
                            color="error"
                            title="Удалить"
                            onClick={() => removeDocHandler(prdDoc.id)}
                          >
                            <Delete />
                          </IconButton>
                        </TableCell>
                      )}
                    </StyledTableRow>
                  )
                })}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
      {currentPrd && (
        <PrdListsDialog
          document={currentPrd}
          values={currentLists}
          onSelect={handleListsUpdate}
          open={listsOpen}
          onClose={() => setListsOpen(false)}
        />
      )}
    </>
  )
}
export { Item3ViewPrd }
