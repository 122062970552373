import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { addAuthorization } from '../../util/http/addAuthorization'
import { IRepresentative } from '../../types/representative'
import { IDocument, ISar } from '../../types/document'

export const gwjApi = createApi({
  reducerPath: 'gwj',
  tagTypes: [
    'Items1',
    'Items2',
    'Items3',
    'Items4',
    'Items5',
    'Items7',
    'Representatives',
    'Sar',
    'TitleChange',
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1/',
    prepareHeaders: addAuthorization,
    validateStatus: (response) => response.ok,
  }),
  endpoints: (builder) => ({
    addGwj3ItemAmount: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/gwj/3-items/add-amount`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Items3'],
    }),
    removeGwj3ItemAmount: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/gwj/3-items/remove-amount`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Items3'],
    }),
    addAllRepresentatives: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/gwj/add-all-representatives`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Representatives'],
    }),
  }),
})

export const {
  useAddGwj3ItemAmountMutation,
  useRemoveGwj3ItemAmountMutation,
  useAddAllRepresentativesMutation,
} = gwjApi
