import { Button } from '@mui/material'
import { FormDialog } from '../common/FormDialog'
import { DocumentForm } from './index'
import * as React from 'react'
import { useState } from 'react'
import { useEditExternalDocumentMutation } from '../../store/externalDocuments'
import { toast } from 'react-toastify'
import { TExternalDocument } from '../../types/external-document'

const EditDialog = ({
  document,
  onSave,
}: {
  document?: TExternalDocument
  onSave?: (id: string) => void
}) => {
  const id = 'edit-external-document-dialog'
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [editDocument] = useEditExternalDocumentMutation()

  if (!document) {
    return <></>
  }

  const handleSubmit = (values: any, methods: any) => {
    setIsLoading(true)
    editDocument({
      buildingId: document.building_id,
      body: { ...values, document_id: document.id },
    })
      .unwrap()
      .then(() => {
        methods.reset()
        setOpen(false)
        setIsLoading(false)
        toast.success('Документ сохранен')
        onSave && onSave(document.id)
      })
      .catch(() => {
        toast.error('Произошла ошибка')
        setIsLoading(false)
      })
  }

  return (
    <>
      <Button onClick={() => setOpen(true)} variant={'outlined'} color={'success'}>
        Редактировать
      </Button>
      <FormDialog
        formId={id}
        open={open}
        onClose={() => setOpen(false)}
        title={'Редактировать документ'}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <DocumentForm id={id} onSubmit={handleSubmit} values={document} />
      </FormDialog>
    </>
  )
}

export default EditDialog
