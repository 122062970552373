import { TBuilding, TCompany } from '../../types/building'
import { array, object, string, TypeOf } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { useGetGroupsQuery, useGetWorksQuery } from '../../store/works'
import React, { useMemo, useState } from 'react'
import { worksList } from '../../util/works'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { Add } from '@mui/icons-material'
import FormSelect from '../form/FormSelect'
import { LoadingButton } from '@mui/lab'
import { toastError } from '../../util/toastError'
import { useSetCompanyWorksMutation } from '../../store/companies'
import { LightButton } from '../common/LightButton'

const schema = object({
  works: array(string()),
})

type Props = {
  building: TBuilding
  company: TCompany
  values: any
}
const CompanyWorksDialog = ({ company, building, values }: Props) => {
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const registerSchema = schema

  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: values || { works: [] },
  })

  const { data: works } = useGetWorksQuery(building.id)
  const { data: groups } = useGetGroupsQuery(building.id)

  const worksItems = useMemo(() => worksList(groups, works) || [], [groups, works])

  const { handleSubmit } = methods

  const [setWorks] = useSetCompanyWorksMutation()

  const onSubmitHandler = (values: any) => {
    setIsLoading(true)
    setWorks({
      buildingId: building.id,
      body: {
        ...values,
        company_id: company.id,
      },
    })
      .unwrap()
      .then((res) => {
        setOpen(false)
      })
      .catch(toastError)
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <>
      <LightButton startIcon={<Add />} onClick={() => setOpen(true)}>
        {values.works && values.works.length > 0 ? 'Изменить' : 'Добавить'}
      </LightButton>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth={'lg'} fullWidth>
        <DialogTitle>{'Выбрать работы'}</DialogTitle>
        <DialogContent>
          <FormProvider {...methods}>
            <Box
              component="form"
              id={'company-works-form'}
              onSubmit={handleSubmit(onSubmitHandler)}
              noValidate
              autoComplete="off"
            >
              <Box sx={{ pt: 1 }}>
                <FormSelect
                  multiple
                  name="works"
                  label="Список выполняемых работ"
                  items={worksItems}
                  hint="Заполняется в случае необходимости ограничения выполняемых работ для компании"
                />
              </Box>
            </Box>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant={'contained'}
            type="submit"
            form={'company-works-form'}
            loading={isLoading}
            color={'success'}
          >
            {'Отправить'}
          </LoadingButton>

          <Button onClick={() => setOpen(false)}>Закрыть</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export { CompanyWorksDialog }
