import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { addAuthorization } from '../../util/http/addAuthorization'
import { IStore } from '../../types/store'

export const storeApi = createApi({
  reducerPath: 'store',
  tagTypes: ['Store'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1/',
    prepareHeaders: addAuthorization,
    validateStatus: (response) => response.ok,
  }),
  endpoints: (builder) => ({
    getStore: builder.query<IStore[], string>({
      query: (buildingId) => ({
        url: `/buildings/${buildingId}/store`,
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetStoreQuery } = storeApi
