import { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { toast } from 'react-toastify'
import { Alert, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { parseError } from '../../api'
import { useAddProfileMutation } from '../../store/profile'
import { useGetUserQuery } from '../../store/user'
import { ProfileFrom } from './ProfileFrom'

const ProfileDialogForm = ({ values }: { values: any }) => {
  const [isLoading, setIsLoading] = useState(false)

  const { data: user } = useGetUserQuery()

  const [addProfile] = useAddProfileMutation()

  const onSubmitHandler = (values: any, methods: any) => {
    setIsLoading(true)
    const data = { ...values, email: user?.email }
    addProfile(data)
      .then((res: any) => {
        if (res.error) {
          throw res.error
        }
        toast.success('Профиль успешно сохранен', {
          position: 'top-center',
        })
        setIsLoading(false)
        methods.reset()
      })
      .catch(async (error) => {
        const text = await parseError(error)
        toast.error(text, {
          position: 'top-center',
        })
        setIsLoading(false)
      })
  }

  return (
    <Dialog open={true} disableEscapeKeyDown>
      <DialogTitle>Профиль</DialogTitle>
      <DialogContent>
        <Alert severity={'info'} sx={{ mb: 3 }}>
          Для дальнейшей работы в системе, заполните пожалуйста профиль
        </Alert>
        <ProfileFrom id="profile-add-form" onSubmit={onSubmitHandler} values={values} />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="contained"
          sx={{ mt: 1 }}
          type="submit"
          disableElevation
          loading={isLoading}
          form={'profile-add-form'}
        >
          Сохранить
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default ProfileDialogForm
