import { TBuilding, TCompany, TEmployee } from '../../../../types/building'
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  IconButton,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { formatDate } from '../../../../util/date'
import { useGetCompaniesQuery } from '../../../../store/companies'
import { StatusChip } from '../../../common/StatusChip'
import * as React from 'react'
import { Delete } from '@mui/icons-material'
import Box from '@mui/material/Box'
import { ReactNode, useMemo } from 'react'
import { AddEmployeeDialog } from './AddEmployeeDialog'
import ParseError from '../../../../api/parseError'
import { toast } from 'react-toastify'
import { IDocument } from '../../../../types/document'
import { useRemoveEmployeeMutation } from '../../../../store/document'
import { useGetEmployeesQuery } from '../../../../store/employees'
import { DataRow } from '../../../common/DataRow'
import EditItem2Dialog from './EditItem2Dialog'
import { SignDocument } from '../../../document/SignDocument'

const employeeCompanyName = (employee: TEmployee, companies: TCompany[]) => {
  return companies.find((c) => c.id === employee.company_id)?.name || ''
}

const signerName = (employees: TEmployee[], item: IDocument): string => {
  return (
    employees.find((em) => em.id === item.employees.signer[0].employee_id)?.profile.name
      .full_name || ''
  )
}

const AccordionHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Typography variant={'subtitle1'} sx={{ fontWeight: 'medium', flexGrow: 1 }}>
      {children}
    </Typography>
  )
}

const CountChip = ({ length = 0 }: { length: number }) => {
  return length > 0 ? (
    <Box sx={{ width: '30px', mr: 1 }}>
      <Chip color="success" label={length} size="small" />
    </Box>
  ) : (
    <></>
  )
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

export const Item2View = ({
  building,
  item,
  onChange,
  isManage,
}: {
  building: TBuilding
  item: IDocument
  onChange: () => void
  isManage: boolean
}) => {
  const { data: employees } = useGetEmployeesQuery(building.id)
  const { data: companies } = useGetCompaniesQuery(building.id)
  const [removeEmployee] = useRemoveEmployeeMutation()

  if (employees === undefined || companies === undefined) {
    return <></>
  }

  const removeItemEmployeeHandler = (id: string) => {
    removeEmployee({
      buildingId: building.id,
      body: {
        document_id: item.id,
        document_employee_id: id,
      },
    })
      .unwrap()
      .then((res) => {
        onChange()
      })
      .catch(async (e) => {
        const error = await ParseError(e)
        toast.error(error)
      })
  }

  return (
    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems={'flex-start'}>
      <Box>
        <Card>
          <CardHeader avatar={<StatusChip status={item.status} />} />
          <CardContent>
            <DataRow label={'Журнал'} text={item.data.name} />
            <DataRow
              label={'Дата передачи застройщику или техническому заказчику законченного журнала'}
              text={formatDate(item.data.date_pass)}
            />
            <DataRow
              label={`Уполномоченный представитель застройщика или технического заказчика,
                  подтверждающий получение законченного журнала`}
              text={signerName(employees, item)}
            />
          </CardContent>
          {isManage && (
            <CardContent>
              <EditItem2Dialog document={item} />
            </CardContent>
          )}
        </Card>
        <Box sx={{ mt: 2 }}>
          <SignDocument document={item} onSign={onChange} onReady={onChange} />
        </Box>
      </Box>
      <Card variant={'elevation'} elevation={0} sx={{ mb: 2 }}>
        <CardHeader
          titleTypographyProps={{ variant: 'body1', sx: { fontWeight: 'medium' } }}
          title={`Список лиц, осуществляющих строительство или лиц,
          осуществляющих подготовку проектной документации, ведущих журнал, их уполномоченных представителей`}
        />
        <CardContent sx={{ p: 0 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {isManage && (
                  <TableCell align="right">
                    <AddEmployeeDialog
                      building={building}
                      item={item}
                      onAdd={onChange}
                    ></AddEmployeeDialog>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {item.employees.employee &&
                item.employees.employee.map((docEmployee) => {
                  const employee = employees.find((e) => e.id === docEmployee.employee_id)
                  if (!employee) return ''
                  return (
                    <StyledTableRow key={docEmployee.id}>
                      <TableCell>
                        <Typography>{employee.profile.name.full_name}</Typography>
                        <Typography variant="caption" sx={{ lineHeight: 1.5 }}>
                          {employeeCompanyName(employee, companies)}
                        </Typography>
                      </TableCell>
                      {isManage && (
                        <TableCell align="right">
                          <IconButton
                            color="error"
                            title="Удалить"
                            onClick={() => removeItemEmployeeHandler(docEmployee.id)}
                          >
                            <Delete />
                          </IconButton>
                        </TableCell>
                      )}
                    </StyledTableRow>
                  )
                })}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </Stack>
  )
}
