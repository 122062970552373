import { useAttachBuildingUserMutation, useGetProfilesQuery } from '../../store/buildings'
import {
  useDeactivateEmployeeMutation,
  useActivateEmployeeMutation,
  useRemoveAdministrativeDocumentMutation,
  useRemoveEmployeeMutation,
} from '../../store/employees'
import Box from '@mui/material/Box'
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormGroup,
  IconButton,
  MenuItem,
  Stack,
  Switch,
  TableContainer,
  Typography,
} from '@mui/material'
import { toast } from 'react-toastify'
import { green, red } from '@mui/material/colors'
import { Delete, Person } from '@mui/icons-material'
import { employeeRoles, TBuilding, TCompany, TEmployee } from '../../types/building'
import FormControlLabel from '@mui/material/FormControlLabel'
import React, { useState } from 'react'
import { AddAdministrativeDocumentDialog } from './AddAdministrativeDocumentDialog'
import { BaseContainer } from '../common/Container'
import { useAuthorization } from '../../auth'
import { MoreVertMenu } from '../menu'
import { toastError } from '../../util/toastError'
import { useNavigate } from 'react-router-dom'
import { EditEmployeeDialog } from './EditEmployeeDialog'
import { useGetWorksQuery } from '../../store/works'
import ExtDocLine from '../ExternalDocuments/ExtDocLine'
import { EmployeeWorks } from './EmployeeWorks'
import { useConfirm } from '../../util/useConfirm'


type Props = {
  building: TBuilding
  company: TCompany
  employee: TEmployee
}

export const Employee = ({ building, company, employee }: Props) => {

  const [open, setOpen] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)

  const { data: profiles } = useGetProfilesQuery(building.id)
  const { data: works } = useGetWorksQuery(building.id)

  const [deactivate] = useDeactivateEmployeeMutation()
  const [activate] = useActivateEmployeeMutation()
  const [removeAD] = useRemoveAdministrativeDocumentMutation()
  const [remove] = useRemoveEmployeeMutation()
  const [attach] = useAttachBuildingUserMutation()

  const navigate = useNavigate()
  const confirm = useConfirm()

  const isActive = employee.status === 'active'

  const authManager = useAuthorization()
  const isAdmin: boolean = authManager.vote('Employee', 'manage')

  const handleActiveChange = (employeeId: string, checked: boolean) => {

    const mutation = checked ? activate : deactivate
    mutation({ buildingId: building.id, employeeId })
      .unwrap()
      .then((res) => {
        toast.success('Готово!')
      })
      .catch((res) => {
        toast.error('Что-то пошло не так :(')
      })
  }

  const handleRemoveAD = () => {
    removeAD({
      buildingId: building.id,
      body: {
        employee_id: employee.id,
      },
    })
  }

  const handleRemove = () => {
    const companyId = employee.company_id
    remove({
      buildingId: building.id,
      body: {
        employee_id: employee.id,
      },
    })
      .unwrap()
      .then((res) => {
        toast.success('Сотрудник удалена')
        navigate(`../../company/${companyId}`)
      })
      .catch(toastError)
  }

  const handleAttachBuildingUser = () => {

    confirm({
      title: 'Добавить права',
      description: 'Сотрудник будет добавлен как пользователь объекта, после чего можно будет назначить ему дополнительные права',
    }).then(() => {
      attach({
        buildingId: building.id,
        body: {user_id: employee.user_id}
      })
        .unwrap()
        .then((res) => {
          navigate(`/building/${building.id}/data/users#${res.id}`)
        })
        .catch(toastError)
    })
  }

  return (
    <BaseContainer>
      <TableContainer component={Card} sx={{ mb: 2 }}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: isActive ? green[500] : red[500] }}>
              <Person />
            </Avatar>
          }
          action={
            isAdmin && (
              <Stack direction={'row'}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={employee.status === 'active'}
                        onChange={(e) => handleActiveChange(employee.id, e.target.checked)}
                      />
                    }
                    label="Активен"
                  />
                </FormGroup>
                <MoreVertMenu
                  open={menuOpen}
                  onClose={() => setMenuOpen(false)}
                  onOpen={() => setMenuOpen(true)}
                >
                  <MenuItem
                    onClick={() => {
                      setOpen(true)
                      setMenuOpen(false)
                    }}
                  >
                    Редактировать
                  </MenuItem>
                  <MenuItem onClick={handleAttachBuildingUser}>Добавить права</MenuItem>
                  <MenuItem onClick={handleRemove}>Удалить</MenuItem>
                </MoreVertMenu>
              </Stack>
            )
          }
          title={isActive ? 'Активен' : 'Не активен'}
        />
        <CardContent>
          <Box sx={{ mb: 2 }}>
            <Typography variant={'h6'}>
              {(profiles &&
                profiles[employee.user_id] &&
                profiles[employee.user_id].name.full_name) ||
                employee.id}
            </Typography>
            <Typography variant={'overline'}>{employeeRoles[employee.role]}</Typography>
            <Typography variant={'body1'}>{employee.position}</Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography>{company.name}</Typography>
          </Box>
          <Divider sx={{ mt: 1, mb: 1 }} />
          <Typography variant={'caption'} color="text.secondary">
            {'Распорядительный документ, подтверждающие полномочия'}
          </Typography>
          <Box sx={{ mt: 1 }}>
            {employee.administrative_document ? (
              <Stack direction={'row'} justifyContent={'space-between'}>
                <ExtDocLine document={employee.administrative_document.document} />
                <IconButton color="error" title="Удалить документ" onClick={handleRemoveAD}>
                  <Delete />
                </IconButton>
              </Stack>
            ) : (
              <Box>
                <AddAdministrativeDocumentDialog building={building} employee={employee} />
              </Box>
            )}
          </Box>
        </CardContent>
      </TableContainer>

      {/* <Box sx={{ mt: 2 }}> */}
      {/*  <EmployeeWorks employee={employee} building={building} /> */}
      {/* </Box> */}

      <EditEmployeeDialog
        building={building}
        company={company}
        employee={employee}
        open={open}
        values={{ role: employee.role, position: employee.position, works: employee.works || [] }}
        onClose={() => setOpen(false)}
      />
    </BaseContainer>
  )
}
