import { TBuilding } from '../../../types/building'
import { useParams } from 'react-router-dom'
import { TBaseDocumentType } from '../../../types/document'
import { Card, CardHeader } from '@mui/material'
import { XmlDownloadList } from '../../document/XmlDownloadList'
import React from 'react'

type Props = {
  building: TBuilding
}
const XmlList = ({building}: Props) => {

  const { journalId } = useParams()

  const filteredTypes: TBaseDocumentType[] = [
    'incomingMaterialControlJournal',
    'materialsSelection',
    'incomingMaterialsControlInfo'
  ]

  return(
    <>
      <Card>
        <CardHeader
          title={'Экспорт в XML'}
          subheader={'экспорт в формат Минстрой'}
        />
        {journalId && <XmlDownloadList building={building} documentId={journalId} types={filteredTypes} />}
      </Card>
    </>
  )

}

export {XmlList}
