import {
  FormHelperText,
  FormControl,
  InputProps,
  Autocomplete,
  debounce,
  Grid,
} from '@mui/material'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import Diversity3Icon from '@mui/icons-material/Diversity3'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

interface ICompany {
  value: string
  unrestricted_value: string
  data: any
}

type IFormInputProps = {
  name: string
  label: string
  hint?: string
  onInnSelect: (value: ICompany | null) => void
  cType?: 'LEGAL' | 'INDIVIDUAL'
} & InputProps

const FormInputInn: FC<IFormInputProps> = ({
  name,
  label,
  hint,
  onInnSelect,
  cType,
  ...otherProps
}) => {
  const {
    control,
    formState: { errors, defaultValues },
  } = useFormContext()

  const [optionValue, setOptionValue] = useState<ICompany | null>(null)
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState<readonly ICompany[]>([])
  const errorMessage: string = (errors[name] ? errors[name]?.message : '') as string

  useEffect(() => {
    if (defaultValues && defaultValues[name]) {
      const val = {
        value: defaultValues[name],
        unrestricted_value: defaultValues[name],
        data: { inn: defaultValues[name], ogrn: '' },
      }
      setOptionValue(val)
      setOptions([val])
      setInputValue(defaultValues[name])
    }
  }, [defaultValues])

  const fetchCompany = useMemo(
    () =>
      debounce(
        async (request: { input: string }, callback: (results?: readonly ICompany[]) => void) => {
          const result = await fetch(
            '/api/company.php?term=' + request.input + (cType ? '&type=' + cType : '')
          ).then((response) => {
            return response.json()
          })
          callback(result)
        },
        400
      ),
    []
  )

  useEffect(() => {
    let active = true

    if (inputValue === '') {
      setOptions(optionValue ? [optionValue] : [])
      return undefined
    }

    if (inputValue.length > 9) {
      fetchCompany({ input: inputValue }, (results?: readonly ICompany[]) => {
        if (active) {
          let newOptions: readonly ICompany[] = []

          if (optionValue) {
            newOptions = [optionValue]
          }

          if (results) {
            newOptions = [...newOptions, ...results]
          }

          setOptions(newOptions)
        }
      })
    }
    return () => {
      active = false
    }
  }, [optionValue, inputValue, fetchCompany])

  return (
    <Controller
      control={control}
      defaultValue=""
      name={name}
      render={({ field }) => (
        <FormControl fullWidth margin={'dense'}>
          <Autocomplete
            getOptionLabel={(option) => {
              return typeof option === 'string' ? option : option.data.inn
            }}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={optionValue}
            noOptionsText="Поиск..."
            filterOptions={(x) => x}
            // freeSolo
            onChange={(event: any, newValue: ICompany | null) => {
              setOptions(newValue ? [newValue, ...options] : options)
              setOptionValue(newValue)
              onInnSelect(newValue)
            }}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue)
            }}
            renderInput={(params) => (
              <TextField {...params} {...field} error={!!errors[name]} label={label} />
            )}
            renderOption={(props, option) => {
              return (
                <li {...props}>
                  <Grid container alignItems="center">
                    <Grid item sx={{ display: 'flex', width: 44 }}>
                      <Diversity3Icon sx={{ color: 'text.secondary' }} />
                    </Grid>
                    <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                      {option.unrestricted_value}
                      <Typography variant={'subtitle2'} color={'grey'}>
                        ИНН: {option.data.inn}, ОГРН: {option.data.ogrn}
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
              )
            }}
          />
          {hint && <FormHelperText>{hint}</FormHelperText>}
          <FormHelperText error={!!errors[name]}>{errorMessage}</FormHelperText>
        </FormControl>
      )}
    />
  )
}

export default FormInputInn
