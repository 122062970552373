import React from 'react'
import { useOutletContext } from 'react-router-dom'
import { IOutletBuilding } from '../../../../types/outlet-context'
import { XmlList } from '../../../../components/journals/gwj/xml/XmlList'

export const Xml = () => {
  const { building } = useOutletContext<IOutletBuilding>()

  return <XmlList building={building} />
}
