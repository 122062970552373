import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { addAuthorization } from '../../util/http/addAuthorization'
import { TCompany } from '../../types/building'

export const companiesApi = createApi({
  reducerPath: 'companies',
  tagTypes: ['Companies'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1/',
    prepareHeaders: addAuthorization,
  }),
  endpoints: (builder) => ({
    getCompanies: builder.query<TCompany[], string>({
      query: (id) => ({
        url: `/buildings/${id}/companies`,
        method: 'GET',
      }),
      keepUnusedDataFor: 10,
      providesTags: (result, error, arg) => [{ type: 'Companies', id: arg }],
    }),
    attachCompanyLegal: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/attach-company-legal`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Companies', id: arg.buildingId }],
    }),
    editCompanyLegal: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/edit-company-legal`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Companies', id: arg.buildingId }],
    }),
    attachCompanyIE: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/attach-company-individual-entrepreneur`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Companies', id: arg.buildingId }],
    }),
    editCompanyIE: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/edit-company-ie`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Companies', id: arg.buildingId }],
    }),
    removeCompany: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/remove-company`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Companies', id: arg.buildingId }],
    }),
    setCompanyWorks: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/company-works`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Companies', id: arg.buildingId }],
    }),
    setCompanyPrd: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/company-prd`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Companies', id: arg.buildingId }],
    }),
    addM4d: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/companies/add-m4d`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Companies', id: arg.buildingId }],
    }),
    importM4d: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/companies/import-m4d`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Companies', id: arg.buildingId }],
    }),
    removeM4d: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/companies/remove-m4d`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Companies', id: arg.buildingId }],
    }),
  }),
})

export const {
  useGetCompaniesQuery,
  useAttachCompanyLegalMutation,
  useEditCompanyLegalMutation,
  useAttachCompanyIEMutation,
  useEditCompanyIEMutation,
  useRemoveCompanyMutation,
  useSetCompanyWorksMutation,
  useSetCompanyPrdMutation,
  useAddM4dMutation,
  useImportM4dMutation,
  useRemoveM4dMutation,
} = companiesApi
