import { TWork, TWorkGroup } from '../types/works'

export const worksList = (
  groups: Record<string, TWorkGroup> | undefined,
  works: Record<string, TWork> | undefined,
  include: string[] = [],
  exclude: string[] = []
) => {
  if (groups === undefined || works === undefined) return []

  let worksArr = Object.values(works)

  worksArr = include.length > 0 ? worksArr.filter((w) => include.includes(w.id)) : worksArr
  worksArr = exclude.length > 0 ? worksArr.filter((w) => !include.includes(w.id)) : worksArr

  let data: any[] = []

  Object.values(groups).forEach((group) => {
    const items = worksArr
      .filter((work) => work.group_id === group.id)
      .map((work) => {
        return { label: work.name, value: work.id, description: work.description }
      })
    if (items.length > 0) {
      data.push({ label: group.name, description: group.description, isGroup: true })
      data = [...data, ...items]
    }
  })
  return data
}


export const worksListStrings = (
  groups: Record<string, TWorkGroup> | undefined,
  works: Record<string, TWork> | undefined,
  include: string[] = [],
  exclude: string[] = []
): string[] => {
  if (groups === undefined || works === undefined) return []

  let worksArr = Object.values(works)

  worksArr = include.length > 0 ? worksArr.filter((w) => include.includes(w.id)) : worksArr
  worksArr = exclude.length > 0 ? worksArr.filter((w) => !include.includes(w.id)) : worksArr

  let data: string[] = []

  Object.values(groups).forEach((group) => {
    const items = worksArr
      .filter((work) => work.group_id === group.id)
      .map((work) => `${group.name}/${work.name}`)
    if (items.length > 0) {
      data = [...data, ...items]
    }
  })
  return data
}

