import { TCompany, TEmployee } from '../../../types/building'
import { documentType, IDocument } from '../../../types/document'
import { TWork, TWorkGroup } from '../../../types/works'
import { useGetDocumentsQuery } from '../../../store/document'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography
} from '@mui/material'
import { StatusChip } from '../../common/StatusChip'
import { DataRow } from '../../common/DataRow'
import { formatDate, formatPeriod } from '../../../util/date'
import { locationString } from '../../../util/location'
import * as React from 'react'
import { useGetCompaniesQuery } from '../../../store/companies'
import { useGetEmployeesQuery } from '../../../store/employees'
import { useGetGroupsQuery, useGetWorksQuery } from '../../../store/works'
import { ActOSRDialog } from './ActOSRDialog'
import { doc } from 'prettier'


const companyName = (companies: TCompany[]|undefined, id: string) =>
  companies && companies.find(c => c.id === id)?.name

const employeeName = (employees: TEmployee[]|undefined, id: string) =>
  employees && employees.find(e => e.id === id)?.profile.name.full_name

const employeeOrder = (employees: TEmployee[]|undefined, id: string) => {
  if (!employees) return undefined
  const ad = employees.find(e => e.id === id)?.administrative_document?.document
  if (!ad) return undefined
  return `${ad.name} ${ad.number}`
}

const worksList = (
  document: IDocument,
  items3: IDocument[] | undefined,
  works: Record<string, TWork> | undefined,
  groups: Record<string, TWorkGroup> | undefined) => {

  if (!items3 || !works || ! groups) return []

  const ids = document.over[documentType.GWJ3Item].map(over => over.over_id)
  const items = items3?.filter(item => ids.includes(item.id))

  return items ? items.map(item => {
    const work = works[item.works[0]]
    const group = groups[work.group_id]
    return `${group.name}/${work.name}` + (item.location ? ` (${locationString(item.location)})` : '')
  }).filter((value, index, array) => array.indexOf(value) === index) : []
}

const ecName = (employees: TEmployee[]|undefined, companies: TCompany[]|undefined, id: string) => {
  if (!employees || !companies) return ''
  const employee = employees.find(e => e.id === id)
  if (!employee) return ''
  const cn = companyName(companies, employee.company_id)
  return `${employee.profile.name.full_name} (${cn})`
}

type Props = {
  document: IDocument
  isManage: boolean
}

const ActOSRViewData = ({ document, isManage }: Props) => {

  const {data: companies} = useGetCompaniesQuery(document.building_id)
  const {data: employees} = useGetEmployeesQuery(document.building_id)
  const {data: items3} =
    useGetDocumentsQuery({buildingId: document.building_id, params: {type: documentType.GWJ3Item}})
  const { data: works } = useGetWorksQuery(document.building_id)
  const { data: groups } = useGetGroupsQuery(document.building_id)



  return (
    <>
      <Card variant={'elevation'} elevation={0} sx={{ mb: 2 }}>
        <CardHeader
          title={`${document.data.name} ${document.number}`}
          action={<StatusChip status={document.status} />}
        />
        <Divider />
        <CardContent sx={{ p: 2 }}>

          <DataRow label={'Дата составления акта'} text={formatDate(document.date)} />

          <DataRow
            label={'Застройщик (Технический заказчик, Лицо, ответственное за эксплуатацию, Региональный оператор)'}
            text={companyName(companies, document.companies.developer[0].company_id)}
          />
          <DataRow
            label={'Лицо, осуществляющее строительство'}
            text={companyName(companies, document.companies.buildingContractor[0].company_id)}
          />
          <DataRow
            label={'Лицо, осуществляющее подготовку проектной документации'}
            text={companyName(companies, document.companies.projectDocumentationContractor[0].company_id)}
          />
          <DataRow
            label={'Лицо, выполнившее работы / конструкции/участки сетей, в отношении которых проведено освидетельствование'}
            text={companyName(companies, document.companies.workExecutor[0].company_id)}
          />

          <Divider sx={{mt: 2, mb: 2}} />

          <DataRow
            label={`Представитель
            Застройщика/Технического заказчика/Лица, ответственного за эксплуатацию/Регионального оператора
            по вопросам строительного контроля`}
            text={employeeName(employees, document.employees.developerRepresentative[0].employee_id)}
            description={employeeOrder(employees, document.employees.developerRepresentative[0].employee_id)}
          />
          <DataRow
            label={`Представитель лица, осуществляющего строительство`}
            text={employeeName(employees, document.employees.buildingContractorRepresentative[0].employee_id)}
            description={employeeOrder(employees, document.employees.buildingContractorRepresentative[0].employee_id)}
          />
          <DataRow
            label={`Представитель лица, осуществляющего строительство, по вопросам строительного контроля
            (специалист по организации строительства)`}
            text={employeeName(employees, document.employees.constructionManagementRepresentative[0].employee_id)}
            description={employeeOrder(employees, document.employees.constructionManagementRepresentative[0].employee_id)}
          />
          <DataRow
            label={`Представитель лица, осуществляющего подготовку проектной документации`}
            text={employeeName(employees, document.employees.projDocContractRepresentative[0].employee_id)}
            description={employeeOrder(employees, document.employees.projDocContractRepresentative[0].employee_id)}
          />
          <DataRow
            label={`Представитель лица, выполнившего работы/конструкции/участки сетей`}
            text={employeeName(employees, document.employees.workExecutorRepresentative[0].employee_id)}
            description={employeeOrder(employees, document.employees.workExecutorRepresentative[0].employee_id)}
          />
          {document.employees.otherRepresentative &&
            <DataRow label={`Иные представители лиц, участвующих в освидетельствовании`}>
              {document.employees.otherRepresentative.map(de => (
                <Box key={de.id}>
                  <Typography variant={'body2'}>
                    {ecName(employees, companies, de.employee_id)}
                  </Typography>
                </Box>
              ))}
            </DataRow>
          }

          <Divider sx={{mt: 2, mb: 2}} />

          <DataRow
            label={`Работы, предъявленные к освидетельствованию`}
            text={worksList(document, items3 && items3.items, works, groups).join(", ")}
          />
          <DataRow
            label={`Даты начала и окончания работ`}
            text={formatPeriod(document.data.dateBegin, document.data.dateEnd)}
          />
          <DataRow
            label={`Работы или элементы, выполнение которых разрешается`}
            text={document.data.allowedWorks.join(", ")}
          />
          <DataRow
            label={`Дополнительные сведения`}
            text={document.data.additionalInfo}
          />

        </CardContent>
        {isManage && <CardContent>
          <ActOSRDialog
            isCreate={false}
            buildingId={document.building_id}
            document={document}
          />
        </CardContent>}
      </Card>
    </>
  )
}

export { ActOSRViewData }
