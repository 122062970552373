import { object, string, TypeOf } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { Box } from '@mui/material'
import FormInput from '../form/FormInput'
import FormSelect from '../form/FormSelect'
import FormInputDate from '../form/FormInputDate'

const schema = {
  name: string().min(1, 'Необходимо заполнить'),
  description: string().min(0),
}
const registerSchema = object(schema)

interface Props {
  values?: any
  onSubmit: (values: TypeOf<typeof registerSchema>, methods: any) => void
  id?: string
}

const GroupForm = ({ values, onSubmit, id }: Props) => {
  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: values || {},
  })

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful },
    setValue,
    setError,
    watch,
  } = methods

  const submitHandler = (values: any) => {
    onSubmit(values, methods)
  }

  return (
    <FormProvider {...methods}>
      <Box
        id={id}
        component="form"
        onSubmit={handleSubmit(submitHandler)}
        noValidate
        autoComplete="off"
      >
        <FormInput name="name" label="Наименование" />
        <FormInput name="description" label="Описание" />
      </Box>
    </FormProvider>
  )
}

export default GroupForm
