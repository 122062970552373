import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material'
import * as React from 'react'
import { extDocTypesList, TExternalDocument, TExternalDocumentType } from '../../types/external-document'
import { AddDialog } from './index'
import { useOutletContext } from 'react-router-dom'
import { IOutletBuilding } from '../../types/outlet-context'
import { useEffect, useState } from 'react'
import { TFilterSetting, useFilter } from '../filter/useFilter'
import { FilterPanel } from '../filter/FilterPanel'
import ExtDocsList from './ExtDocsList'
import { FilterButton } from '../filter/FilterButton'


const typeItems = Object.keys(extDocTypesList).map((key) => ({
  label: extDocTypesList[key as TExternalDocumentType],
  value: key,
}))


const filterSettings: TFilterSetting[] = [
  {name: 'type', label: 'Тип', filter: 'list', data: typeItems},
  {name: 'name', label: 'Наименование', filter: 'string'},
  {name: 'number', label: 'Номер', filter: 'string'},
  {name: 'date', label: 'Дата', filter: 'dateRange'}
]

type Props = {
  documents: TExternalDocument[]
  open: boolean
  onClose: () => void
  onDocumentClick: (id: string) => void
  variant: 'full' | 'select'
  docType?: TExternalDocumentType
  onAdd?: (id: string) => void
}
const ExtDocListDialog = ({ documents, onClose, open, onDocumentClick, docType, onAdd }: Props) => {
  const { building } = useOutletContext<IOutletBuilding>()

  const [filterOpen, setFilterOpen] = useState(false)
  const filter = useFilter(filterSettings, docType && {type: [docType]})

  useEffect(() => {
    if (documents) {
      filter.setItems(documents)
    }
  }, [documents, filter])

  const handleAdd = (res: any) => {
    onAdd && onAdd(res.id)
  }

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth={'lg'} fullWidth scroll={'paper'}>
        <DialogTitle>Выбор документа</DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <ExtDocsList
            items={filter.filtered}
            variant={'full'}
            onRowClick={onDocumentClick}
          />
        </DialogContent>
        <DialogActions>
          <AddDialog building={building} onAdd={handleAdd} type={docType} />
          <FilterButton count={filter.state.count} onClick={() => setFilterOpen(true)} />
          <Button onClick={onClose}>Закрыть</Button>
        </DialogActions>
      </Dialog>
      <FilterPanel
        open={filterOpen}
        onOpen={() => setFilterOpen(true)}
        onClose={() => setFilterOpen(false)}
        filter={filter}
        variant={'dialog'}
      />
    </>
  )
}

export default ExtDocListDialog
