import React from 'react'
import System from '../../layout/system'
import { Link as RouterLink } from 'react-router-dom'
import useAuth from '../../oauth/useAuth'
import AlertError from '../../components/alert/AlertError'
import LoginIcon from '@mui/icons-material/Login'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import { Link } from '@mui/material'

function OAuth(): JSX.Element {
  const { error, loading } = useAuth()

  return (
    <System>
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LoginIcon />
      </Avatar>
      <Typography component="h1" variant="h3" sx={{ mb: 1 }}>
        Авторизация
      </Typography>
      <AlertError message={error} />
      {loading ? <p>Loading...</p> : null}
      <Typography component="div" variant="body1" sx={{ mb: 1 }}>
        <Link to="/" variant="body2" component={RouterLink}>
          На главную
        </Link>
      </Typography>
    </System>
  )
}

export default OAuth
