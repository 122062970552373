import { TBuilding, TEmployee } from '../../types/building'
import { object, string, TypeOf } from 'zod'
import React, { useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { Box, Button } from '@mui/material'
import { Add } from '@mui/icons-material'
import { FormDialog } from '../common/FormDialog'
import { useAddAdministrativeDocumentMutation } from '../../store/employees'
import FormInputExtDocument from '../form/FormInputExtDocument'
import { worksList } from '../../util/works'
import { useGetGroupsQuery, useGetWorksQuery } from '../../store/works'
import { toastError } from '../../util/toastError'
import { LightButton } from '../common/LightButton'

const schema = {
  document_id: string().min(1, 'Необходимо заполнить'),
}

export const AddAdministrativeDocumentDialog = ({
  building,
  employee,
}: {
  building: TBuilding
  employee: TEmployee
}) => {
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [addDocument] = useAddAdministrativeDocumentMutation()
  const { data: works } = useGetWorksQuery(building.id)
  const { data: groups } = useGetGroupsQuery(building.id)

  const registerSchema = object(schema)

  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: {},
  })

  const { handleSubmit, reset } = methods

  const worksItems = useMemo(() => worksList(groups, works), [groups, works])

  const onSubmitHandler = (values: any) => {
    setIsLoading(true)
    addDocument({
      buildingId: building.id,
      body: {
        document_id: values.document_id,
        employee_id: employee.id,
      },
    })
      .unwrap()
      .then((res) => {
        reset()
        setOpen(false)
      })
      .catch(toastError)
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <>
      <LightButton startIcon={<Add />} onClick={() => setOpen(true)}>
        Добавить документ
      </LightButton>
      <FormDialog
        formId={'add-administrative-document'}
        open={open}
        onClose={() => setOpen(false)}
        title={'Добавить документ'}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <FormProvider {...methods}>
          <Box
            component="form"
            id={'add-administrative-document'}
            onSubmit={handleSubmit(onSubmitHandler)}
            noValidate
            autoComplete="off"
          >
            <Box sx={{ pt: 1 }}>
              <FormInputExtDocument
                name="document_id"
                label="Документ"
                docType={'administrative_document'}
              />
            </Box>
          </Box>
        </FormProvider>
      </FormDialog>
    </>
  )
}
