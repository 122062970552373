import {
  Box,
  Button,
  CardHeader,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React, { useMemo, useState } from 'react'
import { TBuilding, TEmployee } from '../../../../types/building'
import { useParams } from 'react-router-dom'
import { useGetEmployeesQuery } from '../../../../store/employees'
import { formatDateTime } from '../../../../util/date'
import { StatusChip } from '../../../common/StatusChip'
import { Delete } from '@mui/icons-material'
import { toast } from 'react-toastify'
import { parseError } from '../../../../api'
import { useGetDocumentsQuery, useRemoveDocumentMutation } from '../../../../store/document'
import { documentType, IDocument } from '../../../../types/document'
import { useAuthorization } from '../../../../auth'
import { useGetUserQuery } from '../../../../store/user'
import { RightPanel } from '../../../common'
import { TitleChangeView } from './TitleChangeView'
import TitleChangeItemDialog from './TitleChangeItemDialog'
import { nextNumber } from '../../../../util/maxNumber'

const signerName = (employees: TEmployee[], item: IDocument): string => {
  return (
    employees.find((em) => em.id === item.employees.signer[0].employee_id)?.profile.name
      .full_name || ''
  )
}

export const TitleChangeList = ({ building }: { building: TBuilding }) => {
  const { journalId } = useParams()
  const { data, isLoading, refetch } = useGetDocumentsQuery({
    buildingId: building.id,
    params: {
      type: documentType.TitleChangesListItem,
      parent_id: journalId,
    },
  })
  const [removeItem] = useRemoveDocumentMutation()

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [currentId, setCurrentId] = useState<string | undefined>()

  const currentItem = useMemo(() => {
    return data && data.items.find((item) => item.id === currentId)
  }, [data, currentId])

  const sorted = useMemo(() => {
    if (!data) return []
    return Array.from(data.items).sort(
      (a, b) => parseInt(a.number || '0') - parseInt(b.number || '0')
    )
  }, [data])

  const { data: employees } = useGetEmployeesQuery(building.id)

  const authManager = useAuthorization()
  const { data: user } = useGetUserQuery()
  const isCreate = authManager.vote('Document.TitleChangesListItem', 'create')
  const isManage = useMemo(() => {
    return (
      (currentItem &&
        user &&
        authManager.vote(
          'Document.TitleChangesListItem',
          'manage',
          currentItem.owner_id === user.id
        )) ||
      false
    )
  }, [currentItem, user])

  if (isLoading || data === undefined || employees === undefined) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    )
  }

  const viewItem = (id: string) => {
    setCurrentId(id)
    setDrawerOpen(true)
  }

  const removeHandler = (id: string, e: React.MouseEvent) => {
    e.stopPropagation()
    removeItem({
      buildingId: building.id,
      body: {
        document_id: id,
        type: documentType.TitleChangesListItem,
      },
    })
      .unwrap()
      .then((res) => {
        refetch()
      })
      .catch(async (e) => toast.error(await parseError(e)))
  }

  return (
    <>
      <Paper>
        <CardHeader
          title={'Лист изменений'}
          action={
            isCreate && (
              <TitleChangeItemDialog
                buildingId={building.id}
                onSave={refetch}
                nextNumber={nextNumber(data.items)}
              />
            )
          }
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>№</TableCell>
              <TableCell>Изменения в записях с указанием основания</TableCell>
              <TableCell>Ответственный</TableCell>
              <TableCell>Дата</TableCell>
              <TableCell>Статус документа</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sorted &&
              sorted.map((item) => (
                <TableRow
                  key={item.id}
                  hover
                  onClick={() => viewItem(item.id)}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell>{item.number}</TableCell>
                  <TableCell>
                    <Typography variant="body1">{item.data.description}</Typography>
                    <Typography variant="subtitle2" sx={{ color: 'text.disabled' }}>
                      {item.data.basis}
                    </Typography>
                  </TableCell>
                  <TableCell>{employees && signerName(employees, item)}</TableCell>
                  <TableCell>{formatDateTime(item.date)}</TableCell>
                  <TableCell>
                    <StatusChip status={item.status} />
                  </TableCell>
                  <TableCell align="right">
                    {authManager.vote(
                      'Document.TitleChangesListItem',
                      'manage',
                      user && item.owner_id === user.id
                    ) && (
                      <IconButton
                        color="error"
                        title="Удалить"
                        onClick={(e) => removeHandler(item.id, e)}
                      >
                        <Delete />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
      <RightPanel
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
        actions={
          <>
            {isManage && currentItem && (
              <Button
                variant={'outlined'}
                color={'error'}
                onClick={(e) => removeHandler(currentItem.id, e)}
              >
                Удалить
              </Button>
            )}
          </>
        }
      >
        {currentItem && <TitleChangeView document={currentItem} onSign={refetch} />}
      </RightPanel>
    </>
  )
}
