import { coerce, object, string, TypeOf } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { Box } from '@mui/material'
import { useGetCompaniesQuery } from '../../../../store/companies'
import { employeesList } from '../../../../util/employees'
import FormInput from '../../../form/FormInput'
import FormSelect from '../../../form/FormSelect'
import FormInputDate from '../../../form/FormInputDate'
import { useGetEmployeesQuery } from '../../../../store/employees'

const schema = {
  number: string()
    .min(1, 'Необходимо заполнить')
    .refine((val) => /^[1-9]+$/.test(val), {
      message: 'Должно быть целым положительным числом',
    }),
  signer_id: string().min(1, 'Необходимо заполнить'),
  date: string().min(1, 'Необходимо заполнить дату'),
  data: object({
    description: string().min(1, 'Необходимо заполнить'),
    basis: string().min(1, 'Необходимо заполнить'),
  }),
}
const registerSchema = object(schema)

interface Props {
  buildingId: string
  values?: any
  onSubmit: (values: TypeOf<typeof registerSchema>, methods: any) => void
  id?: string
}

export const ItemForm = ({ buildingId, values, onSubmit, id }: Props) => {
  const { data: employees } = useGetEmployeesQuery(buildingId)
  const { data: companies } = useGetCompaniesQuery(buildingId)

  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: values || {},
  })

  const { handleSubmit } = methods

  const submitHandler = (values: any) => {
    onSubmit(values, methods)
  }

  const signerItems =
    companies && employees
      ? employeesList(
          companies,
          employees,
          ['building_contractor', 'developer', 'operating_person', 'regional_operator', 'customer'],
          ['auth_representative']
        )
      : []

  return (
    <FormProvider {...methods}>
      <Box
        id={id}
        component="form"
        onSubmit={handleSubmit(submitHandler)}
        noValidate
        autoComplete="off"
      >
        <FormInput name="number" label="Порядковый номер записи" />
        <FormSelect
          name="signer_id"
          label="Представитель, ответственный за внесение изменений"
          items={signerItems}
        />
        <FormInputDate name="date" label="Дата внесения изменения" />
        <FormInput name="data.description" label="Внесённые изменения" />
        <FormInput name="data.basis" label="Основания для внесения данного изменения" />
      </Box>
    </FormProvider>
  )
}
