import { TPrdDocument } from '../../types/prd'
import { Box, Divider, Paper, Typography } from '@mui/material'
import * as React from 'react'
import { useGetCompaniesQuery } from '../../store/companies'
import { companyName } from '../../util/companies'
import { TProfile } from '../../types/Profile'
import { formatDate } from '../../util/date'
import { PrdDocumentFile } from './PrdDocumentFile'
import { useGetProfilesQuery } from '../../store/buildings'
import { DataRow } from '../common/DataRow'
import { PrdDocumentChanges } from './PrdDocumentChanges'

const infoRow = (title?: string, value?: string) => {
  return (
    <Box sx={{ pt: 0.5, pb: 0.5, pl: 2, pr: 2 }}>
      {title && (
        <Typography variant={'caption'} color={'text.secondary'}>
          {title}
        </Typography>
      )}
      <Typography variant={'body1'}>{value || ''}</Typography>
    </Box>
  )
}

const userName = (profiles: Record<string, TProfile>, userId: string) => {
  return profiles[userId].name.full_name
}

type Props = {
  document: TPrdDocument
}
const PrdDocumentView = ({ document }: Props) => {
  const { data: companies } = useGetCompaniesQuery(document.building_id)
  const { data: profiles } = useGetProfilesQuery(document.building_id)

  return (
    <>
      <Paper sx={{ p: 2, mb: 2 }}>
        <DataRow label={'Наименование'} text={document.name} />
        <DataRow label={'Шифр'} text={document.code} />
        <DataRow label={'Количество листов'} text={document.lists_count + ''} />
        <DataRow
          label={'Лицо, осуществляющее подготовку проектной документации'}
          text={companies && companyName(companies, document.designer_id)}
        />
        <DataRow label={'Добавил'} text={profiles && userName(profiles, document.user_id)} />
        <DataRow label={'Дата добавления'} text={formatDate(document.created_at)} />
      </Paper>

      <Paper sx={{ p: 2, mb: 2 }}>
        <PrdDocumentFile document={document} />
      </Paper>

      {document.type === 'rd' && <PrdDocumentChanges document={document} />}
    </>
  )
}
export { PrdDocumentView }
