import React from 'react'
import { useOutletContext } from 'react-router-dom'
import { TitleChangeList } from '../../../../components/journals/gwj/titleChangeList/TitleChangeList'
import { IOutletBuilding } from '../../../../types/outlet-context'

export const ChangeList = () => {
  const { building } = useOutletContext<IOutletBuilding>()

  return <TitleChangeList building={building} />
}
