import { IDocument } from '../../types/document'
import { useSignMutation } from '../../store/document'
import { SignDialog } from './SignDialog'
import { useGetUserQuery } from '../../store/user'
import { useGetProfileQuery } from '../../store/profile'
import { TEmployee } from '../../types/building'
import React, { useMemo, useState } from 'react'
import { toastError } from '../../util/toastError'

const signerName = (employees: TEmployee[], employee_id: string): string => {
  return employees.find((em) => em.id === employee_id)?.profile.name.full_name || ''
}

type Props = {
  document: IDocument
  onSign?: () => void
  size?: 'small' | 'medium'
}
const SignButton = ({ document, onSign, size = 'medium' }: Props) => {
  const { data: user, isSuccess: isUserSuccess, isLoading: isUserLoading } = useGetUserQuery()
  const [sign] = useSignMutation()
  const { data: profile } = useGetProfileQuery()

  const signer = useMemo(() => {
    if (!user || !document.signatures) return undefined
    return document.signatures.find((s) => s.user_id === user.id && !s.is_signed)
  }, [user])


  const handleSign = (signature: any) => {
    sign({
      buildingId: document.building_id,
      body: {
        document_id: document.id,
        signature,
      },
    })
      .unwrap()
      .then((res) => {
        onSign && onSign()
      })
      .catch(toastError)
  }

  return (
    <>
      {signer && profile && (
        <SignDialog document={document} profile={profile} onSign={handleSign} />
      )}
    </>
  )
}

export { SignButton }
