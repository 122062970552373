import React, { useState } from 'react'
import { Link as RouterLink, Navigate, useLocation } from 'react-router-dom'
import System from '../../../layout/system'
import api from '../../../api'
import Avatar from '@mui/material/Avatar'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import { Link, Grid, Alert } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { object, string, TypeOf } from 'zod'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import FormInput from '../../../components/form/FormInput'
import { toastError } from '../../../util/toastError'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const registerSchema = object({
  password: string().min(6, 'Необходимо придумать пароль, минимум 6 символов'),
})

type RegisterInput = TypeOf<typeof registerSchema>

function InvaiteConfirm() {
  const methods = useForm<RegisterInput>({
    resolver: zodResolver(registerSchema),
    defaultValues: {},
  })

  const { handleSubmit } = methods

  const [buttonActive, setButtonActive] = useState(true)
  const [success, setSuccess] = useState(false)
  const query = useQuery()
  const token = query.get('token')

  const onSubmitHandler: SubmitHandler<RegisterInput> = (values) => {
    setButtonActive(false)
    setSuccess(false)

    api
      .post('/v1/auth/invite/confirm', {
        ...values,
        token,
      })
      .then(() => {
        setSuccess(true)
        setButtonActive(true)
      })
      .catch(async (error) => {
        await toastError(error)
        setButtonActive(true)
      })
  }

  if (success) {
    return <Navigate to="/join/success" replace />
  }

  if (!token) {
    return <Navigate to="/" replace />
  }

  return (
    <System>
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5" sx={{ mb: 1 }}>
        Регистрация в системе
      </Typography>
      <FormProvider {...methods}>
        {!success && (
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(onSubmitHandler)}
            sx={{ mt: 3, width: '100%', maxWidth: '350px' }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormInput name={'password'} type={'password'} label={'Придумайте пароль'} />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={!buttonActive}
              data-testid="join-button"
            >
              Подтвердить
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to="/login" component={RouterLink} variant="body2">
                  Войти
                </Link>
              </Grid>
            </Grid>
          </Box>
        )}
      </FormProvider>
    </System>
  )
}

export default InvaiteConfirm
