import { Alert } from '@mui/material'
import { ReactNode } from 'react'

function AlertError({
  message,
  children,
}: {
  message?: string | null
  children?: ReactNode
}): JSX.Element {
  return message || children ? (
    <Alert severity="error" sx={{ mb: 2 }}>
      {message || children}
    </Alert>
  ) : (
    <>{null}</>
  )
}

export default AlertError
