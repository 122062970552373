import * as React from 'react'
import Toolbar from '@mui/material/Toolbar'
import CssBaseline from '@mui/material/CssBaseline'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import LeftSidebar from './LeftSidebar'
import MainAppBar from './MainAppBar'
import { Outlet, useMatches, Link as RouterLink } from 'react-router-dom'
import { AlertVerified } from '../../components/profile/AlertVerified'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useAppDispatch } from '../../store'
import { userApi } from '../../store/user'
import { Breadcrumbs, Chip, IconButton, Link, Paper, useMediaQuery } from '@mui/material'
import { Home } from '@mui/icons-material'
import { useGetAuthQuery } from '../../store/auth'
import { AuthManagerContext } from '../../auth'
import { useMemo } from 'react'
import { AuthManager } from '../../auth/authManager'
import { ConfirmProvider } from 'material-ui-confirm'
import * as Sentry from "@sentry/react";

const BC = () => {
  const matches = useMatches()
  const isMobile = useMediaQuery('(max-width:600px)')
  const crumbs = matches
    .filter((match) => Boolean((match.handle as any)?.crumb))
    .map((match) => {
      const handle = match.handle as any
      return { name: handle.crumb, path: match.pathname + (handle.path ? `/${handle.path}` : '') }
    })

  return (
    <>
      {!isMobile && crumbs.length > 0 && (
        <Paper square elevation={0} sx={{ pt: 3, pb: 2 }}>
          <Container maxWidth={false}>
            <Breadcrumbs sx={{ overflow: 'auto', whiteSpace: 'nowrap' }} separator="›">
              <IconButton size="small" component={RouterLink} to={'/'}>
                <Home fontSize="small" />
              </IconButton>
              {crumbs.map((crumb, index) => (
                <Chip
                  sx={{ cursor: 'pointer' }}
                  key={index}
                  size={'small'}
                  component={RouterLink}
                  to={crumb.path}
                  label={crumb.name}
                />
              ))}
            </Breadcrumbs>
          </Container>
        </Paper>
      )}
    </>
  )
}

export default function Layout(): JSX.Element {
  const [drawerOpen, setDrawerOpen] = React.useState(false)

  const dispatch = useAppDispatch()
  dispatch(userApi.endpoints.getUser.initiate())

  const { data: authContext } = useGetAuthQuery()

  const auth = useMemo(() => {
    return new AuthManager(authContext)
  }, [authContext])

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }
    setDrawerOpen(open)
  }

  if (!authContext) {
    return <></>
  }

  return (
    <>
      <CssBaseline />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="colored"
      />
      <AuthManagerContext.Provider value={auth}>
        <Box sx={{ backgroundColor: 'background.bbg', minHeight: '100vh' }}>
          <MainAppBar toggleDrawer={toggleDrawer} />
          <Toolbar />
          <LeftSidebar toggleDrawer={toggleDrawer} open={drawerOpen} />
          <React.Suspense fallback={<div>Loading...</div>}>
            <AlertVerified />
            <BC />
            <ConfirmProvider
              defaultOptions={{
                title: 'Подтвердите действие',
                confirmationText: 'Да',
                cancellationText: 'Нет',
                confirmationButtonProps: {
                  variant: 'contained',
                },
                buttonOrder: ['confirm', 'cancel'],
              }}
            >
              <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
                <Outlet />
              </Sentry.ErrorBoundary>
            </ConfirmProvider>
          </React.Suspense>
        </Box>
      </AuthManagerContext.Provider>
    </>
  )
}
