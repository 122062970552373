import { object, string, TypeOf } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { FormInputDateTime } from '../../../form'

const schema = {
  from: string().min(1, 'Необходимо заполнить'),
  to: string().min(1, 'Необходимо заполнить'),
}
const registerSchema = object(schema)

interface Props {
  values?: any
  onSubmit: (values: TypeOf<typeof registerSchema>, methods: any) => void
  id?: string
}

export const AddControlEventForm = ({ values, onSubmit, id }: Props) => {
  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: values || {},
  })

  const { handleSubmit } = methods

  const submitHandler = (values: any) => {
    onSubmit(values, methods)
  }

  return (
    <FormProvider {...methods}>
      <Box
        id={id}
        component="form"
        onSubmit={handleSubmit(submitHandler)}
        noValidate
        autoComplete="off"
      >
        <Typography variant={'subtitle1'}>Сроки проведения контрольного мероприятия</Typography>
        <Stack direction="row" spacing={1}>
          <FormInputDateTime label="с" name="from" />
          <FormInputDateTime label="по" name="to" />
        </Stack>
      </Box>
    </FormProvider>
  )
}
