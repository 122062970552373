import { object, string, TypeOf, z } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { useMemo } from 'react'
import { Box, Divider } from '@mui/material'
import { useGetCompaniesQuery } from '../../../../store/companies'
import { employeesList } from '../../../../util/employees'
import FormSelect from '../../../form/FormSelect'
import FormInputDate from '../../../form/FormInputDate'
import { IDocument } from '../../../../types/document'
import { useGetEmployeesQuery } from '../../../../store/employees'
import { FormInput } from '../../../form'

const schema = {
  data: object({
    defectsFixingFactDateInfo: string().nullish(),
    defectControlDenialReason: string().nullish(),
  }),
  signerId: string().min(1, 'Необходимо заполнить'),
}

const registerSchema = object(schema)

interface Props {
  item: IDocument
  values?: any
  onSubmit: (values: TypeOf<typeof registerSchema>, methods: any) => void
  id?: string
}

export const Part7ItemForm = ({ item, onSubmit, id }: Props) => {
  const buildingId = item.building_id

  const { data: employees } = useGetEmployeesQuery(buildingId)
  const { data: companies } = useGetCompaniesQuery(buildingId)

  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: {
      signerId: item.employees.signer[0].employee_id || undefined,
      data: item.data,
    },
  })

  const {
    handleSubmit,
    formState: { errors },
  } = methods

  const submitHandler = (values: any) => {
    onSubmit(values, methods)
  }

  const signerItems =
    companies && employees
      ? employeesList(companies, employees, ['gsn'], ['auth_supervisory_control'])
      : []

  return (
    <FormProvider {...methods}>
      <Box
        id={id}
        component="form"
        onSubmit={handleSubmit(submitHandler)}
        noValidate
        autoComplete="off"
      >
        <FormInputDate
          name="data.defectsFixingFactDateInfo"
          label="Фактическая дата устранения выявленных нарушений"
        />
        <FormInput
          name="data.defectControlDenialReason"
          label="Причина снятия нарушения с контроля"
        />
        <FormSelect
          name="signerId"
          label="Должностное лицо государственного строительного надзора"
          items={signerItems}
        />
      </Box>
    </FormProvider>
  )
}
