import { useParams } from 'react-router-dom'
import { Add, Edit } from '@mui/icons-material'
import { FormDialog } from '../../../common/FormDialog'
import * as React from 'react'
import { useState } from 'react'
import { TBuilding } from '../../../../types/building'
import { AddSarForm } from './AddSarForm'
import dayjs from 'dayjs'
import { LightButton } from '../../../common/LightButton'
import { documentType, IDocument } from '../../../../types/document'
import { useAddDocumentMutation, useEditDocumentMutation } from '../../../../store/document'
import { toastError } from '../../../../util/toastError'

interface IProps {
  building: TBuilding
  sar?: IDocument
}

export const AddSarDialog = ({ building, sar }: IProps) => {
  const id = 'add-sar-dialog'
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { journalId } = useParams()
  const [addSar] = useAddDocumentMutation()
  const [editSar] = useEditDocumentMutation()

  const isChange = sar !== undefined

  const values = isChange
    ? {
        number: sar?.number,
        date: sar?.date,
        signer_id: sar?.employees.signer[0].employee_id,
      }
    : { date: dayjs().format('YYYY-MM-DD HH:mm:ss') }

  const handleSubmit = (values: any, methods: any) => {
    setIsLoading(true)

    const apiMethod = isChange ? editSar : addSar

    const body = {
      type: documentType.SupervisoryAuthorityRegistry,
      employees: [{ id: values.signer_id, type: 'signer' }],
      ...values,
    }

    if (isChange) {
      body.document_id = sar.id
    } else {
      body.parent_id = journalId
    }

    apiMethod({ buildingId: building.id, body: body })
      .unwrap()
      .then((res) => {
        methods.reset()
        setOpen(false)
      })
      .catch(toastError)
      .finally(() => setIsLoading(false))
  }

  return (
    <>
      <LightButton startIcon={isChange ? null : <Add />} onClick={() => setOpen(true)}>
        {isChange ? 'Изменить' : 'Внести'} надпись
      </LightButton>
      <FormDialog
        formId={id}
        open={open}
        onClose={() => setOpen(false)}
        title={
          isChange ? 'Редактировать регистрационную надпись' : 'Добавить регистрационную надпись'
        }
        button={'Отправить'}
        isLoading={isLoading}
      >
        <AddSarForm id={id} onSubmit={handleSubmit} building={building} values={values} />
      </FormDialog>
    </>
  )
}
