import { documentType } from '../../../../types/document'
import { Card, CardContent, CardHeader, Skeleton, Stack, Typography } from '@mui/material'
import * as React from 'react'
import { DataRow } from '../../../common/DataRow'
import { CommonInfoDialog } from './CommonInfoDialog'
import { formatDate } from '../../../../util/date'
import { TBuilding } from '../../../../types/building'
import { useParams } from 'react-router-dom'
import { useGetDocumentsQuery } from '../../../../store/document'
import { useMemo } from 'react'
import { SignDocumentButton } from '../../../document/SignDocumentButton'
import { useAuthorization } from '../../../../auth'

type Props = {
  building: TBuilding
}

const CommonInfo = ({ building }: Props) => {
  const { journalId } = useParams()

  const authManager = useAuthorization()
  const isManage = authManager.vote('Gwj', 'manage')

  const { data: journals, refetch } = useGetDocumentsQuery({
    buildingId: building.id,
    params: { type: documentType.GeneralWorkJournal },
  })

  const document = useMemo(() => {
    if (!journals) return null
    return journals.items.find((journal) => journal.id === journalId) || null
  }, [journals])

  if (!document) {
    return <Skeleton variant="rounded" height={160} />
  }

  return (
    <Card>
      <CardHeader title="Общие сведения" />
      <CardContent>
        <DataRow label={'Номер журнала'} text={document.number} />
        <Typography variant={'body1'} sx={{ fontWeight: 'meduim' }}>
          Сведения о временном периоде строительства, содержащемся в журнале:
        </Typography>
        <DataRow
          label={'Дата начала'}
          text={
            document?.data?.worksPeriod?.beginDate
              ? formatDate(document.data.worksPeriod.beginDate)
              : '-'
          }
        />
        <DataRow
          label={'Дата окончания'}
          text={
            document.data?.worksPeriod?.endDate ? formatDate(document.data.worksPeriod.endDate) : '-'
          }
        />
        <DataRow label={'Объём журнала'} text={document.data && document.data.journalVolume && document.data.journalVolume || ''} />
      </CardContent>
      <CardContent>
        <Stack spacing={1} direction={'row'}>
          {isManage && <CommonInfoDialog document={document} />}
          <SignDocumentButton document={document} onReady={refetch} onSign={refetch} />
        </Stack>
      </CardContent>
    </Card>
  )
}

export { CommonInfo }
