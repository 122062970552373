import { toast } from 'react-toastify'
import { useEditBuildingMutation } from '../../../store/buildings'
import React, { useState } from 'react'
import { TBuilding } from '../../../types/building'
import { toastError } from '../../../util/toastError'
import { FormDialog } from '../../common/FormDialog'
import { BuildingForm } from './BuildingForm'

interface Props {
  building: TBuilding
  open: boolean
  onClose: () => void
}

export const EditBuildingDialog = ({ building, open, onClose }: Props) => {
  const id = 'edit-building-form'
  const [editBuilding] = useEditBuildingMutation()
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = (values: any, methods: any) => {
    setIsLoading(true)
    editBuilding({ buildingId: building.id, body: values })
      .unwrap()
      .then((res: any) => {
        toast.success('Объект успешно сохранен')
        onClose()
        methods.reset()
      })
      .catch(toastError)
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <FormDialog
      formId={id}
      open={open}
      onClose={onClose}
      title={'Редактировать объект'}
      button={'Отправить'}
      isLoading={isLoading}
    >
      <BuildingForm
        onSubmit={handleSubmit}
        values={{ address: building.postal_address?.string_address, ...building }}
        id={id}
      />
    </FormDialog>
  )
}
