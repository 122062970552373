import { buildingDocumentTypes, TBuilding, TBuildingDocumentType } from '../../types/building'
import {
  Card,
  CardHeader,
  Skeleton,
  TableBody,
  Table,
  TableContainer,
  TableCell,
  Typography,
  TableRow,
  IconButton,
} from '@mui/material'
import React, { useMemo } from 'react'
import {
  useGetBuildingDocumentsQuery,
  useRemoveBuildingDocumentMutation,
} from '../../store/buildingDocuments'
import ExtDocLine from '../ExternalDocuments/ExtDocLine'
import { useGetExternalDocumentsQuery } from '../../store/externalDocuments'
import { TExternalDocument } from '../../types/external-document'
import BuildingDocumentForm from './BuildingDocumentForm'
import { Delete } from '@mui/icons-material'
import { toastError } from '../../util/toastError'

type Props = {
  type: TBuildingDocumentType
  building: TBuilding
  isManage: boolean
}
const BuildingDocuments = ({ building, isManage, type }: Props) => {
  const { data: documents, isLoading } = useGetBuildingDocumentsQuery(building.id)
  const { data: extDocuments, isLoading: isExtLoading } = useGetExternalDocumentsQuery({
    buildingId: building.id,
  })
  const [removeDocument] = useRemoveBuildingDocumentMutation()

  const typeDocs = useMemo(() => {
    if (!documents) return []
    return documents.filter((document) => document.type === type)
  }, [type, documents])

  const extDocs: Record<string, TExternalDocument> = useMemo(() => {
    if (!documents || !extDocuments) return {}
    return documents.reduce((acc, doc) => {
      if (doc.type === type) {
        acc = {
          ...acc,
          [doc.document_id]: extDocuments.items.find((d) => d.id === doc.document_id),
        }
      }
      return acc
    }, {})
  }, [type, documents, extDocuments])

  const removeDocumentHandler = (id: string) => {
    removeDocument({
      buildingId: building.id,
      body: {
        document_id: id,
      },
    })
      .unwrap()
      .catch(toastError)
  }

  if (isLoading || isExtLoading) {
    return <Skeleton variant="rounded" height={100} />
  }

  return (
    <TableContainer component={Card}>
      <CardHeader
        titleTypographyProps={{ variant: 'body1' }}
        subheaderTypographyProps={{ variant: 'caption' }}
        action={isManage && <BuildingDocumentForm building={building} type={type} />}
        title={buildingDocumentTypes[type]}
        subheader={
          type === 'pd_examination' &&
          'в случаях, предусмотренных статьей 49 Градостроительного кодекса Российской Федерации'
        }
      />
      <Table>
        <TableBody>
          {typeDocs &&
            extDocs &&
            typeDocs.map((doc) => (
              <TableRow key={doc.id}>
                <TableCell>
                  {extDocs[doc.document_id] && <ExtDocLine document={extDocs[doc.document_id]} />}
                  <Typography variant={'subtitle2'}>{doc.organization}</Typography>
                </TableCell>
                <TableCell align={'right'}>
                  {isManage && (
                    <IconButton
                      color="error"
                      title="Удалить"
                      onClick={() => removeDocumentHandler(doc.id)}
                    >
                      <Delete />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export { BuildingDocuments }
