import { TBuilding } from '../../../types/building'
import { object, string, TypeOf, union } from 'zod'
import React, { useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import {
  Alert,
  Box,
  Button,
  debounce,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import FormInput from '../../form/FormInput'
import { Add } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { useApi } from '../../../api/useApi'
import { useInviteMutation } from '../../../store/user'
import { useAttachBuildingUserMutation, useGetProfilesQuery } from '../../../store/buildings'
import { toast } from 'react-toastify'
import { toastError } from '../../../util/toastError'

const schema1 = {
  email: string().email({ message: 'Некорректный адрес' }).min(1, 'Необходимо заполнить'),
  user_id: string().min(1),
}
const schema2 = object({
  email: string().email({ message: 'Некорректный адрес' }).min(1, 'Необходимо заполнить'),
  user_id: string(),
  first_name: string(),
  last_name: string(),
  middle_name: string(),
})
  .refine((data) => data.user_id || data.first_name, {
    message: 'Необходимо заполнить Имя',
    path: ['first_name'],
  })
  .refine((data) => data.user_id || data.last_name, {
    message: 'Необходимо заполнить Фамилию',
    path: ['last_name'],
  })

export const AddBuildingUserDialog = ({ building }: { building: TBuilding }) => {
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isUserLoading, setIsUserLoading] = useState(false)
  const [user, setUser] = useState<any | null>(null)
  const api = useApi()

  const registerSchema = schema2

  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: { user_id: '' },
  })

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    reset,
  } = methods

  const searchUser = useCallback(
    debounce((val: string | undefined) => {
      if (!isUserLoading && val && val?.length > 6) {
        setIsUserLoading(true)
        setUser(null)
        setValue('user_id', '')

        api
          .post('/v1/auth/by-email', {
            email: val,
          })
          .then((res) => {
            setUser(res)
            if (res && res.id) {
              setValue('user_id', res.id)
            }
          })
          .catch(toastError)
          .finally(() => {
            setIsUserLoading(false)
          })
      }
    }, 500),
    []
  )

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'email') {
        setUser(null)
        searchUser(value[name])
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])

  const [invite] = useInviteMutation()
  const [attach] = useAttachBuildingUserMutation()

  const sendData = useCallback((values: any) => {
    setIsLoading(true)
    attach({ buildingId: building.id, body: values })
      .unwrap()
      .then((res) => {
        setIsLoading(false)
        toast.success('Готово!')
        setOpen(false)
        setUser(null)
        reset()
      })
      .catch(toastError)
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const onSubmitHandler = (values: any) => {
    setIsLoading(true)
    if (user && user.status === 'active') {
      sendData(values)
    } else {
      invite(values.email)
        .unwrap()
        .then((res) => {
          sendData({ ...values, user_id: res.id })
        })
        .catch(toastError)
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  return (
    <>
      <Button startIcon={<Add />} onClick={() => setOpen(true)}>
        Пригласить
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth={'lg'} fullWidth>
        <DialogTitle>{'Добавить пользователя'}</DialogTitle>
        <DialogContent>
          <FormProvider {...methods}>
            <Box
              component="form"
              id={'add-building-user-form'}
              onSubmit={handleSubmit(onSubmitHandler)}
              noValidate
              autoComplete="off"
            >
              <Box sx={{ pt: 1 }}>
                <FormInput name="email" label="Адрес электронной почты" />
                {user && <input type="hidden" name="user_id" />}
                {!user && (
                  <>
                    <FormInput name={'last_name'} label={'Фамилия'} />
                    <FormInput name={'first_name'} label={'Имя'} />
                    <FormInput name={'middle_name'} label={'Отчество'} />
                  </>
                )}
                {user?.profile && <Alert severity="success">{user.profile}</Alert>}
              </Box>
            </Box>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant={'contained'}
            type="submit"
            form={'add-building-user-form'}
            loading={isLoading}
            color={'success'}
          >
            {'Добавить'}
          </LoadingButton>

          <Button onClick={() => setOpen(false)}>Закрыть</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
