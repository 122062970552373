import { TWork, TWorkGroup } from '../../types/works'
import { TBuilding } from '../../types/building'
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material'
import { DragHandle, ExpandLess, ExpandMore, MoreVert } from '@mui/icons-material'
import React, { ReactNode, useEffect, useState } from 'react'
import { WorkItem } from './WorkItem'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { useDeleteGroupMutation, useSortWorksMutation } from '../../store/works'
import { MenuItemDelete, MenuItemEdit, MoreVertMenu } from '../menu'
import ParseError from '../../api/parseError'
import { toast } from 'react-toastify'

type TProps = {
  group: TWorkGroup
  groupIndex: number
  building: TBuilding
  works: Record<string, TWork>
  sortKey: string
  onWorkClick: (work: TWork) => void
  onEditGroup: (group: TWorkGroup) => void
  onEditWork: (work: TWork) => void
  isManage: boolean
}

export const GroupItem = (props: TProps) => {
  const {
    group,
    groupIndex,
    building,
    sortKey,
    works,
    onWorkClick,
    onEditGroup,
    onEditWork,
    isManage,
  } = props

  const [open, setOpen] = React.useState(false)
  const [items, setItems] = useState<string[]>([])
  const [sortWorks] = useSortWorksMutation()

  useEffect(() => {
    if (works) {
      const items = Object.values(works).reduce((arr, work) => {
        if (work.group_id === group.id) {
          return [...arr, work.id]
        }
        return arr
      }, [] as string[])

      items.sort((a, b) => works[a].pos - works[b].pos)

      setItems(items)
    }
  }, [works, group])

  const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef } =
    useSortable({ id: sortKey })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  const handleClick = () => {
    setOpen(!open)
  }

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const newItems = (items: string[], active: string, over: string) => {
    const oldIndex = items.indexOf(active)
    const newIndex = items.indexOf(over)
    return arrayMove(items, oldIndex, newIndex)
  }

  function handleDragEnd(event: any) {
    const { active, over } = event
    if (over && active.id !== over.id) {
      const sortedItems = newItems(items, active.id, over.id)
      setItems(sortedItems)
      sortWorks({ buildingId: building.id, body: { works: sortedItems } })
    }
  }

  const [menuOpen, setMenuOpen] = useState(false)
  const handleEdit = () => {
    setMenuOpen(false)
    onEditGroup(group)
  }

  const [deleteGroup] = useDeleteGroupMutation()

  const handleDelete = () => {
    setMenuOpen(false)
    deleteGroup({ buildingId: group.building_id, body: { group_id: group.id } })
      .unwrap()
      .catch(async (e) => {
        const error = await ParseError(e)
        toast.error(error)
      })
  }

  return (
    <div style={style} {...attributes} ref={setNodeRef}>
      <List component="div" disablePadding>
        <ListItem
          secondaryAction={
            isManage && (
              <MoreVertMenu
                open={menuOpen}
                onOpen={() => setMenuOpen(true)}
                onClose={() => setMenuOpen(false)}
              >
                <MenuItemEdit onClick={handleEdit}>Редактировать</MenuItemEdit>
                <MenuItemDelete onClick={handleDelete}>Удалить</MenuItemDelete>
              </MoreVertMenu>
            )
          }
          component="div"
          sx={{ ':hover': { backgroundColor: 'action.hover' } }}
        >
          <ListItemIcon
            ref={setActivatorNodeRef}
            {...listeners}
            sx={{ cursor: 'move', display: isManage ? 'inline-flex' : 'none' }}
          >
            <DragHandle />
          </ListItemIcon>
          <ListItemIcon onClick={handleClick}>
            {items.length > 0 && <IconButton>{open ? <ExpandLess /> : <ExpandMore />}</IconButton>}
          </ListItemIcon>
          <ListItemText>
            <Stack direction={'row'}>
              <Typography variant={'body1'} sx={{ pr: 1 }}>
                {groupIndex + 1}.
              </Typography>
              <Box>
                <Link href={'#' + group.id} onClick={handleClick} underline={'hover'}>
                  <Typography variant="body1" component="div" sx={{ fontWeight: 'bold' }}>
                    {group.name}
                  </Typography>
                </Link>
                <Typography variant="body2" color="text.disabled">
                  {group.description}
                </Typography>
              </Box>
            </Stack>
          </ListItemText>
        </ListItem>
      </List>
      <Divider />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext items={items} strategy={verticalListSortingStrategy}>
            {items.map(
              (item, index) =>
                works[item] && (
                  <WorkItem
                    isManage={isManage}
                    work={works[item]}
                    groupIndex={groupIndex}
                    workIndex={index}
                    building={building}
                    key={works[item].id}
                    sortKey={item}
                    onClick={onWorkClick}
                    onEditWork={onEditWork}
                  />
                )
            )}
          </SortableContext>
        </DndContext>
      </Collapse>
    </div>
  )
}
