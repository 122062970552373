import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { addAuthorization } from '../../util/http/addAuthorization'
import { TAuth, TPolicyItem, TPolicyItemWithId } from '../../types/auth'

export const authApi = createApi({
  reducerPath: 'auth',
  tagTypes: ['Policy'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1/',
    prepareHeaders: addAuthorization,
    validateStatus: (response) => response.ok,
  }),
  endpoints: (builder) => ({
    getBuildingAuth: builder.query<TAuth, string>({
      query: (buildingId) => ({
        url: `/buildings/${buildingId}/policy`,
        method: 'GET',
      }),
    }),
    getAuth: builder.query<TAuth, void>({
      query: () => ({
        url: `/policy`,
        method: 'GET',
      }),
    }),
    getSubjectPolicy: builder.query<TPolicyItemWithId[], any>({
      query: ({buildingId, role}) => ({
        url: `/buildings/${buildingId}/policy/subject`,
        method: 'GET',
        params: {
          role
        }
      }),
      providesTags: (result, error, arg) => [{ type: 'Policy' as const, id: arg.role }],
    }),
    addPolicy: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/policy/add`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Policy' as const, id: arg.body.role }],
    }),
    removePolicy: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/policy/remove`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Policy' as const, id: arg.body.role }],
    }),


  }),
})

export const {
  useGetAuthQuery,
  useGetBuildingAuthQuery,
  useAddPolicyMutation,
  useGetSubjectPolicyQuery,
  useRemovePolicyMutation
} = authApi
