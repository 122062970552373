import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { employeeRoles, TBuilding, TCompany, TEmployee } from '../../../types/building'
import { ColoredAvatar } from '../../common/ColoredAvatar'
import { Delete } from '@mui/icons-material'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import { useGetCompaniesQuery } from '../../../store/companies'
import { useGetEmployeesQuery } from '../../../store/employees'
import { useAuthorization } from '../../../auth'
import { useGetDocumentsQuery, useRemoveDocumentMutation } from '../../../store/document'
import { documentType, IDocument } from '../../../types/document'
import { toastError } from '../../../util/toastError'
import { AddRepresentativeDialog } from './AddRepresentativeDialog'
import { SignDocumentButton } from '../../document/SignDocumentButton'

const repCompany = (companies: TCompany[], employee: TEmployee) => {
  return companies.find((c) => c.id === employee.company_id)?.name
}

type Props = {
  building: TBuilding
}

const representativeEmployee = (
  representative: IDocument,
  employees: TEmployee[]
): TEmployee | undefined => {
  return employees.find((e) => e.id === representative.employees.employee[0].employee_id)
}

const Representatives = ({ building }: Props) => {
  const authManager = useAuthorization()

  const { journalId } = useParams()
  const { data: representatives, isLoading, refetch } = useGetDocumentsQuery({
    buildingId: building.id,
    params: {
      type: documentType.IMCRepresentative,
      parent_id: journalId,
    },
  })

  const { data: companies } = useGetCompaniesQuery(building.id)
  const { data: employees } = useGetEmployeesQuery(building.id)

  const [removeRepresentative] = useRemoveDocumentMutation()

  const isManage = authManager.vote('Imc', 'manage')

  if (
    isLoading ||
    representatives === undefined ||
    companies === undefined ||
    employees === undefined
  ) {
    return <>Loading...</>
  }

  const removeRepresentativeHandler = (id: string) => {
    removeRepresentative({
      buildingId: building.id,
      body: {
        document_id: id,
        type: documentType.IMCRepresentative,
      },
    })
      .unwrap()
      .catch(toastError)
  }

  return (
    <Box flexGrow={1}>
      <Card>
        <CardHeader
          title="Представители"
          subheader={'Представители выполняющие входной контроль материалов'}
        />
        <Table>
          <TableBody>
            {representatives.items.map((representative) => {
              const employee = representativeEmployee(representative, employees)
              return employee ? (
                <TableRow key={employee.id}>
                  <TableCell>
                    <Stack direction="row">
                      <Box sx={{ pr: 1 }}>
                        <ColoredAvatar text={employee.profile.name.full_name} />
                      </Box>
                      <Box sx={{ flexGrow: 1 }}>
                        <Typography sx={{ fontWeight: 500 }}>
                          {employee.profile.name.full_name}
                        </Typography>
                        <Typography variant="caption" sx={{ lineHeight: 1.5 }}>
                          {repCompany(companies, employee)}
                        </Typography>
                        {employee.position && (
                          <Typography variant="caption" sx={{ lineHeight: 1.5 }}>
                            {' '}
                            ({employee.position})
                          </Typography>
                        )}
                        <Typography variant="subtitle2" color="text.disabled">
                          {employeeRoles[employee.role]}
                        </Typography>
                        <Box sx={{ mt: 1 }}>
                          <SignDocumentButton
                            document={representative}
                            onReady={refetch}
                            onSign={refetch}
                            size={'small'}
                          />
                        </Box>
                      </Box>
                    </Stack>

                  </TableCell>
                  {isManage && (
                    <TableCell align="right">
                      <IconButton
                        color="error"
                        title="Удалить"
                        onClick={() => removeRepresentativeHandler(representative.id)}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ) : (
                <></>
              )
            })}
          </TableBody>
        </Table>
        <CardContent>
          <AddRepresentativeDialog building={building} />
        </CardContent>
      </Card>
    </Box>
  )
}

export { Representatives }
