import { useState } from 'react'
import { useSignFileMutation } from '../../store/files/files'
import { getFile64 } from '../../util/file'
import { base64Signature } from '../../util/signature/signatureMethods'
import { toast } from 'react-toastify'
import { Button } from '@mui/material'
import CertListDialog from '../sign/CertListDialog'
import { TFile } from '../../types/file'
import { useGetProfileQuery } from '../../store/profile'

type Props = {
  file: TFile
  onSign?: () => void
  single?: boolean
  text?: string
}
export const FileSignButton = ({file, single = false, text = "Подписать", onSign}: Props) => {

  const [open, setOpen] = useState(false)

  const { data: profile } = useGetProfileQuery()
  const [signFile] = useSignFileMutation()

  const handleSign = async (cert: string) => {

    try {
      const base64 = await getFile64(file.id, file.building_id)
      const signature = await base64Signature(base64, cert)
      signFile({
        buildingId: file.building_id,
        body: {
          file_id: file.id,
          signature,
          single
        },
      })
        .unwrap()
        .then((res) => {
          toast.success('Документ подписан')
          setOpen(false)
          onSign && onSign()
        })
    } catch (e: any) {
      toast.error(e.message)
    }
  }

  if (!profile) return <></>

  return (
    <>
      <Button variant={'outlined'} color={'success'} onClick={() => setOpen(true)}>
        {text}
      </Button>
      <CertListDialog
        profile={profile}
        open={open}
        onClose={() => setOpen(false)}
        onSign={handleSign}
      />
    </>
  )
}
