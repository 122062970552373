import { useState } from 'react'
import { toast } from 'react-toastify'
import { Button } from '@mui/material'
import { Add } from '@mui/icons-material'
import { FormDialog } from '../../../common/FormDialog'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import { ItemForm } from './ItemForm'
import dayjs from 'dayjs'
import {
  useAddDocumentMutation,
  useAddEmployeeMutation,
  useEditDocumentMutation,
} from '../../../../store/document'
import { documentType } from '../../../../types/document'
import { LightButton } from '../../../common/LightButton'
import { toastError } from '../../../../util/toastError'
import { AddButton } from '../../../common/AddButton'

type Props = {
  buildingId: string
  documentId?: string
  values?: any
  onSave?: () => void
  nextNumber?: number
}

const TitleChangeItemDialog = ({ buildingId, documentId, values, onSave, nextNumber }: Props) => {
  const id = 'add-item-dialog'
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { journalId } = useParams()
  const isEdit = Boolean(values && documentId)

  const [addDocument] = useAddDocumentMutation()
  const [editDocument] = useEditDocumentMutation()
  const [addEmployee] = useAddEmployeeMutation()

  const handleAdd = (values: any, methods: any) => {
    const { date, number, signer_id, data } = values

    const body = {
      type: documentType.TitleChangesListItem,
      date,
      number,
      parent_id: journalId,
      data,
      employees: [{ id: signer_id, type: 'signer' }],
    }

    setIsLoading(true)

    addDocument({ buildingId: buildingId, body })
      .unwrap()
      .then(() => {
        methods.reset()
        setOpen(false)
        toast.success('Документ добавлен')
        onSave && onSave()
      })
      .catch(toastError)
      .finally(() => setIsLoading(false))
  }

  const handleEdit = (vals: any, methods: any) => {
    const { date, signer_id, data } = vals

    const body = {
      document_id: documentId,
      type: documentType.TitleChangesListItem,
      date,
      data,
      employees: [{ id: signer_id, type: 'signer' }],
    }

    setIsLoading(true)

    editDocument({ buildingId: buildingId, body })
      .unwrap()
      .then(() => {
        methods.reset()
        setOpen(false)
        toast.success('Документ изменен')
        onSave && onSave()
      })
      .catch(toastError)
      .finally(() => setIsLoading(false))
  }

  const handleSubmit = isEdit ? handleEdit : handleAdd

  return (
    <>
      {isEdit && <LightButton onClick={() => setOpen(true)}>Изменить</LightButton>}
      {!isEdit && (
        <AddButton onClick={() => setOpen(true)}>
          Добавить запись
        </AddButton>
      )}
      <FormDialog
        formId={id}
        open={open}
        onClose={() => setOpen(false)}
        title={isEdit ? 'Добавить запись' : 'Изменить'}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <ItemForm
          buildingId={buildingId}
          id={id}
          onSubmit={handleSubmit}
          values={
            values || { date: dayjs().format('YYYY-MM-DD HH:mm:ss'), number: nextNumber + '' }
          }
        />
      </FormDialog>
    </>
  )
}

export default TitleChangeItemDialog
