import { Box, Card, CardContent, CardHeader, MenuItem, Stack, Typography } from '@mui/material'
import { AddControlEventDialog } from './AddControlEventDialog'
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view'
import { documentType, IDocument } from '../../../../types/document'
import * as React from 'react'
import {
  useEditDocumentMutation,
  useGetDocumentsQuery,
  useRemoveDocumentMutation,
} from '../../../../store/document'
import { TBuilding } from '../../../../types/building'
import { useAuthorization } from '../../../../auth'
import { useGetUserQuery } from '../../../../store/user'
import { SyntheticEvent, useMemo, useState } from 'react'
import { formatPeriodDig } from '../../../../util/date'
import { MoreVertMenu } from '../../../menu'
import { toastError } from '../../../../util/toastError'
import { EditControlEventDialog } from './EditControlEventDialog'

type MenuProps = {
  event: IDocument
  onDelete: () => void
  onEdit: () => void
}

const TreeLabel = ({ event, onDelete, onEdit }: MenuProps) => {
  const [open, setOpen] = useState(false)

  const authManager = useAuthorization()
  const { data: user } = useGetUserQuery()
  const isManage =
    user && authManager.vote('Document.GWJ4ControlEvent', 'manage', event.owner_id === user.id)

  const handleDelete = (e: SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()
    onDelete()
    setOpen(false)
  }

  const handleEdit = (e: SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()
    onEdit()
    setOpen(false)
  }

  const onOpen = (e: SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setOpen(true)
  }
  const onClose = (e: SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setOpen(false)
  }

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      sx={{ mt: 0.5, mb: 0.5 }}
    >
      <Typography variant={'body2'}>{formatPeriodDig(event.data.from, event.data.to)}</Typography>
      {isManage && (
        <MoreVertMenu onClose={onClose} open={open} onOpen={onOpen}>
          <MenuItem onClick={handleEdit}>Редактировать</MenuItem>
          <MenuItem onClick={handleDelete}>Удалить</MenuItem>
        </MoreVertMenu>
      )}
    </Box>
  )
}

type Props = {
  building: TBuilding
  journalId: string
  onSelect: (id: string) => void
}

const Events = ({ building, journalId, onSelect }: Props) => {
  const {
    data: events,
    isLoading: isEventsLoading,
    isSuccess: isEventsSuccess,
  } = useGetDocumentsQuery({
    buildingId: building.id,
    params: {
      type: documentType.GWJ4ControlEvent,
      parent_id: journalId,
    },
  })

  const [editEvent, setEditEvent] = useState<IDocument>()
  const [editOpen, setEditOpen] = useState(false)

  const [deleteEvent] = useRemoveDocumentMutation()

  const authManager = useAuthorization()
  const isCreate = useMemo(() => authManager.vote('Document.GWJ4ControlEvent', 'create'), [])

  const handleEventSelect = (e: SyntheticEvent, nodeId: string, isSelected: boolean) => {
    if (isSelected) {
      onSelect(nodeId)
    }
  }

  const handleDelete = (id: string) => {
    deleteEvent({
      buildingId: building.id,
      body: {
        type: documentType.GWJ4ControlEvent,
        document_id: id,
      },
    })
      .unwrap()
      .catch(toastError)
  }

  const handleEdit = (id: string) => {
    const event = events ? events.items.find((e) => e.id === id) : undefined
    setEditEvent(event)
    setEditOpen(Boolean(event))
  }

  return (
    <>
      <Card>
        <CardHeader
          title={'Инспекции'}
          subheader={'контрольные мероприятия'}
          action={
            <Stack direction="row">
              {isCreate && <AddControlEventDialog building={building} journalId={journalId} />}
            </Stack>
          }
        />
        <CardContent>
          <SimpleTreeView onItemSelectionToggle={handleEventSelect}>
            {events &&
              events.items.map((event: IDocument) => (
                <TreeItem
                  key={event.id}
                  itemId={event.id}
                  label={
                    <TreeLabel
                      event={event}
                      onEdit={() => handleEdit(event.id)}
                      onDelete={() => handleDelete(event.id)}
                    />
                  }
                />
              ))}
          </SimpleTreeView>
        </CardContent>
      </Card>
      {editEvent && editOpen && (
        <EditControlEventDialog
          event={editEvent}
          onSave={() => setEditEvent(undefined)}
          open={editOpen}
          onClose={() => setEditOpen(false)}
        />
      )}
    </>
  )
}

export { Events }
