import {
  AppBar,
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
  Toolbar,
  IconButton,
  DialogContent, Card
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import React, { ReactElement, ReactNode } from 'react'
import Box from '@mui/material/Box'
import CloseIcon from '@mui/icons-material/Close';
import useScrollTrigger from '@mui/material/useScrollTrigger'
import Slide from '@mui/material/Slide'


const HideOnScroll = ({ children }: { children: ReactElement }) => {
  const trigger = useScrollTrigger()
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

interface Props {
  formId: string
  open: boolean
  title?: string
  button?: string | boolean
  onClose: () => void
  isLoading?: boolean
  buttons?: ReactNode
  maxWidth?: false | Breakpoint
  fullWidth?: boolean
  children: any
}

const FormFullscreenDialog = ({
  formId,
  open,
  onClose,
  title,
  button = 'Сохранить',
  isLoading = false,
  buttons,
  children,
}: Props) => {
  return (
    <Dialog open={open} onClose={onClose} scroll={'body'} fullScreen>
      <Box sx={{backgroundColor: 'background.default'}} minHeight={'100vh'}>
        <AppBar sx={{ top: 0 }} position="sticky">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {title}
            </Typography>
            {button && (
              <LoadingButton
                variant="outlined"
                type="submit"
                form={formId}
                loading={isLoading}
                color={'inherit'}
              >
                {button}
              </LoadingButton>
            )}
            <Button autoFocus color="inherit" onClick={onClose}>
              Закрыть
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          {children}
        </DialogContent>
        <DialogActions>
          {button && (
            <LoadingButton
              variant="outlined"
              type="submit"
              form={formId}
              loading={isLoading}
              color={'success'}
            >
              {button}
            </LoadingButton>
          )}
          {buttons}
          <Button onClick={onClose}>Закрыть</Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export { FormFullscreenDialog }
