import { IDocument } from '../types/document'

export const nextNumber = (documents: IDocument[], getNumber?: (d: IDocument) => number) => {
  let max = 0
  documents.forEach((d) => {
    const docNumber = getNumber ? getNumber(d) : parseInt(d.number || '0')
    max = Math.max(max, docNumber)
  })
  return max + 1
}
