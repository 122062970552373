import { TConstructionSiteAddress, TPostalAddress } from './address'
import { TExternalDocument } from './external-document'
import { TProfile } from './Profile'
import { string } from 'zod'

export type TLocation = {
  latitude: number
  longitude: number
}

type TSro = {
  name: string
  inn: string
  ogrn: string
}

export type TBuildingUser = {
  id: string
  user_id: string
  role: 'admin' | 'reader'
  profile: TProfile
}

export const employeeRoles = {
  auth_representative: 'Уполномоченный представитель',
  auth_representative_control: 'Представитель по вопросам строительного контроля',
  auth_representative_doc_control: 'Представитель по вопросам авторского надзора',
  auth_itp: 'Производитель работ, ИТП',
  auth_supervisory: 'Представитель Госстройнадзора (регистрация журнала)',
  auth_supervisory_control:
    'Должностное лицо органа государственного строительного надзора (инспектор)',
}
export type TEmployeeRole = keyof typeof employeeRoles

type TEmployeeExtDoc = {
  id: string
  document: TExternalDocument
}

export type TEmployee = {
  position: string
  id: string
  user_id: string
  company_id: string
  role: TEmployeeRole
  status: 'active' | 'detached'
  administrative_document?: TEmployeeExtDoc
  profile: TProfile
  works: string[] | null
}

export const companyRoles = {
  developer: 'Застройщик',
  operating_person: 'Лицо, ответственное за эксплуатацию здания/сооружения',
  regional_operator: 'Региональный оператор',
  developer_control:
    'Лицо, осуществляющее строительный контроль застройщика/регоператора/экспл.орг',
  customer: 'Технический заказчик',
  customer_control: 'Лицо, осуществляющее строительный контроль технического заказчика',
  building_contractor: 'Лицо осуществляющее строительство',
  designer: 'Лицо осуществляющее подготовку проектной документации/Авторский надзор',
  contractor: 'Подрядная организация',
  supplier: 'Поставщик',
  gsn: 'Государственный строительный надзор',
}

export type TCompanyRole = keyof typeof companyRoles

type TCeRoles = Record<TCompanyRole, TEmployeeRole[]>

export const ceRoles: TCeRoles = {
  developer: ['auth_representative', 'auth_representative_control'],
  operating_person: ['auth_representative', 'auth_representative_control'],
  regional_operator: ['auth_representative', 'auth_representative_control'],
  customer: ['auth_representative', 'auth_representative_control'],
  designer: ['auth_representative_doc_control'],
  building_contractor: ['auth_representative', 'auth_representative_control', 'auth_itp'],
  contractor: ['auth_representative', 'auth_representative_control', 'auth_itp'],
  supplier: [],
  gsn: ['auth_supervisory', 'auth_supervisory_control'],
  developer_control: ['auth_representative_control'],
  customer_control: ['auth_representative_control'],
}

export type TCompanyType = 'legal' | 'individual_entrepreneur'

export type TCompanyFilter = {
  role: TCompanyRole[]
}

export interface IIndividualName {
  full_name: string
  short_name: string
  full_i_o_f: string
  last_name: string
  first_name: string
  middle_name: string
}

export interface IM4d {
  id: string
  number: string
  date: string
  date_begin: string
  date_end: string
  inn: string[]
}

export interface ICompanyBase {
  id: string
  building_id: string
  status: string
  type: TCompanyType
  role: TCompanyRole
  name: string
  inn: string
  ogrn: string
  address: TPostalAddress
  phone: string
  email: string
  sro?: TSro
  works: string[] | null
  m4d: IM4d[]
  prd: string[] | null
}

export interface TCompanyLegal extends ICompanyBase {
  full_name: string
}

export interface TCompanyIndividualEntrepreneur extends ICompanyBase {
  individual_name: IIndividualName
}

export interface TCompany extends TCompanyLegal, TCompanyIndividualEntrepreneur {}

export type TBuilding = {
  id: string
  owner_id: string
  name: string
  status: string
  type: string
  created_at: string
  date_begin: string
  date_end: string | null
  specifications: string
  location: TLocation | null
  postal_address: TPostalAddress | null
  construction_site_address: TConstructionSiteAddress | null
  users: any[]
  companies: TCompany[]
  employees: TEmployee[]
}

export type TBuildingDocumentType = 'permission' | 'pd_examination'

export type TBuildingDocument = {
  id: string
  document_id: string
  type: TBuildingDocumentType
  organization: string
}

export const buildingDocumentTypes: Record<TBuildingDocumentType, string> = {
  permission: 'Разрешения на строительство',
  pd_examination: 'Сведения о государственной экспертизе проектной документации',
}
