import { Add } from '@mui/icons-material'
import { FormDialog } from '../../../common/FormDialog'
import * as React from 'react'
import { useState } from 'react'
import { TBuilding } from '../../../../types/building'
import { AddControlEventForm } from './AddControlEventForm'
import { useAddDocumentMutation } from '../../../../store/document'
import { documentType } from '../../../../types/document'
import { LightButton } from '../../../common/LightButton'
import { toastError } from '../../../../util/toastError'
import { AddButton } from '../../../common/AddButton'
import { IconButton } from '@mui/material'

interface IProps {
  building: TBuilding
  journalId: string | undefined
}

export const AddControlEventDialog = ({ building, journalId }: IProps) => {
  const id = 'add-control-event-form'
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [addDocument] = useAddDocumentMutation()

  const handleSubmit = (values: any, methods: any) => {
    setIsLoading(true)
    addDocument({
      buildingId: building.id,
      body: {
        parent_id: journalId,
        type: documentType.GWJ4ControlEvent,
        data: values,
      },
    })
      .unwrap()
      .then((res) => {
        methods.reset()
        setIsLoading(false)
        setOpen(false)
        console.log(res)
      })
      .catch(toastError)
  }

  return (
    <>
      <IconButton
        onClick={() => setOpen(true)}
        sx={{
          backgroundColor: 'info.main',
          color: 'background.paper',
          ':hover': {backgroundColor: 'info.dark', color: 'background.paper'}
        }}>
        <Add />
      </IconButton>
      <FormDialog
        formId={id}
        open={open}
        onClose={() => setOpen(false)}
        title={'Добавить контрольное мероприятие'}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <AddControlEventForm id={id} onSubmit={handleSubmit} />
      </FormDialog>
    </>
  )
}
