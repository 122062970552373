import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material'

import { DateOrNon } from '../date/DateOrNon'
import { TBuilding } from '../../types/building'
import { postalToString } from '../../util/address/address'
import AttachPostalAddressDialog from './form/AttachPostalAddressDialog'
import { green, red } from '@mui/material/colors'
import { Domain, DomainDisabled, MoreVert } from '@mui/icons-material'
import { formatDate } from '../../util/date'
import React, { useState } from 'react'
import { EditBuildingDialog } from './form/EditBuildingDialog'
import nl2br from 'react-nl2br'
import { useAuthorization } from '../../auth'
import { MoreVertMenu } from '../menu'
import { DataRow } from '../common/DataRow'

const typesForDate = {
  building: ' строительства',
  reconstruction: ' реконструкции',
  repair: ' капитального ремонта',
}

const types = {
  building: 'строительство',
  reconstruction: 'реконструкция',
  repair: 'капитальный ремонт',
}

type BuildingFullCardProps = {
  building: TBuilding
}

export const BuildingFullCard = ({ building }: BuildingFullCardProps) => {
  const [open, setOpen] = useState(false)
  const authManager = useAuthorization()

  const [openEdit, setOpenEdit] = useState(false)
  const handleEditClick = () => {
    setOpenEdit(true)
    setOpen(false)
  }
  const handleEditClose = () => {
    setOpenEdit(false)
  }

  const type = building.type as keyof typeof typesForDate | keyof typeof types
  const isActive: boolean = building.status === 'active'
  const isManage: boolean = authManager.vote('Building', 'manage')

  return (
    <TableContainer component={Card}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: isActive ? green[500] : red[500] }} aria-label="recipe">
            {isActive ? <Domain /> : <DomainDisabled />}
          </Avatar>
        }
        action={
          isManage && (
            <MoreVertMenu open={open} onOpen={() => setOpen(true)} onClose={() => setOpen(false)}>
              <MenuItem onClick={handleEditClick}>Редактировать</MenuItem>
            </MoreVertMenu>
          )
        }
        title={isActive ? 'Активен' : 'Не активен'}
        subheader={'Создан ' + formatDate(building.created_at)}
      />
      <EditBuildingDialog building={building} open={openEdit} onClose={handleEditClose} />
      <CardContent>
        <Typography variant={'h6'}>{building.name}</Typography>
        <Typography variant={'overline'}>{types[type]}</Typography>
      </CardContent>
      <CardContent>
        <DataRow
          label={`Дата начала ${typesForDate[type]}`}
          text={formatDate(building.date_begin)}
        />
        {building.date_end && (
          <DataRow
            label={`Дата окончания ${typesForDate[type]}`}
            text={formatDate(building.date_end)}
          />
        )}
        <DataRow
          label={'Адрес объекта'}
          text={building.postal_address && postalToString(building.postal_address)}
        />
        <DataRow label={'Краткие проектные характеристики объекта'}>
          <Typography variant={'body2'}>{nl2br(building.specifications)}</Typography>
        </DataRow>
      </CardContent>
    </TableContainer>
  )
}
