import { TBuilding, TBuildingUser } from '../../../types/building'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@mui/material'
import { DataRow } from '../../common/DataRow'
import { useChangeBuildingUserRoleMutation } from '../../../store/buildings'
import { toast } from 'react-toastify'
import FormControlLabel from '@mui/material/FormControlLabel'
import React from 'react'
import { toastError } from '../../../util/toastError'
import { useGetUserQuery } from '../../../store/user'
import { useGetSubjectPolicyQuery, useRemovePolicyMutation } from '../../../store/auth'
import { actionsLabels, policyObjectsData, TPolicyItemWithId } from '../../../types/auth'
import KeyIcon from '@mui/icons-material/Key'
import KeyOffIcon from '@mui/icons-material/KeyOff'
import { AddPolicyDialog } from './AddPolicyDialog'
import { Delete } from '@mui/icons-material'

const policyObjectData = (policy: TPolicyItemWithId) => {
  return policyObjectsData.find(po => po.object === policy.object)
}

const policyRow = (policy: TPolicyItemWithId, onDelete: (id: string) => void) => {
  const po = policyObjectData(policy)
  if (!po) return null
  return (
    <>
      <TableCell>{po.label}</TableCell>
      <TableCell>{actionsLabels[policy.action]}</TableCell>
      <TableCell>
        <Box title={policy.access ? 'разрешено' : 'запрещено'}>
          {policy.access && <KeyIcon color={'success'} />}
          {!policy.access && <KeyOffIcon color={'disabled'} />}
        </Box>
      </TableCell>
      <TableCell sx={{textAlign: 'right'}}>
        <IconButton
          color="error"
          title="Удалить"
          onClick={() => onDelete(policy.id)}
        >
          <Delete />
        </IconButton>
      </TableCell>
    </>
  )
}

type Props = {
  user: TBuildingUser
  building: TBuilding
  isManage: boolean
  onChange?: () => void
}
export const UserView = ({user, building, isManage = false, onChange}: Props) => {

  const [changeRole] = useChangeBuildingUserRoleMutation()
  const [remove] = useRemovePolicyMutation()
  const { data: currentUser, isSuccess: isUserSuccess } = useGetUserQuery()
  const {data: policy} = useGetSubjectPolicyQuery({buildingId: building.id, role: user.id})


  const handleRole = (userId: string, admin: boolean) => {
    changeRole({
      buildingId: building.id,
      body: { user_id: userId, role: admin ? 'admin' : 'reader' },
    })
      .unwrap()
      .then(() => {
        toast.success('Роль изменена')
        onChange && onChange()
      })
      .catch(toastError)
  }

  const handleRemove = (id: string) => {
    remove({
      buildingId: building.id,
      body: { policy_id: id},
    })
      .unwrap()
      .then(() => {
        onChange && onChange()
      })
      .catch(toastError)
  }


  return(
    <>
      <Stack direction={'column'} spacing={2}>
        <Card>
          <CardContent>
            <DataRow label={'Пользователь'} text={user.profile.name.full_name} />
            { isManage && currentUser && currentUser.id !== user.user_id && building.owner_id !== user.user_id && (<Box>
              <FormControlLabel
                control={
                  <Switch
                    checked={user.role === 'admin'}
                    onChange={(event) =>
                      handleRole(user.user_id, event.target.checked)
                    }
                  />
                }
                label="Администратор"
              />
            </Box>)}
          </CardContent>
        </Card>
        <Card>
          <CardHeader
            title={'Права'}
            subheader={'Дополнительные права. Если пользователь является сотрудником на объекте, у него уже есть права сотрудника.'}
          />
          <Box>
            <Table width={'100%'}>
              <TableBody>
                {policy && policy.map(p => (
                  <TableRow key={p.id}>
                    {policyRow(p, handleRemove)}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
          <CardContent>
            {isManage && <AddPolicyDialog buildingId={building.id} buId={user.id} />}
          </CardContent>
        </Card>
      </Stack>
    </>
  )
}
