import React, { ReactNode, useState } from 'react'
import { Box, IconButton, Menu } from '@mui/material'
import { MoreVert } from '@mui/icons-material'

type Props = {
  children?: ReactNode
  open: boolean
  onOpen: (event: React.MouseEvent<HTMLElement>) => void
  onClose: (event: React.MouseEvent<HTMLElement>) => void
}

const MoreVertMenu = ({ children, open, onOpen, onClose }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
    onOpen(event)
  }

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    onClose(event)
  }

  if (!children) return <></>

  return (
    <Box>
      <IconButton edge="start" onClick={handleMenu}>
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open && Boolean(anchorEl)}
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </Menu>
    </Box>
  )
}

export { MoreVertMenu }
