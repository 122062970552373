import * as React from 'react'
import { Box, Stack } from '@mui/material'
import { IDocument } from '../../../types/document'
import { SignDocument } from '../../document/SignDocument'
import { ActOSRViewData } from './ActOSRViewData'
import { ActOSRViewPrd } from './ActOSRViewPrd'
import { ActOSRExtDocs } from './ActOSRExtDocs'
import { ActOSRMaterials } from './ActOSRMaterials'

type Props = {
  document: IDocument
  onReady: () => void
  onSign: () => void
  onChange: () => void
  isManage: boolean
}

export const ActOSRView = ({ document, onSign, onReady, onChange, isManage }: Props) => {

  return (
    <Box>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
        <Box>
            <ActOSRViewData
              document={document}
              isManage={isManage}
            />
        </Box>
        <Box>
          <ActOSRViewPrd document={document} isManage={isManage} type={'pd'} meta={'pd'} onChange={console.log} />
          <ActOSRViewPrd document={document} isManage={isManage} type={'rd'} meta={'pd'} onChange={console.log} />
          <ActOSRViewPrd document={document} isManage={isManage} type={'pd'} meta={'accordantPd'} onChange={console.log} />
          <ActOSRViewPrd document={document} isManage={isManage} type={'rd'} meta={'accordantRd'} onChange={console.log} />
        </Box>
        <Box>
          <ActOSRMaterials document={document} isManage={isManage} onChange={console.log} />
          <ActOSRExtDocs document={document} isManage={isManage} type={'isp'} onChange={console.log} />
          <ActOSRExtDocs document={document} isManage={isManage} type={'result'} onChange={console.log} />
        </Box>
      </Stack>
      <Box>
        <SignDocument document={document} onReady={onReady} onSign={onSign} />
      </Box>
    </Box>
  )
}
