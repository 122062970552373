import { Button, Chip, styled } from '@mui/material'
import { darken, lighten } from '@mui/system'

const LightChip = styled(Chip)(({ theme, color = 'default' }) => ({
  backgroundColor: (theme.palette.mode === 'light' ? lighten : darken)(
    (theme.palette[color === 'default' ? 'primary' : color]).light,
    0.8
  ),
  color: (theme.palette.mode === 'light' ? lighten : darken)(
    (theme.palette[color === 'default' ? 'primary' : color]).dark, 0
  ),
  borderRadius: 5
}))


export {LightChip}
