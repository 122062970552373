import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { addAuthorization } from '../../util/http/addAuthorization'

export const journalsApi = createApi({
  reducerPath: 'journals',
  tagTypes: ['Journals'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1/',
    prepareHeaders: addAuthorization,
    validateStatus: (response) => response.ok,
  }),
  endpoints: (builder) => ({
    getJournals: builder.query<Record<string, any[]>, string>({
      query: (buildingId) => ({
        url: `/buildings/${buildingId}/journals`,
        method: 'GET',
      }),
      providesTags: ['Journals'],
    }),
    createGwj: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/gwj`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Journals'],
    }),
    createImc: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/imc`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Journals'],
    }),
    export: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/xml/export`,
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  useCreateGwjMutation,
  useCreateImcMutation,
  useGetJournalsQuery,
  useExportMutation
} = journalsApi
