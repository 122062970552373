import React from 'react'
import { BaseContainer } from '../../../components/common/Container'
import { Grid, Stack } from '@mui/material'
import { BuildingFullCard } from '../../../components/building/BuildingFullCard'
import { useOutletContext } from 'react-router-dom'
import { IOutletBuilding } from '../../../types/outlet-context'
import { useAuthorization } from '../../../auth'
import { BuildingDocuments } from '../../../components/building/BuildingDocuments'
import { BuildingLocation } from '../../../components/building/BuildingLocation'


const BuildingCard = () => {
  const { building } = useOutletContext<IOutletBuilding>()
  const authManager = useAuthorization()
  const isManage = authManager.vote('Building', 'manage')


  return (
    <BaseContainer>
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <Stack direction={{lg: 'row', xs: 'column'}} spacing={2}>
            <BuildingFullCard building={building} />
            <Stack direction={'column'} spacing={2}>
              <BuildingDocuments building={building} type={'permission'} isManage={isManage} />
              <BuildingDocuments building={building} type={'pd_examination'} isManage={isManage} />
            </Stack>
          </Stack>
        </Grid>
        <Grid item md={4} xs={12}>
          <BuildingLocation building={building} isManage={isManage} />
        </Grid>
      </Grid>
    </BaseContainer>
  )
}

export { BuildingCard }
