import { useOutletContext } from 'react-router-dom'
import React from 'react'
import { BaseContainer } from '../../../components/common/Container'
import { Prd } from '../../../components/prd/Prd'
import { IOutletBuilding } from '../../../types/outlet-context'

export const Pd = () => {
  const { building } = useOutletContext<IOutletBuilding>()
  return (
    <>
      <BaseContainer>
        <Prd building={building} type={'pd'} />
      </BaseContainer>
    </>
  )
}
