import { Box, Chip, Stack } from '@mui/material'
import { TFilterSetting, UseFilterResult } from './useFilter'
import React from 'react'

const stringValue = (value: any, filter: TFilterSetting) => {
  switch (filter.filter) {
    case 'dateRange' :
      return `: ${value.begin.substring(0, 10)} - ${value.end.substring(0, 10)}`
    case 'range' :
      return `: ${value.begin} - ${value.end}`
    case 'string' :
      return value
    case 'list':
      return  ': ' + filter.data?.find(d => d.value === value[0]).label +
        (value.length > 1 ? `, ещё ${value.length - 1}...` : '')
    case 'date':
      return ': ' + value.substring(0, 10)
    case 'select':
      return filter.data?.find(i => i.value === value)?.label
    case 'bool':
      return ''
  }
}

export const FilterChips = ({filter}: {filter: UseFilterResult}) => {

  if (Object.keys(filter.values).length === 0) {
    return <></>
  }

  return(
    <>
      <Box sx={{ pt: 2 }}>
        <Stack direction="row" spacing={1} useFlexGap flexWrap={'wrap'}>
          {Object.keys(filter.values).map(name => {
            const filterSetting = filter.settings.find(fs => fs.name === name)
            if (!filterSetting) return null
            return <Chip
              key={name}
              label={`${filterSetting.label} ${stringValue(filter.values[name], filterSetting)}`}
              onDelete={() => filter.removeValue(name)}
            />
          })}
          <Chip label={`Очистить`} variant={'outlined'} onDelete={filter.clear} />
        </Stack>
      </Box>
    </>
  )
}
