import { object, string, TypeOf } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { useMemo } from 'react'
import { Box } from '@mui/material'
import { useGetGroupsQuery, useGetWorksQuery } from '../../../../store/works'
import { useGetCompaniesQuery } from '../../../../store/companies'
import { employeesList } from '../../../../util/employees'
import FormSelect from '../../../form/FormSelect'
import { worksList } from '../../../../util/works'
import FormInputDate from '../../../form/FormInputDate'
import { useGetEmployeesQuery } from '../../../../store/employees'

const schema = {
  work_id: string().min(1, 'Необходимо заполнить'),
  signer_id: string().min(1, 'Необходимо заполнить'),
  date: string().min(1, 'Необходимо заполнить дату'),
}
const registerSchema = object(schema)

interface Props {
  buildingId: string
  values?: any
  onSubmit: (values: TypeOf<typeof registerSchema>, methods: any) => void
  id?: string
}

const Item3Form = ({ buildingId, values, onSubmit, id = 'item-3-form' }: Props) => {
  const { data: works } = useGetWorksQuery(buildingId)
  const { data: groups } = useGetGroupsQuery(buildingId)
  const { data: employees } = useGetEmployeesQuery(buildingId)
  const { data: companies } = useGetCompaniesQuery(buildingId)

  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: values || {},
  })

  const { handleSubmit } = methods

  const submitHandler = (values: any) => {
    onSubmit(values, methods)
  }

  const signerItems =
    companies && employees
      ? employeesList(companies, employees, ['building_contractor', 'contractor'], 'auth_itp')
      : []

  const worksItems = useMemo(() => worksList(groups, works), [groups, works])

  return (
    <FormProvider {...methods}>
      <Box
        id={id}
        component="form"
        onSubmit={handleSubmit(submitHandler)}
        noValidate
        autoComplete="off"
      >
        <FormSelect name="work_id" label="Работа" items={worksItems} />
        <FormSelect name="signer_id" label="Ответственный" items={signerItems} />
        <FormInputDate name="date" label="Дата выполнения работ" />
      </Box>
    </FormProvider>
  )
}

export default Item3Form
