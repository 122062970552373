import { coerce, object, string, TypeOf } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { Box, Stack } from '@mui/material'
import FormInput from '../form/FormInput'
import AutocompleteInput from '../form/AutocompleteInput'

const schema = {
  material: string().min(1, 'Необходимо выбрать материал'),
  amount: coerce.number({
    invalid_type_error: 'Значение должно быть числом',
    required_error: 'Значение не должно быть пустым',
  }),
  unit: string().min(1, 'Необходимо заполнить'),
}
const registerSchema = object(schema)

interface Props {
  values?: any
  onSubmit: (values: TypeOf<typeof registerSchema>, methods: any) => void
  materials: string[]
  units: string[]
  id?: string
}

const MaterialAmountForm = ({ values, onSubmit, materials, units, id }: Props) => {
  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: values || {
      type: 'default',
    },
  })

  const { handleSubmit } = methods

  const submitHandler = (values: any) => {
    onSubmit(values, methods)
  }

  return (
    <FormProvider {...methods}>
      <Box
        id={id}
        component="form"
        onSubmit={handleSubmit(submitHandler)}
        noValidate
        autoComplete="off"
      >
        <AutocompleteInput name="material" label="Материал" variants={materials} />
        <Stack direction="row" spacing={1}>
          <FormInput name="amount" label="Объем" />
          <AutocompleteInput name="unit" label="Единица измерения" variants={units} />
        </Stack>
      </Box>
    </FormProvider>
  )
}

export default MaterialAmountForm
