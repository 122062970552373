import { useOutletContext } from 'react-router-dom'
import { IOutletBuilding } from '../../../types/outlet-context'
import React from 'react'
import { ActsOSRList } from '../../../components/acts/aosr/ActsOSRList'
import { BaseContainer } from '../../../components/common/Container'

export const ActsOSR = () => {
  const { building } = useOutletContext<IOutletBuilding>()
  return (
    <BaseContainer>
      <ActsOSRList building={building}></ActsOSRList>
    </BaseContainer>
  )
}
