import { TExternalDocument, TExternalDocumentType } from '../types/external-document'
import { formatDate } from './date'

export const extDocsList = (
  documents: TExternalDocument[],
  types: TExternalDocumentType[] = []
) => {
  const filterDocs =
    types.length > 0 ? documents.filter((doc) => types.includes(doc.type)) : documents

  return filterDocs.map((doc) => {
    return {
      label: doc.name + (doc.number && ` № ${doc.number}`) + ' от ' + formatDate(doc.date),
      value: doc.id,
    }
  })
}

export const extDocName = (doc: TExternalDocument) => {
  return (
    doc.name + (doc.number ? ' № ' + doc.number : '') + (doc.date ? ' ' + formatDate(doc.date) : '')
  )
}
