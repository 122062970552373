import { cadespluginOnload } from './cades'

const base64Signature = async (sBase64Data: string, thumbprint: string) => {
  const certsApi = await cadespluginOnload()
  return await certsApi.signBase64(thumbprint, sBase64Data)
}

const hashSignature = async (sBase64Data: string, thumbprint: string) => {
  const certsApi = await cadespluginOnload()
  return await certsApi.signHash(thumbprint, sBase64Data)
}

const xmlTemplate = (
  xml: string,
  signAlgorithm: string,
  hashAlgorithm: string,
  b64Cert: string
) => {
  return xml
    .replace('{{signMethod}}', signAlgorithm)
    .replace('{{digestMethod}}', hashAlgorithm)
    .replace('{{b64cert}}', b64Cert)
}



const xmlSignature = async (xml: string, id: string, thumbprint: string): Promise<string> => {
  const certsApi = await cadespluginOnload()

  const { signAlgorithm, hashAlgorithm } = await certsApi.xmlSignatureAlgorithm(thumbprint)
  const b64cert = (await certsApi.b64Cert(thumbprint)).replace(/[\r\n]/g, '')

  const serXml = xmlTemplate(xml, signAlgorithm, hashAlgorithm, b64cert)

  const signedXml = await certsApi.signXml(thumbprint, serXml, 2)

  return signedXml || Promise.reject(new Error('Не удалось получить подпись'))
}

export { base64Signature, hashSignature, xmlSignature }
