import { FormDialog } from '../common/FormDialog'
import * as React from 'react'
import { useState } from 'react'
import PrdDocumentSelectForm from './PrdDocumentSelectForm'
import { TPrdType } from '../../types/prd'

type Props = {
  buildingId: string
  type: TPrdType
  onSelect: (values: any) => void
  onClose: () => void
  open: boolean
  values?: any
}

const PrdDocumentSelectDialog = ({ onSelect, buildingId, type, open, onClose, values }: Props) => {
  const id = 'prd-document-select-dialog'
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = (values: any, methods: any) => {
    onSelect(values)
  }

  return (
    <>
      <FormDialog
        formId={id}
        open={open}
        onClose={onClose}
        title={'Выбрать документ'}
        button={'Выбрать'}
        isLoading={isLoading}
      >
        <PrdDocumentSelectForm
          id={id}
          buildingId={buildingId}
          type={type}
          values={values}
          onSubmit={handleSubmit}
        />
      </FormDialog>
    </>
  )
}

export default PrdDocumentSelectDialog
