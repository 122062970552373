import { object, string, TypeOf } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { Box } from '@mui/material'
import FormInput from '../form/FormInput'
import FormSelect from '../form/FormSelect'
import FormInputDate from '../form/FormInputDate'
import { extDocTypesList, TExternalDocumentType } from '../../types/external-document'

const typeItems = Object.keys(extDocTypesList).map((key) => ({
  label: extDocTypesList[key as TExternalDocumentType],
  value: key,
}))

const schema = {
  name: string().min(1, 'Необходимо заполнить'),
  type: string().min(1, 'Необходимо заполнить'),
  number: string().min(1, 'Необходимо заполнить'),
  date: string().min(1, 'Необходимо заполнить дату'),
  date_expire: string()
    .min(0)
    .nullish()
    .refine((val) => val !== '')
    .catch(null),
}
const registerSchema = object(schema)

interface Props {
  values?: any
  onSubmit: (values: TypeOf<typeof registerSchema>, methods: any) => void
  id?: string
}

const DocumentForm = ({ values, onSubmit, id = 'external-document-form' }: Props) => {
  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: values || {
      type: 'default',
    },
  })

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful },
    setValue,
    setError,
    watch,
  } = methods

  const hs = handleSubmit((values: any) => {
    onSubmit(values, methods)
  })

  const onFormSubmit = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    return hs(e)
  }

  return (
    <FormProvider {...methods}>
      <Box id={id} component="form" onSubmit={onFormSubmit} noValidate autoComplete="off">
        <FormSelect name="type" label="Тип" items={typeItems} />
        <FormInput name="name" label="Наименование" />
        <FormInput name="number" label="Номер" />
        <FormInputDate name="date" label={'Дата документа'} />
        <FormInputDate name="date_expire" label={'Дата окончания действия'} />
      </Box>
    </FormProvider>
  )
}

export default DocumentForm
