import { useState } from 'react'
import { toast } from 'react-toastify'
import { Add } from '@mui/icons-material'
import { FormDialog } from '../../../common/FormDialog'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import { useAddDocumentMutation, useEditDocumentMutation } from '../../../../store/document'
import { documentType, IDocument } from '../../../../types/document'
import { LightButton } from '../../../common/LightButton'
import { Part7AddForm } from './Part7AddForm'
import { toastError } from '../../../../util/toastError'
import { AddButton } from '../../../common/AddButton'

type Props = {
  buildingId: string
  nextNumber?: string
}

const Part7AddDialog = ({ buildingId, nextNumber }: Props) => {
  const id = 'add-item-7-dialog'
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { journalId } = useParams()

  const [addDocument] = useAddDocumentMutation()
  const [editDocument] = useEditDocumentMutation()

  const handleSubmit = (values: any, methods: any) => {
    setIsLoading(true)

    addDocument({
      buildingId: buildingId,
      body: {
        type: documentType.GWJ7Defect,
        parent_id: journalId,
        employees: [{ id: values.signerId, type: 'signer' }],
        external_documents: [{ id: values.actKnm, type: 'actKnm' }],
        ...values,
      },
    })
      .unwrap()
      .then((res) =>
        addDocument({
          buildingId: buildingId,
          body: {
            type: documentType.GWJ7Item,
            parent_id: journalId,
            number: values.number,
            employees: [{ id: values.signerId, type: 'signer' }],
            over: [{ id: res.id, type: 'defect' }],
          },
        }).unwrap()
      )
      .then((res) => {
        toast.success('Запись добавлена')
        setOpen(false)
      })
      .catch(toastError)
      .finally(() => setIsLoading(false))

    methods.reset()
  }

  return (
    <>
      <AddButton onClick={() => setOpen(true)}>
        Добавить запись
      </AddButton>
      <FormDialog
        formId={id}
        open={open}
        onClose={() => setOpen(false)}
        title={'Добавить запись'}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <Part7AddForm
          id={id}
          buildingId={buildingId}
          onSubmit={handleSubmit}
          nextNumber={nextNumber}
        />
      </FormDialog>
    </>
  )
}

export { Part7AddDialog }
