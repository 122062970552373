import FileUploadIcon from '@mui/icons-material/FileUpload'
import * as React from 'react'
import { useState } from 'react'
import { useImportM4dMutation } from '../../store/companies'
import { toastError } from '../../util/toastError'
import { LightButton } from '../common/LightButton'
import { FormDialog } from '../common/FormDialog'
import { TCompany } from '../../types/building'
import { FormProvider, useForm } from 'react-hook-form'
import { custom, object, TypeOf } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Button, FormHelperText, Stack } from '@mui/material'
import { MuiFileInput } from 'mui-file-input'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import CloseIcon from '@mui/icons-material/Close'

const fileUploadSchema = object({
  file: custom<File>(),
})

interface IProps {
  company: TCompany
}

export const M4dImportDialog = ({ company }: IProps) => {
  const id = 'm4d-import-dialog'
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState<File | null>(null)
  const [importM4d] = useImportM4dMutation()

  const methods = useForm<TypeOf<typeof fileUploadSchema>>({
    resolver: zodResolver(fileUploadSchema),
  })

  const { handleSubmit } = methods

  const handleChange = (newFile: File | null) => {
    setFile(newFile)
  }

  const submitHandler = (values: any) => {
    if (!file) return
    const formData = new FormData()
    formData.append('file', file)
    formData.append('company_id', company.id)

    setIsLoading(true)
    importM4d({ buildingId: company.building_id, body: formData })
      .unwrap()
      .then((res) => {
        methods.reset()
        setOpen(false)
      })
      .catch(toastError)
      .finally(() => setIsLoading(false))
  }

  return (
    <>
      <LightButton startIcon={<FileUploadIcon />} onClick={() => setOpen(true)}>
        Добавить МЧД
      </LightButton>
      <FormDialog
        formId={id}
        open={open}
        onClose={() => setOpen(false)}
        title={'Добавить МЧД'}
        button={file ? 'Отправить' : false}
        isLoading={isLoading}
        maxWidth={false}
        fullWidth={false}
      >
        <FormProvider {...methods}>
          <Box
            id={id}
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(submitHandler)}
          >
            <Stack direction={'row'} alignItems={'center'} sx={{ mt: 2 }} spacing={0.5}>
              <MuiFileInput
                value={file}
                onChange={handleChange}
                size={'small'}
                InputProps={{
                  startAdornment: <AttachFileIcon />,
                  inputProps: {
                    accept: 'text/xml',
                  },
                }}
                label={'Файл МЧД в формате 003 (XML)'}
                placeholder={'Выбрать файл...'}
                clearIconButtonProps={{
                  title: 'Remove',
                  children: <CloseIcon fontSize="small" />,
                }}
              />
            </Stack>
            <FormHelperText error={!!methods.formState.errors.file}>
              {methods.formState.errors.file?.message}
            </FormHelperText>
          </Box>
        </FormProvider>
      </FormDialog>
    </>
  )
}
