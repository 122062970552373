import { Alert, Button } from '@mui/material'
import { Add } from '@mui/icons-material'
import { FormDialog } from '../common/FormDialog'
import * as React from 'react'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { useAddMaterialAmountMutation } from '../../store/works'
import { useGetMaterialsQuery, useGetUnitsQuery } from '../../store/lists'
import MaterialAmountForm from './MaterialAmountForm'
import { TWork } from '../../types/works'
import { additionUnits } from '../../util/additionUnits'
import { parseError } from '../../api'
import { LightButton } from '../common/LightButton'

const AddMaterialAmountDialog = ({ work }: { work: TWork }) => {
  const id = 'add-material-amount-dialog'
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const {
    data: materials,
    isSuccess: isGroupsSuccess,
    refetch: refetchMaterials,
  } = useGetMaterialsQuery(work.building_id)

  const {
    data: units,
    isSuccess: isUnitsSuccess,
    refetch: refetchUnits,
  } = useGetUnitsQuery(work.building_id)

  const [addAmount] = useAddMaterialAmountMutation()

  if (!isGroupsSuccess || !isUnitsSuccess) {
    return <></>
  }

  const selectMaterialsItems = Object.values(materials).map((material) => material.name)
  const selectUnitsItems = additionUnits(Object.values(units).map((unit) => unit.name))

  const handleSubmit = (values: any, methods: any) => {
    setIsLoading(true)
    addAmount({ buildingId: work.building_id, body: { ...values, work_id: work.id } })
      .unwrap()
      .then(() => {
        methods.reset()
        setOpen(false)
        setIsLoading(false)
        refetchMaterials()
        refetchUnits()
      })
      .catch(async (error) => {
        const text = await parseError(error)
        toast.error(text)
        setIsLoading(false)
      })
  }

  return (
    <>
      <LightButton startIcon={<Add />} onClick={() => setOpen(true)}>
        Добавить объем
      </LightButton>
      <FormDialog
        formId={id}
        open={open}
        onClose={() => setOpen(false)}
        title={'Добавить объем'}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <Alert severity={'info'}>
          Внимательно укажите <b>материал</b> и <b>единицу измерения</b>, в дальнейшем их изменение
          будет невозможно.
          <br />
          Но до использования (списания объемов) в журналах, можно будет удалить (пересоздать)
          строку целиком.
        </Alert>
        <MaterialAmountForm
          id={id}
          onSubmit={handleSubmit}
          materials={selectMaterialsItems}
          units={selectUnitsItems}
        />
      </FormDialog>
    </>
  )
}

export default AddMaterialAmountDialog
