import { Box, Grid, Typography } from '@mui/material'
import React, { ReactNode } from 'react'

export function PageHead1({
  text,
  rightElement,
}: {
  text: string
  rightElement?: ReactNode
}): JSX.Element {
  const right = rightElement ? <Grid item>{rightElement}</Grid> : null

  return (
    <Box sx={{ mb: '15px' }}>
      <Grid container spacing={2} sx={{ justifyContent: 'space-between' }}>
        <Grid item>
          <Typography variant="h1" sx={{ fontSize: '30px', fontWeight: 900 }}>
            Объекты
          </Typography>
        </Grid>
        {right}
      </Grid>
    </Box>
  )
}
