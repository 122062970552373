import { Box, Pagination } from '@mui/material'
import React from 'react'

interface TPaginationProps {
  pages: number
  page: number
  onChange: (e: any, p: number) => void
}

const SkidPagination = ({ pages, page, onChange }: TPaginationProps) => {
  return (
    <Box sx={{ mt: 4, mb: 4, justifyContent: 'center', display: 'flex' }}>
      <Pagination
        count={pages}
        page={page}
        color="primary"
        onChange={onChange}
        size="large"
        hidden={pages < 2}
      />
    </Box>
  )
}

export default SkidPagination
