import {
  FormHelperText,
  FormControl,
  InputProps,
  OutlinedInput,
  InputLabel,
  SxProps,
  Theme,
  FormControlLabel,
  Switch,
  FormGroup,
} from '@mui/material'
import { FC, ReactNode } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

type IFormInputProps = {
  name: string
  label: string
  hint?: ReactNode
  sx?: SxProps<Theme>
} & InputProps

const FormInputSwitch: FC<IFormInputProps> = ({ name, label, hint, ...otherProps }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const errorMessage: string = (errors[name] ? errors[name]?.message : '') as string

  const hintEl = hint ? <FormHelperText>{hint}</FormHelperText> : null

  return (
    <Controller
      control={control}
      defaultValue=""
      name={name}
      render={({ field: { onChange, value } }) => (
        <FormControl fullWidth margin={'dense'}>
          <FormControlLabel
            label={label}
            control={<Switch checked={!!value} onChange={onChange} />}
          />
          {hintEl}
          <FormHelperText error={!!errors[name]}>{errorMessage}</FormHelperText>
        </FormControl>
      )}
    />
  )
}

export default FormInputSwitch
