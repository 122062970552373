import { Chip } from '@mui/material'
import * as React from 'react'
import { statusLabel, TDocumentStatus } from '../../types/document'
import { LightChip } from './LightChip'
type TStatus = {
  label: string
  color: 'warning' | 'info' | 'success' | 'error' | 'default' | 'primary' | 'secondary'
}
type TStatuses = Record<TDocumentStatus, TStatus>

const statuses: TStatuses = {
  draft: { label: statusLabel.draft, color: 'warning' },
  agreement: { label: statusLabel.agreement, color: 'info' },
  ready: { label: statusLabel.ready, color: 'info' },
  sign_start: { label: statusLabel.sign_start, color: 'info' },
  success: { label: statusLabel.success, color: 'success' },
  old: { label: statusLabel.old, color: 'error' },
}

export const StatusChip = ({ status, label }: { status: TDocumentStatus; label?: string }) => {
  return (
    <LightChip color={statuses[status].color} label={label || statuses[status].label} size="small" />
  )
}
