import { number, object, record, string, TypeOf } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { useState } from 'react'
import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material'
import FormInput from '../../../form/FormInput'
import FormSelect from '../../../form/FormSelect'
import { TWork } from '../../../../types/works'
import { useGetMaterialsQuery, useGetUnitsQuery } from '../../../../store/lists'
import { TMaterial } from '../../../../types/lists'
import AlertError from '../../../alert/AlertError'
import { useApi } from '../../../../api/useApi'
import { formatDateTime } from '../../../../util/date'
import { IDocument } from '../../../../types/document'
import { useGetStoreQuery } from '../../../../store/store'
import { Link } from 'react-router-dom'

const schema = {
  wma_id: string().min(1, 'Необходимо заполнить'),
  store: record(string(), string().or(number())),
}
const registerSchema = object(schema)

interface Props {
  values?: any
  onSubmit: (values: TypeOf<typeof registerSchema>, methods: any) => void
  work: TWork
  item: IDocument
  itemMa: Record<string, number>
  id?: string
}

const maList = (work: TWork, materials: Record<string, TMaterial> | undefined) => {
  if (!materials || !work.materials_amounts) return []
  return work.materials_amounts?.map((ma) => {
    return {
      label: materials[ma.material_id].name,
      value: ma.id,
    }
  })
}

const Item3AmountForm = ({ values, work, item, onSubmit, id, itemMa }: Props) => {
  const { data: units } = useGetUnitsQuery(work.building_id)
  const { data: materials } = useGetMaterialsQuery(work.building_id)
  const { data: store } = useGetStoreQuery(work.building_id)
  const [unit, setUnit] = useState('')
  const [error, setError] = useState('')
  const [balance, setBalance] = useState(0)
  const [storesRest, setStoresRest] = useState(0)
  const [rests, setRests] = useState<any[]>([])
  const [restsIsSuccess, setRestsIsSuccess] = useState(false)
  const [sum, setSum] = useState('')
  const api = useApi()

  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: values || {},
  })

  const {
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = methods

  const submitHandler = (values: any) => {
    if (restsIsSuccess && rests.length === 0) {
      return
    }

    let sum = 0
    const newStore: Record<string, number> = {}

    for (const key in values.store) {
      let val = Number(values.store[key])
      val = isNaN(val) ? 0 : val
      newStore[key] = val
      sum += val
    }

    if (sum === 0) {
      setError('Необходимо указать объем')
      return
    }
    values = { ...values, store: newStore }

    if (itemMa[values.wma_id]) {
      setError(
        'Данный материал уже добавлен в эту запись. Если вам необходимо изменить объем, сначала удалите существующий и добавьте его заново.'
      )
      return
    }
    onSubmit(values, methods)
  }

  const checkBalance = (balance: number, value: number) => {
    if (balance < value) {
      setError('Выбранный объем превышает необходимое значение')
    } else {
      setError('')
    }
  }

  const getRests = (materialId: string, unitId: string, date: string) => {
    const params = new URLSearchParams({
      material_id: materialId,
      unit_id: unitId,
      date: date,
    })
    return api.get(`/v1/buildings/${item.building_id}/gwj/rests?${params}`).then((res) => res)
  }

  const calcStores = (sumValue: any) => {
    let s = Number(sumValue)
    s = isNaN(s) ? 0 : s

    rests.forEach((store: { balance: number; id: string }) => {
      setValue(`store.${store.id}`, 0)
    })

    if (s > 0) {
      let rest = s
      rests.forEach((store: { balance: number; id: string }) => {
        if (rest <= 0) return
        if (rest >= store.balance) {
          setValue(`store.${store.id}`, store.balance)
          rest -= store.balance
        } else {
          setValue(`store.${store.id}`, rest)
          rest = 0
        }
      })
    }
  }

  const handleChangeMaterial = (values: any) => {
    setRests([])
    const ma = work.materials_amounts?.find((ma) => ma.id === values.wma_id)

    if (!ma) return

    setUnit(units ? units[ma.unit_id]?.name : '')

    const balance = ma.value - ma.used
    setBalance(balance)
    checkBalance(balance, Number(values.value))

    getRests(ma.material_id, ma.unit_id, item.date).then((stores) => {
      setRests(stores)
      setRestsIsSuccess(true)
      const sr = stores.reduce((sum: number, store: any) => sum + store.balance, 0)
      setStoresRest(sr)
    })
  }

  const handleChangeValue = (e: any) => {
    const sumValue = e.target.value.replace(',', '.')
    setSum(sumValue)
    calcStores(sumValue)
  }

  const handleChangeStore = (storesValue: any) => {
    let sum = 0
    for (const key in storesValue) {
      const defStore = rests.find((s) => s.id === key)
      let value = Number(storesValue[key])
      if (defStore && value > defStore.balance) {
        value = defStore.balance
        setValue(`store.${key}`, String(value))
      }
      if (value > 0) {
        sum += value
      }
    }
    setSum(sum > 0 ? String(sum) : '')
  }

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'wma_id') {
        handleChangeMaterial(value)
      }
      if (name && name.startsWith('store.')) {
        handleChangeStore(value.store)
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])

  const handleAddAll = () => {
    setSum(String(balance))
    calcStores(balance)
  }

  const handleError = (e: any) => {
    console.log(e)
  }

  return (
    <FormProvider {...methods}>
      <Box
        id={id}
        component="form"
        onSubmit={handleSubmit(submitHandler)}
        noValidate
        autoComplete="off"
      >
        <FormSelect name="wma_id" label="Материал" items={maList(work, materials)} />
        {(balance > 0 || error) && (
          <Box sx={{ mb: 2 }}>
            {balance > 0 && (
              <Alert severity="success">
                <Stack direction="row" spacing={2} alignItems="center">
                  <Box>
                    <Box>
                      {'Осталось использовать в работе: '}
                      <b>
                        {balance} {unit}
                      </b>
                    </Box>
                    {storesRest > 0 && (
                      <Box>
                        {'Имеется в поставках:'}{' '}
                        <b>
                          {storesRest} {unit}
                        </b>
                      </Box>
                    )}
                  </Box>
                  <Button onClick={handleAddAll} variant="outlined" color="success" size="small">
                    внести все
                  </Button>
                </Stack>
              </Alert>
            )}
            <AlertError message={error} />
          </Box>
        )}

        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Объем</InputLabel>
          <OutlinedInput
            onChange={handleChangeValue}
            label="Объем"
            endAdornment={<InputAdornment position="end">{unit}</InputAdornment>}
            value={sum}
          />
        </FormControl>

        {rests.length > 0 && (
          <>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              Списать с поставок
            </Typography>

            {rests.map((store: any) => (
              <Box key={store.id}>
                <Divider />
                <Stack
                  sx={{ mt: 1, mb: 1, pl: '2px' }}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                >
                  <Box sx={{ width: '70%' }}>
                    <Typography>
                      остаток: {store.balance} {unit}
                    </Typography>
                    <Typography color="text.disabled">{formatDateTime(store?.date)}</Typography>
                  </Box>
                  <Box>
                    <FormInput
                      name={`store.${store.id}`}
                      label={''}
                      endAdornment={<InputAdornment position="end">{unit}</InputAdornment>}
                      sx={{ mb: 0 }}
                      size="small"
                    />
                  </Box>
                </Stack>
              </Box>
            ))}
          </>
        )}
        {restsIsSuccess && rests.length === 0 && (
          <Alert severity="error">
            Данного материала нет в поставках.
            Для начала необходимо внести записи в <Link to={`/building/${item.building_id}/journals`}>журнал входного контроля</Link>
          </Alert>
        )}
      </Box>
    </FormProvider>
  )
}

export default Item3AmountForm
