import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { addAuthorization } from '../../util/http/addAuthorization'
import { TWork, TWorkGroup } from '../../types/works'

export const worksApi = createApi({
  reducerPath: 'works',
  tagTypes: ['Works', 'Units', 'Materials', 'Groups'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1/',
    prepareHeaders: addAuthorization,
    validateStatus: (response) => response.ok,
  }),
  endpoints: (builder) => ({
    getWorks: builder.query<Record<string, TWork>, string>({
      query: (buildingId) => ({
        url: `/buildings/${buildingId}/works`,
        method: 'GET',
      }),
      transformResponse(response: TWork[]) {
        return response.reduce((acc, curr) => {
          acc[curr.id] = curr
          return acc
        }, {} as Record<string, TWork>)
      },
      providesTags: ['Works'],
    }),
    getGroups: builder.query<Record<string, TWorkGroup>, string>({
      query: (buildingId) => ({
        url: `/buildings/${buildingId}/works/groups`,
        method: 'GET',
      }),
      transformResponse(response: TWorkGroup[]) {
        return response.reduce((acc, curr) => {
          acc[curr.id] = curr
          return acc
        }, {} as Record<string, TWorkGroup>)
      },
      providesTags: ['Groups'],
    }),
    addGroup: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/works/groups`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Groups'],
    }),
    editGroup: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/works/edit-group`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Groups'],
    }),
    deleteGroup: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/works/delete-group`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Groups'],
    }),
    addWork: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/works`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Works'],
    }),
    editWork: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/works/edit-work`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Works'],
    }),
    moveWork: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/works/move`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Works'],
    }),
    deleteWork: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/works/delete-work`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Works'],
    }),
    addMaterialAmount: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/works/add-material-amount`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Works', 'Units', 'Materials'],
    }),
    deleteMaterialAmount: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/works/delete-amount`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Works'],
    }),
    editAmount: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/works/edit-amount`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Works'],
    }),
    addWorkPrd: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/works/add-prd`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Works'],
    }),
    removeWorkPrd: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/works/delete-prd`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Works'],
    }),

    sortWorks: builder.mutation({
      query: ({ buildingId, body }: { buildingId: string; body: { works: string[] } }) => ({
        url: `/buildings/${buildingId}/works/sort-works`,
        method: 'POST',
        body,
      }),
      async onQueryStarted({ buildingId, body }, { dispatch, queryFulfilled }) {
        const result = dispatch(
          worksApi.util.updateQueryData('getWorks', buildingId, (draft) => {
            body.works.forEach((workId, i) => {
              draft[workId].pos = i + 1
            })
          })
        )
        queryFulfilled.catch(result.undo)
      },
      // invalidatesTags: ['Works']
    }),
    sortGroups: builder.mutation({
      query: ({ buildingId, body }: { buildingId: string; body: { groups: string[] } }) => ({
        url: `/buildings/${buildingId}/works/sort-groups`,
        method: 'POST',
        body,
      }),
      async onQueryStarted({ buildingId, body }, { dispatch, queryFulfilled }) {
        const result = dispatch(
          worksApi.util.updateQueryData('getGroups', buildingId, (draft) => {
            body.groups.forEach((groupId, i) => {
              draft[groupId].pos = i + 1
            })
          })
        )
        queryFulfilled.catch(result.undo)
      },
      // invalidatesTags: ['Groups']
    }),
  }),
})

export const {
  useGetWorksQuery,
  useAddWorkMutation,
  useGetGroupsQuery,
  useAddGroupMutation,
  useAddMaterialAmountMutation,
  useSortWorksMutation,
  useSortGroupsMutation,
  useEditWorkMutation,
  useEditGroupMutation,
  useMoveWorkMutation,
  useDeleteGroupMutation,
  useEditAmountMutation,
  useDeleteMaterialAmountMutation,
  useDeleteWorkMutation,
  useAddWorkPrdMutation,
  useRemoveWorkPrdMutation,
} = worksApi
