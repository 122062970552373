import { FormDialog } from '../common/FormDialog'
import * as React from 'react'
import { useState } from 'react'
import PrdGroupForm from './PrdGroupForm'
import { useAddGroupMutation } from '../../store/prd'
import { TBuilding } from '../../types/building'
import { TPrdType } from '../../types/prd'
import { toastError } from '../../util/toastError'

type Props = {
  building: TBuilding
  type: TPrdType
  onSave?: (id: string) => void
  onClose: () => void
  parentId?: string
  open: boolean
}

const PrdGroupAddDialog = ({ onSave, building, type, parentId, open, onClose }: Props) => {
  const id = 'add-prd-group-dialog'
  const [isLoading, setIsLoading] = useState(false)

  const [addGroup] = useAddGroupMutation()

  const handleSubmit = (values: any, methods: any) => {
    setIsLoading(true)
    const body = {
      name: values.name,
      type,
      parent_id: parentId || null,
    }
    addGroup({ buildingId: building.id, body })
      .unwrap()
      .then((res) => {
        methods.reset()
        setIsLoading(false)
        onSave && onSave(res.id)
      })
      .catch(async (e) => {
        await toastError(e)
        setIsLoading(false)
      })
  }

  return (
    <>
      <FormDialog
        formId={id}
        open={open}
        onClose={onClose}
        title={'Добавить раздел'}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <PrdGroupForm id={id} onSubmit={handleSubmit} />
      </FormDialog>
    </>
  )
}

export default PrdGroupAddDialog
