import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import { useAuth } from '../../oauth'
import { useState } from 'react'
import api, { parseError } from '../../api'
import AlertError from '../../components/alert/AlertError'
import AlertSuccess from '../../components/alert/AlertSuccess'
import System from '../../layout/system'
import { object, string, TypeOf } from 'zod'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import FormInput from '../../components/form/FormInput'
import { LoadingButton } from '@mui/lab'

const registerSchema = object({
  email: string().min(1, 'Необходимо указать E-mail').email('Некорректный E-mail'),
  password: string().min(6, 'Необходимо придумать пароль, минимум 6 символов'),
})

type RegisterInput = TypeOf<typeof registerSchema>

function Join() {
  const { isAuthenticated, login, logout } = useAuth()

  const methods = useForm<RegisterInput>({
    resolver: zodResolver(registerSchema),
    defaultValues: {},
  })

  const { handleSubmit } = methods

  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)

  const onSubmitHandler: SubmitHandler<RegisterInput> = (values) => {
    setIsLoading(true)
    api
      .post('/v1/auth/join', values)
      .then(() => {
        setSuccess('Проверьте почту. Для дальнейших действий следуйте инструкциям в письме.')
        setIsLoading(false)
      })
      .catch(async (error) => {
        setIsLoading(false)
        const message = await parseError(error)
        setError(message)
      })
  }

  return (
    <System>
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5" sx={{ mb: 1 }}>
        Регистрация
      </Typography>
      <AlertError>{error}</AlertError>
      <AlertSuccess>{success}</AlertSuccess>
      {!success && (
        <FormProvider {...methods}>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmitHandler)}
            noValidate
            autoComplete="off"
          >
            <Box>
              <FormInput name={'email'} label={'Адрес электронной почты'} />
              <FormInput name={'password'} type={'password'} label={'Придумайте пароль'} />
            </Box>
            <LoadingButton
              variant="contained"
              fullWidth
              size={'large'}
              type="submit"
              loading={isLoading}
              color={'success'}
            >
              Зарегистрироваться
            </LoadingButton>
          </Box>
        </FormProvider>
      )}
    </System>
  )
}

export default Join
