import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import AuthContext from './AuthContext'
import { generateCodeChallenge, generateCodeVerifier, generateState } from './crypt'
import { Auth } from './Auth'

type Props = {
  auth: Auth
  children: ReactNode
}

function AuthProvider({ auth, children }: Props) {
  const [isAuthenticated, setIsAuthenticated] = useState(
    window.localStorage.getItem('auth.tokens') !== null
  )

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    setError(null)
    setLoading(true)

    auth
      .code()
      .then(() => {
        setLoading(false)
        setIsAuthenticated(window.localStorage.getItem('auth.tokens') !== null)
      })
      .catch((error: Error) => {
        setError(error.message)
        setLoading(false)
        setIsAuthenticated(false)
      })
  }, [])

  useEffect(() => {
    const listener = (e: StorageEvent) => {
      if (e.key === 'auth.tokens') {
        setIsAuthenticated(e.newValue ? JSON.parse(e.newValue) !== null : false)
      }
    }
    window.addEventListener('storage', listener)
    return () => window.removeEventListener('storage', listener)
  }, [])

  const login = useCallback(() => {
    auth.login()
  }, [])

  const logout = useCallback(() => {
    auth.logout()
    setIsAuthenticated(false)
  }, [])

  const getToken = useCallback(() => {
    setLoading(true)

    return auth
      .getToken()
      .then((token: string) => {
        setLoading(false)
        setIsAuthenticated(true)
        return token
      })
      .catch((error: any) => {
        setLoading(false)
        setIsAuthenticated(false)
        throw error
      })
  }, [])

  const contextValue = useMemo(
    () => ({
      isAuthenticated,
      getToken,
      login,
      logout,
      loading,
      error,
    }),
    [isAuthenticated, getToken, login, logout, loading, error]
  )

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}

export default AuthProvider
