import { any, array, boolean, object, record, string, TypeOf, z } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { useMemo } from 'react'
import { Box } from '@mui/material'
import { useGetGroupsQuery, useGetWorksQuery } from '../../../../store/works'
import { useGetCompaniesQuery } from '../../../../store/companies'
import { employeesList } from '../../../../util/employees'
import FormInput from '../../../form/FormInput'
import FormSelect from '../../../form/FormSelect'
import { worksList } from '../../../../util/works'
import FormInputDate from '../../../form/FormInputDate'
import FormTextarea from '../../../form/FormTextarea'
import { IDocument } from '../../../../types/document'
import { useGetEmployeesQuery } from '../../../../store/employees'
import FormInputLocation from '../../../form/FormInputLocation'
import { location, positiveIntegerRequire } from '../../../../util/zodTypes'

const schema = {
  date: string().min(1, 'Необходимо указать дату'),
  signer_id: string().min(1, 'Необходимо заполнить'),
  description: string().min(1, 'Необходимо заполнить'),
  number: positiveIntegerRequire(),
  structural_element: string().min(1, 'Необходимо заполнить'),
  works: array(string()),
  location: location(),
  defect_fixing_plan_date: string().min(1, 'Необходимо указать дату'),
}

const registerSchema = object(schema)

interface Props {
  defect: IDocument
  values?: any
  onSubmit: (values: TypeOf<typeof registerSchema>, methods: any) => void
  id?: string
}

export const DefectForm = ({ defect, onSubmit, id }: Props) => {
  const buildingId = defect.building_id

  const { data: works } = useGetWorksQuery(buildingId)
  const { data: groups } = useGetGroupsQuery(buildingId)
  const { data: employees } = useGetEmployeesQuery(buildingId)
  const { data: companies } = useGetCompaniesQuery(buildingId)

  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: {
      date: defect.date,
      number: defect.number || undefined,
      signer_id: defect.employees.signer[0].employee_id || undefined,
      description: defect.data.description,
      structural_element: defect.data.structural_element,
      defect_fixing_plan_date: defect.data.defect_fixing_plan_date,
      works: defect.works,
      location: defect.location,
    },
  })

  const {
    handleSubmit,
    formState: { errors },
  } = methods

  const submitHandler = (values: any) => {
    onSubmit(values, methods)
  }

  const worksListItems = useMemo(() => worksList(groups, works), [groups, works])

  const signerItems =
    companies && employees
      ? employeesList(
          companies,
          employees,
          ['developer', 'operating_person', 'regional_operator', 'customer'],
          ['auth_representative_control']
        )
      : []

  return (
    <FormProvider {...methods}>
      <Box
        id={id}
        component="form"
        onSubmit={handleSubmit(submitHandler)}
        noValidate
        autoComplete="off"
      >
        <FormSelect name="signer_id" label="Сотрудник, проводивший контроль" items={signerItems} />
        <FormInputDate name="date" label="Дата внесения записи о недостатке" />
        <FormInput name="number" label="Номер недостатка" />
        <FormInputLocation name="location" label="Местоположение выявленного недостатка" />
        <FormInput
          name="structural_element"
          label="Наименование и обозначение структурного элемента"
        />
        <FormSelect
          name="works"
          label="Список работ в отношении структурного элемента и результата работ в указанном месторасположении"
          items={worksListItems}
          multiple
        />
        <FormTextarea name="description" label="Описание выявленного недостатка" />
        <FormInputDate
          name="defect_fixing_plan_date"
          label="Установленная (запланированная) дата устранения выявленного недостатка"
        />
      </Box>
    </FormProvider>
  )
}
