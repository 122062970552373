import { TPrdGroup } from '../../types/prd'
import { FormDialog } from '../common/FormDialog'
import { useMemo, useState } from 'react'
import FormSelect from '../form/FormSelect'
import { object, string, TypeOf } from 'zod'
import { useForm, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { Box } from '@mui/material'
import { useGetGroupsQuery, useMoveDocumentsMutation } from '../../store/prd'
import { toastError } from '../../util/toastError'
import { toast } from 'react-toastify'

const schema = {
  to_id: string().min(1, 'Необходимо заполнить'),
}
const registerSchema = object(schema)

type Props = {
  open: boolean
  documents: string[]
  group: TPrdGroup
  onClose: () => void
}
const MoveDialog = ({ open, group, documents, onClose }: Props) => {
  const [isLoading, setIsLoading] = useState(false)

  const { data: groups } = useGetGroupsQuery(group.building_id)

  const groupsList = useMemo(() => {
    if (!groups) return []
    const data: any = []
    const typeGroups = groups.filter((g) => g.type === group.type)
    typeGroups.forEach((g) => {
      if (g.parent_id === null) {
        data.push({ label: <b>{g.name}</b>, value: g.id })
        typeGroups.forEach((sg) => {
          if (sg.parent_id === g.id) {
            data.push({ label: ' - ' + sg.name, value: sg.id })
          }
        })
      }
    })
    return data
  }, [groups])

  const [move] = useMoveDocumentsMutation()

  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
  })

  const {
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods

  const hs = handleSubmit((values: any) => {
    move({
      buildingId: group.building_id,
      body: {
        group_id: group.id,
        documents,
        to_id: values.to_id,
      },
    })
      .unwrap()
      .then((res) => {
        onClose()
        toast.success('Документы перемещены')
      })
      .catch(toastError)
  })

  return (
    <FormDialog
      formId={'move-documents'}
      open={open}
      onClose={onClose}
      title={'Переместить документы'}
      button={'Переместить'}
      isLoading={isLoading}
    >
      <FormProvider {...methods}>
        <Box id={'move-documents'} component="form" onSubmit={hs} noValidate autoComplete="off">
          <FormSelect name={'to_id'} label={'В раздел'} items={groupsList} />
        </Box>
      </FormProvider>
    </FormDialog>
  )
}

export { MoveDialog }
