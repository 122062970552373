import { Add } from '@mui/icons-material'
import { FormDialog } from '../../../common/FormDialog'
import * as React from 'react'
import { useState } from 'react'
import { useEditDocumentMutation } from '../../../../store/document'
import { documentType, IDocument } from '../../../../types/document'
import { LightButton } from '../../../common/LightButton'
import { DefectForm } from './DefectForm'
import { toastError } from '../../../../util/toastError'

interface IProps {
  defect: IDocument
  onChange: () => void
}

export const EditRecord4DefectDialog = ({ defect, onChange }: IProps) => {
  const id = 'edit-control-defect-form'
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [edit] = useEditDocumentMutation()

  const handleSubmit = async (values: any, methods: any) => {
    setIsLoading(true)

    edit({
      buildingId: defect.building_id,
      body: {
        document_id: defect.id,
        type: documentType.GWJ4DefectInfo,
        number: values.number,
        date: values.date,
        data: {
          description: values.description,
          structural_element: values.structural_element,
          defect_fixing_plan_date: values.defect_fixing_plan_date,
        },
        works: values.works,
        location: values.location,
        employees: [{ id: values.signer_id, type: 'signer' }],
      },
    })
      .unwrap()
      .then((res) => {
        setOpen(false)
        onChange()
      })
      .catch(toastError)
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <>
      <LightButton startIcon={<Add />} onClick={() => setOpen(true)}>
        Редактировать
      </LightButton>
      <FormDialog
        formId={id}
        open={open}
        onClose={() => setOpen(false)}
        title={'Редактировать запись'}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <DefectForm id={id} onSubmit={handleSubmit} defect={defect} />
      </FormDialog>
    </>
  )
}
