import isJsonResponse from './isJsonResponse'

async function parseError(error: Error | Response | any) {
  if (error instanceof Error) {
    return error.message
  }
  if (error.status === 422) {
    console.error(error)
    return null
  }

  if (error.status && error?.data?.message) {
    return error?.data.message
  }

  if (isJsonResponse(error)) {
    const data = await error.json()
    if (data.exception && data.exception[0].message) {
      return data.exception[0].message
    }
    if (data.message) {
      return data.message
    }
  }
  return error.statusText
}

export default parseError
