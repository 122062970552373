import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { object, string, TypeOf } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { TBuilding, TBuildingDocumentType } from '../../types/building'
import { FormInputExtDocument, FormInput } from '../form'
import { useAddBuildingDocumentMutation } from '../../store/buildingDocuments'
import { toastError } from '../../util/toastError'
import { Add } from '@mui/icons-material'
import { LightButton } from '../common/LightButton'

type FormProps = {
  building: TBuilding
  type: TBuildingDocumentType
  values?: any
}

const BuildingDocumentForm = ({ building, type, values }: FormProps) => {
  const schema = {
    document_id: string().min(1, 'Необходимо заполнить'),
    organization: string().min(1, 'Необходимо заполнить'),
  }

  const registerSchema = object(schema)

  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: values || {},
  })

  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [addDocument] = useAddBuildingDocumentMutation()

  const { handleSubmit } = methods

  const onSubmitHandler: SubmitHandler<TypeOf<typeof registerSchema>> = (values) => {
    setIsLoading(true)
    addDocument({
      buildingId: building.id,
      body: {
        ...values,
        type: type,
      },
    })
      .unwrap()
      .then(() => {
        setOpen(false)
      })
      .catch(toastError)
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <>
      <LightButton startIcon={<Add />} onClick={() => setOpen(true)}>
        Добавить
      </LightButton>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth={'lg'} fullWidth>
        <DialogTitle>{'Добавить документ'}</DialogTitle>
        <DialogContent>
          <FormProvider {...methods}>
            <Box
              component="form"
              id={'building-document-form'}
              onSubmit={handleSubmit(onSubmitHandler)}
              noValidate
              autoComplete="off"
            >
              <Box sx={{ pt: 1 }}>
                <FormInputExtDocument name="document_id" label="Документ" />
                <FormInput
                  name="organization"
                  label="Организация"
                  hint={
                    'Наименование органа исполнительной власти или органа местного самоуправления (организации), выдавшего документ'
                  }
                />
              </Box>
            </Box>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            type="submit"
            form={'building-document-form'}
            loading={isLoading}
            color={'success'}
          >
            {'Отправить'}
          </LoadingButton>
          <Button onClick={() => setOpen(false)}>Закрыть</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default BuildingDocumentForm
