import { TCompany } from '../../types/building'
import {
  Alert,
  AlertTitle,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Link,
  Stack,
  Typography,
} from '@mui/material'
import { formatDate } from '../../util/date'
import { Delete } from '@mui/icons-material'
import * as React from 'react'
import { useRemoveM4dMutation } from '../../store/companies'
import { toastError } from '../../util/toastError'
import { M4dImportDialog } from './M4dImportDialog'
import DownloadIcon from '@mui/icons-material/Download'
import { download } from '../../util/download'

const isActual = (dateBegin: string, dateEnd: string) => {
  const now = Date.now()
  return new Date(dateBegin).getTime() <= now && now <= new Date(dateEnd).getTime()
}

type Props = {
  company: TCompany
}
const M4dList = ({ company }: Props) => {
  const [remove] = useRemoveM4dMutation()

  const removeHandler = (id: string) => {
    remove({
      buildingId: company.building_id,
      body: {
        company_id: company.id,
        m4d_id: id,
      },
    })
      .unwrap()
      .catch(toastError)
  }

  const handleDownload = (id: string) => {
    download(
      `/api/v1/buildings/${company.building_id}/companies/download-m4d?company_id=${company.id}&m4d_id=${id}`,
      'МЧД.xml',
      'text/xml'
    )
  }

  return (
    <Card>
      <CardHeader
        title="МЧД"
        subheader="список МЧД организации"
        action={
          <Stack direction={'row'} spacing={1}>
            <M4dImportDialog company={company} />
            {/* <M4dDialog company={company} /> */}
          </Stack>
        }
      />
      <CardContent>
        <Stack direction="column" spacing={2}>
          {company.m4d.map((m4d) => (
            <Alert
              severity={isActual(m4d.date_begin, m4d.date_end) ? 'success' : 'error'}
              key={m4d.id}
              action={
                <Stack direction={'row'} spacing={1}>
                  <IconButton title="Скачать" onClick={() => handleDownload(m4d.id)}>
                    <DownloadIcon color={'success'} />
                  </IconButton>
                  <IconButton title="Удалить" onClick={() => removeHandler(m4d.id)}>
                    <Delete color={'error'} />
                  </IconButton>
                </Stack>
              }
            >
              <AlertTitle>
                {m4d.number} (
                {isActual(m4d.date_begin, m4d.date_end) ? 'действующий' : 'недействительный'})
              </AlertTitle>
              <Typography variant="subtitle2">
                Срок действия: с <b>{formatDate(m4d.date_begin)}</b> по{' '}
                <b>{formatDate(m4d.date_end)}</b>
              </Typography>
              <Typography variant="caption">Представителей: {m4d.inn.length}</Typography>
              <Typography variant="caption" component={'div'}>
                <Link
                  href={`https://m4d.nalog.gov.ru/emchd/check-status?guid=${m4d.number}`}
                  target={'_blank'}
                >
                  <span>Проверить статус в сервисе ФНС</span>
                </Link>
              </Typography>
            </Alert>
          ))}
        </Stack>
      </CardContent>
    </Card>
  )
}

export { M4dList }
