import { useState } from 'react'
import { toast } from 'react-toastify'
import { Add } from '@mui/icons-material'
import { FormDialog } from '../../../common/FormDialog'
import * as React from 'react'
import { Item2Form } from './Item2Form'
import { useAddEmployeeMutation, useEditDocumentMutation } from '../../../../store/document'
import { documentType, IDocument } from '../../../../types/document'
import { LightButton } from '../../../common/LightButton'
import { toastError } from '../../../../util/toastError'

type Props = {
  document: IDocument
}

const EditItem2Dialog = ({ document }: Props) => {
  const id = 'edit-item-2-dialog'
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [editDocument] = useEditDocumentMutation()
  const [addEmployee] = useAddEmployeeMutation()

  const handleSubmit = (values: any, methods: any) => {
    const { date, date_pass, name, sequence_number, signer_id } = values

    setIsLoading(true)

    const promises = [
      addEmployee({
        buildingId: document.building_id,
        body: {
          document_id: document.id,
          employee_id: signer_id,
          type: 'signer',
        },
      }).unwrap(),
      editDocument({
        buildingId: document.building_id,
        body: {
          document_id: document.id,
          type: documentType.GWJ2Item,
          date: date,
          data: { date_pass, name, sequence_number },
        },
      }).unwrap(),
    ]

    Promise.all(promises)
      .then(() => {
        methods.reset()
        setOpen(false)
        toast.success('Документ добавлен')
      })
      .catch(toastError)
      .finally(() => setIsLoading(false))
  }

  return (
    <>
      <LightButton startIcon={<Add />} onClick={() => setOpen(true)}>
        Изменить
      </LightButton>
      <FormDialog
        formId={id}
        open={open}
        onClose={() => setOpen(false)}
        title={'Добавить запись'}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <Item2Form
          id={id}
          onSubmit={handleSubmit}
          buildingId={document.building_id}
          values={{
            ...document.data,
            date: document.date,
            signer_id: document.employees.signer[0].employee_id,
          }}
        />
      </FormDialog>
    </>
  )
}

export default EditItem2Dialog
