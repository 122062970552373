import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { addAuthorization } from '../../util/http/addAuthorization'
import { TExternalDocument } from '../../types/external-document'

type TResult = {
  items: TExternalDocument[]
  metadata: any
}

export const externalDocumentsApi = createApi({
  reducerPath: 'externalDocuments',
  tagTypes: ['ExternalDocuments'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1/',
    prepareHeaders: addAuthorization,
    validateStatus: (response) => response.ok,
  }),
  endpoints: (builder) => ({
    getExternalDocuments: builder.query<TResult, any>({
      query: ({ buildingId, params }) => ({
        url: `/buildings/${buildingId}/external-documents`,
        method: 'GET',
        // params: params
      }),
      providesTags: ['ExternalDocuments'],
    }),
    addExternalDocument: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/external-documents`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ExternalDocuments'],
    }),
    editExternalDocument: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/external-documents/edit`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ExternalDocuments'],
    }),
    removeExternalDocument: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/external-documents/remove`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ExternalDocuments'],
    }),
    attachFile: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/external-documents/attach-file`,
        method: 'POST',
        body,
        formData: true,
      }),
      invalidatesTags: ['ExternalDocuments'],
    }),
    detachFile: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/external-documents/detach-file`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ExternalDocuments'],
    }),
    signFile: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/external-documents/sign`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ExternalDocuments'],
    }),
  }),
})

export const {
  useGetExternalDocumentsQuery,
  useAddExternalDocumentMutation,
  useEditExternalDocumentMutation,
  useRemoveExternalDocumentMutation,
  useAttachFileMutation,
  useDetachFileMutation,
  useSignFileMutation,
} = externalDocumentsApi
