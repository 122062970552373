import { createTheme, ThemeOptions } from '@mui/material'
import { ruRU } from '@mui/material/locale'

const common: ThemeOptions = {
  components: {
    MuiTab: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          textTransform: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 5,
          boxShadow: 'none',
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
  },
  shape: {
    borderRadius: 8,
  },
}

const darkTheme = createTheme(
  {
    palette: {
      mode: 'dark',
      background: {
        default: '#121212',
        paper: '#292929',
      },
    },
    ...common,
  },
  ruRU
)

const lightTheme = createTheme(
  {
    palette: {
      mode: 'light',
      primary: {
        dark: '#24387c', // '#001970',
        main: '#3451b2', // '#303f9f',
        light: '#5c73c1', // '#666ad1',
      },
      secondary: {
        dark: '#1E88E5',
        main: '#2196F3',
        light: '#E3F2FD',
      },
      error: {
        dark: '#C62828',
        main: '#F44336',
        light: '#EF9A9A',
      },
      success: {
        dark: '#27632a',
        main: '#388e3c',
        light: '#5fa463',
      },
      info: {
        dark: '#1769aa',
        main: '#2196f3',
        light: '#4dabf5',
      },
      background: {
        default: 'rgb(238, 242, 246)',
      },
    },
    ...common,
  },
  ruRU
)

export { darkTheme, lightTheme }
