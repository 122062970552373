import { TFile } from './file'

export type TExternalDocumentType =
  | 'administrative_document'
  | 'default'
  // 'pd' |
  // 'rd' |
  | 'isp'
  | 'result'
  | 'dpk'
  | 'act'

export const extDocTypesList: Record<TExternalDocumentType, string> = {
  administrative_document: 'Распорядительный документ, подтверждающий полномочия',
  // 'pd' : 'Раздел ПД',
  // 'rd' : 'Раздел РД',
  isp: 'Исполнительная схема (чертеж)',
  result: 'Результат экспертиз, обследований, лабораторных и иных испытаний',
  dpk: 'Документ, подтверждающий качество (ДПК) материала (изделия)',
  act: 'Акт',
  default: 'Другое',
}

export type TExternalDocumentSignature = {
  id: string
  user_id: string
  date: string
}

export type TExternalDocument = {
  id: string
  building_id: string
  name: string
  number: string
  date: string
  date_expire: string | null
  type: TExternalDocumentType
  file?: TFile
  owner_id: string
  signatures: TExternalDocumentSignature[]
}
