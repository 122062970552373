import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { addAuthorization } from '../../util/http/addAuthorization'
import { IXmlDocument } from '../../types/xml'

type TResponse = IXmlDocument[]

type TCreateRequest = {
  buildingId: string
  body: {
    document_id: string
    type: string
  }
}

export const xmlApi = createApi({
  reducerPath: 'xml',
  tagTypes: ['XmlDocuments'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1/',
    prepareHeaders: addAuthorization,
    validateStatus: (response) => response.ok,
  }),
  endpoints: (builder) => ({
    getXmlDocuments: builder.query<TResponse, string>({
      query: ( buildingId ) => ({
        url: `/buildings/${buildingId}/xml/documents`,
        method: 'GET',
        // params: params
      }),
      providesTags: (result, error, arg) => [{ type: 'XmlDocuments', id: arg }],
    }),
    createXmlDocument: builder.mutation<void, TCreateRequest>({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/xml/create-document`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'XmlDocuments', id: arg.buildingId }],
    }),
  }),
})

export const {
  useGetXmlDocumentsQuery,
  useCreateXmlDocumentMutation,
} = xmlApi
