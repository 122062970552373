import { TPrdDocument } from '../../types/prd'
import {
  Backdrop,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import * as React from 'react'
import { useAuthorization } from '../../auth'
import { download } from '../../util/download'
import { formatDate } from '../../util/date'
import { useState } from 'react'
import PrdChangeAddDialog from './PrdChangeAddDialog'
import { Add, Delete } from '@mui/icons-material'
import { LightButton } from '../common/LightButton'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { useDeleteChangeMutation } from '../../store/prd'
import { toastError } from '../../util/toastError'
import { useConfirm } from '../../util/useConfirm'

type Props = {
  document: TPrdDocument
}
const PrdDocumentChanges = ({ document }: Props) => {
  const authManager = useAuthorization()
  const isManage = authManager.vote('Prd', 'manage')
  const [open, setOpen] = useState(false)
  const [openBackdrop, setOpenBackdrop] = useState(false)
  const [deleteChange] = useDeleteChangeMutation()
  const confirm = useConfirm()

  const handleDownload = (file: any) => {
    const url = `/api/v1/buildings/${document.building_id}/files/${file.id}`
    setOpenBackdrop(true)
    download(url, file.name, file.media_type)
      .finally(() => setOpenBackdrop(false))
  }

  const handleDelete = (id: string) => {
    confirm({
      description: 'Удалить изменение?',
    }).then(() => {
      deleteChange({
        buildingId: document.building_id,
        body: {
          document_id: document.id,
          change_id: id,
        },
      })
        .unwrap()
        .catch(toastError)
    })
  }

  return (
    <>
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card variant={'elevation'} elevation={0} sx={{ mb: 2 }}>
        <CardHeader
          titleTypographyProps={{ variant: 'body1', sx: { fontWeight: 'medium' } }}
          title={'Изменения'}
        />
        <CardContent sx={{ p: 0 }}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>№</TableCell>
                <TableCell>Описание</TableCell>
                <TableCell></TableCell>
                {isManage && (
                  <TableCell align="right">
                    <LightButton startIcon={<Add />} onClick={() => setOpen(true)}>
                      Добавить
                    </LightButton>
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
            <TableBody>
              {document.changes.map((change) => (
                <TableRow key={change.id}>
                  <TableCell>{change.number}</TableCell>
                  <TableCell>
                    <Typography variant={'caption'}>{formatDate(change.date)}</Typography>
                    <Typography variant={'subtitle2'}>{change.description}</Typography>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => handleDownload(change.file)}
                      title={change.file.name}
                      color={'success'}
                    >
                      <AttachFileIcon />
                    </IconButton>
                  </TableCell>
                  {isManage && (
                    <TableCell align="right">
                      <IconButton
                        color="error"
                        title="Удалить"
                        onClick={() => handleDelete(change.id)}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
      <PrdChangeAddDialog
        open={open}
        onClose={() => setOpen(false)}
        document={document}
        onSave={() => setOpen(false)}
      />
    </>
  )
}

export { PrdDocumentChanges }
