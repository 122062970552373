import { CardHeader } from '@mui/material'
import React from 'react'

export const Part5 = () => {
  return (
    <>
      <CardHeader title={'Сведения о строительном контроле лица, осуществляющего строительство'} />
    </>
  )
}
