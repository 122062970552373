import { Box } from '@mui/material'
import FormInput from '../../form/FormInput'
import { FormProvider, useForm } from 'react-hook-form'
import React from 'react'
import Typography from '@mui/material/Typography'
import FormInputAddress from '../../form/FormInputAddress'
import FormSelect from '../../form/FormSelect'
import FormInputInn from '../../form/FormInputInn'
import { object, string, TypeOf } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { toast } from 'react-toastify'
import { useCompaniesRolesList } from '../../../util/useCompaniesRolesList'
import { TCompanyFilter } from '../../../types/building'

let registerSchema = object({
  inn: string()
    .min(1, 'Необходимо заполнить')
    .regex(/^\d{10}(\d{2})?$/, 'Некорректный ИНН'),
  ogrn: string()
    .min(1, 'Необходимо заполнить')
    .regex(/^\d{13}$/, 'Некорректный ОГРН'),
  name: string().min(1, 'Необходимо заполнить'),
  full_name: string().min(1, 'Необходимо заполнить'),
  phone: string().nullable().refine(
    (value) => value ? /^(\+7|7|8)[()\d\s-]{7,20}$/.test(value) : true,
    'Некорректный номер телефона'
  ), // regex(/^(\+7|7|8)[()\d\s-]{7,20}$/, 'Некорректный номер телефона'),
  email: string().nullable().refine(
    (value) => value ? /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,5}$/.test(value) : true,
    'Некорректный E-mail'
  ),
  address: string().min(1, 'Необходимо заполнить'),
  sro_name: string(),
  sro_inn: string(),
  sro_ogrn: string(),
})

type TFilter = {
  role: string[]
}

type Props = {
  id: string
  onSubmit: (values: any) => void
  values?: Record<string, string>
  buildingId: string
  isAdd: boolean,
  filter?: TCompanyFilter
}

export const CompanyLegalForm = ({ onSubmit, id, values = {}, buildingId, isAdd = true, filter }: Props) => {
  if (isAdd) {
    registerSchema = registerSchema.extend({
      role: string().min(1, 'Необходимо заполнить'),
    })
  }

  type RegisterInput = TypeOf<typeof registerSchema>

  const methods = useForm<RegisterInput>({
    resolver: zodResolver(registerSchema),
    defaultValues: values || {},
    values: (values as any) || {},
  })

  const { setValue, trigger } = methods

  const roles = useCompaniesRolesList(buildingId, filter)

  const handleInnSelect = (data: any) => {
    if (!data) return
    if (data.data.type !== 'LEGAL') {
      toast.error('Компания не являетя юридическим лицом', { position: 'top-center' })
      return
    }
    setValue('name', data.data.name.short_with_opf)
    setValue('full_name', data.data.name.full_with_opf)
    setValue('ogrn', data.data.ogrn)
    setValue('address', data.data.address.unrestricted_value)
    trigger('address')
  }

  const handleSroInnSelect = (data: any) => {
    if (!data) return
    setValue('sro_name', data.data.name.short_with_opf)
    setValue('sro_ogrn', data.data.ogrn)
  }

  const onFormSubmit = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    return methods.handleSubmit((values: any) => {
      onSubmit(values)
    })(e)
  }

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        id={id}
        onSubmit={onFormSubmit}
        noValidate
        autoComplete="off"
      >
        <Box sx={{ pt: 1 }}>
          {isAdd && <FormSelect name="role" label="Назначение" items={roles} />}
          <FormInputInn name="inn" label="ИНН" cType={'LEGAL'} onInnSelect={handleInnSelect} />
          <FormInput name="name" label="Наименование" />
          <FormInput name="full_name" label="Полное наименование" />
          <FormInput name="ogrn" label="ОГРН" />
          <FormInputAddress name="address" label="Адрес" />
          <FormInput name="phone" label="Телефон" />
          <FormInput name="email" label="E-mail" />
          <Typography variant={'h6'}>СРО:</Typography>
          <FormInputInn name="sro_inn" label="ИНН" onInnSelect={handleSroInnSelect} />
          <FormInput name="sro_name" label="Наименование" />
          <FormInput name="sro_ogrn" label="ОГРН" />
        </Box>
      </Box>
    </FormProvider>
  )
}
