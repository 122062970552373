import { TAuth, TPolicyItem, TPolicyObject } from '../types/auth'

export class AuthManager {
  readonly context: TAuth | undefined
  usedPolicy = ''

  constructor(context: TAuth | undefined) {
    if (context !== undefined) {
      this.context = context
    }
  }

  public vote(object: TPolicyObject, action: string, or = false): boolean {
    if (this.context === undefined) {
      throw Error('The context is not initialized')
    }

    if (or) {
      this.usedPolicy = 'OR'
      return or
    }

    this.usedPolicy = ''

    const config = this.filterObjectAction(object, action, this.context.policy)

    if (config.length === 0) {
      this.usedPolicy = 'There are no policies for the object role and action'
      return false
    }

    for (let i = 0; i < this.context.roles.length; i++) {
      if (this.roleAccess(this.context.roles[i], config)) {
        return true
      }
    }

    return false
  }

  roleAccess(role: string, config: TPolicyItem[]): boolean {
    const results: Record<number, TPolicyItem> = {}

    config.forEach((item) => {
      if (this.isMatch(role, item.role)) {
        results[this.priority(item)] = item
      }
    })

    if (Object.keys(results).length > 0) {
      const item = this.getPriorityItem(results)
      this.usedPolicy =
        `${item.role} | ${item.object} | ${item.action} | ` + (item.access ? 'GRANTED' : 'DENIED')
      return item.access
    }
    return false
  }

  getPriorityItem(data: Record<number, TPolicyItem>): TPolicyItem {
    let i = 0
    let result = data[Number(Object.keys(data)[0])]

    for (const priority in data) {
      if (Object.hasOwn(data, priority) && Number(priority) >= i) {
        result = data[priority]
        i = Number(priority)
      }
    }
    return result
  }

  private priority(item: TPolicyItem): number {
    let priority = 0

    priority += Math.pow(this.isWildCard(item.role) ? 2 : 3, 3)
    const objBase = this.isAsterisk(item.object) ? 1 : this.isWildCard(item.object) ? 2 : 3
    priority += Math.pow(objBase, 2)
    priority += this.isAsterisk(item.action) ? 3 : 1

    return priority
  }

  private filterObjectAction(
    object: TPolicyObject,
    action: string,
    policy: TPolicyItem[]
  ): TPolicyItem[] {
    return policy.filter((item) => {
      const isAction = this.isAsterisk(item.action) || item.action === action
      return this.isMatch(object, item.object) && isAction
    })
  }

  private isMatch(value: string, configValue: string) {
    if (value === configValue || configValue === '*') return true

    // check wildcard
    if (this.isWildCard(configValue)) {
      return this.isStart(value, configValue)
    }

    return false
  }

  private isWildCard(value: string): boolean {
    return value.indexOf('.*', value.length - 2) !== -1
  }

  private isAsterisk(value: string): boolean {
    return value === '*'
  }

  private isStart(value: string, configValue: string): boolean {
    return value.indexOf(configValue.substring(0, configValue.length - 1)) === 0
  }
}
