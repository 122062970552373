import { TBuilding, TCompany } from '../../types/building'
import { Card, CardContent, CardHeader, List, ListItem, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { useAuthorization } from '../../auth'
import { useGetDocumentsQuery, useGetGroupsQuery } from '../../store/prd'
import { TPrdDocument, TPrdGroup } from '../../types/prd'
import { CompanyPrdDialog } from './CompanyPrdDialog'

type TGroupedDocs = { group: TPrdGroup; docs: TPrdDocument[] }[]

const groupPrd = (
  company: TCompany,
  docs: TPrdDocument[],
  groups: TPrdGroup[]
): TGroupedDocs => {
  if (!company.prd) return []

  const companyPrd: string[] = company.prd
  const res: TGroupedDocs = []

  groups.forEach((group) => {
    const groupDocs = docs.filter(
      (doc) => companyPrd.includes(doc.id) && doc.group_id === group.id
    )
    if (groupDocs.length > 0) {
      res.push({
        group: group,
        docs: groupDocs,
      })
    }
  })
  return res
}

type Props = {
  company: TCompany
  building: TBuilding
}
const CompanyPrd = ({ company, building }: Props) => {
  const authManager = useAuthorization()
  const isManage = authManager.vote('Company', 'manage')

  const { data: documents } = useGetDocumentsQuery(building.id)
  const { data: groups } = useGetGroupsQuery(building.id)

  const groupedPrd = useMemo(() => {
    if (documents && groups) {
      return groupPrd(company, documents, groups)
    }
    return []
  }, [company, documents, groups])

  return (
    <Card>
      <CardHeader
        title="Разделы проектной документации"
        subheader={'ограничение по разделам'}
        action={
          isManage && (
            <CompanyPrdDialog
              building={building}
              company={company}
              values={company.prd ? { documents: company.prd } : { documents: [] }}
            />
          )
        }
      />
      <CardContent>
        {company.prd && company.prd.length > 0 ? (
          groupedPrd.map((groupData) => (
            <List key={groupData.group.id} dense disablePadding>
              <ListItem disablePadding>
                <Typography variant={'body1'}>{groupData.group.name}</Typography>
              </ListItem>
              <List dense>
                {groupData.docs.map((doc) => (
                  <ListItem key={doc.id}>
                    <Typography variant={'subtitle2'} color={'text.disabled'}>
                      {doc.name} {doc.code}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </List>
          ))
        ) : (
          <Typography variant={'body1'}>Без ограничений</Typography>
        )}
      </CardContent>
    </Card>
  )
}

export { CompanyPrd }
