async function parseErrors(error: Error | Response | any) {
  if (error instanceof Error) {
    return {}
  }
  if (error.status === 422) {
    if (error?.data.errors) {
      return error.data.errors
    }

    const data = await error.json()
    return data.errors
  }
  return {}
}

export default parseErrors
