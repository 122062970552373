import { FormDialog } from '../common/FormDialog'
import * as React from 'react'
import { useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { useEditWorkMutation, useGetGroupsQuery, useMoveWorkMutation } from '../../store/works'
import { TWork } from '../../types/works'
import WorkForm from './WorkForm'

type Props = {
  work: TWork
  onEdit: () => void
  onClose: () => void
  open: boolean
}

const EditWorkDialog = ({ work, open, onEdit, onClose }: Props) => {
  const id = 'edit-work-dialog'
  const [isLoading, setIsLoading] = useState(false)

  const [editWork] = useEditWorkMutation()
  const [moveWork] = useMoveWorkMutation()
  const { data: groups } = useGetGroupsQuery(work.building_id)

  const selectGroupItems = useMemo(
    () => groups && Object.values(groups).map((group) => ({ value: group.id, label: group.name })),
    [groups]
  )

  const handleSubmit = (values: any, methods: any) => {
    setIsLoading(true)

    editWork({
      buildingId: work.building_id,
      body: { ...values, work_id: work.id },
    })
      .unwrap()
      .then(() => {
        methods.reset()
        setIsLoading(false)
        onEdit()
      })
      .catch((e) => {
        toast.error(e)
        setIsLoading(false)
      })

    if (values.group_id !== work.group_id) {
      moveWork({
        buildingId: work.building_id,
        body: { work_id: work.id, group_id: values.group_id },
      })
    }
  }

  return (
    <>
      <FormDialog
        formId={id}
        open={open}
        onClose={onClose}
        title={'Редактировать группу'}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <WorkForm id={id} onSubmit={handleSubmit} values={work} groups={selectGroupItems} />
      </FormDialog>
    </>
  )
}

export default EditWorkDialog
