import { TPrdGroup } from '../../types/prd'
import { useDeleteDocumentMutation, useGetDocumentsQuery } from '../../store/prd'
import { useEffect, useMemo, useState } from 'react'
import { Box, Button, Link, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { RightPanel, SkidPagination, useSkidPagination } from '../common'
import * as React from 'react'
import Checkbox from '@mui/material/Checkbox'
import { useChecked } from '../../util/useChecked'
import { useGetCompaniesQuery } from '../../store/companies'
import { companyName } from '../../util/companies'
import Typography from '@mui/material/Typography'
import { PrdDocumentView } from './PrdDocumentView'
import PrdDocumentEditDialog from './PrdDocumentEditDialog'
import { TBuilding } from '../../types/building'
import { useGetUserQuery } from '../../store/user'
import { toastError } from '../../util/toastError'
import { MoveDialog } from './MoveDialog'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { TableHoverRow } from '../common/TableHoverRow'

type Props = {
  building: TBuilding
  group: TPrdGroup
  id?: string
  isManage: boolean
}

const DocumentsList = ({ group, building, id, isManage }: Props) => {
  const { pages, page, offset, limit, onPageChange, setTotal, reset } = useSkidPagination(20)
  const { data: documents } = useGetDocumentsQuery(group.building_id)
  const { data: companies } = useGetCompaniesQuery(group.building_id)
  const { data: user } = useGetUserQuery()
  const [editOpen, setEditOpen] = useState(false)
  const [moveOpen, setMoveOpen] = useState(false)
  const [deleteDocument] = useDeleteDocumentMutation()
  const [currentId, setCurrentId] = useState<string | undefined>()

  const groupDocuments = useMemo(() => {
    if (!documents) return []
    return documents.filter((d) => d.group_id === group.id).slice(offset, offset + limit)
  }, [group, documents, offset, limit])

  const { onCheck, checked, isCheckedAll, isIndeterminate } = useChecked(
    groupDocuments.map((d) => d.id)
  )

  useEffect(() => {
    if (id !== undefined) {
      setCurrentId(id)
    }
  }, [id])

  const document = useMemo(() => {
    if (!currentId) return
    return groupDocuments.find((d) => d.id === currentId)
  }, [currentId, groupDocuments])

  useEffect(() => {
    setTotal(groupDocuments.length)
  }, [groupDocuments])

  const handleDelete = () => {
    if (!document) return
    if (confirm('Удалить документ?')) {
      deleteDocument({
        buildingId: building.id,
        body: {
          group_id: document.group_id,
          document_id: document.id,
        },
      })
        .unwrap()
        .then((res) => {
          setCurrentId(undefined)
        })
        .catch(toastError)
    }
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            {isManage && (
              <TableCell sx={{ width: '50px' }}>
                <Checkbox
                  checked={isCheckedAll}
                  value={'all'}
                  onChange={onCheck}
                  indeterminate={isIndeterminate}
                />
              </TableCell>
            )}
            <TableCell>Наименование/шифр</TableCell>
            <TableCell>Количество листов</TableCell>
            <TableCell>Автор</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {groupDocuments.map((doc) => (
            <TableHoverRow key={doc.id} onClick={() => setCurrentId(doc.id)}>
              {isManage && (
                <TableCell onClick={(e) => e.stopPropagation()}>
                  <Checkbox checked={checked.includes(doc.id)} value={doc.id} onChange={onCheck} />
                </TableCell>
              )}
              <TableCell>
                <Typography variant={'body2'} sx={{ fontWeight: 'medium' }}>
                  {doc.name}
                </Typography>
                <Typography variant={'caption'} color={'text.disabled'}>
                  {doc.code}
                </Typography>
              </TableCell>
              <TableCell>{doc.lists_count}</TableCell>
              <TableCell>{companies && companyName(companies, doc.designer_id)}</TableCell>
              <TableCell>{doc.file && <AttachFileIcon color="primary" />}</TableCell>
            </TableHoverRow>
          ))}
        </TableBody>
      </Table>
      <SkidPagination page={page} pages={pages} onChange={onPageChange} />
      {isManage && (
        <Box sx={{ p: 2 }}>
          {checked.length > 0 && (
            <Button variant={'outlined'} onClick={() => setMoveOpen(true)}>
              Переместить
            </Button>
          )}
        </Box>
      )}
      <RightPanel
        open={Boolean(currentId)}
        onClose={() => setCurrentId(undefined)}
        onOpen={() => setCurrentId(undefined)}
        actions={
          <>
            {document && isManage && (
              <Button onClick={() => setEditOpen(true)} variant={'outlined'}>
                Редактировать
              </Button>
            )}
            {document && isManage && (
              <Button onClick={handleDelete} color={'error'} variant={'outlined'}>
                Удалить
              </Button>
            )}
          </>
        }
      >
        {document && <PrdDocumentView document={document} />}
      </RightPanel>
      {document && isManage && (
        <PrdDocumentEditDialog
          building={building}
          document={document}
          open={editOpen}
          onClose={() => setEditOpen(false)}
          onSave={() => setEditOpen(false)}
        />
      )}
      {checked.length > 0 && (
        <MoveDialog
          open={moveOpen}
          documents={checked}
          group={group}
          onClose={() => setMoveOpen(false)}
        />
      )}
    </>
  )
}
export { DocumentsList }
