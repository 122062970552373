import { FormDialog } from '../common/FormDialog'
import * as React from 'react'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { useEditGroupMutation } from '../../store/works'
import GroupForm from './GroupForm'
import { TWorkGroup } from '../../types/works'

type Props = {
  group: TWorkGroup
  onEdit: () => void
  onClose: () => void
  open: boolean
}

const EditGroupDialog = ({ group, open, onEdit, onClose }: Props) => {
  const id = 'edit-work-group-dialog'
  const [isLoading, setIsLoading] = useState(false)

  const [editGroup] = useEditGroupMutation()

  const handleSubmit = (values: any, methods: any) => {
    setIsLoading(true)

    editGroup({
      buildingId: group.building_id,
      body: { ...values, group_id: group.id },
    })
      .unwrap()
      .then(() => {
        methods.reset()
        setIsLoading(false)
        onEdit()
      })
      .catch((e) => {
        toast.error(e)
        setIsLoading(false)
      })
  }

  return (
    <>
      <FormDialog
        formId={id}
        open={open}
        onClose={onClose}
        title={'Редактировать группу'}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <GroupForm id={id} onSubmit={handleSubmit} values={group} />
      </FormDialog>
    </>
  )
}

export default EditGroupDialog
