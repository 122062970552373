import {
  FormHelperText,
  FormControl,
  InputProps,
  Autocomplete,
  TextField,
} from '@mui/material'
import React, { FC, ReactNode } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

type IFormInputProps = {
  name: string
  label: string
  hint?: string
  startAdornment?: ReactNode
  endAdornment?: ReactNode
  variants: string[]
  multiple?: boolean
} & InputProps

const AutocompleteInput = ({
  name,
  label,
  hint,
  startAdornment,
  endAdornment,
  variants,
  multiple = false,
  ...otherProps
}: IFormInputProps) => {
  const {
    control,
    setValue,
    formState: { errors, defaultValues },
    getValues,
    getFieldState
  } = useFormContext()

  const value = getValues(name)

  const error = getFieldState(name).error
  const errorMessage: string = (error ? error.message : '') as string
  const isInvalidTypeError = error && error.type === 'invalid_type'

  const hintEl = hint ? <FormHelperText>{hint}</FormHelperText> : null

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <FormControl fullWidth margin={'dense'}>
          <Autocomplete
            freeSolo
            options={variants}
            defaultValue={value}
            onChange={(e, val) => {
              field.onChange(val)
            }}
            autoSelect={multiple}
            multiple={multiple}
            renderInput={(params) => (
              <TextField
                {...params}
                {...field}
                error={!!error && !isInvalidTypeError}
                label={label}
                variant="outlined"
              />
            )}
          />
          {hintEl}
          {!isInvalidTypeError && <FormHelperText error={!!error}>{errorMessage}</FormHelperText>}
        </FormControl>
      )}
    />
  )
}

export default AutocompleteInput
