import { Link, useOutletContext } from 'react-router-dom'
import React from 'react'
import {
  Box,
  Button,
  CardHeader,
  LinearProgress,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
} from '@mui/material'
import AutoStoriesIcon from '@mui/icons-material/AutoStories'
import Avatar from '@mui/material/Avatar'
import {
  useCreateGwjMutation,
  useCreateImcMutation,
  useGetJournalsQuery,
} from '../../../store/journals'
import { Add } from '@mui/icons-material'
import { BaseContainer } from '../../../components/common/Container'
import { IOutletBuilding } from '../../../types/outlet-context'

export const JournalsMain = () => {
  const { building } = useOutletContext<IOutletBuilding>()

  const { data, isLoading, isSuccess } = useGetJournalsQuery(building.id)
  const [createGwj] = useCreateGwjMutation()
  const [createImc] = useCreateImcMutation()

  if (isLoading) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    )
  }

  const handleCreateGwj = () => {
    createGwj({
      buildingId: building.id,
      body: {
        number: '1',
      },
    })
  }

  const handleCreateImc = () => {
    createImc({
      buildingId: building.id,
      body: {
        number: '1',
      },
    })
  }

  return (
    <BaseContainer>
      <Paper>
        <CardHeader
          action={
            <>
              {data && data.gwj.length === 0 && (
                <Button startIcon={<Add />} onClick={handleCreateGwj}>
                  Создать ОЖР
                </Button>
              )}
              {data && data.imc.length === 0 && (
                <Button startIcon={<Add />} onClick={handleCreateImc}>
                  Создать ЖВК
                </Button>
              )}
            </>
          }
        />
        <List component="nav" sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {data &&
            data.gwj &&
            data.gwj.map((gwj) => {
              return (
                <ListItemButton key={gwj.id} component={Link} to={`gwj/${gwj.id}`}>
                  <ListItemAvatar>
                    <Avatar>
                      <AutoStoriesIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Общий журнал работ" secondary={`№ ${gwj.number}`} />
                </ListItemButton>
              )
            })}
          {data &&
            data.imc &&
            data.imc.map((imc) => {
              return (
                <ListItemButton key={imc.id} component={Link} to={`imc/${imc.id}`}>
                  <ListItemAvatar>
                    <Avatar>
                      <AutoStoriesIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Журнал входного контроля материалов"
                    secondary={`№ ${imc.number}`}
                  />
                </ListItemButton>
              )
            })}
        </List>
      </Paper>
    </BaseContainer>
  )
}
