import { Alert } from '@mui/material'
import { ReactNode } from 'react'

function AlertSuccess({
  message,
  children,
}: {
  message?: string | null
  children?: ReactNode
}): JSX.Element {
  return message || children ? <Alert severity="success">{message || children}</Alert> : <>{null}</>
}

export default AlertSuccess
