import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material'
import AddItem3AmountDialog from './AddItem3AmountDialog'
import { Delete } from '@mui/icons-material'
import * as React from 'react'
import { TWork } from '../../../../types/works'
import { useMemo } from 'react'
import { useGetStoreQuery } from '../../../../store/store'
import { IDocument } from '../../../../types/document'
import { useGetMaterialsQuery, useGetUnitsQuery } from '../../../../store/lists'
import { IStore } from '../../../../types/store'
import { StyledTableRow } from './StyledTableRow'
import ParseError from '../../../../api/parseError'
import { toast } from 'react-toastify'
import { useRemoveGwj3ItemAmountMutation } from '../../../../store/gwj'

const workMaterialAmount = (work: TWork, maId: string): any => {
  return work.materials_amounts?.find((wma) => wma.id === maId)
}

const getWma = (id: string, stores: IStore[] | undefined) => {
  const data = {} as Record<string, number>
  if (stores === undefined) return data
  stores.forEach((store) => {
    store.debits.forEach((debit) => {
      if (debit.item_id === id) {
        if (data[debit.wma_id] === undefined) {
          data[debit.wma_id] = 0
        }
        data[debit.wma_id] += debit.value
      }
    })
  })
  return data
}

type Props = {
  item: IDocument
  works: Record<string, TWork>
  isManage: boolean
}

const Item3ViewMaterials = ({ item, works, isManage }: Props) => {
  const { data: materials } = useGetMaterialsQuery(item.building_id)

  const { data: store, refetch: refetchStore } = useGetStoreQuery(item.building_id)

  const { data: units } = useGetUnitsQuery(item.building_id)

  const [removeItemAmount] = useRemoveGwj3ItemAmountMutation()

  const itemMa = useMemo(() => getWma(item.id, store), [store, item])

  const removeItemAmountHandler = (wmaId: string) => {
    if (window.confirm('Удалить использование данного материала из записи?')) {
      removeItemAmount({
        buildingId: item.building_id,
        body: {
          record_id: item.id,
          work_id: item.works[0],
          wma_id: wmaId,
        },
      })
        .unwrap()
        .then((res) => {
          refetchStore()
        })
        .catch(async (e) => {
          const error = await ParseError(e)
          toast.error(error)
        })
    }
  }

  return (
    <Card variant={'elevation'} elevation={0} sx={{ mb: 2 }}>
      <CardHeader
        titleTypographyProps={{ variant: 'body1', sx: { fontWeight: 'medium' } }}
        title={'Используемые материалы'}
      />
      <CardContent sx={{ p: 0 }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Материал</TableCell>
              <TableCell>Объем</TableCell>
              <TableCell align="right">
                {isManage && (
                  <AddItem3AmountDialog item={item} itemMa={itemMa} onAdd={() => refetchStore()} />
                )}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            {itemMa &&
              Object.keys(itemMa).map((wma_id) => {
                if (!works || !materials || !units) return ''
                const wma = workMaterialAmount(works[item.works[0]], wma_id)
                if (!wma) return ''
                return (
                  <StyledTableRow key={wma_id}>
                    <TableCell sx={{ whiteSpace: 'pre-wrap', width: '100%' }}>
                      {materials[wma.material_id]?.name}
                    </TableCell>
                    <TableCell sx={{ width: '1px', whiteSpace: 'nowrap' }}>
                      {itemMa[wma_id]} {units[wma.unit_id]?.name}
                    </TableCell>
                    <TableCell align="right">
                      {isManage && (
                        <IconButton
                          color="error"
                          title="Удалить"
                          onClick={() => removeItemAmountHandler(wma_id)}
                        >
                          <Delete />
                        </IconButton>
                      )}
                    </TableCell>
                  </StyledTableRow>
                )
              })}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}
export { Item3ViewMaterials }
