import React, { useEffect, useMemo, useState } from 'react'
import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  LinearProgress,
  OutlinedInput,
} from '@mui/material'
import { BuildingCard } from './BuildingCard'
import AlertError from '../alert/AlertError'
import { useGetBuildingsQuery } from '../../store/buildings'
import { IBuildingCard } from '../../store/buildings/types'
import SearchIcon from '@mui/icons-material/Search'
import { SkidPagination } from '../common'

function BuildingsList(): JSX.Element {
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(24)
  const [offset, setOffset] = useState(0)

  const [filter, setFilter] = useState<string>('')
  const [searchValue, setSearchValue] = useState<string>('')

  const { data, error, isLoading, isSuccess } = useGetBuildingsQuery(
    { limit, offset, filter: filter },
    {
      refetchOnMountOrArgChange: true,
      pollingInterval: 120000,
    }
  )

  const pages = useMemo(() => {
    if (isSuccess && data && data.metadata) {
      return Math.ceil(data.metadata.total / limit)
    }
    return 10
  }, [page, isSuccess, data])

  useEffect(() => {
    const timeOutId = setTimeout(() => setFilter(searchValue), 1000)
    return () => clearTimeout(timeOutId)
  }, [searchValue])

  if (isLoading) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    )
  }

  if (error) {
    return <AlertError>Произошла ошибка, попробуйте перезагрузить страницу позднее</AlertError>
  }

  const handlePageChange = (e: any, p: number) => {
    setPage(p)
    setOffset((p - 1) * limit)
  }

  const handleFilterChange = (e: any) => {
    setSearchValue(e.target.value)
  }

  return (
    <>
      {isSuccess && (
        <>
          <Box sx={{ mb: 4, justifyContent: 'center', display: 'flex' }}>
            <FormControl sx={{ width: '100%', maxWidth: '500px' }} variant="outlined">
              <OutlinedInput
                type={'text'}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                onChange={handleFilterChange}
                value={searchValue}
                autoFocus
              />
            </FormControl>
          </Box>
          <Grid container spacing={2} sx={{ alignItems: 'stretch' }}>
            {data &&
              data.items.map((building: IBuildingCard) => {
                return (
                  <Grid item xs={12} sm={6} md={4} key={building.id}>
                    <BuildingCard building={building} />
                  </Grid>
                )
              })}
          </Grid>
          <SkidPagination page={page} pages={pages} onChange={handlePageChange} />
        </>
      )}
    </>
  )
}

export default BuildingsList
