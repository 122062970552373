import { Box, Container, ContainerOwnProps, styled } from '@mui/material'

const BaseContainer = ({ maxWidth, ...props }: ContainerOwnProps) => {
  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <Container {...props} maxWidth={false}>
        {props.children}
      </Container>
    </Box>
  )
}

export { BaseContainer }
