import React, { useEffect, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { toast } from 'react-toastify'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { parseError } from '../../api'
import { useAddProfileMutation, useGetProfileQuery } from '../../store/profile'
import { ProfileFrom } from './ProfileFrom'
import { Edit } from '@mui/icons-material'

export const EditDialog = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const { data: profile } = useGetProfileQuery()
  const [addProfile] = useAddProfileMutation()
  const [formValues, setFormValues] = useState<Record<string, string | null>>({})

  useEffect(() => {
    if (profile === undefined || profile === null) return
    setFormValues({
      last_name: profile.last_name,
      first_name: profile.first_name,
      middle_name: profile.middle_name,
      inn: profile.inn,
      specialist_id_number: profile.specialist_id_number || '',
    })
  }, [profile])

  const onSubmitHandler = (values: any, methods: any) => {
    setIsLoading(true)
    addProfile(values)
      .then((res: any) => {
        if (res.error) {
          throw res.error
        }
        toast.success('Профиль успешно сохранен', {
          position: 'top-center',
        })
        setIsLoading(false)
        methods.reset()
        setOpen(false)
      })
      .catch(async (error) => {
        const text = await parseError(error)
        toast.error(text, {
          position: 'top-center',
        })
        setIsLoading(false)
      })
  }

  return (
    <>
      <Button startIcon={<Edit />} onClick={() => setOpen(true)}>
        Изменить
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Профиль</DialogTitle>
        <DialogContent>
          <ProfileFrom id="profile-edit-form" onSubmit={onSubmitHandler} values={formValues} />
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            type="submit"
            disableElevation
            loading={isLoading}
            form={'profile-edit-form'}
          >
            Сохранить
          </LoadingButton>
          <Button onClick={() => setOpen(false)}>Закрыть</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
