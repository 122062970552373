import { TBuilding } from '../../types/building'
import { TPrdGroup } from '../../types/prd'
import { Card, CardHeader, Stack } from '@mui/material'
import { Add } from '@mui/icons-material'
import React, { useState } from 'react'
import { DocumentsList } from './DocumentsList'
import PrdDocumentAddDialog from './PrdDocumentAddDialog'
import { LightButton } from '../common/LightButton'

type Props = {
  building: TBuilding
  group: TPrdGroup
  isManage: boolean
}

const PrdGroup = ({ building, group, isManage }: Props) => {
  const [addedId, setAddedId] = useState<string | undefined>()
  const [openAddDocument, setOpenAddDocument] = useState(false)

  return (
    <>
      <Card>
        <CardHeader
          subheader={group.name}
          action={
            isManage && (
              <Stack direction={'row'} spacing={1}>
                <LightButton
                  color={'primary'}
                  startIcon={<Add />}
                  onClick={() => setOpenAddDocument(true)}
                >
                  Добавить документ
                </LightButton>
              </Stack>
            )
          }
        />
        <DocumentsList group={group} building={building} id={addedId} isManage={isManage} />
      </Card>
      {isManage && (
        <PrdDocumentAddDialog
          building={building}
          open={openAddDocument}
          groupId={group.id}
          onClose={() => setOpenAddDocument(false)}
          onSave={(id) => {
            setAddedId(id)
            setOpenAddDocument(false)
          }}
        />
      )}
    </>
  )
}

export { PrdGroup }
