import { object, string, TypeOf } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Alert, Box, Stack } from '@mui/material'
import FormInput from '../form/FormInput'
import React from 'react'

const schema = {
  axes: string().nullable(),
  marks: string().nullable(),
  ranks: string().nullable(),
  floors: string().nullable(),
  tiers: string().nullable(),
  sections: string().nullable(),
  premises: string().nullable(),
  place: string().nullable(),
}
const registerSchema = object(schema)
  .partial()
  .refine(
    (data) =>
      data.axes ||
      data.marks ||
      data.ranks ||
      data.floors ||
      data.tiers ||
      data.sections ||
      data.premises ||
      data.place,
    {
      message: 'Должно быть заполнено хотя бы одно значение.',
      path: ['emptyError'],
    }
  )

interface Props {
  values?: any
  onSubmit: (values: TypeOf<typeof registerSchema>, methods: any) => void
  id?: string
}

const LocationForm = ({ values, onSubmit, id }: Props) => {
  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: values || {},
  })

  const {
    handleSubmit,
    formState: { isSubmitSuccessful, errors },
  } = methods

  const hs = handleSubmit((values: any) => {
    onSubmit(values, methods)
  })

  const onFormSubmit = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    return hs(e)
  }

  return (
    <FormProvider {...methods}>
      {(errors as any)?.emptyError && (
        <Alert variant={'outlined'} severity={'error'}>
          {(errors as any).emptyError?.message}
        </Alert>
      )}
      <Box id={id} component="form" onSubmit={onFormSubmit} noValidate autoComplete="off">
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ mt: 2, mb: 2 }}>
          <FormInput name="axes" label="Оси" />
          <FormInput name="marks" label="Отметки" />
        </Stack>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ mt: 2, mb: 2 }}>
          <FormInput name="ranks" label="Ряды" />
          <FormInput name="floors" label="Этажи" />
        </Stack>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ mt: 2, mb: 2 }}>
          <FormInput name="tiers" label="Ярусы" />
          <FormInput name="sections" label="Секции" />
        </Stack>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ mt: 2, mb: 2 }}>
          <FormInput name="premises" label="Помещения" />
          <FormInput name="place" label="Информация о местоположении, одной строкой" />
        </Stack>
      </Box>
    </FormProvider>
  )
}

export default LocationForm
