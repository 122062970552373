import { TFilterSetting, UseFilterResult } from './useFilter'
import React, { useEffect } from 'react'
import { Box, Card, CardContent, Stack } from '@mui/material'
import { FormInput, FormInputDate, FormInputSwitch, FormSelect } from '../form'
import Typography from '@mui/material/Typography'
import * as z from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { object, TypeOf } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'



const filterHeader = (text: string) => (
  <Box sx={{mb: 1}}>
    <Typography variant={'body1'} sx={{fontWeight: 'medium'}}>{text}</Typography>
  </Box>
)

const filters = {
  string: (setting: TFilterSetting) => (
    <FormInput name={setting.name} label={setting.label} />
  ),
  range: (setting: TFilterSetting) => (
    <>
      {filterHeader(setting.label)}
      <Stack spacing={2} direction={'row'}>
        <FormInput name={`${setting.name}.begin`} label={'от'}  />
        <FormInput name={`${setting.name}.end`} label={'до'} />
      </Stack>
    </>
  ),
  select: (setting: TFilterSetting) => (
    <FormSelect name={setting.name} label={setting.label} items={setting.data} />
  ),
  list: (setting: TFilterSetting) => (
    <FormSelect name={setting.name} label={setting.label} items={setting.data} multiple />
  ),
  date: (setting: TFilterSetting) => (
    <FormInputDate name={setting.name} label={setting.label} />
  ),
  dateRange: (setting: TFilterSetting) => (
    <>
      {filterHeader(setting.label)}
      <Stack spacing={2} direction={'row'}>
        <FormInputDate name={`${setting.name}.begin`} label={'от'}  />
        <FormInputDate name={`${setting.name}.end`} label={'до'} />
      </Stack>
    </>
  ),
  bool: (setting: TFilterSetting) => (
    <FormInputSwitch name={setting.name} label={setting.label} />
  ),

}

const getProperty = (obj: any, name: string) => name.split('.').reduce((o,i) => o !== undefined && o[i], obj)

interface Props  {
  filter: UseFilterResult
}

export const Filter = ({filter}: Props) => {

  const schema = filter.settings.reduce((acc, fs) => {
    if (['range', 'dateRange'].includes(fs.filter)) {
      return {...acc, [fs.name]: object({begin: z.string(), end: z.string()})}
    }
    if (['checkbox'].includes(fs.filter)){
      return {...acc, [fs.name]: z.array(z.string())}
    }
    if (fs.filter === 'bool') {
      return {...acc, [fs.name]: z.boolean()}
    }
    return {...acc, [fs.name]: z.string()}
  }, {})

  const registerSchema = object(schema)

  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: Object.keys(filter.values).length > 0 ? filter.values : undefined
  })

  const { watch, reset, setValue, formState } = methods

  useEffect(() => {
    const subscription = watch((data) => {
      filter.setValues(data)
    })
    return () => subscription.unsubscribe()
  }, [watch])

  useEffect(() => {
    const subscription = filter.watchClear(() => {
      reset()
    })
    return () => subscription.unsubscribe()
  }, [filter.watchClear])

  useEffect(() => {
    const subscription = filter.watchRemove((name: string) => {
      const f = filter.settings.find(s => s.name === name)?.filter
      if (!f) return
      const dv = getProperty(filter.defaultValues, name)
      if (['range', 'dateRange'].includes(f)) {

        const dv = getProperty(filter.defaultValues, name)

        setValue(name + '.begin' as never, (dv?.begin ? dv?.begin : '') as never)
        setValue(name + '.end' as never, (dv?.end ? dv?.end : '') as never)

      } else {
        setValue(name as never, dv as never)
      }
    })
    return () => subscription.unsubscribe()
  }, [filter.watchRemove])

  return(
    <Box>
      <FormProvider {...methods}>
        <Box id={'documents-filter'} component="form" noValidate autoComplete="off">
          <Stack direction={'column'} spacing={2}>
            {filter.settings.map(fs => (
              <Box key={fs.name}>
                <Card>
                  <CardContent sx={{pt: 0.7, pb: 0.7, ':last-child': {pb: 0.7}}}>
                    {filters[fs.filter](fs)}
                  </CardContent>
                </Card>
              </Box>
            ))}
          </Stack>
        </Box>
      </FormProvider>
    </Box>
  )
}
