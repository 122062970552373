import { useId, useState } from 'react'
import { toast } from 'react-toastify'
import { Add } from '@mui/icons-material'
import { FormDialog } from '../../../common/FormDialog'
import * as React from 'react'
import { parseError } from '../../../../api'
import Item3ExtDocForm from './Item3ExtDocForm'
import { IDocument } from '../../../../types/document'
import { useAddExtDocMutation } from '../../../../store/document'
import { LightButton } from '../../../common/LightButton'

const AddItem3ExtDocDialog = ({
  item,
  type,
  onAdd,
}: {
  item: IDocument
  type: 'isp' | 'result'
  onAdd: () => void
}) => {
  const id = useId()
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [addExtDoc] = useAddExtDocMutation()

  const handleSubmit = (values: any, methods: any) => {
    const body = {
      document_id: item.id,
      type,
      ...values,
    }

    setIsLoading(true)
    addExtDoc({ buildingId: item.building_id, body })
      .unwrap()
      .then(() => {
        methods.reset()
        setOpen(false)
        setIsLoading(false)
        toast.success('Документ добавлен')
      })
      .catch(async (e) => {
        const error = await parseError(e)
        toast.error('Ошибка: ' + error)
        setIsLoading(false)
      })
  }

  return (
    <>
      <LightButton startIcon={<Add />} onClick={() => setOpen(true)}>
        Добавить
      </LightButton>
      <FormDialog
        formId={id}
        open={open}
        onClose={() => setOpen(false)}
        title={'Добавить документ'}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <Item3ExtDocForm type={type} item={item} id={id} onSubmit={handleSubmit} />
      </FormDialog>
    </>
  )
}

export default AddItem3ExtDocDialog
