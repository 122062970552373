import { IDocument } from '../../../../types/document'
import { useGetEmployeesQuery } from '../../../../store/employees'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  TableBody,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  styled,
} from '@mui/material'
import { StatusChip } from '../../../common/StatusChip'
import { DataRow } from '../../../common/DataRow'
import { formatDate } from '../../../../util/date'
import * as React from 'react'
import { TCompany, TEmployee } from '../../../../types/building'
import { Part6Dialog } from './Part6Dialog'
import { EmployeeDialog } from './EmployeeDialog'
import { Delete } from '@mui/icons-material'
import { useGetCompaniesQuery } from '../../../../store/companies'
import { useRemoveEmployeeMutation } from '../../../../store/document'
import { toastError } from '../../../../util/toastError'
import { SignButton } from '../../../document/SignButton'
import { SignDocumentButton } from '../../../document/SignDocumentButton'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const signerName = (employees: TEmployee[], employee_id: string): string => {
  return employees.find((em) => em.id === employee_id)?.profile.name.full_name || ''
}

const employeeCompanyName = (employee: TEmployee, companies: TCompany[]) => {
  return companies.find((c) => c.id === employee.company_id)?.name || ''
}

type Props = {
  document: IDocument
  onChange: () => void
  isManage: boolean
}
const Part6View = ({ document, isManage, onChange }: Props) => {
  const { data: employees } = useGetEmployeesQuery(document.building_id)
  const { data: companies } = useGetCompaniesQuery(document.building_id)
  const [removeEmployee] = useRemoveEmployeeMutation()

  const removeItemEmployeeHandler = (id: string) => {
    removeEmployee({
      buildingId: document.building_id,
      body: {
        document_id: document.id,
        document_employee_id: id,
      },
    })
      .unwrap()
      .then((res) => {
        onChange()
      })
      .catch(toastError)
  }

  return (
    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems={'flex-start'}>
      <Card>
        <CardHeader
          titleTypographyProps={{ variant: 'body1', sx: { fontWeight: 'medium' } }}
          title={'Документ'}
        />
        <CardContent>
          <DataRow
            label={'Документ'}
            text={
              `${document.data.name} ${document.data.number} от ` + formatDate(document.data.date)
            }
          />
          <DataRow label={'Предмет документирования'} text={document.data.docSubject} />
        </CardContent>
        <CardContent>
          <Stack direction={'row'} spacing={1}>
            {isManage && <Part6Dialog buildingId={document.building_id} document={document} />}
            <SignDocumentButton document={document} onReady={onChange} onSign={onChange} />
          </Stack>
        </CardContent>
      </Card>
      <Card variant={'elevation'} elevation={0} sx={{ mb: 2 }}>
        <CardHeader
          titleTypographyProps={{ variant: 'body1', sx: { fontWeight: 'medium' } }}
          title={`Список подписантов документа`}
        />
        <CardContent sx={{ p: 0 }}>
          <Table>
            <TableBody>
              {employees &&
                companies &&
                document.employees.signer &&
                document.employees.signer.map((docEmployee) => {
                  const employee = employees.find((e) => e.id === docEmployee.employee_id)
                  if (!employee) return ''
                  return (
                    <StyledTableRow key={docEmployee.id}>
                      <TableCell>
                        <Typography>{employee.profile.name.full_name}</Typography>
                        <Typography variant="caption" sx={{ lineHeight: 1.5 }}>
                          {employeeCompanyName(employee, companies)}
                        </Typography>
                      </TableCell>
                      {isManage && (
                        <TableCell align="right">
                          <IconButton
                            color="error"
                            title="Удалить"
                            onClick={() => removeItemEmployeeHandler(docEmployee.id)}
                          >
                            <Delete />
                          </IconButton>
                        </TableCell>
                      )}
                    </StyledTableRow>
                  )
                })}
            </TableBody>
          </Table>
        </CardContent>
        {isManage && (
          <CardContent>
            <EmployeeDialog item={document} onAdd={onChange}></EmployeeDialog>
          </CardContent>
        )}
      </Card>
    </Stack>
  )
}

export { Part6View }
